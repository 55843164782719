<template>
    <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Recent Payments Queue</h4>
                    <ButtonNavigation />

                </div>
                <div class="card-body">
                    <form method="POST" class="needs-validation" novalidate="" autocomplete="off" @submit.prevent="createEmailTemplateMethod">
                        <div class="row">
                            <div class="col-md-6 offset-md-3">
                                <div class="mb-3 row">
                                    <ErrorModalMessage v-if="error.isShow" :error="error" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="mb-3">
                                    <label class="form-label">{{ $t('com.email.template.name') }}</label>
                                    <input v-model="emailTemplate.name" type="text" class="form-control" :placeholder="$t('com.email.template.name')" :disabled="loading" />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">{{ $t('com.email.template.description') }}</label>
                                    <input v-model="emailTemplate.description" type="text" class="form-control" :placeholder="$t('com.email.template.description')" :disabled="loading" />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">{{ $t('com.email.template.language') }}</label>
                                    <VueMultiselect v-model="emailTemplate.language"
                                                    :options="languages"
                                                    :searchable="true"
                                                    :placeholder="$t('com.email.template.language')"
                                                    :multiple="false"
                                                    :custom-label="nameWithLang"
                                                    track-by="id"
                                                    :disabled="loading"
                                                    :showLabels="false"
                                                    :allow-empty="false"
                                    />
                                    <pre class="language-json"><code>{{ emailTemplate.language }}</code></pre>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="row">
                                    <div class="col-md-12">
                                        <p>Allowed variables</p>
                                        <span>{token}</span>
                                        <span>{linkWithToken}</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <editor v-model="emailTemplate.body" />
                                    </div>
                                </div>


                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <button type="submit" class="btn btn-primary" :disabled="loading">
                                        <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        {{ $t('com.global.save') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
</template>

<script charset="utf-8">
    import PageTitle from "../../../components/common/PageTitle.vue";
    import Editor from "../../../components/common/editor/Editor.vue";
    import ButtonNavigation from "../../../components/email/template/ButtonNavigation.vue";
    import I18nService from "../../../services/i18n.service";
    import EmailTemplateService from "../../../services/email.template.service";
    import ErrorModalMessage from "../../../components/common/error/ErrorModalMessage";
    import VueMultiselect from 'vue-multiselect'
    export default {
        components: {
            PageTitle,
            ButtonNavigation,
            VueMultiselect,
            Editor,
            ErrorModalMessage
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "Email Templates Details",
                subTitle: "com.email.template.subtitle",
                emailTemplate: {
                    id: -1,
                    abbr: "",
                    name: "",
                    description: "",
                    language: {},
                    body: "",
                    modifications: -1,
                    author: []
                },
                languages: [],
                error: {
                    isShow: false,
                    header: "test",
                    message: "",
                    errors: []
                },
                loading: false,
            };
        },
        computed: {
            emailTemplateId() {
                return this.$route.params.id;
            }
        },
        watch: {

        },
        mounted() {
            this.fetchEmailTemplate();
            this.fetchLanguages();
        },
        methods: {
            async fetchEmailTemplate() {
                try {
                    this.loading = true;

                    let response = (await EmailTemplateService.GetEmailTemplateById(this.emailTemplateId));

                    if (response.status == 200) {
                        this.emailTemplate = response.data;
                    }

                   
                }
                catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'LoginView' })

                    if (e.response.status == 400) {
                        this.error.isShow = true;
                        this.error.message = e.response.data.message
                    }
                }
                finally {
                    this.loading = false;
                }
            },
            async createEmailTemplateMethod() {
                try {
                    this.loading = true;
                    this.clearError();
                    const req = {
                        id: this.emailTemplate.id,
                        name: this.emailTemplate.name,
                        description: this.emailTemplate.description,
                        body: this.emailTemplate.body,
                        languageID: this.emailTemplate.language.id
                    };

                    let response = (await EmailTemplateService.UpdateEmailTemplate(req))
                    if (response.status == 200) {
                        this.$toast.success(this.$t('com.email.template.successupdated'));
                        //this.clearData();
                    }
                }
                catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'LoginView' })

                    if (e.response.status == 400) {
                        this.error.isShow = true;
                        this.error.message = e.response.data.message
                    }
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'NotFound404' })
                    }
                }
                finally {
                    this.loading = false;
                }
            },
            async fetchLanguages() {
                try {
                    const req = {
                        PageNumber: 1,
                        ItemsOnPage: 100,
                    }
                    this.languages = (await I18nService.GetLanguages(req)).data.languageItems

                }
                catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'LoginView' })
                }
            },
            nameWithLang(obj) {
                if (obj === undefined)
                    return "test";

                return obj.name + " (" + obj.culture + ")"
            },
            clearError() {
                this.error.isShow = false;
                this.error.header = "test";
                this.error.essage = "";
                this.error.errors = []
            },
            clearData() {
                this.emailTemplate.name = "";
                this.emailTemplate.description = "";
                this.emailTemplate.language = {};
                this.emailTemplate.body = "";
            },
        }
    }
</script>

<style type="text/css">
</style>
