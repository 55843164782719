<template>
    <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Recent Payments Queue</h4>
                    <div class="btn-group">
                        <router-link :to="{ name: 'CustomersBin' }" type="button" class="btn btn-primary">
                            {{ $t("com.global.bin") }}
                        </router-link>
                        <router-link :to="{ name: 'CustomersList' }" type="button" class="btn btn-primary">
                            {{ $t("com.global.list") }}
                        </router-link>
                        <router-link :to="{ name: 'CustomerCreate' }" type="button" class="btn btn-primary">
                            {{ $t('com.global.add') }}
                        </router-link>
                    </div>

                </div>
                <div class="card-body">
                    <form method="POST" class="needs-validation" novalidate="" autocomplete="off" @submit.prevent="createCustomerMethod">
                        <div class="row">
                            <div class="col-md-6 offset-md-3">
                                <div class="mb-3 row">
                                    <ErrorModalMessage v-if="error.isShow" :error="error" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label">{{ $t('com.customer.name') }}</label>
                                    <input v-model="createCustomer.name" type="text" class="form-control" :placeholder="$t('com.customer.name')" />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">{{ $t('com.customer.email') }}</label>
                                    <input v-model="createCustomer.email" type="text" class="form-control" :placeholder="$t('com.customer.email')" />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">{{ $t('com.customer.phone') }}</label>
                                    <input v-model="createCustomer.phone" type="text" class="form-control" :placeholder="$t('com.customer.phone')" />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">{{ $t('com.customer.country') }}</label>
                                    <VueMultiselect v-model="createCustomer.country"
                                                    :options="countries"
                                                    :searchable="true"
                                                    :multiple="false"
                                                    label="name"
                                                    track-by="id"
                                                    :showLabels="false"
                                                    :allow-empty="false"
                                    />
                                    <pre class="language-json"><code>{{ createCustomer.country }}</code></pre>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">{{ $t('com.customer.language') }}</label>
                                    <VueMultiselect v-model="createCustomer.language"
                                                    :options="languages"
                                                    :searchable="true"
                                                    :placeholder="$t('com.customer.language')"
                                                    :multiple="false"
                                                    :custom-label="nameWithLang"
                                                    track-by="id"
                                                    :showLabels="false"
                                                    :allow-empty="false"
                                    />
                                    <pre class="language-json"><code>{{ createCustomer.language }}</code></pre>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label">{{ $t('com.customer.description') }}</label>
                                    <textarea v-model="createCustomer.description" class="form-control" :placeholder="$t('com.customer.description')" rows="7"></textarea>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">{{ $t('com.customer.city') }}</label>
                                    <input v-model="createCustomer.city" type="text" class="form-control" :placeholder="$t('com.customer.city')" />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">{{ $t('com.customer.street') }}</label>
                                    <input v-model="createCustomer.street" type="text" class="form-control" :placeholder="$t('com.customer.street')" />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">{{ $t('com.customer.postalcode') }}</label>
                                    <input v-model="createCustomer.postalcode" type="text" class="form-control" :placeholder="$t('com.customer.city')" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <button type="submit" class="btn btn-primary">{{ $t('com.global.save') }}</button>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
</template>

<script charset="utf-8">
    import PageTitle from "../../../components/common/PageTitle.vue";
    import ErrorModalMessage from "../../../components/common/error/ErrorModalMessage";
    import VueMultiselect from 'vue-multiselect'
    export default {
        components: {
            PageTitle,
            VueMultiselect,
            ErrorModalMessage
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "Customers Create",
                subTitle: "com.customers.subtitle",
                countries: [],
                languages: [],
                createCustomer: {
                    name: "",
                    description: "",
                    phone: "",
                    email: "",
                    city: "",
                    street: "",
                    postalcode: "",
                    country: {},
                    language: {},
                },
                error: {
                    isShow: false,
                    header: "test",
                    message: "",
                }
            };
        },
        computed: {

        },
        watch: {

        },
        mounted() {
            this.fetchCountries();
            this.fetchLanguages();
        },
        methods: {
            async createCustomerMethod() {
                this.error.isShow = false;
                try {
                    const req = {
                        name: this.createCustomer.name,
                        description: this.createCustomer.description,
                        phone: this.createCustomer.phone,
                        email: this.createCustomer.email,
                        city: this.createCustomer.city,
                        street: this.createCustomer.street,
                        postalcode: this.createCustomer.postalcode,
                        countryid: this.createCustomer.country.id,
                        languageid: this.createCustomer.language.id,
                    }
                    this.$store.dispatch("customer/createCustomer", req).then(
                        () => {
                            this.$toast.success(this.$t('com.customer.successcreate'));
                        },
                        (error) => {
                            this.error.isShow = true;
                            this.error.header = "header";
                            this.error.message = error.data.translatedMessage;
                            console.log(error)
                        }
                    );
                }
                catch (err) {
                    console.log(err);
                }
                //console.log(this.createCustomer)
            },
            async fetchCountries() {
                const req = {
                    PageNumber: 1,
                    ItemsOnPage: 1000,
                }
                this.$store.dispatch("i18n/getCountries", req).then(
                    (res) => {
                        this.countries = res.data.countries;
                        console.log(this.countries)
                    },
                    (error) => {
                        console.log(error)
                    }
                );

            },
            async fetchLanguages() {
                const req = {
                    PageNumber: 1,
                    ItemsOnPage: 100,
                }
                this.$store.dispatch("i18n/getLanguages", req).then(
                    (res) => {
                        this.languages = res.data.languageItems;
                        console.log(this.languages);
                    },
                    (error) => {
                        console.log(error)
                    }
                );
            },
            nameWithLang(obj) {
                if (obj === undefined)
                    return "test";

                return obj.name + " (" + obj.culture + ")"
            }
        }
    }</script>

<style>
</style>
