import api from "./api";
import url from "./url";

class AppSettingValueService {
    GetAll(Pages) {
        return api.get(url.app.setting.value.getAll, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage
            }
        })
            .then((res) => {
                return res;
            })
    }
    GetById(AppValueID) {
        return api.get(url.app.setting.value.getById + "/" + AppValueID)
            .then((res) => {
                return res;
            })
    }
    GetAvailableOptionsByKeyId(KeyID) {
        return api.get(url.app.setting.value.getAvailableOptionsByKeyId + "/" + KeyID)
            .then((res) => {
                return res;
            })
    }
    Create(request) {
        const req = {
            Value: request.value,
            SettingID: request.settingID
        }
        return api.post(url.app.setting.value.create, req)
            .then((response) => {
                return response;
            });
    }
    Update(request) {
        const req = {
            Id: request.id,
            Value: request.value,
            SettingID: request.settingID
        }
        return api.post(url.app.setting.value.update, req)
            .then((response) => {
                return response;
            });
    }
    Delete(AppValueID) {
        return api.delete(url.app.setting.value.delete + "/" + AppValueID)
            .then((res) => {
                return res;
            })
    }
}

export default new AppSettingValueService;