<template>
    <router-view />
</template>

<script charset="utf-8">
    export default {
        components: {
        },
        data() {
            return {
                publicPath: process.env.BASE_URL
            };
        },
        computed: {

        },
        methods: {
        }
    }
</script>
