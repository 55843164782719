<template>
    <div class="position-absolute w-100 h-100 d-flex flex-column align-items-center bg-white justify-content-center quiz-app-element-loader">
        <div :class="GetClasses()" :style="GetLoaderSize()" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</template>


<script>
    export default {
        components: {
        },
        props: {
            type: {
                type: String,
                require: true,
                default: function () {
                    return "spinner-border"
                }
            },
            color: {
                type: String,
                require: true,
                default: function () {
                    return "text-dark"
                }
            },
            size: {
                type: String,
                require: false,
                default: function () {
                    return "small"
                }
            }
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
/*                classList: '',*/
            };
        },
        computed: {
           
        },
        methods: {
            GetClasses() {
                return this.type + " " + this.color;
            },

            GetLoaderSize() {
                let customStyle = "width: 2rem; height: 2rem;";

                if (this.size == "medium") {
                    customStyle = "width: 3rem; height: 3rem;";
                }

                else if (this.size == "big") {
                    customStyle = "width: 4rem; height: 4rem;";
                }

                return customStyle;
            }
        }
    }
</script>

<style type="text/css">
    .quiz-app-element-loader{
        opacity: 0.75;
    }
</style>
