<template>
    <!--<PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />-->
    <router-view />



</template>

<script charset="utf-8">

    //import PageTitle from "../../components/common/PageTitle.vue";

    export default {
        components: {
            /*PageTitle, */
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "com.account",
                subTitle: "com.account.subtitle",
            };
        },
        computed: {

        },

        mounted() {
            //this.FetchRerouces();
        },
        methods: {

        }
    }</script>

<style>
</style>
