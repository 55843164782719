<template>
    <div class="col-4">
        
        <span class="text-muted">{{ $t('com.pagination.info', { start: getStartValue, end: getEndValue, total: pages.totalItems }) }}</span>
    </div>
    <div class="col-4 ms-auto">
        <div class="float-end">
            <nav>
                <ul class="pagination pagination-circle pagination-info" v-if="pages.totalPage < maxPageShow">
                    <li class="page-item page-indicator">
                        <a class="page-link" href="javascript:void(0)" @click="pageClick(1)" :title="$t('com.pagination.firstpage')">
                            <i class="la la-angle-left"></i>
                        </a>
                    </li>

                    <li v-for="n in pages.totalPage" :key="n" class="page-item" :class="n==pages.currentPage ? 'active':''">
                        <a class="page-link" href="javascript:void(0)" @click="pageClick(n)" :title="$t('com.pagination.goto', { page: n } )">{{ n }}</a>
                    </li>
                    <li class="page-item page-indicator">
                        <a class="page-link" href="javascript:void(0)" @click="pageClick(pages.totalPage)" :title="$t('com.pagination.lastpage')">
                            <i class="la la-angle-right"></i>
                        </a>
                    </li>
                </ul>
                <ul class="pagination pagination-circle pagination-info" v-else>
                    <li class="page-item page-indicator">
                        <a class="page-link" href="javascript:void(0)" @click="pageClick(1)" :title="$t('com.pagination.firstpage')">
                            <i class="la la-angle-left"></i>
                        </a>
                    </li>

                    <li v-for="n in getMinizedPageList()" :key="n" class="page-item" :class="n==pages.currentPage ? 'active':''">
                        <a class="page-link" href="javascript:void(0)" @click="pageClick(n)" :title="$t('com.pagination.goto', { page: n } )">{{ n }}</a>
                    </li>
                    <li class="page-item page-indicator">
                        <a class="page-link" href="javascript:void(0)" @click="pageClick(pages.totalPage)" :title="$t('com.pagination.lastpage')">
                            <i class="la la-angle-right"></i>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>

    export default {
        components: {
        },
        props: {
            pages: {
                type: Object,
                require: true,
                default: function () {
                    return {
                        currentPage: 0,
                        totalItems: 0,
                        totalPage: 0,
                    }
                }
            }
        },
        emits: ["page-request"],
        data() {
            return {
                publicPath: process.env.BASE_URL,

                pageOffset: 3,
                maxPageShow: 6,
            };
        },
        computed: {
            getStartValue() {
                if ((this.pages.currentPage - 1) > 0) {
                    return (this.pages.currentPage - 1) * 10 + 1;
                }
                return 1;


                
            },
            getEndValue() {
                if ((this.pages.currentPage * 10) > this.pages.totalItems) {
                    return this.pages.totalItems
                }
                 

                return this.pages.currentPage * 10;
            }
        },
        methods: {
            pageClick(page) {
                this.$emit("page-request", page)
            },

            getMinizedPageList() {
                let arr = [];


                let start = this.pages.currentPage - this.pageOffset
                let end = this.pages.currentPage + this.pageOffset;
                if (start < 1)
                {
                    start = 1;
                }
                    
                if (end > this.pages.totalPage)
                    end = this.pages.totalPage;

                if ((end - start) < this.maxPageShow) {

                    if ((this.pages.currentPage - this.pageOffset) < 1) {
                        end += Math.abs(this.pages.currentPage - this.pageOffset)+1;
                    }

                    if ((this.pages.currentPage + this.pageOffset) > this.pages.totalPage) {
                        start -= this.pages.currentPage + this.pageOffset - end
                    }
                }


                for (let i = start; i <= end; i++) {
                    arr.push(i);
                }

                return arr;
            }
        }
    }</script>

<style type="text/css">
    .pagination .page-item.active .page-link {
        background: #4885ed !important;
        border-color: #4885ed !important;
    }
</style>
