import { createStore } from "vuex";
import { auth } from './modules/auth';
import { i18n } from './modules/i18n';
import { user } from './modules/user';
import { role } from './modules/role';
import { customer } from './modules/customer';
import { group } from './modules/group';

const store = createStore({
    modules: {
        auth,
        i18n,
        user,
        role,
        customer,
        group
    }
});

export default store;