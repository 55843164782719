<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ $t('com.interactive.users.online') }}</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <p v-for="(group, groupIndex) in users" :key="groupIndex">
                                {{ group.group.name }}
                                <ul class="list-group">
                                    <li v-for="(user, userIndex) in group.users" :key="userIndex" class="list-group-item d-flex justify-content-between align-items-start">
                                        <span class="position-absolute top-50 start-0 translate-middle p-2 bg-danger border border-light rounded-circle" v-if="user.state == 0">
                                            <span class="visually-hidden">Offline</span>
                                        </span>
                                        <span class="position-absolute top-50 start-0 translate-middle p-2 bg-success border border-light rounded-circle" v-else-if="user.state == 10">
                                            <span class="visually-hidden">Online</span>
                                        </span>
                                        <div class="ms-2 me-auto">
                                            <div class="fw-bold">{{ user.user.firstname }} {{ user.user.lastname }}</div>
                                            {{ user.user.eMail }}
                                            <div class="dropdown">
                                                <button :id="getDropdownID(userIndex)" type="button" class="btn btn-primary light sharp" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <circle fill="#000000" cx="5" cy="12" r="2" />
                                                            <circle fill="#000000" cx="12" cy="12" r="2" />
                                                            <circle fill="#000000" cx="19" cy="12" r="2" />
                                                        </g>
                                                    </svg>
                                                </button>
                                                <div class="dropdown-menu" :aria-labelledby="getDropdownID(userIndex)">
                                                    <router-link :to="{ name: 'AppSettingKeyEdit', params: { id: user.user.id } }" class="dropdown-item">{{ $t('com.global.edit') }}</router-link>
                                                    <router-link :to="{ name: 'AppSettingKeyDetail', params: { id: user.user.id } }" class="dropdown-item">{{ $t('com.global.details') }}</router-link>
                                                    
                                                </div>
                                            </div>
                                        </div>

                                    </li>
                                </ul>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script charset="utf-8">
    export default {
        components: {
        },
        props: {

            users: {
                type: Array,
                required: true
            },
        },
        emits: [""],

        data() {
            return {
                publicPath: process.env.BASE_URL,
                userData: [],
            }
        },
        watch: {
            users: {
                immediate: true,
                handler(val) {
                    this.userData = val;
                }
            }
        },
        mounted() {
        },

        methods: {
            getDropdownID(str) {
                return "dropdownButton" + str;
            },
        }
    }
</script>
<style type="text/css">
</style>