<template>
    <div class="alert alert-danger left-icon-big alert-dismissible fade show">
        <div class="media">
            <div class="alert-left-icon-big">
                <span><i class="mdi mdi-alert"></i></span>
            </div>
            <div class="media-body">
                <h5 class="mt-1 mb-2">{{ error.header }}</h5>
                <p class="mb-0">{{ error.message }}</p>
                <ul>
                    <li v-for="(item, index) in error.errors" :key="index" style="list-style:circle;">{{ item.message }}</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        components: {
        },
        props: {
            error: {
                type: Object,
                require: true,
                default: function () {
                    return {
                        header: "",
                        message: "",
                        errors: [],
                    }
                }
            }
        },
        data() {
            return {
                publicPath: process.env.BASE_URL
            };
        },
        computed: {

        },
        methods: {
        }
    }</script>

<style>
</style>
