<template>
    <div class="btn-group">
        <router-link :to="{ name: 'QuizVoteBlockBin' }" type="button" class="btn btn-primary">
            {{ $t("com.global.bin") }}
        </router-link>
        <router-link :to="{ name: 'QuizVoteBlockListMy' }" type="button" class="btn btn-primary">
            {{ $t("com.global.list") }}
        </router-link>
        <router-link :to="{name: 'QuizVoteBlockCreate'}" type="button" class="btn btn-primary">
            {{ $t('com.global.add') }}
        </router-link>
    </div>


</template>

<script charset="utf-8">export default {
        components: {
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
            };
        },
        computed: {

        },
        watch: {

        },
        mounted() {

        },
        methods: {

        }
    }</script>

<style>
</style>
