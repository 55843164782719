<template>
    <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Recent Payments Queue</h4>
                    <ButtonNavigation />
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <div class="col-12">
                                    <h5>{{ $t('com.app.setting.key.name') }}</h5>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-12 ">
                                        {{ appSetting.name }}
                                    </div>
                                </div>
                            </div>
                            <div class="row">

                                <div class="col-12">
                                    <h5>{{ $t('com.app.setting.key.description') }}</h5>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12 ">
                                    {{ appSetting.description }}
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-12">
                                    <h5>{{ $t('com.app.setting.key.defaultValue') }}</h5>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12 ">
                                    {{ appSetting.defaultValue }}
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <h5>{{ $t('com.app.setting.key.settingtype') }}</h5>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12 ">
                                    {{ appSetting.stringSettingType }}
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <h5>{{ $t('com.app.setting.key.variabletype') }}</h5>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12 ">
                                    {{ appSetting.stringType }}
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <h5>{{ $t('com.app.setting.key.isrequied') }}</h5>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12 ">
                                    {{ appSetting.isRequired }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <h5>{{ $t('com.app.setting.key.allowmultiple') }}</h5>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12 ">
                                    {{ appSetting.allowMultiple }}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div v-if="enableAddValue()" class="row">
                                <div class="col-md-12">
                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <button type="submit" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addSettingValue">
                                            <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            {{ $t('com.app.setting.key.addvalue') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <table class="table table-responsive-md">
                                    <thead>
                                        <tr>
                                            <th style="width:80px;"><strong>#</strong></th>
                                            <th><strong>{{ $t('com.app.setting.value.name') }}</strong></th>
                                            <th><strong>{{ $t('com.app.setting.value.author') }}</strong></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(settingValue, index) in appSetting.values" :key="index">
                                            <td>{{ getIndex(index) }}</td>
                                            <td>{{ settingValue.details.name }}</td>
                                            <td>{{ settingValue.lastEditor.firstname }} {{ settingValue.lastEditor.lastname }}</td>
                                            <td>
                                                <div class="dropdown">
                                                    <button :id="getDropdownID(index)" type="button" class="btn btn-primary light sharp" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <rect x="0" y="0" width="24" height="24" />
                                                                <circle fill="#000000" cx="5" cy="12" r="2" />
                                                                <circle fill="#000000" cx="12" cy="12" r="2" />
                                                                <circle fill="#000000" cx="19" cy="12" r="2" />
                                                            </g>
                                                        </svg>
                                                    </button>
                                                    <div class="dropdown-menu" :aria-labelledby="getDropdownID(index)">
                                                        <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modalItemDelete" @click="setSettingValue(settingValue)">{{ $t('com.global.delete') }}</a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ModalAddSettingValue :error="errorModalEdit"
                          :closeModal="closeModal"
                          :appSettingID="appSetting.id"
                          @add-setting-value="addSettingValue"
    />

    <ModalDelete :error="errorModalEdit"
                 :deletedItem="deletedItem"
                 :closeModal="closeModal"
                 @unset-item="unsetSettingValue()"
                 @confirm-delete="deleteSettingValue()"
    />
</template>

<script charset="utf-8">
    import PageTitle from "../../../../components/common/PageTitle.vue";
    import ButtonNavigation from "../../../../components/app/setting/key/ButtonNavigation.vue";
    import ModalAddSettingValue from "../../../../components/app/setting/key/ModalAddSettingValue.vue";
    import EmailTemplateService from "../../../../services/email.template.service";
    import ModalDelete from "../../../../components/common/modal/ModalDelete.vue"
    import AppSettingKeyService from "../../../../services/app.setting.key.service";
    import AppSettingValueService from "../../../../services/app.setting.value.service";
    export default {
        components: {
            PageTitle,
            ButtonNavigation,
            ModalDelete,
            ModalAddSettingValue
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "App Setting Key Detail",
                subTitle: "com.app.setting.key.subtitle",
                appSetting: {
                    id: 0,
                    name: "",
                    description: "",
                    defaultValue: "",
                    isRequired: "",
                    allowMultiple: "",
                    stringType: "",
                    stringSettingType: "",
                    values: [],
                },
                selectedSettingValue: {},
                errorModalEdit: {
                    isShow: false,
                    header: "test",
                    message: "",
                },
                deletedItem: "",
                closeModal: false,
                loading: false
            };
        },
        computed: {
            appSettingKeyId() {
                return this.$route.params.id;
            }
        },
        watch: {

        },
        mounted() {
            //this.fetchEmailTemplates(this.getCurrentPage(), this.getCurrentItemsOnPage());
            this.fetchAppSettingKey();
        },
        methods: {

            async fetchAppSettingKey() {
                try {
                    this.loading = true;

                    let res = (await AppSettingKeyService.GetById(this.appSettingKeyId))
                    if (res.status == 200) {
                        this.closeModal = false;
                        this.appSetting = res.data;
                        console.log(this.appSetting);
                    }

                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },

            async fetchEmailTemplates(page = 1, items = 10) {
                try {
                    const req = {
                        PageNumber: page,
                        ItemsOnPage: items,
                    }
                    let data = (await EmailTemplateService.GetEmailTemplates(req)).data;
                    this.emailTemplates = data.emailTemplates;
                    this.pages = data.page
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
            },
            async deleteSettingValue() {
                try {
                    let res = (await AppSettingValueService.Delete(this.selectedSettingValue.id))

                    if (res.status == 200) {
                        this.appSetting.id = -1;
                        this.closeModal = true;
                        this.$toast.success("com.app.setting.value.successdelete");
                        this.fetchAppSettingKey();
                    }
               }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
            },
            async addSettingValue(selectedItem) {
                try {
                    const req = {
                        value: String(selectedItem.settingValue.id),
                        settingID: this.appSettingKeyId
                    };

                    let res = (await AppSettingValueService.Create(req))
                    if (res.status == 200) {
                        this.closeModal = true;
                        this.$toast.success(this.$t('com.app.setting.value.successadd'));
                        this.fetchAppSettingKey();
                        
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
            },
            getCurrentPage() {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;
                return page;
            },
            getCurrentItemsOnPage() {
                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 10;
                if (itemsPerPage == 0)
                    itemsPerPage = 10;
                return itemsPerPage
            },
            getIndex(index) {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;

                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 0;

                return ((page - 1) * itemsPerPage) + index + 1
            },
            getDropdownID(str) {
                return "dropdownButton" + str;
            },
            setSettingValue(settingValue) {

                this.closeModal = false;
                this.selectedSettingValue = settingValue
            },
            unsetSettingValue() {
                this.selectedSettingValue = {}
            },

            enableAddValue() {
                if (this.appSetting.allowMultiple)
                    return true;

                console.log("Ilośc ustawień: {0}", this.appSetting.values.length);
                if (this.appSetting.values.length < 1)
                    return true;
                return false
            }
        }
    }</script>

<style>
</style>
