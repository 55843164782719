<template>
    <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Recent Payments Queue</h4>
                    <div class="btn-group">
                        <router-link :to="{ name: 'CustomersBin' }" type="button" class="btn btn-primary">
                            {{ $t("com.global.bin") }}
                        </router-link>
                        <router-link :to="{ name: 'CustomersList' }" type="button" class="btn btn-primary">
                            {{ $t("com.global.list") }}
                        </router-link>
                        <router-link :to="{ name: 'CustomerEdit', params: { id: customerID } }" type="button" class="btn btn-primary">
                            {{ $t("com.global.edit") }}
                        </router-link>
                        <router-link :to="{name: 'CustomerCreate'}" type="button" class="btn btn-primary">
                            {{ $t('com.global.add') }}
                        </router-link>
                    </div>

                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-12">
                                    <h5>{{ $t('com.customer.name') }}</h5>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12 ">
                                    {{ detailCustomer.name }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <h5>{{ $t('com.customer.email') }}</h5>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12 ">
                                    {{ detailCustomer.eMail }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <h5>{{ $t('com.customer.phone') }}</h5>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12 ">
                                    {{ detailCustomer.phone }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <h5>{{ $t('com.customer.country') }}</h5>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12 ">
                                    {{ detailCustomer.country.name }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <h5>{{ $t('com.customer.language') }}</h5>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12 ">
                                    {{ detailCustomer.language.name }} ({{ detailCustomer.language.culture }} )
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <h5>{{ $t('com.customer.description') }}</h5>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12 ">
                                    {{ detailCustomer.description }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <h5>{{ $t('com.customer.address') }}</h5>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12 ">
                                    {{ detailCustomer.postalCode }} {{ detailCustomer.citty }}, {{ detailCustomer.street }}
                                </div>
                            </div>
                            
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script charset="utf-8">
    import PageTitle from "../../../components/common/PageTitle.vue";
    export default {
        components: {
            PageTitle
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "Customers Detail",
                subTitle: "com.customers.subtitle",
                detailCustomer: {
                    id: "",
                    name: "",
                    description: "",
                    phone: "",
                    eMail: "",
                    citty: "",
                    street: "",
                    postalCode: "",
                    country: {
                        id: "",
                        abbr: "",
                        name: "",
                    },
                    language: {
                        culture: "",
                        description: "",
                        id: "",
                        name: "",
                        shortCulture: ""
                    }
                }
            };
        },
        computed: {
            customerID() {
                return this.$route.params.id;
            }
        },
        watch: {

        },
        mounted() {
            this.fetchCustomer();
        },
        methods: {
            async fetchCustomer() {
                try {
                    this.$store.dispatch("customer/getCustomerById", this.customerID).then(
                        (res) => {
                            this.detailCustomer = res.data;
                            console.log("detailCustomer", this.detailCustomer);
                        },
                        (error) => {
                            console.log("tutak", error)
                            if (error.status == 400) {
                                console.log(error.data.message);
                            }
                        }
                    );
                }
                catch (err) {
                    console.log("Tutaj", err);

                }
            },
        }
    }</script>

<style>
</style>
