import api from "../api";
import url from "../url";

class SettingTypeProviderService {
    GetAll() {
        return api.get(url.app.setting.provider.getAll)
            .then((res) => {
                return res;
            })
    }

}

export default new SettingTypeProviderService;