<template>
    <div class="row mb-3">
        <div class="col-6">
            <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
        </div>
        <div class="col-6">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <EventSettingButtonNavigation />
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ subTitle }}</h4>
                </div>
                <div class="card-body">
                    <form method="post" class="needs-validation" novalidate="" autocomplete="off" @submit.prevent="createEventSettingKey">
                        <div class="row">
                            <div class="col-6 offset-3">
                                <div class="mb-3 row">
                                    <ErrorModalMessage v-if="error.isShow" :error="error" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.event.setting.key.name') }}</strong></label>
                                    <input v-model="eventSetting.name" type="text" class="form-control" :placeholder="$t('com.event.setting.key.name')" :disabled="loading.global" />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.event.setting.key.description') }}</strong></label>
                                    <input v-model="eventSetting.description" type="text" class="form-control" :placeholder="$t('com.event.setting.key.description')" :disabled="loading.global" />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.event.setting.key.defaultValue') }}</strong></label>
                                    <input v-model="eventSetting.defaultValue" type="text" class="form-control" :placeholder="$t('com.event.setting.key.defaultvalue')" :disabled="loading.global" />
                                </div>

                            </div>
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.event.setting.key.variabletype') }}</strong></label>
                                    <VueMultiselect v-model="eventSetting.variableType"
                                                    :options="variableTypes"
                                                    :searchable="true"
                                                    :placeholder="$t('com.event.setting.key.variabletype')"
                                                    :multiple="false"
                                                    label="name"
                                                    track-by="value"
                                                    :disabled="loading.global"
                                                    :showLabels="false"
                                                    :allow-empty="false" />
                                    <pre class="language-json"><code>{{ eventSetting.variableType }}</code></pre>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.event.setting.key.isrequied') }}</strong></label>
                                    <VueMultiselect v-model="eventSetting.isRequired"
                                                    :options="requiedOptions"
                                                    :searchable="true"
                                                    :placeholder="$t('com.event.setting.key.isrequied')"
                                                    :multiple="false"
                                                    :custom-label="translatedLabel"
                                                    label="name"
                                                    track-by="value"
                                                    :disabled="loading.global"
                                                    :showLabels="false"
                                                    :allow-empty="false" />
                                    <pre class="language-json"><code>{{ eventSetting.isRequired }}</code></pre>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.event.setting.key.type') }}</strong></label>
                                    <VueMultiselect v-model="eventSetting.settingType"
                                                    :options="settingTypes"
                                                    :searchable="true"
                                                    :placeholder="$t('com.event.setting.key.type')"
                                                    :multiple="false"
                                                    label="name"
                                                    track-by="id"
                                                    :disabled="loading.global"
                                                    :showLabels="false"
                                                    :allow-empty="false" />
                                    <pre class="language-json"><code>{{ eventSetting.settingType }}</code></pre>
                                </div>
                                <div class="mb-3">
                                    <SettingTypeSelectorByProvider :settingType="eventSetting.settingType" @selected-data="providerSelectedData" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <pre class="language-json"><code>{{ eventSetting }}</code></pre>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <button type="submit" class="btn btn-primary" :disabled="loading.global">
                                        <span v-if="loading.global" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        {{ $t('com.global.save') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script charset="utf-8">
    import PageTitle from "@/components/common/PageTitle.vue";
    import EventSettingButtonNavigation from "@/components/event/setting/EventSettingButtonNavigation.vue"
    import VueMultiselect from 'vue-multiselect'    
    import ErrorModalMessage from "@/components/common/error/ErrorModalMessage";
    import SettingTypeSelectorByProvider from "@/components/common/settingType/SettingTypesSelectorByProvider.vue";


    import EventSettingService from "@/services/event/event.setting.service";
    import VariableTypeService from "@/services/variabletype.service";
    import SettingTypeService from "@/services/common/setting.type.service.js"

    export default {
        components: {
            PageTitle,
            EventSettingButtonNavigation,
            VueMultiselect,
            ErrorModalMessage,
            SettingTypeSelectorByProvider
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "Event Setting",
                subTitle: "com.event.setting",

                variableTypes: [],

                requiedOptions: [
                    { name: "com.global.yes", value: true },
                    { name: "com.global.no", value: false },
                ],

                settingTypes: [],

                eventSetting: {
                    name: "",
                    description: "",
                    defaultValue: "",
                    isRequired: { name: "com.global.no", value: false },
                    variableType: {},
                    settingType: {},
                },
                error: {
                    isShow: false,
                    header: "test",
                    message: "",
                    errors: []
                },

                loading: {
                    variableType: false,
                    settingType: false,
                    global: false,
                }
            };
        },
        computed: {

        },
        watch: {

        },
        mounted() {
            this.fetchVariableTypes();
            this.fetchSettingTypes();
        },
        methods: {
            async fetchVariableTypes() {
                try {
                    this.loading.variableType = true;
                    let res = (await VariableTypeService.GetAll());
                    if (res.status == 200) {
                        this.variableTypes = res.data;
                        this.eventSetting.variableType = this.variableTypes[0];
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.variableType = false;
                }
            },

            async createEventSettingKey() {
                try {
                    this.loading.global = true;
                    this.clearError();
                    const req = {
                        name: this.eventSetting.name,
                        variableType: this.eventSetting.variableType.value,
                        defaultValue: this.eventSetting.defaultValue.toString(),
                        isRequired: this.eventSetting.isRequired.value,
                        typeID: this.eventSetting.settingType.id,
                    }

                    let res = (await EventSettingService.Create(req))

                    if (res.status == 200) {
                        this.$toast.success(this.$t("com.event.create.success"));
                        this.clearError();
                    }
                }
                catch (e) {
                    console.log(e)
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.global = false;
                }
            },

            async fetchSettingTypes() {
                try {
                    this.loading.settingType = true;

                    let res = (await SettingTypeService.GetAll())

                    if (res.status == 200) {
                        this.settingTypes = res.data;
                        this.eventSetting.settingType = this.settingTypes[0];
                    }
                }
                catch (e) {
                    console.log(e)
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.settingType = false;
                }
            },

            providerSelectedData(data) {
                this.eventSetting.defaultValue = data;
            },

            clearError() {
                this.error.isShow = false;
                this.error.message = "";
                this.error.errors = "";
            },
            clearData() {
                this.eventSetting = {
                    name: "",
                    description: "",
                    defaultValue: "",
                    isRequired: { name: "com.global.no", value: false },
                    variableType: {},
                };
            },

            translatedLabel(obj) {
                if (obj === undefined)
                    return "test";

                return this.$t(obj.name)
            }
        }
    }</script>

<style>
</style>
