<template>
    <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Recent Payments Queue</h4>
                    <QuizQuestionButtonNavigation />
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <router-link :to="{ name: 'QuizQuestionList' }" class="btn btn-primary">
                                {{ $t('com.global.clearfilters') }}
                            </router-link>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table table-responsive-md table-hover">
                                    <thead>
                                        <tr>
                                            <th style="width:80px;"><strong>#</strong></th>
                                            <th><strong>{{ $t('com.quiz.question.name') }}</strong></th>
                                            <th><strong>{{ $t('com.quiz.question.language') }}</strong></th>
                                            <th><strong>{{ $t('com.quiz.question.customers') }}</strong></th>
                                            <th><strong>{{ $t('com.quiz.question.type') }}</strong></th>
                                            <th><strong>{{ $t('com.quiz.question.categories') }}</strong></th>
                                            <th><strong>{{ $t('com.quiz.question.tags') }}</strong></th>
                                            <th><strong>{{ $t('com.quiz.question.answers') }}</strong></th>
                                            <th><strong>{{ $t('com.quiz.question.points') }}</strong></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(question, index) in questions" :key="index">
                                            <td><strong>{{ getIndex(index) }}</strong></td>
                                            <td>{{ question.name }}</td>
                                            <td>{{ question.language.name }} ({{ question.language.culture }})</td>
                                            <td>
                                                <a v-for="(customer, customerIndex) in question.customers" :key="customerIndex" class="badge badge-rounded badge-outline-info" @click="setSelectedCustomer(customer)">
                                                    {{ customer.name }}
                                                </a> <br />

                                            </td>
                                            <td>{{ $t(question.type.name) }}</td>
                                            <td>
                                                <a v-for="(category, catIndex) in question.categories" :key="catIndex" class="badge badge-rounded badge-outline-info" @click="setSelectedCategory(category)">
                                                    {{ category.name }}
                                                </a> <br />

                                            </td>
                                            <td>
                                                <a v-for="(tag, tagIndex) in question.tags" :key="tagIndex" class="badge badge-rounded badge-outline-info" @click="setSelectedTag(tag)">
                                                    {{ tag.name }}
                                                </a> <br />
                                            </td>
                                            <td>{{ question.answerCount }}</td>
                                            <td>{{ question.maxPoints }}</td>
                                            <td>
                                                <div class="dropdown">
                                                    <button :id="getDropdownID(index)" type="button" class="btn btn-primary light sharp" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <rect x="0" y="0" width="24" height="24" />
                                                                <circle fill="#000000" cx="5" cy="12" r="2" />
                                                                <circle fill="#000000" cx="12" cy="12" r="2" />
                                                                <circle fill="#000000" cx="19" cy="12" r="2" />
                                                            </g>
                                                        </svg>
                                                    </button>
                                                    <div class="dropdown-menu" :aria-labelledby="getDropdownID(index)">
                                                        <router-link :to="{ name: 'QuizQuestionEdit', params: { id: question.id } }" class="dropdown-item">{{ $t('com.global.edit') }}</router-link>
                                                        <router-link :to="{ name: 'QuizQuestionDetail', params: { id: question.id } }" class="dropdown-item">{{ $t('com.global.details') }}</router-link>
                                                        <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modalItemDelete" @click="setQuestion(question)">{{ $t('com.global.delete') }}</a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <ElementLoader v-if="loading" :type="tableLoader.type" :color="tableLoader.color" :size="tableLoader.size" />
                    </div>
                    <div class="row mt-3">
                        <Pagination :pages="pages" @page-request="pageRequest" />
                    </div>

                </div>
            </div>
        </div>
    </div>

    <ModalDelete :error="errorModalEdit"
                 :deletedItem="deletedItem"
                 :closeModal="closeModal"
                 @unset-item="unsetQuestion()"
                 @confirm-delete="deleteQuestion()"
    />
</template>

<script charset="utf-8">
    import PageTitle from "@/components/common/PageTitle.vue";
    import QuizQuestionButtonNavigation from "@/components/quiz/question/QuizQuestionButtonNavigation.vue";
    import QuizQuestionService from "@/services/quiz/quiz.question.service"
    import Pagination from "@/components/common/Pagination.vue"
    import ModalDelete from "@/components/common/modal/ModalDelete.vue"
    import ElementLoader from '@/components/common/ElementLoader.vue';
    export default {
        components: {
            PageTitle,
            QuizQuestionButtonNavigation,
            Pagination,
            ModalDelete,
            ElementLoader
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "Quiz Question List",
                subTitle: "com.quiz.question",

                loading: false,
                questions: [],
                pages: {},

                tableLoader: {
                    type: "spinner-border",
                    color: "text-primary",
                    size: "big"
                },

                errorModalEdit: {
                    isShow: false,
                    header: "test",
                    message: "",
                },

                selectedQuestion: {},
                deletedItem: "",
                closeModal: false

            };
        },
        computed: {

        },
        watch: {
            '$route'(to) {
                this.fetchQuestions(this.getCurrentPage(), this.getCurrentItemsOnPage(), this.getCustomerID(), this.getTagID(), this.getCategoryID())
            }
        },
        mounted() {

            this.fetchQuestions(this.getCurrentPage(), this.getCurrentItemsOnPage(), this.getCustomerID(), this.getTagID(), this.getCategoryID());
        },
        methods: {
            async fetchQuestions(page = 1, items = 10, customerID = -1, tagID = -1, categoryID = -1) {
                try {
                    this.loading = true;
                    const req = {
                        PageNumber: page,
                        ItemsOnPage: items,
                        CustomerID: customerID,
                        TagID: tagID,
                        CategoryID: categoryID,
                    }

                    let res = (await QuizQuestionService.GetAll(req));

                    if (res.status == 200) {
                        this.questions = res.data.items;
                        this.pages = res.data.page;
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },

            async deleteQuestion() {
                try {
                    this.loading = true;
                    let res = (await QuizQuestionService.Delete(this.selectedQuestion.id))

                    if (res.status == 200) {
                        this.closeModal = true;
                        this.fetchQuestions(this.getCurrentPage(), this.getCurrentItemsOnPage());
                        this.$toast.success(this.$t("com.quiz.question.successdelete"));

                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },

            getCurrentPage() {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;
                return page;
            },
            getCurrentItemsOnPage() {
                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 10;
                if (itemsPerPage == 0)
                    itemsPerPage = 10;
                return itemsPerPage
            },
            getIndex(index) {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;

                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 0;

                return ((page - 1) * itemsPerPage) + index + 1
            },
            pageRequest(page) {

                if (this.getCustomerID() != -1) {
                    this.$router.push(
                        {
                            name: 'QuizQuestionListByCustomerPages',
                            params: {
                                page: page,
                                items: this.getCurrentItemsOnPage()
                            }
                        })
                }
                else if (this.getCategoryID() != -1) {
                    this.$router.push(
                        {
                            name: 'QuizQuestionListByCategoryPages',
                            params: {
                                page: page,
                                items: this.getCurrentItemsOnPage()
                            }
                        })
                }
                else if (this.getTagID() != -1) {
                    this.$router.push(
                        {
                            name: 'QuizQuestionListByTagPages',
                            params: {
                                page: page,
                                items: this.getCurrentItemsOnPage()
                            }
                        })
                }
                else {
                    this.$router.push(
                        {
                            name: 'QuizQuestionListPages',
                            params: {
                                page: page,
                                items: this.getCurrentItemsOnPage()
                            }
                        })
                }
                
            },

            getDropdownID(str) {
                return "dropdownButton" + str;
            },
            setQuestion(question) {
                this.closeModal = false;
                this.selectedQuestion = question;
            },
            unsetQuestion() {
                this.selectedQuestion = {};
            },

            setSelectedCustomer(item) {
                this.$router.push(
                    {
                        name: 'QuizQuestionListByCustomer',
                        params: {
                            customerID: item.id
                        }
                    });
            },

            setSelectedTag(item) {
                this.$router.push(
                    {
                        name: 'QuizQuestionListByTag',
                        params: {
                            tagID: item.id
                        }
                    });
            },
            setSelectedCategory(item) {
                this.$router.push(
                    {
                        name: 'QuizQuestionListByCategory',
                        params: {
                            categoryID: item.id
                        }
                    });
            },

            getCustomerID() {
                let customer = parseInt(this.$route.params.customerID);
                if (isNaN(customer))
                    return -1;
                return customer;
            },

            getTagID() {
                let tag = parseInt(this.$route.params.tagID);
                if (isNaN(tag))
                    return -1;
                return tag;
            },
            getCategoryID() {
                let category = parseInt(this.$route.params.categoryID);
                if (isNaN(category))
                    return -1;
                return category;
            }
        }
    }</script>

<style>
</style>
