<template>
    <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Recent Payments Queue</h4>
                    <div class="btn-group">
                        <router-link :to="{ name: 'GroupsBin' }" type="button" class="btn btn-primary">
                            {{ $t("com.global.bin") }}
                        </router-link>
                        <router-link :to="{ name: 'GroupsList' }" type="button" class="btn btn-primary">
                            {{ $t("com.global.list") }}
                        </router-link>
                        <router-link :to="{name: 'GroupCreate'}" type="button" class="btn btn-primary">
                            {{ $t('com.global.add') }}
                        </router-link>
                    </div>

                </div>
                <div class="card-body">
                    <form method="POST" class="needs-validation" novalidate="" autocomplete="off" @submit.prevent="updateGroupMethod">
                        <div class="row">
                            <div class="col-md-6 offset-md-3">
                                <div class="mb-3 row">
                                    <ErrorModalMessage v-if="error.isShow" :error="error" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label">{{ $t('com.group.name') }}</label>
                                    <input v-model="editGroup.name" type="text" class="form-control" :placeholder="$t('com.group.name')" />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">{{ $t('com.group.i18nname') }}</label>
                                    <input v-model="editGroup.i18nName" type="text" class="form-control" :placeholder="$t('com.group.i18nname')" />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">{{ $t('com.group.customer') }}</label>
                                    <VueMultiselect v-model="editGroup.customer"
                                                    :options="customers"
                                                    :searchable="true"
                                                    :placeholder="$t('com.group.customer')"
                                                    :multiple="false"
                                                    label="name"
                                                    track-by="id"
                                                    :showLabels="false"
                                                    :allow-empty="false"
                                    />
                                    <pre class="language-json"><code>{{ editGroup.customer }}</code></pre>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label">{{ $t('com.group.description') }}</label>
                                    <textarea v-model="editGroup.description" class="form-control" :placeholder="$t('com.group.description')" rows="7"></textarea>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">{{ $t('com.group.i18ndescription') }}</label>
                                    <textarea v-model="editGroup.i18nDescription" class="form-control" :placeholder="$t('com.group.i18ndescription')" rows="7"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <button type="submit" class="btn btn-primary">{{ $t('com.global.save') }}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script charset="utf-8">
    import PageTitle from "../../../components/common/PageTitle.vue";
    import VueMultiselect from 'vue-multiselect'
    export default {
        components: {
            PageTitle,
            VueMultiselect
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "Groups Edit",
                subTitle: "com.group.subtitle",
                customers: [],
                editGroup: {
                    name: "",
                    i18nName: "",
                    description: "",
                    i18nDescription: "",
                    customer: {}
                },
                error: {
                    isShow: false,
                    header: "test",
                    message: "",
                }
            };
        },
        computed: {
            groupID() {
                return this.$route.params.id;
            }
        },
        watch: {

        },
        mounted() {
            this.fetchGroup()
            this.fetchCustomers()
        },
        methods: {
            async fetchGroup() {
                try {
                    this.$store.dispatch("group/getGroupByID", this.groupID).then(
                        (res) => {
                            this.editGroup = res.data;
                            console.log(res)
                        },
                        (error) => {
                            console.log(error)
                            this.$toast.error(this.$t(error.data.message))
                        }
                    );
                }
                catch (err) {
                    console.log(err);
                }
            },
            async fetchCustomers() {
                try {
                    const req = {
                        PageNumber: 1,
                        ItemsOnPage: 100000,
                    }
                    this.$store.dispatch("customer/getCustomers", req).then(
                        (res) => {
                            this.customers = res.data.customers;
                            console.log(this.customers);
                        },
                        (error) => {
                            console.log(error)
                        }
                    );
                }
                catch (err) {
                    console.log(err)
                }
            },
            async updateGroupMethod() {
                try {
                    const req = {
                        id: this.editGroup.id,
                        name: this.editGroup.name,
                        i18nname: this.editGroup.i18nName,
                        description: this.editGroup.description,
                        i18ndescription: this.editGroup.i18nDescription,
                        customerid: this.editGroup.customer.id
                    }
                    this.$store.dispatch("group/updateGroup", req).then(
                        () => {
                            this.$toast.success(this.$t('com.group.successcreate'))
                        },
                        (error) => {
                            console.log(error)
                            this.$toast.error(this.$t(error.data.message))
                        }
                    );
                }
                catch (err) {
                    console.log(err);
                }
            }
        }
    }</script>

<style>
</style>
