<template>
    <!-- Modal Create-->
    <form method="POST" class="needs-validation" novalidate="" autocomplete="off" @submit.prevent="createItemMethod">
        <div id="modalCountryCreate" class="modal fade" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ $t('com.country.create') }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3 row">
                            <ErrorModalMessage v-if="error.isShow" :error="error" />
                        </div>
                        <div class="mb-3 row">
                            <label class="col-sm-3 col-form-label">{{ $t('com.country.name') }}</label>
                            <div class="col-sm-9">
                                <input v-model="createItem.name" type="text" class="form-control" :placeholder="$t('com.country.name')" />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button id="createModalEmit" type="button" class="btn btn-danger light" data-bs-dismiss="modal">{{ $t('com.global.close') }}</button>
                        <button type="submit" class="btn btn-primary">{{ $t('com.global.save') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <!-- /Modal Create -->
</template>

<script charset="utf-8">import ErrorModalMessage from "../common/error/ErrorModalMessage";
    export default {
        components: {
            ErrorModalMessage
        },
        props: {

        },
        emits: ["reload-items"],
        data() {
            return {
                publicPath: process.env.BASE_URL,
                createItem: {
                    name: "",
                },
                error: {
                    isShow: false,
                    header: "test",
                    message: "",
                }
            };
        },
        computed: {

        },
        methods: {
            async createItemMethod() {
                this.error.isShow = false;
                try {
                    this.$store.dispatch("i18n/createCountry", this.createItem).then(
                        () => {
                            this.reloadItems()
                            this.clearData()
                            document.getElementById('createModalEmit').click();
                            //document.getElementById('modalLanugageCreate').modal('hide');
                            //$('#modalLanugageCreate').modal('hide')
                        },
                        (error) => {
                            this.error.isShow = true;
                            this.error.header = "header";
                            this.error.message = error.data.translatedMessage;

                        }
                    );
                }
                catch (err) {
                    console.log(err)
                }
            },
            reloadItems() {
                this.$emit("reload-items")
            },
            clearData() {
                this.createItem.name = "";
                this.error.isShow = false;
                this.error.header = "";
                this.error.message = ""
            }
        }
    }</script>

<style>
</style>
