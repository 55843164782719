import api from "./api";
import url from "./url";

class UserService {
    GetAll(Pages) {
        return api.get(url.user.getAll, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage,
                CustomerID: Pages.CustomerID,
                RoleID: Pages.RoleID,
            }
        })
            .then((res) => {
                return res;
            })
    }

    SearchByLogin(Request) {
        return api.get(url.user.searchByLogin, {
            params: {
                PageNumber: Request.PageNumber,
                ItemsOnPage: Request.ItemsOnPage,
                SearchLogin: Request.SearchLogin,
            }
        })
            .then((res) => {
                return res;
            })
    }

    GetById(userId) {
        return api.get(url.user.getById + "/" + userId)
            .then((response) => {
                return response
            })
    }
    Register(request) {
        const req = {
            login: request.login,
            password: request.password,
            passwordConfirm: request.passwordConfirm,
            firstname: request.firstname,
            lastname: request.lastname,
            email: request.email,
            telephone: request.telephone,
            languageID: request.languageID,
        }
        return api.post(url.user.register, req)
            .then((response) => {
                return response;
            })
    }

    CreateUser(request) {
        const req = {
            login: request.login,
            password: request.password,
            confirmPassword: request.confirmPassword,
            firstname: request.firstname,
            lastname: request.lastname,
            email: request.email,
            pilotID: request.pilotID,
            telephone: request.telephone,
            languageID: request.languageID,
            customerID: request.customerID,
            roles: request.roles
        }
        return api.post(url.user.management.createUser, req)
            .then((response) => {
                return response;
            })
    }

    CreateUserFromEvent(request) {
        const req = {
            Firstname: request.Firstname,
            Lastname: request.Lastname,
            Email: request.Email,
            PilotID: request.pilotID,
            Phone: request.Phone,
            Groups: request.Groups
        };

        return api.post(url.user.management.createUserFromEvent, req)
            .then((response) => {
                return response;
            })
    }

    CreateUserFromCsv(request) {
        const req = {
            UsersCSV: request.UsersCSV,
            Groups: request.Groups,
        }
        return api.post(url.user.management.createUserFromCsv, req)
            .then((response) => {
                return response;
            })
    }

    CreateLowPermissionUser(request) {
        const req = {
            login: request.login,
            password: request.password,
            confirmPassword: request.confirmPassword,
            firstname: request.firstname,
            lastname: request.lastname,
            email: request.email,
            pilotID: request.pilotID,
            telephone: request.telephone,
            languageID: request.languageID,
            pilotID: request.pilotID,
            roles: request.roles
        }
        return api.post(url.user.management.createLowPermissionUser, req)
            .then((response) => {
                return response;
            })
    }

    DeleteUser(userID) {
        return api.delete(url.user.management.delete + "/" + userID)
            .then((response) => {
                return response
            })
    }


    UpdateUserPilot(request) {
        const req = {
            UserId: request.UserId,
            PilotId: request.PilotId,
            
        }
        return api.post(url.user.management.updateUserPilot, req)
            .then((response) => {
                return response;
            })
    }


    //userLogs
    GetLastLoginCurrentUser() {
        return api.get(url.user.userLogs.getLastLoginCurrentUser)
            .then((response) => {
                return response
            })
    }

    GetLastFailedLoginCurrentUser() {
        return api.get(url.user.userLogs.getLastFailedLoginCurrentUser)
            .then((response) => {
                return response
            })
    }

    GetLoginsCurrentUser(Pages) {
        return api.get(url.user.userLogs.getLoginsCurrentUser, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage
           }})
            .then((response) => {
                return response
            })
    }

    GetLastLoginByUserId(userId) {
        return api.get(url.user.userLogs.getLastLoginByUserId + "/" + userId)
            .then((response) => {
                return response
            })
    }

    GetLastFailedLoginByUserId(userId) {
        return api.get(url.user.userLogs.getLastFailedLoginByUserId + "/" + userId)
            .then((response) => {
                return response
            })
    }

    GetLoginsByUserId(request) {
        return api.get(url.user.userLogs.getLoginsByUserId + "/" + request.userID + "/" + request.pageNumber + "/" + request.itemsOnPage)
            .then((response) => {
                return response
            })
    }

    //UserManagemend
    ChangeUserPassword(request) {
        const req = {
            userID: request.userID,
            newPassword: request.newPassword,
        }
        return api.post(url.user.management.changeUserPassword, req)
            .then((response) => {
                return response;
            })
    }

    CheckLogin(login) {
        return api.get(url.user.management.checkLogin + "/" + login)
            .then((response) => {
                return response;
            })
    }

    CheckEmail(email) {
        return api.get(url.user.management.checkEmail + "/" + email)
            .then((response) => {
                return response;
            })
    }

    CheckPhone(phone) {
        return api.get(url.user.management.checkPhone + "/" + phone)
            .then((response) => {
                return response;
            })
    }

    UpdateUser(request) {
        const req = {
            id: request.id,
            firstname: request.firstname,
            lastname: request.lastname,
            email: request.email,
            telephone: request.telephone,
            languageID: request.language,
            pilotID: request.pilotID,
            roles: request.roles
        }
        return api.post(url.user.management.updateUser, req)
            .then((res) => {
                return res;
            })
    }

    UpdateUserLowPermission(request) {
        const req = {
            id: request.id,
            firstname: request.firstname,
            lastname: request.lastname,
            email: request.email,
            telephone: request.telephone,
            pilotID: request.pilotID,
            languageID: request.language,
            roles: request.roles
        }
        return api.post(url.user.management.updateUserLowPermission, req)
            .then((res) => {
                return res;
            })
    }

    SendEmailConfirmation() {
        return api.put(url.user.management.sendConfirmationEmail)
            .then((res) => {
                return res;
            })
    }
    ConfirmUserEmail(request) {
        const req = {
            User: request.user,
            Token: request.token,
        }
        return api.post(url.user.management.confirmEmail, req)
            .then((res) => {
                return res;
            })
    }
}

export default new UserService