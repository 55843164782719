<template>
    <div id="modalInteractiveCurrentQuestion" class="modal fade" tabindex="-1" aria-labelledby="modalInteractiveCurrentQuestionLabel" aria-hidden="true" ref="currentQuestionModal" >
        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalInteractiveCurrentQuestionLabel">{{ $t('com.interactive.question.current.title') }} - {{ currentQuestion.name }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-if="showConstrols"></button>
                </div>
                <div class="modal-body">
                    <div class="question-type">
                        <div class="row">
                            <div class="col-12">
                                {{ currentQuestion.type.name }}
                            </div>
                        </div>
                    </div>
                    <div class="question-badget">
                        <div class="row">
                            <div class="col-6">
                                <p class="text-muted">{{ $t('com.interactive.question.tags') }}</p>
                                <p>
                                    <span v-for="(tag, tagIndex) in currentQuestion.tags" :key="tagIndex" class="badge badge-secondary">{{ tag.name }}</span>
                                </p>
                            </div>
                            <div class="col-6">
                                <p class="text-muted">{{ $t('com.interactive.question.categories') }}</p>
                                <p>
                                    <span v-for="(category, categoryIndex) in currentQuestion.categories" :key="categoryIndex" class="badge badge-secondary">{{ category.name }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <p><button type="button" class="btn btn-primary" @click="deActivateQuestion()" v-if="showConstrols">zamknij pytanie</button></p>
                    <div class="row" v-if="showAnswers && !isUserAnswered">
                        <div class="col-12">
                            <div class="row" v-for="(item, index) in currentQuestion.answers" :key="index">
                                <div class="col-12">
                                    <div class="form-check">
                                        <input :id="getAnswerId(item.id)" class="form-check-input" :value="item.id" type="checkbox" v-model="checkedAnswers" />
                                        <label class="form-check-label" :for="getAnswerId(item.id)">
                                            <span>{{ item.name }}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>                         
                        </div>
                        <div class="col-12">
                            <button type="button" class="btn btn-primary" @click="sendUserAnswers">{{ $t('com.quiz.particpatns.sendanswers') }}</button>
                        </div>
                    </div>
                    <div class="row" v-if="isUserAnswered">
                        <div class="col-12">
                            <span class="align-middle">{{ $t('com.quiz.participatns.tankyouforanswer') }}</span>
                        </div>
                    </div>
                    
                    <div class="row" v-if="showUsers">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12">
                                    <span>{{ $t('com.quiz.visualization.answerstat') }}</span>
                                </div>
                                <div class="col-12">
                                    <span> ({{ alreadyAnsweredUsers.length }}/{{ enititledUses.length + alreadyAnsweredUsers.length }})</span>
                                </div>
                            </div>
                            <!-- Already answered -->
                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12">
                                            <h4>{{ $t('com.quiz.visualisation.usersalreadyanswered') }}</h4>
                                        </div>
                                    </div>
                                    <span class="badge bg-success m-1" v-for="(item, index) in alreadyAnsweredUsers" :key="index">{{ item.firstname }} {{ item.lastname }}</span>
                                </div>
                            </div>
                            <!-- /Already answered -->
                            <!-- Entitle users-->
                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12">
                                            <h4>{{ $t('com.quiz.visualisation.entitledusers') }}</h4>
                                        </div>
                                    </div>
                                    <span class="badge bg-dark m-1" v-for="(item, index) in enititledUses" :key="index">{{ item.firstname }} {{ item.lastname }}</span>
                                </div>
                            </div>
                            <!-- Entitle /users-->
                        </div>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" v-if="showConstrols">{{ $t('com.global.close') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script charset="utf-8">

    //import { Modal } from "bootstrap";
    import QuizInteractiveService from "@/services/quiz/quiz.interactive.service"

    export default {
        components: {

        },
        props: {
            currentQuestion: {
                type: Object,
                default: function () {
                    return {
                        name: "",
                        type: {
                            name: "",
                        }
                    }
                }
            },
            showConstrols: {
                type: Boolean,
                default: false
            },
            showAnswers: {
                type: Boolean,
                default: false
            },
            isUserAnswered: {
                type: Boolean,
                default: false
            },
            showUsers: {
                type: Boolean,
                default: false,
            },
            enititledUses: {
                type: Array,
                default: function () {
                    return [];
                },
            },
            alreadyAnsweredUsers: {
                type: Array,
                default: function () {
                    return [];
                },
            }

        },
        emits: ["answers-ready"],
        data() {
            return {
                publicPath: process.env.BASE_URL,

                loading: {
                    sendQuestion: false,
                },

                questionModal: {},

                checkedAnswers: [],

                encryptedAnswers: [],

            };
        },
        computed: {
            eventCode() {
                return this.$route.params.eventCode;
            }
        },
        watch: {
            currentQuestion: {
                immediate: true,
                handler() {
                    if (this.$refs.currentQuestionModal !== undefined) {
                        console.log("Obecne pytanie", this.currentQuestion);
                        console.log(this.questionModal);
                        if (this.currentQuestion.id > 0) {
                            this.questionModal.show();
                        }
                        else {
                            this.questionModal.hide();
                        }


                        //$(this.$refs.currentQuestionModal).show()
                        console.log($(this.$refs.currentQuestionModal));
                    }
                    
                }
            }
        },
        created() {
            
        },
        mounted() {
            this.createModal();
        },
        methods: {


            sendUserAnswers() {

                try {
                    this.loading.sendQuestion = true;

                    this.encryptedAnswers = [];
                    let code = this.currentQuestion.code;

                    if (code.length > 16)
                        code = code.substring(0, 16);

                    code = this.$CryptoJS.enc.Utf8.parse(code)
                    for (const answerId of this.checkedAnswers) {


                        var res = this.$CryptoJS.AES.encrypt(this.$CryptoJS.enc.Utf8.parse(answerId.toString()), code, {
                            keySize: 16,
                            blockSize: 128,
                            iv: code,
                            mode: this.$CryptoJS.mode.CBC,
                            padding: this.$CryptoJS.pad.Pkcs7
                        });



                        this.encryptedAnswers.push(res.toString());
                    }


                    const response = {
                        questionCode: this.currentQuestion.code,
                        answers: this.encryptedAnswers
                    };


                    this.checkedAnswers = [];
                    this.$emit("answers-ready", response);
                }
                catch (e) {
                    console.log(e);
                }
                finally {
                    this.loading.sendQuestion = false;
                }
                


            },
            createModal() {
                this.questionModal = new bootstrap.Modal(this.$refs.currentQuestionModal);
            },
            async deActivateQuestion() {
                try {

                    if (this.currentQuestion.id < 1)
                        return;

                    this.loading.setQuestionActive = true;

                    const req = {
                        EventCode: this.eventCode,
                        QuestionID: this.currentQuestion.id,
                    }

                    let res = (await QuizInteractiveService.SetQuestionDeactive(req))

                    if (res.status == 200) {
                        this.$toast.success(this.$t('com.interactive.question.deactivate.success'))
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                    else if (e.response.status == 400) {
                        this.$toast.warning(this.$t(e.response.data.message))
                    }
                }
                finally {
                    this.loading.setActiveVoteBlock = false;
                }
            },

            getAnswerId(id) {
                return "answer-question-" + id;
            }
        }
    }
</script>
<style type="text/css">
    .modal-style-block{

    }
</style>