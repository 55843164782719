<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title"> {{ event.name }}</h3>
                </div>
                <div class="card-body">
                    <p>{{ event.description }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script charset="utf-8">
    export default {
        components: {
        },
        props: {

            event: {
                type: Object,
                required: true
            },
        },
        emits: [""],

        data() {
            return {
                publicPath: process.env.BASE_URL,
               
            }
        },
        watch: {
           
        },
        mounted() {
        },

        methods: {
            getDropdownID(str) {
                return "dropdownButton" + str;
            },
        }
    }
</script>
<style type="text/css">
</style>