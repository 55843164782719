<template>
    <div class="row mb-3">
        <div class="col-6">
            <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
        </div>
        <div class="col-6">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <EventButtonNavigation />
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Recent Payments Queue</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table table-hover">
                                    <thead>
                                        <tr class="align-middle align-content-center">
                                            <th style="width:80px;"><strong>#</strong></th>
                                            <th><strong>{{ $t('com.event.name') }}</strong></th>
                                            <th><strong>{{ $t('com.event.language') }}</strong></th>
                                            <th><strong>{{ $t('com.event.date.start') }}</strong></th>
                                            <th><strong>{{ $t('com.event.date.end') }}</strong></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(myEvent, index) in myEvents" :key="index">
                                            <td><strong>{{ getIndex(index) }}</strong></td>
                                            <td>{{ myEvent.name }}</td>
                                            <td>{{ myEvent.language.name }} ({{ myEvent.language.culture }})</td>
                                            <td>{{ $moment(myEvent.eventStart).format("LLLL") }}</td>
                                            <td>{{ $moment(myEvent.eventEnd).format("LLLL") }}</td>
                                            <td>
                                                <div class="dropdown">
                                                    <button :id="getDropdownID(index)" type="button" class="btn btn-primary light sharp" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <rect x="0" y="0" width="24" height="24" />
                                                                <circle fill="#000000" cx="5" cy="12" r="2" />
                                                                <circle fill="#000000" cx="12" cy="12" r="2" />
                                                                <circle fill="#000000" cx="19" cy="12" r="2" />
                                                            </g>
                                                        </svg>
                                                    </button>
                                                    <div class="dropdown-menu" :aria-labelledby="getDropdownID(index)">
                                                        <router-link :to="{ name: 'EventEdit', params: { id: myEvent.id } }" class="dropdown-item">{{ $t('com.global.edit') }}</router-link>
                                                        <router-link :to="{ name: 'EventDetail', params: { id: myEvent.id } }" class="dropdown-item">{{ $t('com.global.details') }}</router-link>
                                                        <router-link :to="{ name: 'EventParticipant', params: { eventCode: event.code } }" target="_blank" class="dropdown-item" v-if="event.code != ''">{{ $t('com.global.participant') }}</router-link>
                                                        <router-link :to="{ name: 'EventVisualisationLive', params: { eventCode: myEvent.code } }" target="_blank" class="dropdown-item">{{ $t('com.global.visualisation') }}</router-link>
                                                        <router-link :to="{ name: 'EventInteractive', params: { eventCode: myEvent.code } }" target="_blank" class="dropdown-item" v-if="myEvent.code != ''">{{ $t('com.global.interactive') }}</router-link>
                                                        <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modalItemDelete" @click="setEvent(myEvents)">{{ $t('com.global.delete') }}</a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <Pagination :pages="pages" @page-request="pageRequest" />
                    </div>

                </div>
            </div>
        </div>
    </div>

    <ModalDelete :error="errorModalEdit"
                 :deletedItem="deletedItem"
                 :closeModal="closeModal"
                 @unset-item="unsetEvent()"
                 @confirm-delete="deleteEvent()"
    />
</template>

<script charset="utf-8">
    import PageTitle from "../../components/common/PageTitle.vue";
    import EventButtonNavigation from "../../components/event/EventButtonNavigation.vue"
    import EventService from "../../services/event/event.service"
    import Pagination from "../../components/common/Pagination.vue"
    import ModalDelete from "../../components/common/modal/ModalDelete.vue"
    export default {
        components: {
            PageTitle,
            EventButtonNavigation,
            Pagination,
            ModalDelete
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "Event",
                subTitle: "com.event.my",
                myEvents: [],
                pages: {},
                loading: {
                    global: false,
                },



                selectedEvent: {},

                errorModalEdit: {
                    isShow: false,
                    header: "test",
                    message: "",
                },

                deletedItem: "",
                closeModal: false
            };


        },
        computed: {

        },
        watch: {

        },
        mounted() {
            this.fetchMyEvents(this.getCurrentPage(), this.getCurrentItemsOnPage());
        },
        methods: {
            async fetchMyEvents(page = 1, items = 10) {
                try {
                    this.loading.global = true;
                    const req = {
                        PageNumber: page,
                        ItemsOnPage: items,
                    }

                    let res = (await EventService.GetMyAll(req));

                    if (res.status == 200) {
                        console.log(res.data.items);
                        this.myEvents = res.data.items;
                        this.pages = res.data.page;
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.global = false;
                }
            },

            async deleteEvent() {
                try {
                    this.loading.global = true;

                    let res = (await EventService.Delete(this.selectedEvent.id))
                    if (res.status == 200) {
                        this.closeModal = true;
                        this.$toast.success(this.$t("com.event.delete.success"))
                        this.fetchMyEvents(this.getCurrentPage(), this.getCurrentItemsOnPage());
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.global = false;
                }
            },

            getCurrentPage() {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;
                return page;
            },
            getCurrentItemsOnPage() {
                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 10;
                if (itemsPerPage == 0)
                    itemsPerPage = 10;
                return itemsPerPage
            },
            getIndex(index) {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;

                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 0;

                return ((page - 1) * itemsPerPage) + index + 1
            },
            pageRequest(page) {
                this.$router.push(
                    {
                        name: 'EmailTemplatesListPages',
                        params: {
                            page: page,
                            items: this.getCurrentItemsOnPage()
                        }
                    })
            },
            getDropdownID(str) {
                return "dropdownButton" + str;
            },

            setEvent(obj) {
                this.closeModal = false;
                this.selectedEvent = obj;
            },

            unsetEvent() {
                this.selectedEvent = {}
            }
        }
    }</script>

<style>
</style>
