<template>
    <router-view />
</template>

<script charset="utf-8">

    export default {
        components: {
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "Countries",
                subTitle: "com.countries.subtitle",
                countries: [],
                pages: {},
                selectedCountry: {}
            };
        },
        computed: {

        },
        watch: {
            
        },
        mounted() {
           
        },
        methods: {
           
        }
    }
</script>

<style>
</style>
