<template>
    <div id="eventVoteEdit" class="modal fade" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <form action="post" class="needs-validation" novalidate="" autocomplete="off" @submit.prevent="eventVoteEditMethod">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ $t('com.event.vote.edit') }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-3">
                            <label class="form-label">
                                <strong>{{ $t('com.quiz.vote.name') }}</strong>
                            </label>
                            <input type="text" class="form-control" :placeholder="$t('com.vote.name')" v-model="vote.name" />
                        </div>
                        <div class="row mb-3">
                            <label class="form-label">
                                <strong>{{ $t('com.quiz.vote.description') }}</strong>
                            </label>
                            <input type="text" class="form-control" :placeholder="$t('com.vote.description')" v-model="vote.description" />
                        </div>
                        <div class="row mb-3">
                            <label class="form-label"><strong>{{ $t('com.quiz.vote.type') }}</strong></label>
                            <VueMultiselect v-model="vote.voteType"
                                            :options="voteTypes"
                                            :searchable="true"
                                            :placeholder="$t('com.quiz.vote.type')"
                                            :multiple="false"
                                            :custom-label="translatedType"
                                            track-by="id"
                                            :disabled="loading.types"
                                            :showLabels="false"
                                            :allow-empty="false" />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button id="eventVoteEditEmit" type="button" class="btn btn-danger light" data-bs-dismiss="modal" @click="unsetItem()">
                            {{ $t('com.global.close') }}
                        </button>
                        <button type="submit" class="btn btn-primary">
                            {{ $t('com.global.save') }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script charset="utf-8">
    import QuizVoteService from "@/services/quiz/quiz.vote.service";

    import QuizVoteTypeSertvice from "@/services/quiz/quiz.vote.type.service";
    import VueMultiselect from 'vue-multiselect'

    export default {
        components: {
            VueMultiselect
        },
        props: {
            voteItem: {
                type: Object,
                require: true,
            },
            voteBlockID: {
                type: Number,
                require: true,
            },
            closeModal: {
                type: Boolean,
                require: true,
            }
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                loading: false,

                vote: {
                    id: 0,
                    name: "",
                    description: "", 
                    voteType: {},
                },

                voteTypes: [],
            }
        },
        watch: {
            voteItem: {
                immediate: true,
                handler(val) {
                    this.vote = val;
                }
            }
        },
        mounted() {
            this.fetchVoteType();
        },
        methods: {

            async eventVoteEditMethod() {
                try {
                    this.loading = true;

                    const req = {
                        Id: this.vote.id,
                        Name: this.vote.name,
                        Description: this.vote.description,
                        TypeID: this.vote.voteType.id,
                        VoteBlockID: this.voteBlockID
                    };

                    let res = (await QuizVoteService.UpdateWithoutCustomer(req))

                    if (res.status == 200) {
                        this.$toast.success(this.$t('com.vote.successupdate'));
                    }

                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },
            async fetchVoteType() {
                try {

                    let res = (await QuizVoteTypeSertvice.GetAll())

                    if (res.status == 200) {
                        this.voteTypes = res.data;
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
            },

            translatedType(obj) {

                if (Object.keys(obj).length === 0)
                    return "Select"

                return this.$t(obj.name);
            },
        }
    }
</script>

<style type="text/css">

</style>