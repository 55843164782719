<template>
    <div class="row mb-3">
        <div class="col-6">
            <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
        </div>
        <div class="col-6">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <EventSettingButtonNavigation />
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title"> {{ $t(subTitle) }}</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <!-- eventSetting.name -->
                            <div class="row">
                                <div class="col-12">
                                    <h5>
                                        {{ $t('com.event.setting.key.name') }}
                                    </h5>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12">
                                    {{ $t(eventSetting.name) }}
                                </div>
                            </div>

                            <!-- eventSetting.description -->
                            <div class="row">
                                <div class="col-12">
                                    <h5>
                                        {{ $t('com.event.setting.key.isrequired') }}
                                    </h5>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12">
                                    {{ $t(eventSetting.isRequired.name) }}
                                </div>
                            </div>

                            <!-- eventSetting.defaultValue -->
                            <div class="row">
                                <div class="col-12">
                                    <h5>
                                        {{ $t('com.event.setting.key.defaultvalue') }}
                                    </h5>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12">
                                    {{ eventSetting.defaultValue }}
                                </div>
                            </div>

                            <!-- eventSetting.variabletype -->
                            <div class="row">
                                <div class="col-12">
                                    <h5>
                                        {{ $t('com.event.setting.key.type') }}
                                    </h5>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12">
                                    {{ eventSetting.variableTypeName }}
                                </div>
                            </div>

                            <!-- eventSetting.variabletype -->
                            <div class="row">
                                <div class="col-12">
                                    <h5>
                                        {{ $t('com.event.setting.key.setting.type') }}
                                    </h5>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12">
                                    {{ eventSetting.settingType.name }} ({{ $t('com.app.setting.type.provider') }}: {{ eventSetting.settingType.providerString }})
                                </div>
                            </div>

                        </div>
                        <ElementLoader v-if="loading.global" :type="tableLoader.type" :color="tableLoader.color" :size="tableLoader.size" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script charset="utf-8">
    import PageTitle from "@/components/common/PageTitle.vue";
    import EventSettingButtonNavigation from "@/components/event/setting/EventSettingButtonNavigation.vue"
    import ElementLoader from '@/components/common/ElementLoader.vue';

    import EventSettingService from "@/services/event/event.setting.service";

    export default {
        components: {
            PageTitle,
            EventSettingButtonNavigation,
            ElementLoader
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "com.event.setting",
                subTitle: "com.event.setting.detail",

                requiedOptions: [
                    { name: "com.global.yes", value: true },
                    { name: "com.global.no", value: false },
                ],
                eventSetting: {
                    id: 0,
                    name: "",
                    description: "",
                    defaultValue: "",
                    isRequired: { name: "com.global.no", value: false },
                    variableType: {},
                    settingType: {},
                },

                tableLoader: {
                    type: "spinner-border",
                    color: "text-primary",
                    size: "big"
                },

                loading: {
                    global: false,
                },
            };
        },
        computed: {
            eventSettingId() {
                return this.$route.params.id;
            }
        },
        watch: {

        },
        mounted() {
            this.fetchEventSetting();
        },
        methods: {
            async fetchEventSetting() {
                try {
                    this.loading.global = true;

                    let res = (await EventSettingService.GetById(this.eventSettingId))
                    if (res.status == 200) {
                        this.eventSetting = res.data;
                        let localIsRequired = this.eventSetting.isRequired;

                        //this.eventSetting.variableType = this.variableTypes.find(obj => { return obj.value == res.data.variableType });
                        this.eventSetting.isRequired = this.requiedOptions.find(obj => { return obj.value == localIsRequired });
                        //this.eventSetting.settingType = this.settingTypes.find(obj => { return obj.id == res.data.settingType.id });

                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.global = false;
                }
            },
        }
    }</script>

<style>
</style>
