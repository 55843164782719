<template>
    <div class="row mb-3">
        <div class="col-6">
            <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
        </div>
        <div class="col-6">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <QuizNavigation />
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ $t(subTitle) }}</h4>
                    <router-link :to="{ name: 'QuizListMy' }" class="btn btn-primary">
                        {{ $t('com.global.clearfilters') }}
                    </router-link>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table table-responsive-md table-hover">
                                    <thead>
                                        <tr class="align-content-center align-middle">
                                            <th style="width:80px;"><strong>#</strong></th>
                                            <th><strong>{{ $t('com.quiz.vote.name') }}</strong></th>
                                            <th><strong>{{ $t('com.quiz.vote.type') }}</strong></th>
                                            <th><strong>{{ $t('com.quiz.vote.voteblock.name') }}</strong></th>
                                            <th><strong>{{ $t('com.quiz.vote.questions') }}</strong></th>
                                            <th><strong>{{ $t('com.quiz.vote.availablepoints') }}</strong></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(vote, index) in quizzes" :key="index">
                                            <td>{{ getIndex(index) }}</td>
                                            <td>{{ vote.name }}</td>
                                            <td>{{ $t(vote.voteType.name) }}</td>
                                            <td>
                                                <router-link :to="{ name: 'QuizListMyByVoteBlock', params: {voteBlockID: vote.voteBlock.id } }" class="badge badge-rounded badge-outline-info">
                                                    {{ vote.voteBlock.name }}
                                                </router-link>
                                            </td>
                                            <td>{{ vote.questions }}</td>
                                            <td>{{ vote.availablePoints }}</td>
                                            <td>
                                                <div class="dropdown">
                                                    <button :id="getDropdownID(index)" type="button" class="btn btn-primary light sharp" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <rect x="0" y="0" width="24" height="24" />
                                                                <circle fill="#000000" cx="5" cy="12" r="2" />
                                                                <circle fill="#000000" cx="12" cy="12" r="2" />
                                                                <circle fill="#000000" cx="19" cy="12" r="2" />
                                                            </g>
                                                        </svg>
                                                    </button>
                                                    <div class="dropdown-menu" :aria-labelledby="getDropdownID(index)">
                                                        <router-link :to="{ name: 'QuizEdit', params: { id: vote.id } }" class="dropdown-item">{{ $t('com.global.edit') }}</router-link>
                                                        <router-link :to="{ name: 'QuizDetail', params: { id: vote.id } }" class="dropdown-item">{{ $t('com.global.details') }}</router-link>
                                                        <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modalItemDelete" @click="setVote(vote)">{{ $t('com.global.delete') }}</a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <ElementLoader v-if="loading" :type="tableLoader.type" :color="tableLoader.color" :size="tableLoader.size" />
                    </div>
                    
                    <div class="row mt-3">
                        <Pagination :pages="pages" @page-request="pageRequest" />
                    </div>

                </div>
            </div>
        </div>
    </div>

    <ModalDelete :error="errorModalEdit"
                 :deletedItem="deletedItem"
                 :closeModal="closeModal"
                 @unset-item="unsetVote()"
                 @confirm-delete="confirmDeleted()" />

</template>

<script charset="utf-8">
    //import PageTitle from "../../../components/common/PageTitle.vue";
    import PageTitle from "@/components/common/PageTitle.vue";
    import QuizNavigation from "@/components/quiz/QuizNavigation.vue";
    import QuizVoteService from "@/services/quiz/quiz.vote.service"
    import Pagination from "@/components/common/Pagination.vue"
    import ModalDelete from "@/components/common/modal/ModalDelete.vue"
    import ElementLoader from '@/components/common/ElementLoader.vue';

    export default {
        components: {
            PageTitle,
            QuizNavigation,
            Pagination,
            ModalDelete,
            ElementLoader
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "Quiz My",
                subTitle: "com.quiz.my",
                loading: false,

                questions: [],
                quizzes: [],
                pages: {},

                errorModalEdit: {
                    isShow: false,
                    header: "test",
                    message: "",
                },
                tableLoader: {
                    type: "spinner-border",
                    color: "text-primary",
                    size: "big"
                },
                selectedVote: {},
                deletedItem: "",
                restoreItem: "",
                closeModal: false,
                closeDeleteModal: false,
            };
        },
        computed: {

        },
        watch: {
            '$route'() {
                this.fetchQuizzes(this.getCurrentPage(), this.getCurrentItemsOnPage(), this.getVoteBlockID());
            }
        },
        mounted() {
            this.fetchQuizzes(this.getCurrentPage(), this.getCurrentItemsOnPage(), this.getVoteBlockID());
        },
        methods: {

            async fetchQuizzes(page = 1, items = 10, voteBlockID = -1) {
                try {
                    this.loading = true;

                    const req = {
                        PageNumber: page,
                        ItemsOnPage: items,
                        VoteBlockID: voteBlockID
                    }

                    let res = (await QuizVoteService.GetMy(req))
                    if (res.status == 200) {
                        this.pages = res.data.pages;
                        this.quizzes = res.data.items;
                        console.log(this.quizzes);
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },

            async confirmDeleted() {
                try {
                    this.loading = false;

                    let res = (await QuizVoteService.Delete(this.selectedVote.id))

                    if (res.status == 200) {
                        this.closeModal = true;
                        this.closeDeleteModal = true;
                        this.$toast.success(this.$t('com.quiz.vote.successdelete'));
                        this.fetchQuizzes(this.getCurrentPage(), this.getCurrentItemsOnPage(), this.getVoteBlockID());
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },    

            async confirmRestore() {
                this.loading = false;
                //try {
                //    this.loading = true;
                //    let res = (await QuizQuestionService.Restore(this.selectedQuestion.id))

                //    if (res.status == 200) {
                //        this.closeDeleteModal = true;
                //        this.fetchQuestionBin(this.getCurrentPage(), this.getCurrentItemsOnPage());
                //        this.$toast.success(this.$t("com.quiz.question.successrestored"));

                //    }
                //}
                //catch (e) {
                //    if (e.response.status == 401) {
                //        this.$store.dispatch("auth/logout").then(
                //            () => {
                //                this.$router.push({ name: 'LoginView' })
                //            },
                //            (error) => {
                //                console.log(error)
                //            }
                //        );
                //    }
                //}
                //finally {
                //    this.loading = false;
                //}
            },

            getCurrentPage() {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;
                return page;
            },

            getCurrentItemsOnPage() {
                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 10;
                if (itemsPerPage == 0)
                    itemsPerPage = 10;
                return itemsPerPage
            },

            getVoteBlockID() {
                let voteBlockID = parseInt(this.$route.params.voteBlockID);
                if (isNaN(voteBlockID))
                    voteBlockID = -1;

                console.log(voteBlockID);

                return voteBlockID;
            },
            getIndex(index) {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;

                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 0;

                return ((page - 1) * itemsPerPage) + index + 1
            },
            pageRequest(page) {

                if (this.getVoteBlockID() != -1) {
                    this.$router.push(
                        {
                            name: 'QuizListMyByVoteBlockPages',
                            params: {
                                page: page,
                                items: this.getCurrentItemsOnPage()
                            }
                        })
                }
                else {
                    this.$router.push(
                        {
                            name: 'QuizListMyPages',
                            params: {
                                page: page,
                                items: this.getCurrentItemsOnPage()
                            }
                        })
                }

            },

            getDropdownID(str) {
                return "dropdownButton" + str;
            },

            setVote(vote) {
                this.closeModal = false;
                this.closeDeleteModal = false;
                this.selectedVote = vote;
            },
            unsetVote() {
                this.selectedVote = {};
            }
        }
    }</script>

<style>
</style>
