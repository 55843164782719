<template>
    <div id="eventQuestionAdd" class="modal fade" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable modal-fullscreen-lg-down" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('com.event.question.add') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="event-question-create-new-tab" data-bs-toggle="pill" data-bs-target="#event-question-create-new" type="button" role="tab" aria-controls="event-question-create-new" aria-selected="true">
                                {{ $t('com.event.question.createnew') }}
                            </button>
                        </li>
                        <!--<li class="nav-item" role="presentation">
                            <button class="nav-link" id="event-question-assing-tab" data-bs-toggle="pill" data-bs-target="#event-question-assing" type="button" role="tab" aria-controls="event-question-assing" aria-selected="false">
                                {{ $t('com.event.question.assign') }}
                            </button>
                        </li>-->
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="event-question-create-new" role="tabpanel" aria-labelledby="event-question-create-new-tab">
                            <div class="row mb-3">
                                <div class="col-12">
                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <input type="button" class="btn btn-primary" :value="$t('com.global.save')" @click="eventQuestionCreate()" />
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12">
                                    <label class="form-label">
                                        <strong>
                                            {{ $t('com.quiz.question.name') }}
                                        </strong>
                                    </label>
                                    <input type="text" class="form-control" :placeholder="$t('com.quiz.question.name')" v-model="questionModel.name" :disabled="loading.global" />
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12">
                                    <label class="form-label">
                                        <strong>
                                            {{ $t('com.quiz.question.description') }}
                                        </strong>
                                    </label>
                                    <editor v-model="questionModel.description" :disabled="loading.global" />
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12">
                                    <label class="form-label">
                                        <strong>
                                            {{ $t('com.quiz.question.language') }}
                                        </strong>
                                    </label>
                                    <VueMultiselect v-model="questionModel.language"
                                                    :options="languages"
                                                    :searchable="true"
                                                    :placeholder="$t('com.quiz.question.language')"
                                                    :multiple="false"
                                                    :custom-label="nameWithLang"
                                                    track-by="id"
                                                    :disabled="loading.languages"
                                                    :showLabels="false"
                                                    :allow-empty="false" />
                                </div>
                            </div>

                            <div class="mb-3">
                                <label class="form-label"><strong>{{ $t('com.quiz.question.type') }}</strong></label>
                                <VueMultiselect v-model="questionModel.type"
                                                :options="questionTypes"
                                                :searchable="true"
                                                :placeholder="$t('com.quiz.question.type')"
                                                :multiple="false"
                                                :custom-label="translatedType"
                                                track-by="id"
                                                :disabled="loading.types"
                                                :showLabels="false"
                                                :allow-empty="false" />
                            </div>
                            <div class="row mb-3">
                                <div class="col-12">
                                    <label class="form-label"><strong>{{ $t('com.quiz.question.categories') }}</strong></label>
                                    <VueMultiselect v-model="questionModel.categories"
                                                    :options="categories"
                                                    :searchable="true"
                                                    :placeholder="$t('com.quiz.question.categories')"
                                                    :multiple="true"
                                                    label="name"
                                                    track-by="id"
                                                    :disabled="loading.categories"
                                                    :showLabels="false"
                                                    :taggable="true"
                                                    :allow-empty="false"
                                                    @tag="addQuestionCategory" />
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12">
                                    <label class="form-label"><strong>{{ $t('com.quiz.question.tags') }}</strong></label>
                                    <VueMultiselect v-model="questionModel.tags"
                                                    :options="tags"
                                                    :searchable="true"
                                                    :placeholder="$t('com.quiz.question.tags')"
                                                    :multiple="true"
                                                    track-by="id"
                                                    label="name"
                                                    :disabled="loading.tags"
                                                    :showLabels="false"
                                                    :taggable="true"
                                                    :allow-empty="false"
                                                    @tag="addQuestionTag" />
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12">
                                    <label class="form-label"><strong>{{ $t('com.quiz.question.answer.add') }}</strong></label>
                                    <div class="input-group">
                                        <input type="text" class="form-control" :placeholder="$t('com.quiz.answer.name')" v-model="answer.name" @keyup.enter.prevent="addQuestionAnwer()" />
                                        <div class="input-group-addon">
                                            <button type="button" class="btn btn-primary" @click="addQuestionAnwer()">{{ $t('com.quiz.question.add') }}</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <table class="table table-responsive table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th><strong>{{ $t('com.quiz.answer.name') }}</strong></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(answerItem, index) in questionModel.answers" :key="index">
                                                <td>{{ index+1 }}</td>
                                                <td>{{ answerItem }}</td>
                                                <td>
                                                    <div class="dropdown">
                                                        <button :id="getDropdownID(index)" type="button" class="btn btn-primary light sharp" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24" />
                                                                    <circle fill="#000000" cx="5" cy="12" r="2" />
                                                                    <circle fill="#000000" cx="12" cy="12" r="2" />
                                                                    <circle fill="#000000" cx="19" cy="12" r="2" />
                                                                </g>
                                                            </svg>
                                                        </button>
                                                        <div class="dropdown-menu" :aria-labelledby="getDropdownID(index)">
                                                            <!--<a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#editAnswer" @click="setSettingItem(settingValue)">{{ $t('com.global.edit') }}</a>-->
                                                            <a class="dropdown-item" href="javascript:void(0);" @click="deleteQuestion(answerItem)">{{ $t('com.global.delete') }}</a>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12">
                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <input type="button" class="btn btn-primary" :value="$t('com.global.save')" @click="eventQuestionCreate()" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="event-question-assing" role="tabpanel" aria-labelledby="event-question-assing-tab">...</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script charset="utf-8">

    import Editor from "@/components/common/editor/Editor.vue";
    import I18nService from "@/services/i18n.service";
    import QuizQuestionCategoryService from "@/services/quiz/quiz.question.category.service"
    import QuizQuestionTagService from "@/services/quiz/quiz.question.tag.service"
    import QuizQuestionService from "@/services/quiz/quiz.question.service";
    import QuizQuestionTypeService from "@/services/quiz/quiz.question.type.service"
    import VueMultiselect from 'vue-multiselect'


    export default {
        components: {
            Editor,
            VueMultiselect
        },

        props: {
            error: {
                type: Object,
                require: true,
                default: function () {
                    return {
                        isShow: false,
                        header: "test",
                        message: "",
                    }
                },


            },

            voteID: {
                type: Number,
                require: true,
            },
            eventLanguage: {
                type: Object,
                require: true,
            }

        },
        emits: ["reload-vote-questions"],
        data() {
            return {
                publicPath: process.env.BASE_URL,
                loading: {
                    global: false,
                    languages: false,
                    categories: false,
                    tags: false,
                    type: false,
                },

                questionModel: {
                    name: "",
                    description: "",
                    language: {},
                    categories: [],
                    tags: [],
                    answers: [],
                    type: {},

                },
                answer: {
                    name: ""
                },
                tags: [],
                languages: [],
                categories: [],
                questionTypes: [],
            }
        },
        watch: {
            eventLanguage: {
                immediate: true,
                handler(val) {
                    this.questionModel.language = val;
                }
            }
        },
        mounted() {
            this.fetchLanguages();
            this.fetchTags();
            this.fetchCategories();
            this.fetchQuestionTypes();
            //this.fetchCustomers();
        },
        methods: {

            async fetchLanguages() {
                try {
                    const req = {
                        PageNumber: 1,
                        ItemsOnPage: 100,
                    }
                    this.languages = (await I18nService.GetLanguages(req)).data.languageItems

                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
            },

            async fetchTags() {
                try {
                    this.tags = (await QuizQuestionTagService.GetAll()).data
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
            },
            async addQuestionTag(newTag) {
                try {
                    this.loading.tags = true;
                    const req = {
                        name: newTag
                    }

                    let res = (await QuizQuestionTagService.CreateFromQuestion(req))
                    if (res.status == 200) {
                        this.tags.push(res.data);
                        this.questionModel.tags.push(res.data);
                        this.$toast.success(this.$t("com.quiz.tag.createsuccess"));
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.tags = false;
                }
            },

            async fetchCategories() {
                try {
                    this.categories = (await QuizQuestionCategoryService.GetAll()).data
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
            },
            async addQuestionCategory(newCateogry) {
                try {
                    this.loading.categories = true;

                    const req = {
                        name: newCateogry
                    };

                    let res = (await QuizQuestionCategoryService.CreateFromQuestion(req));
                    if (res.status == 200) {
                        this.categories.push(res.data);
                        this.questionModel.categories.push(res.data);
                        this.$toast.success(this.$t("com.quiz.category.createsuccess"));
                    }

                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.categories = false;
                }
            },

            async fetchQuestionTypes() {
                try {
                    this.loading.type = true;

                    let res = (await QuizQuestionTypeService.GetAll());
                    if (res.status == 200) {
                        this.questionTypes = res.data;

                        this.questionModel.type = this.questionTypes[0];

                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.type = false;
                }
            },


            async eventQuestionCreate() {
                try {
                    this.loading.global = true;
                    console.log(this.questionModel);
                    const req = {
                        Name: this.questionModel.name,
                        Description: this.questionModel.description,
                        LanguageID: this.questionModel.language.id,
                        TypeID: this.questionModel.type.id,
                        Tags: this.questionModel.tags.map(i => i.id),
                        VoteID: this.voteID,
                        Categories: this.questionModel.categories.map(i => i.id),
                        Answers: this.questionModel.answers
                    }

                    let res = (await QuizQuestionService.CreateWithAnswers(req));

                    if (res.status == 200) {
                        this.clearFormData();
                        this.$emit("reload-vote-questions");
                        this.$toast.success = this.$t('com.quiz.question.createsuccess');
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.global = false;
                }
            },

            clearFormData() {
                this.questionModel.name = "";
                this.questionModel.description = "";
                this.questionModel.categories = [];
                this.questionModel.tags = [];
                this.questionModel.answers = [];
            },

            addQuestionAnwer() {
                this.questionModel.answers.push(this.answer.name);
                console.log(this.questionModel.answers);
                this.answer.name = "";
            },

            deleteQuestion(item) {
                try {
                    this.questionModel.answers = this.questionModel.answers.filter(el => el != item);
                }
                catch (e) {
                    console.log(e);
                }
            },
            getDropdownID(str) {
                return "dropdownButton" + str;
            },
            nameWithLang(obj) {
                if (Object.keys(obj).length === 0)
                    return "Select"

                return obj.name + " (" + obj.culture + ")"
            },

            translatedType(obj) {

                if (Object.keys(obj).length === 0)
                    return "Select"

                return this.$t(obj.name);
            },
        }

    }
</script>

<style type="text/css">
</style>