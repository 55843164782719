<template>
    <div class="vh-100">
        <div class="authincation h-100">
            <div class="container h-100">
                <div class="row justify-content-center h-100 align-items-center">
                    <div class="col-md-6">
                        <div class="authincation-content">
                            <div class="row no-gutters">
                                <div class="col-xl-12">
                                    <div class="auth-form">
                                        <div class="text-center mb-3">
                                            <img src="images/logo-full.png" alt="" />
                                        </div>
                                        <h4 class="text-center mb-4">Forgot Password</h4>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="mb-3 row">
                                                    <ErrorModalMessage v-if="error.isShow" :error="error" />
                                                </div>
                                            </div>
                                        </div>
                                        <form v-if="!isSuccess" method="POST" class="needs-validation" novalidate="" autocomplete="off" @submit.prevent="requestResetPassword">
                                            <div class="form-group">
                                                <label><strong>{{ $t('com.user.email') }}</strong></label>
                                                <input v-model="forgotPasswordModel.email" type="email" class="form-control" :placeholder="$t('com.user.email')" />
                                            </div>
                                            <div class="form-group">
                                                <label><strong>{{ $t('com.user.password') }}</strong></label>
                                                <input v-model="forgotPasswordModel.password" type="email" class="form-control" :placeholder="$t('com.user.password')" />
                                            </div>
                                            <div class="form-group">
                                                <label><strong>{{ $t('com.user.confirmpassword') }}</strong></label>
                                                <input v-model="forgotPasswordModel.confirmPassword" type="email" class="form-control" :placeholder="$t('com.user.confirmpassword')" />
                                            </div>
                                            <div class="text-center">
                                                <button type="submit" class="btn btn-primary btn-block">SUBMIT</button>
                                            </div>
                                        </form>
                                        <div v-if="isSuccess" class="row">
                                            <div class="col-md-10 offset-md-1">
                                                <p>{{ $t("com.user.resetpassword.successmessage") }}</p>
                                                <div class="d-grid gap-2 d-md-flex justify-content-md-center">
                                                    <router-link :to="{ name: 'LoginView' }" class="btn btn-primary">{{ $t('com.user.signin') }}</router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script charset="utf-8">
    import AuthService from '../../services/auth.service'
    import ErrorModalMessage from "../../components/common/error/ErrorModalMessage";
    export default {
        components: {
            ErrorModalMessage
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                forgotPasswordModel: {
                    email: "",
                    password: "",
                    confirmPassword: "",
                    token: "",
                },
                error: {
                    isShow: false,
                    header: "test",
                    message: "",
                    errors: []
                },
                isSuccess: false,
            };
        },
        computed: {
            getToken() {
                return this.$route.params.token;
            }
        },

        mounted() {
            //this.FetchRerouces();
        },
        methods: {
            async requestResetPassword() {
                try {
                    this.isSuccess = false;
                    this.error.isShow = false;
                    this.forgotPasswordModel.token = this.getToken;
                    let res = (await AuthService.ResetPassword(this.forgotPasswordModel))
                    if (res.status == 200) {
                        this.isSuccess = true;
                    }
                }
                catch (e) {
                    if (e.response.status == 400) {
                        this.error.isShow = true;
                        this.error.message = e.response.data.message
                        this.error.errors = e.response.data.errors
                    }
                }
            }
        }
    }</script>

<style>
</style>
