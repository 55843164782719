<template>
    <div class="deznav">
        <perfect-scrollbar class="deznav-scroll">
            <router-link :to="{ name: 'Dashboard' }" class="add-project-sidebar btn btn-primary">
                {{ $t('com.menu.dashboard') }}
            </router-link>
            <ul id="menu" class="metismenu mm-show">
                <li v-for="(item, index) in menuItems" :key="index">
                    <a class="has-arrow ai-icon" aria-expanded="false" v-if="isElementVissibleForCurrentUser(item.permission)">
                        <i :class="item.icon"></i>
                        <span class="nav-text">{{ $t(item.title) }}</span>
                    </a>
                    <ul v-if="item.children && isElementVissibleForCurrentUser(item.permission)" aria-expanded="false">

                        <li v-for="(subItem, i) in item.children" :key="i">
                            <template v-if="subItem.children">
                                <a class="has-arrow" aria-expanded="false">{{ $t(subItem.title) }}</a>
                                <ul aria-expanded="false" class="mm-collapse">
                                    <li v-for="(subsubItem, j) in subItem.children" :key="j">
                                        <router-link :to="{ name: subsubItem.router.name }">
                                            {{ $t(subsubItem.title) }}
                                        </router-link>
                                    </li>
                                </ul>
                            </template>
                            <router-link v-else :to="{ name: subItem.router.name }">
                                {{ $t(subItem.title) }}
                            </router-link>
                        </li>
                    </ul>
                </li>


            </ul>
        </perfect-scrollbar>
    </div>
</template>

<script>
    export default {
        components: {
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                menuItems: [
                    {
                        title: 'com.menu.settings',
                        icon: 'flaticon-381-settings-6',
                        permission: {
                            user: true,
                            operator: false,
                            administrator: false,
                            superAdministrator: true,
                        },
                        children: [
                            {
                                title: 'com.menu.i18n', children: [
                                    { title: 'com.menu.languages', router: { name: 'Languages' } },
                                    { title: 'com.menu.resources', router: { name: 'ResourcesList' } },
                                    { title: 'com.menu.translations', router: { name: 'Translations' } },
                                    { title: 'com.menu.countries', router: { name: 'CountriesList' } }
                                ]
                            },
                            {
                                title: 'com.menu.app.settings', children: [
                                    { title: 'com.menu.app.settings.keys', router: { name: 'AppSettingsKeysList' } },
                                    { title: 'com.menu.app.settings.types', router: { name: 'AppSettingTypeList' } },
                                ]
                            },
                            {
                                title: 'com.menu.emailtemplates', router: { name: 'EmailTemplatesList' }
                            }
                        ]
                    },
                    {
                        title: 'com.menu.users',
                        icon: 'flaticon-381-user-9',
                        permission: {
                            user: true,
                            operator: false,
                            administrator: true,
                            superAdministrator: true,
                        },
                        children: [
                            { title: 'com.menu.usermanagement', router: { name: 'UsersList' } },
                            { title: 'com.menu.customers', router: { name: 'CustomersList' } },
                            { title: 'com.menu.groups', router: { name: 'GroupsList' } },

                        ]
                    },
                    {
                        title: 'com.menu.quiz',
                        icon: 'flaticon-381-user-9',
                        permission: {
                            user: true,
                            operator: false,
                            administrator: false,
                            superAdministrator: false,
                        },
                        children: [
                            {
                                title: 'com.menu.quiz.questions', children: [
                                    { title: 'com.menu.quiz.questions.my', router: { name: 'QuizQuestionListMy' } },
                                    { title: 'com.menu.quiz.questions.all', router: { name: 'QuizQuestionList' } },
                                    { title: 'com.menu.quiz.questions.categories', router: { name: 'QuizQuestionCategoryList' } },
                                    { title: 'com.menu.quiz.questions.tags', router: { name: 'QuizQuestionTagList' } },
                                    { title: 'com.menu.quiz.questions.settings', router: { name: 'QuizSettingQuestionList' } },
                                ]
                            },
                            {
                                title: 'com.menu.quiz.votes', children: [
                                    { title: 'com.menu.quiz.vote.my', router: { name: 'QuizListMy' } },
                                    { title: 'com.menu.quiz.vote.all', router: { name: 'QuizListAll' } },
                                ]
                            },
                            {
                                title: 'com.menu.quiz.vote.block', children: [
                                    { title: 'com.menu.quiz.vote.block.my', router: { name: 'QuizVoteBlockListMy' } },
                                    { title: 'com.menu.quiz.vote.block.all', router: { name: 'QuizVoteBlockListAll' } },
                                ]
                            },
                        ]
                    },
                    {
                        title: 'com.menu.events',
                        icon: 'flaticon-381-user-9',
                        permission: {
                            user: true,
                            operator: false,
                            administrator: false,
                            superAdministrator: false,
                        },
                        children: [
                            { title: 'com.menu.event.settings', router: { name: 'EventSettingList' } },
                            { title: 'com.menu.event.all', router: { name: 'EventListAll' } },
                            { title: 'com.menu.event.my', router: { name: 'EventListMy' } },
                        ]
                    }
                ]
            };
        },
        computed: {
            isSuperAdmin() {
                return this.$store.state.auth.user.isUserIsSuperAdmin;
            },

            isAdmin() {
                return this.$store.state.auth.user.isUserIsAdmin;
            },

            isOperator() {
                return this.$store.state.auth.user.isUserIsOperator;
            },
            isUser() {
                return this.$store.state.auth.user.isUser;
            }
        },
        methods: {
            isElementVissibleForCurrentUser(permission) {
                if (this.isSuperAdmin) {
                    return true;
                }
                else if (this.isAdmin) {

                    if (permission.superAdministrator) {
                        return false;
                    }
                    return true;

                }
                else if (this.isOperator) {
                    if (permission.administrator) {
                        return false;
                    }
                    return true;
                }
                return true;
            }
        }
    }
</script>

<style>
    .ps {
        position: relative;
        height: 100%;
    }
</style>
