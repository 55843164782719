<template>
    <ckeditor v-model="editorData" :editor="editor" :config="editorConfig" @input="onEditorInput" />
</template>

<script>

    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    export default {
        components: {

        },

        props: {
            modelValue: {
                type: String,
                require: true,
                
            },
        },
        emits: ["update:modelValue"],
        data() {
            return {
                test: "",
                editor: ClassicEditor,
                editorData: "",
                editorConfig: {
                    fullPage: true,
                    allowedContent: true,
                    height: 500,
                    
                    toolbar: {
                        items: ['bold', 'italic', 'undo', 'redo', 'numberedList', 'bulletedList'],

                        //shouldNotGroupWhenFull: true
                    }
                },
                simpleUpload: {
                    uploadUrl: 'http://example.com',
                    headers: {
                        'X-CSRF-TOKEN': 'CSRF-Token',
                        Authorization: 'Bearer <JSON Web Token>'
                    }
                }
            }
        },

        watch: {
            modelValue: {
                // the callback will be called immediately after the start of the observation
                immediate: true,
                handler(val, oldVal) {
                    if (val != oldVal)
                        this.editorData = val;
                }
            }
            
        },
        mounted() {
        },

        beforeUnmount() {
           
        },

        methods: {
            onEditorInput() {
                this.$emit('update:modelValue', this.editorData)
            }
        },
    }
   
</script>

<style lang="scss">
   
</style>