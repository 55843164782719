<template>
    <!-- Modal Edit-->
    <form method="POST" class="needs-validation" novalidate="" autocomplete="off" @submit.prevent="UpdateLanguage">
        <div id="exampleModalCenter" class="modal fade" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Edit {{ selectedLanguage.name }} ({{ selectedLanguage.culture }})</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" @click="unsetLanguage()">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3 row">
                            <ErrorModalMessage v-if="error.isShow" :error="error" />
                        </div>
                        <input v-model="editLanguage.id" type="hidden" class="form-control" placeholder="Name" />
                        <div class="mb-3 row">
                            <label class="col-sm-3 col-form-label">
                                <strong>
                                    {{ $t('com.language.name') }}
                                </strong>
                            </label>
                            <div class="col-sm-9">
                                <input v-model="editLanguage.name" type="text" class="form-control" :placeholder="$t('com.language.name')" />
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <label class="col-sm-3 col-form-label"><strong>{{ $t('com.language.culture') }}</strong></label>
                            <div class="col-sm-9">
                                <input v-model="editLanguage.culture" type="text" class="form-control" :placeholder="$t('com.language.culture')" />
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <label class="col-sm-3 col-form-label"><strong>{{ $t('com.language.culture.short') }}</strong></label>
                            <div class="col-sm-9">
                                <input v-model="editLanguage.shortCulture" type="text" class="form-control" :placeholder="$t('com.language.culture.short')" />
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <label class="col-sm-3 col-form-label"><strong>{{ $t('com.language.description') }}</strong></label>
                            <div class="col-sm-9">
                                <input v-model="editLanguage.description" type="text" class="form-control" :placeholder="$t('com.language.description')" />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button id="editModalEmit" type="button" class="btn btn-danger light" data-bs-dismiss="modal" @click="unsetLanguage()">{{ $t('com.global.close') }}</button>
                        <button type="submit" class="btn btn-primary">{{ $t('com.global.save') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <!-- /Modal Edit -->
</template>

<script charset="utf-8">
    import ErrorModalMessage from "../common/error/ErrorModalMessage";
    export default {
        components: {
            ErrorModalMessage
        },
        props: {
            selectedLanguage: {
                type: Object,
                require: true,
                
            }
        },
        emits: ["unset-language", "reload-languages"],
        data() {
            return {
                publicPath: process.env.BASE_URL,
                editLanguage: {
                    id: 0,
                    name: "",
                    culture: "",
                    description: "",
                    shortCulture: "",
                },
                error: {
                    isShow: false,
                    header: "test",
                    message: "",
                }
            };
        },
        computed: {

        },
        watch: {
            selectedLanguage: {
                // the callback will be called immediately after the start of the observation
                immediate: true,
                handler(val, oldVal) {
                    if(val != oldVal)
                        this.editLanguage = val
                }
            }
        },
        methods: {
            async UpdateLanguage() {
                this.error.isShow = false;
                try {
                    const req = {
                        id: this.selectedLanguage.id,
                        name: this.selectedLanguage.name,
                        description: this.selectedLanguage.description,
                        culture: this.selectedLanguage.culture,
                        shortCulture: this.selectedLanguage.shortCulture
                    }
                    this.$store.dispatch("i18n/updateLanguage", req).then(
                        () => {
                            this.reloadLanguages();
                            document.getElementById('editModalEmit').click();
                        },
                        (error) => {
                            this.error.isShow = true;
                            this.error.header = "header";
                            this.error.message = error.data.translatedMessage;
                        }
                    );
                }
                catch (err) {
                    console.log(err)
                }
            },
            unsetLanguage() {
                this.$emit("unset-language")
            },
            reloadLanguages() {
                this.$emit("reload-languages")
            },
            clearData() {
                this.error.isShow = false;
                this.error.header = "";
                this.error.message = ""
            }
        }
    }</script>

<style>
</style>
