import api from "./api";
import url from "./url";

class DashboardService {

    GetUserDashboard() {
        return api.get(url.dashboard.userDashboard)
            .then((res) => {
                return res;
            })
    }
}

export default new DashboardService