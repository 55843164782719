import api from "./api";
import url from "./url";

class I18nService {
    GetLanguages(Pages) {
        return api.get(url.i18n.languages.getAll, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage
            }
        })
            .then((res) => {
                return res;
            })
    }
    UpdateLanguage(Language) {
        const req = {
            id: Language.id,
            name: Language.name,
            description: Language.description,
            culture: Language.culture,
            shortCulture: Language.shortCulture
        }
        return api.post(url.i18n.languages.update, req)
            .then((response) => {
                return response;
            });
    }
    DeleteLanguage(langId) {
        return api.delete(url.i18n.languages.delete + "/" + langId)
            .then((response) => {
                return response
            })
    }
    CreateLanguage(Language) {
        const req = {
            name: Language.name,
            description: Language.description,
            culture: Language.culture,
            shortCulture: Language.shortCulture
        }
        return api.post(url.i18n.languages.create,req)
            .then((response) => {
                return response;
            })
    }

    GetResources(Pages) {
        return api.get(url.i18n.resources.getAll, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage
            }
        })
            .then((res) => {
                return res;
            })
    }
    SearchByName(Request) {
        return api.get(url.i18n.resources.searchByName, {
            params: {
                PageNumber: Request.PageNumber,
                ItemsOnPage: Request.ItemsOnPage,
                SearchName: Request.SearchName,
            }
        })
            .then((res) => {
                return res;
            })
    }
    GetResourceById(resId) {
        return api.get(url.i18n.resources.getById + "/" + resId)
            .then((response) => {
                return response
            })
    }
    UpdateResource(Resource) {
        const req = {
            id: Resource.id,
            name: Resource.name,
            description: Resource.description,
            friendlyName: Resource.friendlyName
        }
        return api.post(url.i18n.resources.update, req)
            .then((response) => {
                return response;
            });
    }
    DeleteResource(resId) {
        return api.delete(url.i18n.resources.delete + "/" + resId)
            .then((response) => {
                return response
            })
    }
    CreateResource(Resource) {
        const req = {
            name: Resource.name,
            description: Resource.description,
            friendlyName: Resource.friendlyName
        }
        return api.post(url.i18n.resources.create, req)
            .then((response) => {
                return response;
            })
    }

    GetTranslations(Pages) {
        return api.get(url.i18n.translations.getAll, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage
            }
        })
            .then((res) => {
                return res;
            })
    }
    UpdateTranslation(Translation) {
        const req = {
            id: Translation.id,
            languageID: Translation.languageID,
            resourceID: Translation.resourceID,
            translation: Translation.translation
        }
        return api.post(url.i18n.translations.update, req)
            .then((response) => {
                return response;
            });
    }
    DeleteTranslation(transId) {
        return api.delete(url.i18n.translations.delete + "/" + transId)
            .then((response) => {
                return response
            })
    }
    CreateTranslation(Translation) {
        const req = {
            languageID: Translation.languageID,
            resourceID: Translation.resourceID,
            translation: Translation.translation
        }
        return api.post(url.i18n.translations.create, req)
            .then((response) => {
                return response;
            })
    }

    GetLocales(LanguageID) {
        return api.get(url.i18n.locales.getByLanguage + "/" + LanguageID)
            .then((res) => {
                return res;
            })
    }

    GetAuthLocales(LanguageID) {

        return api.get(url.i18n.locales.getAuthBeLanguage + "/" + LanguageID)
            .then((res) => {
                return res;
            })
    }

    //countries
    GetCountries(Pages) {
        return api.get(url.i18n.countries.getAll, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage
            }
        })
            .then((res) => {
                return res;
            })
    }

    GetCountryByID(CountryID) {
        return api.get(url.i18n.countries.getById + "/" + CountryID)
            .then((res) => {
                return res;
            })
    }

    GetCountryByAbbr(CountryAbbr) {
        return api.get(url.i18n.countries.getByAbbr + "/" + CountryAbbr)
            .then((res) => {
                return res;
            })
    }
    CreateCountry(request) {
        const req = {
            name: request.name
        }
        return api.post(url.i18n.countries.create, req)
            .then((response) => {
                return response;
            });
    }
    UpdateCountry(request) {
        const req = {
            id: request.id,
            name: request.name
        }
        return api.post(url.i18n.countries.update, req)
            .then((response) => {
                return response;
            });
    }

    DeleteCountry(CountryID) {
        return api.delete(url.i18n.countries.delete + "/" + CountryID)
            .then((res) => {
                return res;
            })
    }

    GetCountriesBin(Pages) {
        return api.get(url.i18n.countries.bin, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage
            }
        })
            .then((res) => {
                return res;
            })
    }

    DeletePermanentlyCountry(CountryID) {
        return api.delete(url.i18n.countries.deletePermanently + "/" + CountryID)
            .then((res) => {
                return res;
            })
    }
    RestoreCountry(CountryID) {
        return api.post(url.i18n.countries.retore + "/" + CountryID)
            .then((res) => {
                return res;
            })
    }
}

export default new I18nService