<template>
    <div class="row mb-3">
        <div class="col-6">
            <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
        </div>
        <div class="col-6">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <EventButtonNavigation />
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ subTitle }}</h4>
                </div>
                <div class="card-body">
                    <form method="post" class="needs-validation" novalidate="" autocomplete="off" @submit.prevent="updateEvent">
                        <div class="row">
                            <div class="col-6 offset-3">
                                <div class="mb-3 row">
                                    <ErrorModalMessage v-if="error.isShow" :error="error" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.event.name') }}</strong></label>
                                    <input v-model="event.name" type="text" class="form-control" :placeholder="$t('com.event.name')" :disabled="loading.global" />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.event.description') }}</strong></label>
                                    <input v-model="event.description" type="text" class="form-control" :placeholder="$t('com.event.description')" :disabled="loading.global" />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.event.language') }}</strong></label>
                                    <VueMultiselect v-model="event.language"
                                                    :options="languages"
                                                    :searchable="true"
                                                    :placeholder="$t('com.event.language')"
                                                    :multiple="false"
                                                    label="name"
                                                    track-by="id"
                                                    :disabled="loading.global"
                                                    :showLabels="false"
                                                    :allow-empty="false"
                                    />
                                    <pre class="language-json"><code>{{ event.language }}</code></pre>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.event.customer') }}</strong></label>
                                    <VueMultiselect v-model="event.customer"
                                                    :options="customers"
                                                    :searchable="true"
                                                    :placeholder="$t('com.event.customer')"
                                                    :multiple="false"
                                                    label="name"
                                                    track-by="id"
                                                    :disabled="loading.global"
                                                    :showLabels="false"
                                                    :allow-empty="false"
                                    />
                                    <pre class="language-json"><code>{{ event.customer }}</code></pre>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.event.start.date') }}</strong></label>
                                    <Datepicker v-model="event.eventStart" :format-locale="shortCulture" :minDate="new Date()" :disabled="loading.global" :placeholder="$t('com.event.start.date')" />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.event.end.date') }}</strong></label>
                                    <Datepicker v-model="event.eventEnd" :format-locale="shortCulture" :disabled="loading.global" :minDate="event.eventStart" :placeholder="$t('com.event.end.date')" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <pre class="language-json"><code>{{ event }}</code></pre>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <button type="submit" class="btn btn-primary" :disabled="loading.global">
                                        <span v-if="loading.global" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        {{ $t('com.global.save') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script charset="utf-8">
    import PageTitle from "@/components/common/PageTitle.vue";
    import EventButtonNavigation from "@/components/event/EventButtonNavigation.vue"
    import EventService from "@/services/event/event.service";
    import I18nService from "@/services/i18n.service";
    import CustomerService from "@/services/customer.service";
    import ErrorModalMessage from "@/components/common/error/ErrorModalMessage";
    import VueMultiselect from 'vue-multiselect'
    import Datepicker from '@vuepic/vue-datepicker';


    import '@vuepic/vue-datepicker/dist/main.css'
    export default {
        components: {
            PageTitle,
            EventButtonNavigation,
            ErrorModalMessage,
            VueMultiselect,
            Datepicker
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "Event",
                subTitle: "com.event",

                event: {
                    id: 0,
                    name: "",
                    description: "",
                    language: {},
                    customer: {},
                    eventStart: "",
                    eventEnd: "",
                },

                languages: [],

                customers: [],

                error: {
                    isShow: false,
                    header: "test",
                    message: "",
                    errors: []
                },

                loading: {
                    global: false,
                    customers: false,
                }
            };
        },
        computed: {
            shortCulture() {
                return this.$store.state.auth.user.language.shortCulture
            },
            eventId() {
                return this.$route.params.id;
            }
        },
        watch: {

        },
        mounted() {
            this.fetchEvent();
            this.fetchCustomers();
            this.fetchLanguages();

        },
        methods: {
            async fetchEvent() {
                try {
                    this.loading.global = true;

                    let res = (await EventService.GetById(this.eventId));

                    if (res.status == 200) {
                        this.event = res.data;

                        console.log(this.event);
                        //this.event.customer = this.customers.find(obj => { return obj.id })
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.global = false;
                }
            },

            async fetchLanguages() {
                console.log("user auth", this.$store.state.auth.user);
                try {
                    const req = {
                        PageNumber: 1,
                        ItemsOnPage: 100,
                    }
                    this.languages = (await I18nService.GetLanguages(req)).data.languageItems

                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
            },

            async fetchCustomers() {
                try {
                    this.loading.customers = true;

                    const req = {
                        PageNumber: 1,
                        ItemsOnPage: 1000,
                    }

                    let res = (await CustomerService.GetCustomers(req))

                    if (res.status == 200) {
                        this.customers = res.data.customers
                    }

                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.customers = false;
                }
            },

            async updateEvent() {
                try {
                    this.loading.global = true;

                    const req = {
                        id: this.event.id,
                        name: this.event.name,
                        description: this.event.description,
                        customerID: this.event.customer.id,
                        languageID: this.event.language.id,
                        eventStart: this.event.eventStart,
                        eventEnd: this.event.eventEnd
                    }

                    let res = (await EventService.Update(req))
                    if (res.status == 200) {
                        this.$toast.success(this.$t("com.event.update.success"));
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.global = false;
                }
            }

        }
    }</script>

<style>
</style>
