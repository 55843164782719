<template>
    <div id="addSettingValue" class="modal fade" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <form method="POST" class="needs-validation" novalidate="" autocomplete="off" @submit.prevent="addSettingValue">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ $t('com.modal.areyousure') }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label class="form-label"><strong>{{ $t('com.app.setting.key.settingtype') }}</strong></label>
                            <VueMultiselect v-model="modalData.settingValue"
                                            :options="availableOptions"
                                            :searchable="true"
                                            :placeholder="$t('com.app.setting.key.settingtype')"
                                            :multiple="false"
                                            label="name"
                                            track-by="id"
                                            :disabled="loading"
                                            :showLabels="false"
                                            :allow-empty="false"
                            />
                            <pre class="language-json"><code>{{ modalData.settingValue }}</code></pre>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button id="addSettingValueEmit" type="button" class="btn btn-danger light" data-bs-dismiss="modal" @click="unsetItem()">{{ $t('com.global.close') }}</button>
                        <button type="submit" class="btn btn-primary">{{ $t('com.global.add') }}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>


</template>

<script charset="utf-8">
    import AppSettingValueService from "../../../../services/app.setting.value.service";
    import VueMultiselect from 'vue-multiselect'
    export default {
        components: {
            VueMultiselect
        },
        props: {
            error: {
                type: Object,
                require: true,
                default: function () {
                    return {
                        isShow: false,
                        header: "test",
                        message: "",
                    }
                },
                

            },
            appSettingID: {
                type: Number,
                require: true,
            },
            closeModal: {
                type: Boolean,
                require: true,
            }

        },
        emits: ["add-setting-value"],
        data() {
            return {
                publicPath: process.env.BASE_URL,
                availableOptions: [],
                modalData: {
                    settingValue: "",
                }
            };
        },
        computed: {

        },
        watch: {
            closeModal: {
                immediate: true,
                handler(val, oldVal) {
                    if (val != oldVal) {
                        if (val == true) {
                            this.modalData= {
                                settingValue: "",
                            }
                            this.availableOptions = []
                            this.fetchAvailableOptions(this.appSettingID); 
                            document.getElementById('addSettingValueEmit').click();
                        }

                    }
                }
            },
            appSettingID: {
                immediate: true,
                handler(val) {
                        let id = parseInt(val)
                        if (id > 0) {
                            this.fetchAvailableOptions(val);
                        }
                   
                }
            }
        },
        mounted() {
        },
        methods: {
            async fetchAvailableOptions(KeyID) {
                try {
                    let res = (await AppSettingValueService.GetAvailableOptionsByKeyId(KeyID))
                    if (res.status == 200) {
                        this.availableOptions = res.data
                    }
                }
                catch (e) {
                    //console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
            },
            addSettingValue() {
                this.$emit("add-setting-value", this.modalData);
            }
        }
    }</script>

<style>
</style>
