<template>
    <div class="row mb-3">
        <div class="col-6">
            <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
        </div>
        <div class="col-6">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <EventSettingButtonNavigation />
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ $t(subTitle) }}</h4>
                </div>
                <div class="card-body">
                    <table class="table table-responsive">
                        <thead>
                            <tr>
                                <th style="width:80px;"><strong>#</strong></th>
                                <th><strong>{{ $t('com.event.setting.key.name') }}</strong></th>
                                <th><strong>{{ $t('com.event.setting.key.isrequired') }}</strong></th>
                                <th><strong>{{ $t('com.event.setting.key.type') }}</strong></th>
                                <th><strong>{{ $t('com.event.setting.key.defaultvalue') }}</strong></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(eventSetting, index) in eventSettings" :key="index">
                                <td><strong>{{ getIndex(index) }}</strong></td>
                                <td>{{ eventSetting.name }}</td>
                                <td>{{ eventSetting.isRequired }}</td>
                                <td>{{ eventSetting.variableTypeName }}</td>
                                <td>{{ eventSetting.defaultValue }}</td>
                                <td>
                                    <div class="dropdown">
                                        <button :id="getDropdownID(index)" type="button" class="btn btn-success light sharp" data-bs-toggle="dropdown" aria-expanded="false">
                                            <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24" />
                                                    <circle fill="#000000" cx="5" cy="12" r="2" />
                                                    <circle fill="#000000" cx="12" cy="12" r="2" />
                                                    <circle fill="#000000" cx="19" cy="12" r="2" />
                                                </g>
                                            </svg>
                                        </button>
                                        <div class="dropdown-menu" :aria-labelledby="getDropdownID(index)">

                                            <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modalItemRestore" @click="setEventSetting(eventSetting)">{{ $t('com.global.restore') }}</a>
                                            <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modalItemDelete" @click="setEventSetting(eventSetting)">{{ $t('com.global.deletepermanently') }}</a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="row mt-3">
                        <Pagination :pages="pages" @page-request="pageRequest" />
                    </div>
                </div>
            </div>

        </div>
    </div>

    <ModalDelete :error="errorModalEdit"
                 :deletedItem="deletedItem"
                 :closeModal="closeModal"
                 @unset-item="unsetEventSetting()"
                 @confirm-delete="deleteEventSetting()"
    />

    <ModalRestore :error="errorModalEdit"
                  :restoreItem="restoreItem"
                  :closeModal="closeDeleteModal"
                  @unset-item="unsetEventSetting()"
                  @confirm-restore="confirmRestore()"
    />
</template>

<script charset="utf-8">
    import PageTitle from "../../../components/common/PageTitle.vue";
    import EventSettingButtonNavigation from "../../../components/event/setting/EventSettingButtonNavigation.vue"
    import EventSettingService from "../../../services/event/event.setting.service";
    import Pagination from "../../../components/common/Pagination.vue"
    import ModalDelete from "../../../components/common/modal/ModalDelete.vue"
    import ModalRestore from "../../../components/common/modal/ModalRestore.vue"
    export default {
        components: {
            PageTitle,
            EventSettingButtonNavigation,
            Pagination,
            ModalDelete,
            ModalRestore
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "Event Setting",
                subTitle: "com.event.setting",
                loading: true,
                eventSettings: [],
                selectedEventSetting: {},
                pages: {},

                errorModalEdit: {
                    isShow: false,
                    header: "test",
                    message: "",
                },

                selectedQuestion: {},
                deletedItem: "",
                restoreItem: "",
                closeDeleteModal: false,
                closeModal: false
            };
        },
        computed: {

        },
        watch: {

        },
        mounted() {
            this.fetchEventSettings(this.getCurrentPage(), this.getCurrentItemsOnPage());
        },
        methods: {
            async fetchEventSettings(page = 1, items = 10) {
                try {
                    this.loading = true;
                    const req = {
                        PageNumber: page,
                        ItemsOnPage: items,
                    }
                    let res = (await EventSettingService.GetBin(req));
                    if (res.status == 200) {
                        this.eventSettings = res.data.items;
                        this.pages = res.data.page;
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },

            async deleteEventSetting() {
                try {
                    this.loading = true;

                    let res = (await EventSettingService.DeletePermanently(this.selectedEventSetting.id))

                    if (res.status == 200) {
                        this.closeModal = true;
                        this.$toast.success(this.$t("com.event.setting.delete.success"));
                        this.fetchEventSettings(this.getCurrentPage(), this.getCurrentItemsOnPage());
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }

            },

            async confirmRestore() {
                try {
                    this.loading = true;

                    let res = (await EventSettingService.Restore(this.selectedEventSetting.id))

                    if (res.status == 200) {
                        this.fetchEventSettings(this.getCurrentPage(), this.getCurrentItemsOnPage());
                        this.closeDeleteModal = true;
                        this.$toast.success(this.$t("com.event.setting.restore.success"));

                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },
            getCurrentPage() {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;
                return page;
            },
            getCurrentItemsOnPage() {
                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 10;
                if (itemsPerPage == 0)
                    itemsPerPage = 10;
                return itemsPerPage
            },
            getIndex(index) {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;

                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 0;

                return ((page - 1) * itemsPerPage) + index + 1
            },
            pageRequest(page) {
                this.$router.push(
                    {
                        name: 'EventSettingListPages',
                        params: {
                            page: page,
                            items: this.getCurrentItemsOnPage()
                        }
                    })
            },
            getDropdownID(str) {
                return "dropdownButton" + str;
            },

            setEventSetting(item) {
                this.closeModal = false;
                this.closeDeleteModal = false;
                this.selectedEventSetting = item;
            },
            unsetEventSetting() {
                this.selectedEventSetting = {};
            }
        }
    }</script>

<style>
</style>
