import api from "../api";
import url from "../url";

class EventSettingService {
    GetAll(Pages) {
        return api.get(url.event.setting.getAll, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage
            }
        })
            .then((res) => {
                return res;
            })
    }



    GetById(EventID) {
        return api.get(url.event.setting.getById + "/" + EventID)
            .then((res) => {
                return res;
            })
    }

    Create(request) {
        const req = {
            Name: request.name,
            VariableType: request.variableType,
            DefaultValue: request.defaultValue,
            IsRequired: request.isRequired,
            TypeID: request.typeID,

        }

        return api.post(url.event.setting.create, req)
            .then((response) => {
                return response;
            });
    }

    Update(request) {
        const req = {
            Id: request.id,
            Name: request.name,
            VariableType: request.variableType,
            DefaultValue: request.defaultValue.toString(),
            IsRequired: request.isRequired,
            TypeID: request.typeID,
        }
        return api.post(url.event.setting.update, req)
            .then((response) => {
                return response;
            });
    }

    Delete(EventID) {
        return api.delete(url.event.setting.delete + "/" + EventID)
            .then((res) => {
                return res;
            })
    }

    GetBin(Pages) {
        return api.get(url.event.setting.bin, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage
            }
        })
            .then((res) => {
                return res;
            })
    }
    DeletePermanently(EventID) {
        return api.delete(url.event.setting.deletePermanently + "/" + EventID)
            .then((res) => {
                return res;
            })
    }

    Restore(EventID) {
        return api.post(url.event.setting.retore + "/" + EventID)
            .then((res) => {
                return res;
            })
    }

    GetAvailableSettingsByEvent(EventID) {
        return api.get(url.event.setting.getAvailableSettings + "/" + EventID)
            .then((res) => {
                return res;
            })
    }

    GetValueByEventID(EventID) {
        return api.get(url.event.setting.getAllValuesByEvent + "/" + EventID)
    }

    GetValueByEventCode(EventCode) {
        return api.get(url.event.setting.getAllValuesByEventCode + "/" + EventCode)
    }

    CreateSettingValue(request) {
        const req = {
            Value: request.value,
            EventID: request.eventID,
            SettingID: request.settingID,
        }
        return api.post(url.event.setting.createEventValue, req)
            .then((res) => {
                return res;
            })
    }

    UpdateSettingValue(request) {
        const req = {
            Id: request.id,
            Value: request.value,
            SettingID: request.settingID,
        }
        return api.post(url.event.setting.update, req)
            .then((res) => {
                return res;
            })
    }

    DeleteSettingValue(ValueID) {
        return api.delete(url.event.setting.deleteSettingValue + "/" + ValueID);
    }
}

export default new EventSettingService;