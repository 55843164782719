import UserService from '../../services/user.service';

export const user = {
    namespaced: true,
    state: null,
    actions: {
        getAll({ commit }, request) {
            commit('empty');
            return UserService.GetAll(request).then(
                user => {
                    //commit('loginSuccess');
                    return Promise.resolve(user);
                },
                error => {
                    //commit('loginFailure');
                    return Promise.reject(error.response);
                }
            );
        },
        getById({ commit }, userID) {
            commit('empty');
            return UserService.GetById(userID).then(
                user => {
                    return Promise.resolve(user)
                },
                error => {
                    return Promise.reject(error.response)
                }
            );
        },
        register({ commit }, request) {
            commit('empty');
            return UserService.Register(request).then(
                user => {
                    return Promise.resolve(user)
                },
                error => {
                    return Promise.reject(error.response)
                }
            );
        },

        getLastLoginCurrentUser({ commit }) {
            commit('empty');
            return UserService.GetLastLoginCurrentUser().then(
                user => {
                    return Promise.resolve(user)
                },
                error => {
                    return Promise.reject(error.response)
                }
            );
        },

        getLastFailedLoginCurrentUser({ commit }) {
            commit('empty');
            return UserService.GetLastFailedLoginCurrentUser().then(
                user => {
                    return Promise.resolve(user)
                },
                error => {
                    return Promise.reject(error.response)
                }
            );
        },

        getLoginsCurrentUser({ commit }, request) {
            commit('empty');
            return UserService.GetLoginsCurrentUser(request).then(
                user => {
                    return Promise.resolve(user)
                },
                error => {
                    return Promise.reject(error.response)
                }
            );
        },

        getLastLoginByUserId({ commit }, userId) {
            commit('empty');
            return UserService.GetLastLoginByUserId(userId).then(
                user => {
                    return Promise.resolve(user)
                },
                error => {
                    return Promise.reject(error.response)
                }
            );
        },

        getLastFailedLoginByUserId({ commit }, userId) {
            commit('empty');
            return UserService.GetLastFailedLoginByUserId(userId).then(
                user => {
                    return Promise.resolve(user)
                },
                error => {
                    return Promise.reject(error.response)
                }
            );
        },

        getLoginsByUserId({ commit }, request) {
            commit('empty');
            return UserService.GetLoginsByUserId(request).then(
                user => {
                    return Promise.resolve(user)
                },
                error => {
                    return Promise.reject(error.response)
                }
            );
        },
        //management
        createUser({ commit }, request) {
            commit('empty');
            return UserService.CreateUser(request).then(
                user => {
                    return Promise.resolve(user)
                },
                error => {
                    return Promise.reject(error.response)
                }
            );
        },
        deleteUser({ commit }, request) {
            commit('empty');
            return UserService.DeleteUser(request).then(
                user => {
                    return Promise.resolve(user)
                },
                error => {
                    return Promise.reject(error.response)
                }
            );
        },
        changeUserPassword({ commit }, request) {
            commit('empty');
            return UserService.ChangeUserPassword(request).then(
                user => {
                    return Promise.resolve(user)
                },
                error => {
                    return Promise.reject(error.response)
                }
            );
        },

        checkLogin({ commit }, request) {
            commit('empty');
            return UserService.CheckLogin(request).then(
                user => {
                    return Promise.resolve(user)
                },
                error => {
                    return Promise.reject(error.response)
                }
            );
        },
        checkEmail({ commit }, request) {
            commit('empty');
            return UserService.CheckEmail(request).then(
                user => {
                    return Promise.resolve(user)
                },
                error => {
                    return Promise.reject(error.response)
                }
            );
        },

        checkPhone({ commit }, request) {
            commit('empty');
            return UserService.CheckPhone(request).then(
                user => {
                    return Promise.resolve(user)
                },
                error => {
                    return Promise.reject(error.response)
                }
            );
        },

        updateUser({ commit }, request) {
            commit('empty');
            return UserService.UpdateUser(request).then(
                user => {
                    return Promise.resolve(user)
                },
                error => {
                    return Promise.reject(error.response)
                }
            );
        }
        
    },

    mutations: {
        empty() {

        },
    }
};
