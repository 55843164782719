<template>
    <div id="app-visualisation">
        <div id="main-wrapper" class="show">
            <NavHeader />
            <Header />
            <div class="content-body">
                <div class="container-fluid">
                    <router-view />
                </div>
            </div>
            <Footer />
        </div>
    </div>

</template>
<script charset="utf-8">
    import NavHeader from '@/components/common/NavHeader.vue';
    import Header from '@/components/common/Header.vue';
    import Footer from '@/components/common/Footer.vue';
    export default {
        components: {
            Footer,
            NavHeader,
            Header
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,

                componentConfig: {
                    transparent: true,

                }
            };
        },
        computed: {
            userLang() {
                return this.$store.state.auth.user.language
            },
        },
        created() {
            //this.FetchLocale(this.userLang)
        },
        mounted() {
            this.FastInit();
        },
        methods: {
            async FetchLocale(lanID) {
                try {
                    this.loading = true;

                    let res = (await I18nService.GetLocales(lanID))

                    if (res.status == 200) {
                        this.$i18n.setLocaleMessage(res.data.locale, res.data.items)
                        this.$i18n.locale = res.data.locale;
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },

            ///Fasto init methods
            FastInit() {
                this.HandleMetisMenu();
                this.HandleNavigation();
                this.HandleMiniSidebar();
                this.HandleMinHeight();
                this.HandleHeaderHight();
                //this.HandleDzScroll();
                this.HandleMenuTabs();
                this.HandleDzFullScreen();
            },
            HandleMetisMenu() {
                if (jQuery('#menu').length > 0) {
                    jQuery("#menu").metisMenu();
                }
                jQuery('.metismenu > .mm-active ').each(function () {
                    if (!jQuery(this).children('ul').length > 0) {
                        jQuery(this).addClass('active-no-child');
                    }
                });
            },

            HandleNavigation() {
                jQuery(".nav-control").on('click', function () {

                    jQuery('#main-wrapper').toggleClass("menu-toggle");

                    jQuery(".hamburger").toggleClass("is-active");
                });
            },

            HandleMiniSidebar() {
                jQuery("ul#menu>li").on('click', function () {
                    const sidebarStyle = jQuery('body').attr('data-sidebar-style');
                    if (sidebarStyle === 'mini') {
                        console.log(jQuery(this).find('ul'))
                        jQuery(this).find('ul').stop()
                    }
                })
            },

            HandleMinHeight() {
                let win_h = window.outerHeight;
                if (win_h > 0 ? win_h : screen.height) {
                    jQuery(".content-body").css("min-height", win_h + "px");
                }
            },

            HandleHeaderHight() {
                const headerHight = jQuery('.header').innerHeight();
                jQuery(window).scroll(function () {
                    if (jQuery('body').attr('data-layout') === "horizontal" && jQuery('body').attr('data-header-position') === "static" && jQuery('body').attr('data-sidebar-position') === "fixed")
                        jQuery(this.window).scrollTop() >= headerHight ? jQuery('.deznav').addClass('fixed') : jQuery('.deznav').removeClass('fixed')
                });
            },

            HandleMenuTabs() {
                let screenWidth = jQuery(window).width();
                if (screenWidth <= 991) {
                    jQuery('.menu-tabs .nav-link').on('click', function () {
                        if (jQuery(this).hasClass('open')) {
                            jQuery(this).removeClass('open');
                            jQuery('.fixed-content-box').removeClass('active');
                            jQuery('.hamburger').show();
                        } else {
                            jQuery('.menu-tabs .nav-link').removeClass('open');
                            jQuery(this).addClass('open');
                            jQuery('.fixed-content-box').addClass('active');
                            jQuery('.hamburger').hide();
                        }
                        //jQuery('.fixed-content-box').toggleClass('active');
                    });
                    jQuery('.close-fixed-content').on('click', function () {
                        jQuery('.fixed-content-box').removeClass('active');
                        jQuery('.hamburger').removeClass('is-active');
                        jQuery('#main-wrapper').removeClass('menu-toggle');
                        jQuery('.hamburger').show();
                    });
                }
            },
            //HandleDzScroll() {
            //    jQuery('.dz-scroll').each(function () {

            //        var scroolWidgetId = jQuery(this).attr('id');
            //        const ps = new PerfectScrollbar('#' + scroolWidgetId, {
            //            wheelSpeed: 2,
            //            wheelPropagation: true,
            //            minScrollbarLength: 20
            //        });
            //        ps.isRtl = false;
            //    })
            //},


            HandleDzFullScreen() {
                jQuery('.dz-fullscreen').on('click', function () {
                    if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
                        /* Enter fullscreen */
                        if (document.exitFullscreen) {
                            document.exitFullscreen();
                        } else if (document.msExitFullscreen) {
                            document.msExitFullscreen(); /* IE/Edge */
                        } else if (document.mozCancelFullScreen) {
                            document.mozCancelFullScreen(); /* Firefox */
                        } else if (document.webkitExitFullscreen) {
                            document.webkitExitFullscreen(); /* Chrome, Safari & Opera */
                        }
                    }
                    else { /* exit fullscreen */
                        if (document.documentElement.requestFullscreen) {
                            document.documentElement.requestFullscreen();
                        } else if (document.documentElement.webkitRequestFullscreen) {
                            document.documentElement.webkitRequestFullscreen();
                        } else if (document.documentElement.mozRequestFullScreen) {
                            document.documentElement.mozRequestFullScreen();
                        } else if (document.documentElement.msRequestFullscreen) {
                            document.documentElement.msRequestFullscreen();
                        }
                    }
                });
            }
        }
    }
</script>
<style type="text/less">
    #app-visualisation #main-wrapper .content-body {
        margin-left: 0px !important;
    }

    #app-visualisation #main-wrapper .header-right .header-profile > a.nav-link {
        border: 0px !important;
    }
</style>