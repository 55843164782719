<template>
    <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Recent Payments Queue</h4>
                    <QuizQuestionButtonNavigation />
                </div>
                <div class="card-body">
                    <form method="POST" class="needs-validation" novalidate="" autocomplete="off" @submit.prevent="updateQustion">
                        <div class="row">
                            <div class="col-md-6 offset-md-3">
                                <div class="mb-3 row">
                                    <ErrorModalMessage v-if="error.isShow" :error="error" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">

                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.quiz.question.name') }}</strong></label>
                                    <input v-model="quiz.name" type="text" class="form-control" :placeholder="$t('com.quiz.question.name')" :disabled="loading.global" />
                                </div>

                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.quiz.question.customer') }}</strong></label>
                                    <VueMultiselect v-model="quiz.customer"
                                                    :options="customers"
                                                    :searchable="true"
                                                    :placeholder="$t('com.quiz.question.customer')"
                                                    :multiple="false"
                                                    label="name"
                                                    track-by="id"
                                                    :disabled="loading.languages"
                                                    :showLabels="false"
                                                    :allow-empty="false" />
                                </div>

                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.quiz.question.language') }}</strong></label>
                                    <VueMultiselect v-model="quiz.language"
                                                    :options="languages"
                                                    :searchable="true"
                                                    :placeholder="$t('com.quiz.question.language')"
                                                    :multiple="false"
                                                    :custom-label="nameWithLang"
                                                    track-by="id"
                                                    :disabled="loading.languages"
                                                    :showLabels="false"
                                                    :allow-empty="false" />
                                </div>

                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.quiz.question.type') }}</strong></label>
                                    <VueMultiselect v-model="quiz.type"
                                                    :options="questionTypes"
                                                    :searchable="true"
                                                    :placeholder="$t('com.quiz.question.type')"
                                                    :multiple="false"
                                                    :custom-label="translatedType"
                                                    track-by="id"
                                                    :disabled="loading.types"
                                                    :showLabels="false"
                                                    :allow-empty="false" />
                                </div>

                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.quiz.question.categories') }}</strong></label>
                                    <VueMultiselect v-model="quiz.categories"
                                                    :options="categories"
                                                    :searchable="true"
                                                    :placeholder="$t('com.quiz.question.categories')"
                                                    :multiple="true"
                                                    label="name"
                                                    track-by="id"
                                                    :disabled="loading.categories"
                                                    :showLabels="false"
                                                    :taggable="true"
                                                    :allow-empty="false"
                                                    @tag="addQuestionCategory" />
                                </div>

                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.quiz.question.tags') }}</strong></label>
                                    <VueMultiselect v-model="quiz.tags"
                                                    :options="tags"
                                                    :searchable="true"
                                                    :placeholder="$t('com.quiz.question.tags')"
                                                    :multiple="true"
                                                    track-by="id"
                                                    label="name"
                                                    :disabled="loading.tags"
                                                    :showLabels="false"
                                                    :taggable="true"
                                                    :allow-empty="false"
                                                    @tag="addQuestionTag" />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.quiz.question.description') }}</strong></label>
                                    <editor v-model="quiz.description" :disabled="loading.global" />
                                    
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <button type="submit" class="btn btn-primary" :disabled="loading.global">
                                        <span v-if="loading.global" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        {{ $t('com.global.save') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script charset="utf-8">
    import VueMultiselect from 'vue-multiselect'
    import PageTitle from "@/components/common/PageTitle.vue";
    import QuizQuestionButtonNavigation from "@/components/quiz/question/QuizQuestionButtonNavigation.vue";
    import Editor from "@/components/common/editor/Editor.vue";
    import ErrorModalMessage from "@/components/common/error/ErrorModalMessage";
    import FileManager from "@/components/file/FileManager.vue"


    import QuizQuestionCategoryService from "@/services/quiz/quiz.question.category.service"
    import QuizQuestionTagService from "@/services/quiz/quiz.question.tag.service"
    import QuizQuestionService from "@/services/quiz/quiz.question.service"
    import QuizQuestionTypeService from "@/services/quiz/quiz.question.type.service"
    import CustomerService from "@/services/customer.service";
    import I18nService from "@/services/i18n.service";
    export default {
        components: {
            PageTitle,
            QuizQuestionButtonNavigation,
            VueMultiselect,
            Editor,
            ErrorModalMessage
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "Quiz Question Edit",
                subTitle: "com.quiz.question",
                quiz: {
                    name: "",
                    description: "",
                    language: {},
                    customer: {},
                    categories: [],
                    tags: [],
                    type: {},
                },
                languages: [],
                categories: [],
                questionTypes: [],
                tags: [],
                customers: [],
                error: {
                    isShow: false,
                    header: "test",
                    message: "",
                    errors: []
                },
                loading: {
                    global: false,
                    categories: false,
                    tags: false,
                    languages: false,
                    type: false,
                }
            };
        },
        computed: {
            questionId() {
                return this.$route.params.id;
            }
        },
        watch: {

        },
        mounted() {
            this.fetchLanguages();
            this.fetchQuestionTypes();
            this.fetchTags();
            this.fetchCategories();
            this.fetchCustomers();
            this.fetchQuestion();

        },
        methods: {

            async fetchLanguages() {
                try {
                    const req = {
                        PageNumber: 1,
                        ItemsOnPage: 100,
                    }
                    this.languages = (await I18nService.GetLanguages(req)).data.languageItems

                }
                catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'LoginView' })
                }
            },

            async fetchTags() {
                try {
                    this.tags = (await QuizQuestionTagService.GetAll()).data
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
            },

            async fetchCategories() {
                try {
                    this.categories = (await QuizQuestionCategoryService.GetAll()).data
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
            },

            async fetchCustomers() {
                try {
                    this.loading.customers = true;

                    const req = {
                        PageNumber: 1,
                        ItemsOnPage: 1000,
                    }

                    let res = (await CustomerService.GetCustomers(req))

                    if (res.status == 200) {
                        this.customers = res.data.customers
                    }

                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.customers = false;
                }
            },

            async fetchQuestionTypes() {
                try {
                    this.loading.type = true;

                    let res = (await QuizQuestionTypeService.GetAll());
                    if (res.status == 200) {
                        this.questionTypes = res.data;

                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.type = false;
                }
            },

            async fetchQuestion() {
                try {
                    this.loading.global = true;

                    let res = (await QuizQuestionService.GetById(this.questionId));

                    if (res.status == 200) {
                        this.quiz = res.data;
                        this.quiz.customer = res.data.customers[0];
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.global = false;
                }
            },

            async addQuestionTag(newTag) {
                try {
                    this.loading.tags = true;
                    const req = {
                        name: newTag
                    }

                    let res = (await QuizQuestionTagService.CreateFromQuestion(req))
                    if (res.status == 200) {
                        this.tags.push(res.data);
                        this.quiz.tags.push(res.data);
                        this.$toast.success(this.$t("com.quiz.tag.createsuccess"));
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.tags = false;
                }
            },

            async addQuestionCategory(newCateogry) {
                try {
                    this.loading.categories = true;

                    const req = {
                        name: newCateogry
                    };

                    let res = (await QuizQuestionCategoryService.CreateFromQuestion(req));
                    if (res.status == 200) {
                        this.categories.push(res.data);
                        this.quiz.categories.push(res.data);
                        this.$toast.success(this.$t("com.quiz.category.createsuccess"));
                    }

                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.categories = false;
                }
            },

            async updateQustion() {
                try {
                    this.loading.global = true;

                    const req = {
                        id: this.quiz.id,
                        name: this.quiz.name,
                        description: this.quiz.description,
                        languageID: this.quiz.language.id,
                        customerID: this.quiz.customer.id,
                        typeID: this.quiz.type.id,
                        fileID: 0,
                        categories: this.quiz.categories.map(item => item.id),
                        tags: this.quiz.tags.map(item => item.id),
                    }

                    const res = (await QuizQuestionService.Update(req));

                    if (res.status == 200) {
                        this.$toast.success(this.$t('com.quiz.question.updatesuccess'));
                        this.fetchQuestion();
                    }



                }
                catch (e) {
                    console.log("Bład", e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.global = false;
                }
            },

            nameWithLang(obj) {
                if (Object.keys(obj).length === 0)
                    return "Select";

                return obj.name + " (" + obj.culture + ")"
            },


            translatedType(obj) {

                if (Object.keys(obj).length === 0)
                    return "Select"

                return this.$t(obj.name);
            },

        }
    }</script>

<style>
</style>
