<template>
    <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Recent Payments Queue</h4>
                    <div class="btn-group">
                        <router-link :to="{ name: 'CountriesBin' }" type="button" class="btn btn-primary">
                            {{ $t("com.global.bin") }}
                        </router-link>
                        <router-link :to="{ name: 'CountriesList' }" type="button" class="btn btn-primary">
                            {{ $t("com.global.list") }}
                        </router-link>
                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalCountryCreate"><i>+</i> {{ $t('com.global.add') }}</button>
                    </div>

                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-responsive-md">
                            <thead>
                                <tr>
                                    <th style="width:80px;"><strong>#</strong></th>
                                    <th><strong>{{ $t('com.country.name') }}</strong></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(country, index) in countries" :key="index">
                                    <td><strong>{{ getIndex(index) }}</strong></td>
                                    <td>{{ country.name }}</td>
                                    <td>
                                        <div class="dropdown">
                                            <button type="button" class="btn btn-primary light sharp" data-bs-toggle="dropdown">
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24" /><circle fill="#000000" cx="5" cy="12" r="2" /><circle fill="#000000" cx="12" cy="12" r="2" /><circle fill="#000000" cx="19" cy="12" r="2" /></g></svg>
                                            </button>
                                            <div class="dropdown-menu">
                                                <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modalItemEdit" @click="setCountry(country)">{{ $t('com.global.edit') }}</a>
                                                <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modalItemDelete" @click="setCountry(country)">{{ $t('com.global.delete') }}</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row mt-3">
                        <Pagination :pages="pages" @page-request="pageRequest" />
                    </div>

                </div>
            </div>
        </div>
    </div>

    <ModalCreate @reload-items="reloadCountry()" />
    <ModalEdit :selectedItem="selectedCountry"
               @unset-item="unsetCountry()"
               @reload-items="reloadCountry()"
    />
    <ModalDelete :error="errorModalEdit"
                 :deletedItem="deletedItem"
                 :closeModal="closeModal"
                 @unset-item="unsetCountry()"
                 @confirm-delete="deleteCountry()"
    />


</template>

<script charset="utf-8">
    import PageTitle from "../../../components/common/PageTitle.vue";
    import Pagination from "../../../components/common/Pagination.vue"
    import ModalDelete from "../../../components/common/modal/ModalDelete.vue"
    import ModalCreate from "../../../components/country/ModalCreate.vue"
    import ModalEdit from "../../../components/country/ModalEdit.vue"

    export default {
        components: {
            PageTitle,
            Pagination,
            ModalDelete,
            ModalCreate,
            ModalEdit
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "Countries",
                subTitle: "com.countries.subtitle",
                countries: [],
                pages: {},
                selectedCountry: {},
                errorModalEdit: {
                    isShow: false,
                    header: "test",
                    message: "",
                },
                deletedItem: "",
                closeModal: false
            };
        },
        computed: {

        },
        watch: {
            '$route'(to) {
                let page = parseInt(to.params.page);
                if (isNaN(page))
                    page = 1;

                let items = parseInt(to.params.items)
                if (isNaN(items))
                    items = 10
                this.FetchCountries(page, items)
            }
        },
        mounted() {
            this.FetchCountries();
        },
        methods: {
            async FetchCountries(page = 1, items = 10) {
                const req = {
                    PageNumber: page,
                    ItemsOnPage: items,
                }
                this.$store.dispatch("i18n/getCountries", req).then(
                    (res) => {
                        this.countries = res.data.countries;
                        this.pages = res.data.page;
                        console.log(res);
                    },
                    (error) => {
                        console.log(error)
                    }
                );
            },
            pageRequest(page) {
                this.$router.push(
                    {
                        name: 'CountriesListPages',
                        params: {
                            page: page,
                            items: 10
                        }
                    })
                console.log("Otrzymano stronę", page);
            },
            setCountry(country) {
                this.closeModal = false;
                this.selectedCountry = country
            },
            unsetCountry() {
                this.selectedCountry = {}
            },
            getCurrentPage() {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;
                return page;
            },
            getCurrentItemsOnPage() {
                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 10;
                if (itemsPerPage == 0)
                    itemsPerPage = 10;
                return itemsPerPage
            },
            getIndex(index) {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;

                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 0;

                return ((page - 1) * itemsPerPage) + index + 1
            },
            reloadCountry() {
                this.FetchCountries(this.getCurrentPage(), this.getCurrentItemsOnPage());
            },
            async deleteCountry() {

                this.$store.dispatch("i18n/deleteCountry", this.selectedCountry.id).then(
                    () => {
                        this.closeModal = true;
                        this.FetchCountries(this.getCurrentPage(), this.getCurrentItemsOnPage());
                    },
                    (error) => {
                        console.log(error)
                    }
                );
            }
        }
    }
</script>

<style>
</style>
