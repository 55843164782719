<template>
    <div class="row mb-3">
        <div class="col-6">
            <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
        </div>
        <div class="col-6">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <QuizVoteBlockNavigation />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-4 col-md-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ $t(subTitle) }}</h4>
                </div>
                <div class="card-body">
                    <!-- VoteBlock.Name -->
                    <div class="row">
                        <div class="col-12">
                            <h5>{{ $t('com.quiz.vote.block.name') }}</h5>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            {{ voteBlock.name }}
                        </div>
                    </div>
                    <!-- /VoteBlock.Name -->
                    <!-- VoteBlock.Name -->
                    <div class="row">
                        <div class="col-12">
                            <h5>{{ $t('com.quiz.vote.block.type') }}</h5>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            {{ $t(voteBlock.voteBlockType.name) }}
                        </div>
                    </div>
                    <!-- /VoteBlock.Name -->
                    <!-- VoteBlock.Description -->
                    <div class="row">
                        <div class="col-12">
                            <h5>{{ $t('com.quiz.vote.block.description') }}</h5>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            {{ voteBlock.description }}
                        </div>
                    </div>
                    <!-- /VoteBlock.Description -->
                    <!-- VoteBlock.Event -->
                    <div class="row">
                        <div class="col-12">
                            <h5>{{ $t('com.quiz.vote.block.event') }}</h5>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            {{ voteBlock.event.name }} <br />
                            {{ voteBlock.event.description }}
                        </div>
                    </div>
                    <!-- /VoteBlock.Evnet -->
                    <!-- VoteBlock.Customer -->
                    <div class="row">
                        <div class="col-12">
                            <h5>{{ $t('com.quiz.vote.block.customer') }}</h5>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            {{ voteBlock.customer.name }} ({{ voteBlock.customer.eMail }}) <br />
                            {{ voteBlock.customer.postalCode }} {{ voteBlock.customer.citty }}, {{ voteBlock.customer.street }}
                        </div>
                    </div>
                    <!-- /VoteBlock.Customer -->

                </div>
            </div>
        </div>
        <div class="col-lg-8 col-md-12">
            <VoteBlockVoteLists :voteBlock="voteBlock"
                                :votes="votes" />
        </div>
    </div>
</template>

<script charset="utf-8">
    //views
    import PageTitle from "@/components/common/PageTitle.vue";
    import QuizVoteBlockNavigation from "@/components/quiz/voteblock/QuizVoteBlockNavigation.vue"
    import VoteBlockVoteLists from '@/components/quiz/voteblock/VoteBlockVoteLists.vue'

    //services
    import QuizVoteBlockService from "@/services/quiz/quiz.voteblock.service";
    import QuizVoteService from "@/services/quiz/quiz.vote.service";


    export default {
        components: {
            QuizVoteBlockNavigation,
            PageTitle,
            VoteBlockVoteLists
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "com.quiz.voteblock.detail.maintitle",
                subTitle: "com.quiz.voteblock.detail.subtitle",

                loading: {
                    global: false,
                    event: false,
                    vote: false,
                },

                voteBlock: {
                    customer: {},
                    event: {},
                    voteBlockType: {
                        name: "",
                    },
                },

                votes: [],

                modal: {
                    vote: {
                        delete: {
                            id: "voteDeleteItem",
                            target: "#voteDeleteItem",
                            text: "jakis tam tekst",
                            close: false,
                            error: {
                                isShow: false,
                                header: "test",
                                message: "",
                                errors: []
                            }
                        },
                        edit: {
                            close: false,
                        }
                    },
                    question: {
                        delete: {
                            id: "questionDeleteItem",
                            target: "#questionDeleteItem",
                            text: "jakis tam tekst",
                            close: false,
                            error: {
                                isShow: false,
                                header: "test",
                                message: "",
                                errors: []
                            }
                        }
                    }

                }
            };
        },
        computed: {
            getVoteBlockID() {
                return this.$route.params.id;
            },
        },
        watch: {

        },
        mounted() {
            this.fetchVoteBlock();
            this.fetchVotesByVoteBlock();
        },
        methods: {
            async fetchVoteBlock() {
                try {
                    this.loading.global = true;

                    let res = (await QuizVoteBlockService.GetById(this.getVoteBlockID))

                    if (res.status == 200) {
                        this.voteBlock = res.data;
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.global = false;
                }
            },

            async fetchVotesByVoteBlock() {
                try {
                    this.loading.vote = true;

                    let res = (await QuizVoteService.GetByVoteBlock(this.getVoteBlockID));

                    if (res.status == 200) {
                        this.votes = res.data;
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.vote = false;
                }
            },


            getVoteCardHeadId(index) {
                return "card-heading-event-vote-" + index + "-head";
            },
            getVoteCardHeadBSTarget(index) {
                return "#" + this.getVoteCardHeadId(index);
            },
            getVoteCardId(index) {
                return "card-heading-event-vote-" + index + "-body";
            },

            getVoteBSTarger(index) {
                return "#" + this.getVoteCardId(index);
            },

            getDropdownID(str) {
                return "dropdownButtonEventQuestion" + str;
            },

        }
    }</script>

<style>
</style>
