<template>
    <div class="footer">
        <div class="copyright">
            <p>Copyright � Designed &amp; Developed by <a href="https://mwc.pl/" target="_blank">MWC</a> 2022</p>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
        },
        data() {
            return {
                publicPath: process.env.BASE_URL
            };
        },
        computed: {
            
        },
        methods: {
        }
    }
</script>

<style>

</style>
