<template>
    <div class="card">
        <div class="card-header">
            <h4 class="card-title">{{ $t('com.quiz.vote.block.votes') }}</h4>
            <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#eventVoteAdd">
                <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {{ $t('com.event.vote.add') }}
            </button>
        </div>
        <div class="card-body">
            <!-- Vote details-->
            <div id="accordion-event-vote-detial" class="accordion">
                <div class="card" v-for="(vote, voteIndex) in localVotes" :key="voteIndex">
                    <div class="card-header" data-bs-toggle="collapse" :data-bs-target="getVoteBSTarger(vote.id)" aria-expanded="true" :aria-controls="getVoteCardId(vote.id)">
                        <h5 class="card-title">
                            {{ vote.name }}
                        </h5>
                        <h6 class="card-subtitle text-muted">{{ vote.description }}</h6>
                        <span class="accicon"><i class="fas fa-angle-down rotate-icon"></i></span>
                    </div>
                    <div :id="getVoteCardId(vote.id)" class="collapse show" :aria-labelledby="getVoteCardHeadId(voteIndex)" :data-bs-parent="getVoteCardHeadBSTarget(vote.id)">
                        <div class="card-body">
                            <!--Questions detaild globalButton-->
                            <div class="row mb-3">
                                <div class="col-12">
                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <div class="btn-group" role="group" aria-label="Basic example">
                                            <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" :data-bs-target="modal.vote.delete.target" @click="setVoteItem(vote)">
                                                <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                {{ $t('com.global.delete') }}
                                            </button>
                                            <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#eventVoteEdit" @click="setVoteItem(vote, localVoteBlock)">
                                                <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                {{ $t('com.global.edit') }}
                                            </button>
                                            <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#eventQuestionAdd" @click="setVoteItem(vote, localVoteBlock)">
                                                <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                {{ $t('com.event.question.add') }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="row">
                                    <div class="table-responsive">
                                        <table class="table table-responsive table-hover">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>{{ $t('com.quiz.question.name') }}</th>
                                                    <th>{{ $t('com.quiz.question.points') }}</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(question, questionIndex) in vote.questions" :key="questionIndex">
                                                    <td>{{ questionIndex + 1 }}</td>
                                                    <td>{{ question.name }}</td>
                                                    <td>{{ question.maxPoints }}</td>
                                                    <td>
                                                        <div class="dropdown">
                                                            <button :id="getDropdownID(questionIndex)" type="button" class="btn btn-primary light sharp" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <rect x="0" y="0" width="24" height="24" />
                                                                        <circle fill="#000000" cx="5" cy="12" r="2" />
                                                                        <circle fill="#000000" cx="12" cy="12" r="2" />
                                                                        <circle fill="#000000" cx="19" cy="12" r="2" />
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                            <div class="dropdown-menu" :aria-labelledby="getDropdownID(questionIndex)">
                                                                <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="offcanvas" data-bs-target="#eventQuestionEdit" aria-controls="eventQuestionEdit" @click="setQuestionItem(question, vote, localVoteBlock)">{{ $t('com.global.edit') }}</a>
                                                                <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modalQuestionPrievew" @click="setQuestionItem(question, vote, localVoteBlock)">{{ $t('com.quiz.question.preview') }}</a>
                                                                <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" :data-bs-target="modal.question.delete.target" @click="setQuestionItem(question, vote, localVoteBlock)">{{ $t('com.global.delete') }}</a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /Vote Details -->
        </div>
    </div>

    <!-- /Vote Block Modals-->
    <!-- Vote Modals -->
    <EventVoteAdd :voteBlockID="localVoteBlock.id"
                  @reload-event-votes="fetchVoteBlockVotes(localVoteBlock.id)" />

    <EventVoteEdit :voteItem="selectedVote"
                   :voteBlockID="localVoteBlock.id"
                   :closeModal="modal.vote.edit.close" />
    <!-- /Vote Modals-->
    <!-- Question Modals-->

    <EventQuestionAdd :voteID="selectedVote.id"
                      :eventLanguage="localVoteBlock.event.language"
                      @reload-vote-questions="fetchQuestionsByVote()" />

    <EventQuestionEdit :question="selectedQuestion"
                       @reload-vote-questions="fetchQuestionsByVote()" />

    <ModalQuizQuestionPreview :question="selectedQuestion" />
    <!-- /Question Modals-->

    <ModalDelete :modalId="modal.vote.delete.id"
                 :error="modal.vote.delete.error"
                 :deletedItem="modal.vote.delete.error.text"
                 :closeModal="modal.vote.delete.close"
                 @unset-item="unsetVoteItem()"
                 @confirm-delete="deleteVote()" />

    <ModalDelete :modalId="modal.question.delete.id"
                 :error="modal.question.delete.error"
                 :deletedItem="modal.question.delete.error.text"
                 :closeModal="modal.question.delete.close"
                 @unset-item="unsetQuestionItem()"
                 @confirm-delete="deleteQuestion()" />

</template>
<script charset="utf-8">

    //views
    import EventVoteAdd from "@/components/event/EventVoteAdd.vue";
    import EventVoteEdit from "@/components/event/EventVoteEdit.vue";
    import EventQuestionAdd from "@/components/event/EventQuestionAdd.vue";
    import EventQuestionEdit from "@/components/event/EventQuestionEdit.vue";
    import ModalQuizQuestionPreview from "@/components/quiz/question/ModalQuizQuestionPreview.vue";
    import ModalDelete from "@/components/common/modal/ModalDelete.vue"

    //services
    import QuizVoteService from "@/services/quiz/quiz.vote.service";
    import QuizService from "@/services/quiz/quiz.question.service";

    export default {
        components: {
            EventVoteAdd,
            EventVoteEdit,
            EventQuestionAdd,
            EventQuestionEdit,
            ModalQuizQuestionPreview,
            ModalDelete
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                localVotes: [],
                localVoteBlock: {
                    customer: {},
                    event: {
                        language: {}
                    }
                },
                localLanguage: {},

                selectedVote: {},
                selectedQuestion: {},

                modal: {
                    vote: {
                        delete: {
                            id: "voteDeleteItem",
                            target: "#voteDeleteItem",
                            text: "jakis tam tekst",
                            close: false,
                            error: {
                                isShow: false,
                                header: "test",
                                message: "",
                                errors: []
                            }
                        },
                        edit: {
                            close: false,
                        }
                    },
                    question: {
                        delete: {
                            id: "questionDeleteItem",
                            target: "#questionDeleteItem",
                            text: "jakis tam tekst",
                            close: false,
                            error: {
                                isShow: false,
                                header: "test",
                                message: "",
                                errors: []
                            }
                        }
                    }

                }
            }
        },

        props: {
            voteBlock: {
                type: Object,
                require: true
            },

            votes: {
                type: Array,
                require: true,
            },
            language: {
                type: Object,
                require: true
            }
        },

        watch: {
            voteBlock: {
                immediate: true,
                handler(val) {
                    this.localVoteBlock = val;
                }
            },
            votes: {
                immediate: true,
                handler(val) {
                    this.localVotes = val;
                }
            },
           
        },
        methods: {

            async fetchVoteBlockVotes() {
                try {

                    let res = (await QuizVoteService.GetByVoteBlock(this.localVoteBlock.id));

                    if (res.status == 200) {
                        this.localVotes = res.data;
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }

            },

            async deleteVote() {
                try {
                    this.loading = true;

                    let res = (await QuizVoteService.Delete(this.selectedVote.id));

                    if (res.status == 200) {
                        this.modal.vote.delete.close = true;
                        this.fetchVoteBlockVotes();
                        this.$toast.success(this.$t('com.quiz.vote.deletesuccess'));
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },


            async fetchQuestionsByVote() {
                try {

                    let res = (await QuizService.GetByVoteId(this.selectedVote.id));

                    if (res.status == 200) {

                        this.updateQuestionList(this.selectedVote.id, res.data);
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },

            async deleteQuestion() {
                try {

                    let res = (await QuizService.Delete(this.selectedQuestion.id))

                    if (res.status == 200) {
                        this.modal.question.delete.close = true;
                        this.fetchQuestionsByVote();
                        this.$toast.success(this.$t('com.quiz.question.deletesuccess'));

                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },


            updateQuestionList(VoteID, questionList) {
                let voteIndex = this.localVotes.findIndex(x => x.id === VoteID);
                this.localVotes[voteIndex].questions = questionList;

            },

            setVoteItem(item) {
                this.modal.vote.delete.close = false;
                this.selectedVote = item;
            },
            unsetVoteItem() {
                this.selectedVote = {};
            },

            setQuestionItem(question, vote, voteBlock) {
                console.log()
                this.selectedQuestion = question;
                this.selectedVote = vote;
                this.selectedVoteBlock = voteBlock;
                this.modal.question.delete.close = false;
            },

            //helpers to generate id & targets
            getVoteCardHeadId(index) {
                return "card-heading-event-vote-" + index + "-head";
            },
            getVoteCardHeadBSTarget(index) {
                return "#" + this.getVoteCardHeadId(index);
            },
            getVoteCardId(index) {
                return "card-heading-event-vote-" + index + "-body";
            },

            getVoteBSTarger(index) {
                return "#" + this.getVoteCardId(index);
            },

            getDropdownID(str) {
                return "dropdownButtonEventQuestion" + str;
            },
        }
    }
</script>
<style type="text/css">
</style>