<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">
                        <span class="fw-bolder">
                            {{ $t('com.visualisation.current.voteBlock') }}
                        </span> - {{ item.name }}
                    </h4>
                </div>
                <div class="card-body">
                    <p>{{ item.description }}</p>

                    <div class="row" v-if="curentVote.id != -1">
                        <div class="row">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">
                                        <span class="fw-bolder">
                                            {{ $t('com.visualisation.current.vote') }}
                                        </span> - {{ curentVote.name }}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>
<script charset="utf-8">
    export default {
        components: {

        },
        props: {
            item: {
                type: Object,
                require: true,
                default: function () {
                    return {
                        name: "",
                        description: "",
                        type: {
                            name: "",
                        }
                    }
                }
            },
            curentVote: {
                type: Object,
                require: true,
                default: function () {
                    return {
                        name: "",
                        type: {
                            name: "",
                        }
                    }
                }
            }
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "Quiz My",
                subTitle: "com.quiz.my",
                loading: false,

            };
        },
        computed: {

        },
        watch: {

        },
        mounted() {

        },
        methods: {

            getAccoridionMainID() {
                return "accordion-visualisation-vote-block" + this.item.id;
            },

            getAccoridionMainRef() {
                return "#" + this.getAccoridionMainID();
            },

            getAccoridionTargetID() {
                return "card-heading-visualisation-vote-block-body" + this.item.id;
            },

            getAccoridionTargetRef() {
                return "#" + this.getAccoridionTargetID();
            }

        }
    }
</script>
<style type="text/css"></style>