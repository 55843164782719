<template>
    <div class="row vote-block-interactive">
        <div class="col-12">
            <div :id="getAccordionMainID()" class="accordion" :class="{ active: isActive() }">
                <div class="card card-vote-block-wrapper">
                    <div :id="getAccordionHeadingID()" class="card-header" :class="{ collapsed: !isActive() }" data-bs-toggle="collapse" :data-bs-target="getAccordionBodyRef()" :aria-expanded="[ isActive() ? 'true' : 'false' ]" :aria-controls="getAccordionBodyID()">
                        <h4 class="card-title">
                            {{ voteBlock.name }}
                        </h4>
                        <span class="card-subtitle">
                            {{ voteBlock.description }}
                        </span>
                        <span class="accicon"><i class="fas fa-angle-down rotate-icon"></i></span>
                    </div>
                    <div :id="getAccordionBodyID()" class="accordion-collapse collapse" :class="{ show: isActive() }"  :aria-labelledby="getAccordionHeadingID(voteBlock.id)" :data-bs-parent="getAccordionMainRef()">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="btn-group" role="group">
                                        <button type="button" class="btn btn-primary" @click="setActiveVoteBlock()" :disabled="isActive()" >{{ $t('com.interactive.activate.voteblock') }}</button>
                                        <button type="button" class="btn btn-primary" @click="setDeactiveVoteBlock()" :disabled="!isActive()">{{ $t('com.interactive.deactivate.voteblock') }}</button>
                                    </div>
                                </div>
                            </div>
                            <div :id="getAccordionVoteMainID()" class="accordion">
                                <EventVote v-for="(voteData, voteIndex) in voteBlock.votes"
                                           :key="voteIndex"
                                           :vote="voteData"
                                           :activeVoteBlock="isActive()"
                                           :accordionRef="getAccordionVoteMainRef()"
                                           :currentVote="currentVote"
                                           :currentQuestion="currentQuestion"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script charset="utf-8">

    import EventVote from "@/components/interactive/common/EventVote.vue"

    import QuizInteractiveService from "@/services/quiz/quiz.interactive.service"

    export default {
        components: {
            EventVote
        },
        props: {

            voteBlock: {
                type: Object,
                required: true
            },
            currentVoteBlock: {
                type: Object,

            },
            currentVote: {
                type: Object,
            },
            currentQuestion: {
                type: Object,
                default: function () {
                    return {
                        name: "",
                        type: {
                            name: "",
                        }
                    }
                }
            }
        },
        emits: [""],

        data() {
            return {
                publicPath: process.env.BASE_URL,

                loading: {
                    setActiveVoteBlock: false,
                }

            }
        },
        computed: {
            eventCode() {
                return this.$route.params.eventCode;
            }
        },
        watch: {

        },
        mounted() {
        },

        methods: {

            async setActiveVoteBlock(id) {
                try {
                    this.loading.setActiveVoteBlock = true;

                    const req = {
                        EventCode: this.eventCode,
                        VoteBlockId: this.voteBlock.id,
                    }
                    let res = (await QuizInteractiveService.SetVoteBlockActive(req));

                    if (res.status == 200) {
                        this.$toast.success(this.$t('com.interactive.voteblock.success.activated'));
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                    else if (e.response.status == 400) {
                        this.$toast.warning(this.$t(e.response.data.message))
                    }
                }
                finally {
                    this.loading.setActiveVoteBlock = false;
                }
            },

            async setDeactiveVoteBlock() {
                try {
                    this.loading.setActiveVoteBlock = true;

                    const req = {
                        EventCode: this.eventCode,
                        VoteBlockId: this.voteBlock.id,
                    }
                    let res = (await QuizInteractiveService.SetVoteBlockDeactive(req));

                    if (res.status == 200) {
                        this.$toast.success(this.$t('com.interactive.voteblock.success.deactivated'));
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                    else if (e.response.status == 400) {
                        this.$toast.warning(this.$t(e.response.data.message))
                    }
                }
                finally {
                    this.loading.setActiveVoteBlock = false;
                }
            },

            isActive() {
                if (this.voteBlock.id == this.currentVoteBlock.id)
                    return true;
                return false;
            },

            //vote
            //accordionMain
            getAccordionVoteMainID() {
                return "accordion-wraper-vote" + this.voteBlock.id;
            },
            getAccordionVoteMainRef() {
                return "#" + this.getAccordionVoteMainID();
            },


            //voteBlock
            //accordionMain
            getAccordionMainID() {
                return "accordion-wraper-id" + this.voteBlock.id.toString();
            },
            getAccordionMainRef() {
                return "#" + this.getAccordionMainID();
            },

            //accordionHeading
            getAccordionHeadingID() {
                return "accordion-header-id" + this.voteBlock.id.toString();
            },
            getAccordionHeadingRef() {
                return "#" + this.getAccordionHeadingID();
            },

            //accordionBody
            getAccordionBodyID() {
                return "accordion-body-id" + this.voteBlock.id.toString();
            },
            getAccordionBodyRef() {
                return "#" + this.getAccordionBodyID();
            },

            getDropdownID(str) {
                return "dropdownButton" + str;
            },
        }
    }
</script>
<style type="text/css">
    #app-interactive .vote-block-interactive .card{
        border-style: solid;
        border-width: 10px;
        background-color: lightgray;
    }

    #app-interactive .vote-block-interactive .active .card-vote-block-wrapper {
        border-color: lightgreen;
        background-color: white;
    }
</style>