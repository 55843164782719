<template>
    <div id="eventGroupAdd" class="modal fade" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered " role="document">
            <form method="POST" class="needs-validation" novalidate="" autocomplete="off" @submit.prevent="eventGroupAdd">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ $t('com.event.group.add') }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label class="form-label"><strong>{{ $t('com.event.group') }}</strong></label>
                            <VueMultiselect v-model="modalData.group"
                                            :options="availableOptions"
                                            :searchable="true"
                                            :placeholder="$t('com.event.group')"
                                            :multiple="false"
                                            label="name"
                                            track-by="id"
                                            :disabled="loading"
                                            :showLabels="false"
                                            :allow-empty="false"
                                            :taggable="true"
                                            @tag="addGroupFromSelect"
                            />
                            <pre class="language-json"><code>{{ modalData.settingValue }}</code></pre>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button id="eventGroupAddEmit" type="button" class="btn btn-danger light" data-bs-dismiss="modal" @click="unsetItem()">{{ $t('com.global.close') }}</button>
                        <button type="submit" class="btn btn-primary">{{ $t('com.global.add') }}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>


</template>

<script charset="utf-8">
    //import AppSettingValueService from "../../../../services/app.setting.value.service";
    import EventService from "../../services/event/event.service";
    import GroupService from "@/services/group.service";
    import VueMultiselect from 'vue-multiselect'
    export default {
        components: {
            VueMultiselect
        },
        props: {
            error: {
                type: Object,
                require: true,
                default: function () {
                    return {
                        isShow: false,
                        header: "test",
                        message: "",
                    }
                },


            },

            eventID: {
                type: Number,
                require: true,
            },
            closeModal: {
                type: Boolean,
                require: true,
            }

        },
        emits: ["event-group-add", "reload-event-groups"],
        data() {
            return {
                publicPath: process.env.BASE_URL,
                availableOptions: [],
                modalData: {
                    group: {},
                },

                loading: false,
            };
        },
        computed: {

        },
        watch: {
            closeModal: {
                immediate: true,
                handler(val, oldVal) {
                    if (val != oldVal) {
                        let id = parseInt(this.eventID)

                        if (id > 0) {
                            this.modalData = {
                                group: {},
                            }
                            this.availableOptions = []
                            this.fetchAvailableOptions();
                            document.getElementById('eventGroupAddEmit').click();
                        }
                    }
                }
            },
            eventID: {
                immediate: true,
                handler(val) {
                        let id = parseInt(val)
                        if (id > 0) {
                            this.fetchAvailableOptions();
                        }

                }
            }
        },
        mounted() {
        },
        methods: {
            async fetchAvailableOptions() {
                try {
                    this.loading = true;

                    let res = (await EventService.GetAvailableGroups(this.eventID))
                    if (res.status == 200) {
                        this.availableOptions = res.data
                    }
                }
                catch (e) {
                    //console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },
            eventGroupAdd() {
                this.$emit("event-group-add", this.modalData.group);

            },
            async addGroupFromSelect(GroupName) {
                try {
                    this.loading = true;

                    const req = {
                        name: GroupName, 
                        eventID: this.eventID,
                    }

                    let res = (await GroupService.CreateFromEvent(req))

                    if (res.status == 200) {
                        this.$emit("reload-event-groups");
                        this.$toast.success(this.$t("com.group.createsuccess"));
                    }
                }
                catch (e) {

                }
                finally {
                    this.loading = false;
                }
                console.log(GroupName);
            }
        }
    }</script>

<style>
</style>
