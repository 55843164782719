import api from "../api";
import url from "../url";

class QuizQuestionService {
    GetAll(Pages) {
        return api.get(url.quiz.question.getAll, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage,
                CustomerId: Pages.CustomerID,
                TagId: Pages.TagID,
                CategoryId: Pages.CategoryID,
            }
        })
            .then((res) => {
                return res;
            })
    }


    GetAllByAuthor(Pages) {
        return api.get(url.quiz.question.getAllByAuthor, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage
            }
        })
            .then((res) => {
                return res;
            })
    }


    GetById(QuestionID) {
        return api.get(url.quiz.question.getById + "/" + QuestionID)
            .then((res) => {
                return res;
            })
    }

    GetByVoteId(VoteID) {
        return api.get(url.quiz.question.getByVoteId + "/" + VoteID)
            .then((res) => {
                return res;
            });
    }

    GetByIdWithAnswers(QuestionID) {
        return api.get(url.quiz.question.getByIdWithAnswers + "/" + QuestionID)
            .then((res) => {
                return res;
            })
    }

    GetAnswersByQuestionId(QuestionID) {
        return api.get(url.quiz.question.getAnswersByQuestionId + "/" + QuestionID)
            .then((res) => {
                return res;
            })
    }

    Create(request) {
        const req = {
            Name: request.name,
            Description: request.description,
            LanguageID: request.languageID,
            CustomerID: request.customerID,
            FileID: request.fileID,
            TypeID: request.typeID,
            Categories: request.categories,
            Tags: request.tags,
        }

        return api.post(url.quiz.question.create, req)
            .then((response) => {
                return response;
            });
    }

    CreateWithAnswers(request) {
        const req= {
            Name: request.Name,
            Description: request.Description,
            LanguageID: request.LanguageID,
            VoteID: request.VoteID,
            Categories: request.Categories,
            TypeID: request.TypeID,
            Tags: request.Tags,
            Answers: request.Answers
        };

        return api.post(url.quiz.question.createWithAnswers, req)
            .then((response) => {
                return response;
            })

    }

    Update(request) {
        const req = {
            Id: request.id,
            Name: request.name,
            FileID: request.fileID,
            Description: request.description,
            LanguageID: request.languageID,
            CustomerID: request.customerID,
            Categories: request.categories,
            TypeID: request.typeID,
            Tags: request.tags,
        }
        return api.post(url.quiz.question.update, req)
            .then((response) => {
                return response;
            });
    }

    UpdateWithoutCustomer(request) {
        const req = {
            Id: request.id,
            Name: request.name,
            FileID: request.fileID,
            Description: request.description,
            LanguageID: request.languageID,
            Categories: request.categories,
            TypeID: request.typeID,
            Tags: request.tags,
        }
        return api.post(url.quiz.question.updateWithoutCustomer, req)
            .then((response) => {
                return response;
            });
    }

    RemoveFile(QuestionID) {
        return api.delete(url.quiz.question.remmoveFile + "/" + QuestionID)
            .then((res) => {
                return res;
            })
    }

    AddFile(request) {
        const req = {
            QuestionID: request.questionID,
            FileID: request.fileID
        }
        return api.post(url.quiz.question.addFile, req)
            .then((response) => {
                return response;
            }); 
    }

    Delete(QuestionID) {
        return api.delete(url.quiz.question.delete + "/" + QuestionID)
            .then((res) => {
                return res;
            })
    }

    GetBin(Pages) {
        return api.get(url.quiz.question.bin, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage
            }
        })
            .then((res) => {
                return res;
            })
    }

    DeletePermanently(QuestionID) {
        return api.delete(url.quiz.question.deletePermanently + "/" + QuestionID)
            .then((res) => {
                return res;
            })
    }

    Restore(QuestionID) {
        return api.post(url.quiz.question.retore + "/" + QuestionID)
            .then((res) => {
                return res;
            })
    }

    AssignFile(questionID, fileID) {
        const req = {
            QuestionID: questionID,
            FileID: fileID
        }

        return api.post(url.quiz.question.assingFile, req)
            .then((res) => {
                return res;
            })
    }
    UnassignFile(questionID, fileID) {
        return api.delete(url.quiz.question.unassingFile, {
            params: {
                QuestionID: questionID,
                FileID: fileID
            }
        })
            .then((res) => {
                return res;
            })
    }
}

export default new QuizQuestionService;