<template>
    <div id="eventVoteAdd" class="modal fade" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <form method="post" class="needs-validation" novalidate="" autocomplete="off" @submit.prevent="eventVoteAddMethod">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ $t('com.event.vote.add') }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-3">
                            <label class="form-label">
                                <strong>{{ $t('com.quiz.vote.name') }}</strong>
                            </label>
                            <input type="text" class="form-control" :placeholder="$t('com.quiz.vote.name')" v-model="voteModel.name" />
                        </div>
                        <div class="row mb-3">
                            <label class="form-label">
                                <strong>{{ $t('com.quiz.vote.description') }}</strong>
                            </label>
                            <input type="text" class="form-control" :placeholder="$t('com.quiz.vote.description')" v-model="voteModel.description" />
                        </div>
                        <div class="row mb-3">
                            <label class="form-label"><strong>{{ $t('com.quiz.vote.type') }}</strong></label>
                            <VueMultiselect v-model="voteModel.type"
                                            :options="voteTypes"
                                            :searchable="true"
                                            :placeholder="$t('com.quiz.vote.type')"
                                            :multiple="false"
                                            :custom-label="translatedType"
                                            track-by="id"
                                            :disabled="loading.types"
                                            :showLabels="false"
                                            :allow-empty="false" />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button id="eventVoteBlockAddEmit" type="button" class="btn btn-danger light" data-bs-dismiss="modal" @click="unsetItem()">
                            {{ $t('com.global.close') }}
                        </button>
                        <button type="submit" class="btn btn-primary">
                            {{ $t('com.global.add') }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script charset="utf-8">

    import QuizVoteService from "@/services/quiz/quiz.vote.service"
    import QuizVoteTypeSertvice from "@/services/quiz/quiz.vote.type.service";
    import VueMultiselect from 'vue-multiselect'

    export default {
        components: {
            VueMultiselect
        },

        props: {
            voteBlockID: {
                type: Number,
                require: true,
            },
            //voteBlocks: {
            //    type: Array,
            //    require: true,
            //}

        },
        emits: ["reload-event-votes"],
        data() {
            return {
                publicPath: process.env.BASE_URL,
                loading: false,

                voteModel: {
                    name: "",
                    description: "",
                    type: {},
                },
                voteTypes: [],
                
            }
        },
        watch: {
         
        },
        mounted() {
            this.fetchVoteType();
        },
        methods: {


            async eventVoteAddMethod() {
                try {
                    let req = {
                        Name: this.voteModel.name,
                        Description: this.voteModel.description,
                        VoteBlockID: this.voteBlockID,
                        TypeID: this.voteModel.type.id,
                    };

                    let res = (await QuizVoteService.CreateWithoutCustomer(req));

                    if (res.status == 200) {
                        this.$toast.success(this.$t('com.vote.createsuccess'));
                        this.$emit("reload-event-votes", this.voteBlockID);
                        this.clearForm();
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },

            async fetchVoteType() {
                try {

                    let res = (await QuizVoteTypeSertvice.GetAll())

                    if (res.status == 200) {
                        this.voteTypes = res.data;
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
            },

            clearForm() {
                this.voteModel.name = "";
                this.voteModel.description = "";
            },
            translatedType(obj) {

                if (Object.keys(obj).length === 0)
                    return "Select"

                return this.$t(obj.name);
            },
        }
        
    }
</script>
<style type="text/css">
</style>