<template>
    <div class="row mb-3">
        <div class="col-6">
            <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
        </div>
        <div class="col-6">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <QuizVoteBlockNavigation />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ $t(subTitle) }}</h4>
                    <button class="btn btn-primary" @click="clearFilter()">
                        {{ $t('com.global.clearfilters') }}
                    </button>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table table-hover">
                                    <thead>
                                        <tr class="align-middle align-content-center">
                                            <th>#</th>
                                            <th>{{ $t('com.quiz.vote.block.name') }}</th>
                                            <th>{{ $t('com.quiz.vote.block.description') }}</th>
                                            <th>{{ $t('com.quiz.vote.block.type') }}</th>
                                            <th>{{ $t('com.quiz.vote.block.event.name') }}</th>

                                            <th>{{ $t('com.quiz.vote.block.vote.count') }}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="align-middle" v-for="(item, index) in voteBlocks" :key="index">
                                            <td>{{ getIndex(index) }}</td>
                                            <td>{{ item.name }}</td>
                                            <td>{{ item.description }}</td>
                                            <td>{{ $t(item.voteBlockType.name) }}</td>
                                            <td>
                                                <router-link :to="{ name: 'QuizVoteBlockListMyByEvent', params: {eventID: item.event.id } }" class="badge badge-rounded badge-outline-info">
                                                    {{ item.event.name }}
                                                </router-link>
                                            </td>
                                            <td>{{ item.votes.length }}</td>
                                            <td>
                                                <div class="dropdown">
                                                    <button :id="getDropdownID(index)" type="button" class="btn btn-primary light sharp" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <rect x="0" y="0" width="24" height="24" />
                                                                <circle fill="#000000" cx="5" cy="12" r="2" />
                                                                <circle fill="#000000" cx="12" cy="12" r="2" />
                                                                <circle fill="#000000" cx="19" cy="12" r="2" />
                                                            </g>
                                                        </svg>
                                                    </button>
                                                    <div class="dropdown-menu" :aria-labelledby="getDropdownID(index)">
                                                        <router-link :to="{ name: 'QuizVoteBlockEdit', params: { id: item.id } }" class="dropdown-item">{{ $t('com.global.edit') }}</router-link>
                                                        <router-link :to="{ name: 'QuizVoteBlockDetail', params: { id: item.id } }" class="dropdown-item">{{ $t('com.global.details') }}</router-link>
                                                        <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modalItemDelete" @click="setVoteBlock(item)">{{ $t('com.global.delete') }}</a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <ElementLoader v-if="loading.global" :type="tableLoader.type" :color="tableLoader.color" :size="tableLoader.size" />
                    </div>
                    <div class="row mt-3">
                        <Pagination :pages="pages" @page-request="pageRequest" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ModalDelete :error="modal.delete.error"
                 :deletedItem="modal.delete.item"
                 :closeModal="modal.delete.close"
                 @unset-item="unsetVoteBlock()"
                 @confirm-delete="deleteVoteBlock()" />

</template>

<script charset="utf-8">
    //components
    import PageTitle from "@/components/common/PageTitle.vue";
    import QuizVoteBlockNavigation from "@/components/quiz/voteblock/QuizVoteBlockNavigation.vue"
    import ElementLoader from '@/components/common/ElementLoader.vue';
    import Pagination from "@/components/common/Pagination.vue"
    import ModalDelete from "@/components/common/modal/ModalDelete.vue"

    //services
    import QuizVoteBlockService from "@/services/quiz/quiz.voteblock.service";

    export default {
        components: {
            QuizVoteBlockNavigation,
            PageTitle,
            ElementLoader,
            Pagination,
            ModalDelete
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "com.quiz.voteblock.list.my.maintitle",
                subTitle: "com.quiz.voteblock.list.my.subtitle",

                loading: {
                    global: false,
                },

                tableLoader: {
                    type: "spinner-border",
                    color: "text-primary",
                    size: "big"
                },
                voteBlocks: [],
                pages: {},

                selectedVoteBlock: {},
                modal: {
                    delete: {
                        error: {
                            isShow: false,
                            header: "",
                            message: ""
                        },
                        item: "",
                        close: false
                    },
                }
            };
        },
        computed: {

        },
        watch: {
            '$route'() {
                this.fetchVoteBlock(this.getCurrentPage(), this.getCurrentItemsOnPage(), this.getEventID());
            }
        },
        mounted() {
            this.fetchVoteBlock(this.getCurrentPage(), this.getCurrentItemsOnPage(), this.getEventID());
        },
        methods: {

            async fetchVoteBlock(page = 1, items = 10, eventID = -1) {
                try {
                    this.loading.global = true;

                    const req = {
                        PageNumber: page,
                        ItemsOnPage: items,
                        EventID: eventID
                    }

                    let res = (await QuizVoteBlockService.GetMy(req));

                    if (res.status == 200) {
                        this.pages = res.data.page;
                        this.voteBlocks = res.data.voteBlocks;
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.global = false;
                }
            },

            async deleteVoteBlock() {
                try {
                    this.loading.global = true;

                    let res = (await QuizVoteBlockService.Delete(this.selectedVoteBlock.id));

                    if (res.status == 200) {
                        this.modal.delete.close = true;
                        this.fetchVoteBlock(this.getCurrentPage(), this.getCurrentItemsOnPage(), this.getEventID());
                        this.$toast.success(this.$t('com.quiz.vote.block.delete.success'));
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.global = false;
                }
            },
            getEventID() {
                let event = parseInt(this.$route.params.eventID);
                if (isNaN(event))
                    return -1;
                return event;
            },
            getCurrentPage() {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;
                return page;
            },
            getCurrentItemsOnPage() {
                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 10;
                if (itemsPerPage == 0)
                    itemsPerPage = 10;
                return itemsPerPage
            },
            getIndex(index) {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;

                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 0;

                return ((page - 1) * itemsPerPage) + index + 1
            },
            pageRequest(page) {
                if (this.getEventID() != -1) {
                    this.$router.push(
                        {
                            name: 'QuizVoteBlockListMyByEventPages',
                            params: {
                                page: page,
                                items: this.getCurrentItemsOnPage()
                            }
                        })
                }
                else {
                    this.$router.push(
                        {
                            name: 'QuizVoteBlockListMyPages',
                            params: {
                                page: page,
                                items: this.getCurrentItemsOnPage()
                            }
                        })
                }

            },

            clearFilter() {
                this.$router.push(
                    {
                        name: 'QuizVoteBlockListMy',
                    })
            },

            getDropdownID(str) {
                return "dropdownButton" + str;
            },

            setVoteBlock(item) {
                this.selectedVoteBlock = item;
            },
            unsetVoteBlock() {
                this.modal.delete.close = false;
                this.selectedVoteBlock = {};
            }
        }
    }</script>

<style>
</style>
