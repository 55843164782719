<template>
    <div class="row">
        <div class="col-md-4">
            coś tam
        </div>
        <div class="col-md-8">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">

                            <div class="mb-3">
                                <label class="form-label"><strong>{{ $t('com.user.firstname') }}</strong></label>
                                <input v-model="userModel.firstname" type="text" class="form-control" :placeholder="$t('com.user.firstname')" :disabled="loading" />
                            </div>
                            <div class="mb-3">
                                <label class="form-label"><strong>{{ $t('com.user.lastname') }}</strong></label>
                                <input v-model="userModel.lastname" type="text" class="form-control" :placeholder="$t('com.user.lastname')" :disabled="loading" />
                            </div>
                            <div class="mb-3">
                                <label class="form-label"><strong>{{ $t('com.user.email') }}</strong></label>
                                <input v-model="userModel.eMail" type="text" class="form-control" :class="{'is-invalid' : isEmailConfirmedError()}" :placeholder="$t('com.user.email')" :disabled="loading" />
                                <div class="invalid-feedback">
                                    {{ $t('com.user.email.notconfirmed') }} <button class="btn btn-sm btn-outline-primary" @click="sendConfirmationEmail()">{{ $t('com.user.sendconfirmationemail') }}</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label class="form-label"><strong>{{ $t('com.user.phone') }}</strong></label>
                                <input v-model="userModel.telephone" type="text" class="form-control" :placeholder="$t('com.user.phone')" :disabled="loading" />
                            </div>
                            <div class="mb-3">
                                <label class="form-label"><strong>{{ $t('com.user.language') }}</strong></label>
                                <VueMultiselect v-model="userModel.language"
                                                :options="languages"
                                                :searchable="true"
                                                :placeholder="$t('com.user.language')"
                                                :multiple="false"
                                                :custom-label="nameWithLang"
                                                track-by="id"
                                                :disabled="loading"
                                                :showLabels="false"
                                                :allow-empty="false"
                                />
                                <pre class="language-json"><code>{{ userModel.language }}</code></pre>
                            </div>
                        </div>
                    </div>

                    <!--<div class="profile-tab">
                        <div class="custom-tab-1">
                            <ul class="nav nav-tabs">
                                <li class="nav-item">
                                    <a href="#about-me" data-bs-toggle="tab" class="nav-link active">About Me</a>
                                </li>
                                <li class="nav-item">
                                    <a href="#profile-settings" data-bs-toggle="tab" class="nav-link">Setting</a>
                                </li>
                            </ul>
                            <div class="tab-content">

                                <div id="about-me" class="tab-pane fade active show">
                                    <div class="profile-about-me">
                                        <div class="pt-4 border-bottom-1 pb-3">
                                            <h4 class="text-primary">About Me</h4>
                                            <p class="mb-2">A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence was created for the bliss of souls like mine.I am so happy, my dear friend, so absorbed in the exquisite sense of mere tranquil existence, that I neglect my talents.</p>
                                            <p>A collection of textile samples lay spread out on the table - Samsa was a travelling salesman - and above it there hung a picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame.</p>
                                        </div>
                                    </div>
                                    <div class="profile-skills mb-5">
                                        <h4 class="text-primary mb-2">Skills</h4>
                                        <a href="javascript:void(0);" class="btn btn-primary light btn-xs mb-1">Admin</a>
                                        <a href="javascript:void(0);" class="btn btn-primary light btn-xs mb-1">Dashboard</a>
                                        <a href="javascript:void(0);" class="btn btn-primary light btn-xs mb-1">Photoshop</a>
                                        <a href="javascript:void(0);" class="btn btn-primary light btn-xs mb-1">Bootstrap</a>
                                        <a href="javascript:void(0);" class="btn btn-primary light btn-xs mb-1">Responsive</a>
                                        <a href="javascript:void(0);" class="btn btn-primary light btn-xs mb-1">Crypto</a>
                                    </div>
                                    <div class="profile-lang  mb-5">
                                        <h4 class="text-primary mb-2">Language</h4>
                                        <a href="javascript:void(0);" class="text-muted pe-3 f-s-16"><i class="flag-icon flag-icon-us"></i> English</a>
                                        <a href="javascript:void(0);" class="text-muted pe-3 f-s-16"><i class="flag-icon flag-icon-fr"></i> French</a>
                                        <a href="javascript:void(0);" class="text-muted pe-3 f-s-16"><i class="flag-icon flag-icon-bd"></i> Bangla</a>
                                    </div>
                                    <div class="profile-personal-info">
                                        <h4 class="text-primary mb-4">Personal Information</h4>
                                        <div class="row mb-2">
                                            <div class="col-sm-3 col-5">
                                                <h5 class="f-w-500">
                                                    Name <span class="pull-end">:</span>
                                                </h5>
                                            </div>
                                            <div class="col-sm-9 col-7">
                                                <span>Mitchell C.Shay</span>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-sm-3 col-5">
                                                <h5 class="f-w-500">
                                                    Email <span class="pull-end">:</span>
                                                </h5>
                                            </div>
                                            <div class="col-sm-9 col-7">
                                                <span>example@examplel.com</span>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-sm-3 col-5">
                                                <h5 class="f-w-500">Availability <span class="pull-end">:</span></h5>
                                            </div>
                                            <div class="col-sm-9 col-7">
                                                <span>Full Time (Free Lancer)</span>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-sm-3 col-5">
                                                <h5 class="f-w-500">
                                                    Age <span class="pull-end">:</span>
                                                </h5>
                                            </div>
                                            <div class="col-sm-9 col-7">
                                                <span>27</span>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-sm-3 col-5">
                                                <h5 class="f-w-500">Location <span class="pull-end">:</span></h5>
                                            </div>
                                            <div class="col-sm-9 col-7">
                                                <span>
                                                    Rosemont Avenue Melbourne,
                                                    Florida
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-sm-3 col-5">
                                                <h5 class="f-w-500">Year Experience <span class="pull-end">:</span></h5>
                                            </div>
                                            <div class="col-sm-9 col-7">
                                                <span>07 Year Experiences</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="profile-settings" class="tab-pane fade">
                                    <div class="pt-3">
                                        <div class="settings-form">
                                            <h4 class="text-primary">Account Setting</h4>
                                            <form>
                                                <div class="row">
                                                    <div class="mb-3 col-md-6">
                                                        <label class="form-label">Email</label>
                                                        <input type="email" placeholder="Email" class="form-control">
                                                    </div>
                                                    <div class="mb-3 col-md-6">
                                                        <label class="form-label">Password</label>
                                                        <input type="password" placeholder="Password" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="mb-3">
                                                    <label class="form-label">Address</label>
                                                    <input type="text" placeholder="1234 Main St" class="form-control">
                                                </div>
                                                <div class="mb-3">
                                                    <label class="form-label">Address 2</label>
                                                    <input type="text" placeholder="Apartment, studio, or floor" class="form-control">
                                                </div>
                                                <div class="row">
                                                    <div class="mb-3 col-md-6">
                                                        <label class="form-label">City</label>
                                                        <input type="text" class="form-control">
                                                    </div>
                                                    <div class="mb-3 col-md-4">
                                                        <label class="form-label">State</label>
                                                        <div class="dropdown bootstrap-select form-control default-select wide">
                                                            <select class="form-control default-select wide" id="inputState">
                                                                <option selected="">Choose...</option>
                                                                <option>Option 1</option>
                                                                <option>Option 2</option>
                                                                <option>Option 3</option>
                                                            </select><button type="button" tabindex="-1" class="btn dropdown-toggle btn-light" data-bs-toggle="dropdown" role="combobox" aria-owns="bs-select-1" aria-haspopup="listbox" aria-expanded="false" title="Choose..." data-id="inputState"><div class="filter-option"><div class="filter-option-inner"><div class="filter-option-inner-inner">Choose...</div></div> </div></button><div class="dropdown-menu "><div class="inner show" role="listbox" id="bs-select-1" tabindex="-1"><ul class="dropdown-menu inner show" role="presentation"></ul></div></div>
                                                        </div>
                                                    </div>
                                                    <div class="mb-3 col-md-2">
                                                        <label class="form-label">Zip</label>
                                                        <input type="text" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="mb-3">
                                                    <div class="form-check custom-checkbox">
                                                        <input type="checkbox" class="form-check-input" id="gridCheck">
                                                        <label class="form-check-label form-label" for="gridCheck"> Check me out</label>
                                                    </div>
                                                </div>
                                                <button class="btn btn-primary" type="submit">
                                                    Sign
                                                    in
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>-->
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import UserService from '../../services/user.service';
    import VueMultiselect from 'vue-multiselect';
    import I18nService from "../../services/i18n.service";
    export default {
        components: {
            VueMultiselect
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                error: {
                    isShow: false,
                    header: "test",
                    message: "",
                    errors: []
                },
                userModel: {
                    language: {},
                },
                languages: [],
                loading: false,
            };
        },
        computed: {
            accountErrors() {
                return this.$store.state.auth.user.accountErrors;
            },
        },

        mounted() {
            this.fetchUserProfile();
            this.fetchLanguages();
        },
        methods: {

            async fetchUserProfile() {
                try {
                    console.log("User: {0}", this.$store.state.auth.user.id);

                    let res = (await UserService.GetById(this.$store.state.auth.user.id));
                    if (res.status == 200) {
                        this.userModel = res.data
                    }
                    console.log("UserData: {0}", res);

                }
                catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'LoginView' })

                    if (e.response.status == 400) {
                        this.error.isShow = true;
                        this.error.message = e.response.data.message
                    }
                }
            },
            async fetchLanguages() {
                try {
                    const req = {
                        PageNumber: 1,
                        ItemsOnPage: 100,
                    }
                    this.languages = (await I18nService.GetLanguages(req)).data.languageItems

                }
                catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'LoginView' })
                }
            },
            async sendConfirmationEmail() {
                try {
                    let res = (await UserService.SendEmailConfirmation())

                    if (res.status) {
                        this.$toast.success(this.$t("com.user.emailconfimation.sendsuccess"));
                    }
                   
                }
                catch (e) {
                    this.$toast.error(this.$t("com.user.emailconfimation.failedsend"));
                    if (e.response.status == 401)
                        this.$router.push({ name: 'LoginView' })
                }
            },
            nameWithLang(obj) {
                if (obj === undefined)
                    return "test";

                return obj.name + " (" + obj.culture + ")"
            },

            isEmailConfirmedError() {
                let emailConfirmedError = this.accountErrors.filter(obj => {
                    return obj.name === "not.email.confirmed"
                });
                if (emailConfirmedError.length === 0)
                    return false;
                return true;
            }


        }
    }</script>

<style>
</style>
