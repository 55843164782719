<template>
    <AccountErrorItem v-for="(err, index) in accountErrors" :key="index" :accountErr="err" />
</template>

<script>
    import AccountErrorItem from "./AccountErrorItem.vue"

    export default {
        components: {
            AccountErrorItem
        },
        data() {
            return {
                publicPath: process.env.BASE_URL
            };
        },
        computed: {
            accountErrors() {
                return this.$store.state.auth.user.accountErrors;
            },
        },
        mounted() {
            console.log(this.accountErrors);
        },
        methods: {
        }
    }</script>

<style>
</style>
