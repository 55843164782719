<template>
    <!-- Modal Edit-->
    <div id="userPilotManagementModal" class="modal fade" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <form method="POST" class="needs-validation" novalidate="" autocomplete="off" @submit.prevent="userPilotManagement">

                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ $t('com.user.pilot.management') }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" @click="unsetItem()">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3 row">
                            <ErrorModalMessage v-if="error.isShow" :error="error" />
                        </div>
                        <div class="mb-3 row">
                            <label class="col-sm-3 col-form-label">{{ $t('com.user.pilot.id') }}</label>
                            <div class="col-sm-9">
                                <input v-model="user.pilotId" type="text" class="form-control" :placeholder="$t('com.user.pilot.id')" />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button id="updatePilotModalEmit" type="button" class="btn btn-danger light" data-bs-dismiss="modal" @click="unsetItem()">{{ $t('com.global.close') }}</button>
                        <button type="submit" class="btn btn-primary">{{ $t('com.global.save') }}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>


    <!-- /Modal Edit -->
</template>

<script charset="utf-8">
    import ErrorModalMessage from "@/components/common/error/ErrorModalMessage";
    import UserService from "@/services/user.service";
    export default {
        components: {
            ErrorModalMessage
        },
        props: {
            pilotID: {
                type: Number,
                require: true
            },
            userID: {
                type: String,
                require: true
            }
        },
        emits: ["unset-item", "reload-items"],
        data() {
            return {
                publicPath: process.env.BASE_URL,
                user: {
                    id: "",
                    pilotId: 0,
                },

                loading: {
                    pilots: false,
                },

                error: {
                    isShow: false,
                    header: "test",
                    message: "",
                    errors: []
                }
            };
        },
        computed: {

        },
        watch: {
            pilotID: {
                immediate: true,
                handler(val) {
                    this.user.pilotId = val
                }
            },

            userID: {
                immediate: true,
                handler(val) {
                    this.user.id = val
                }
            }
        },
        methods: {

            async userPilotManagement() {
                try {
                    this.loading.pilots = true;

                    const req = {
                        UserId: this.user.id,
                        PilotId: this.user.pilotId,
                    };

                    let res = (await UserService.UpdateUserPilot(req))

                    if (res.status == 200) {
                        this.$toast.success(this.$t('com.user.pilot.update.success'));
                        document.getElementById("updatePilotModalEmit").click();
                        this.$emit("reload-items")
                    }
                }
                catch {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.pilots = false;
                }
            },

            unsetItem() {
                this.$emit("unset-item")
            },
            clearData() {
                this.error.isShow = false;
                this.error.header = "";
                this.error.message = ""
                this.editItem.newPassword = ""
            }
        }
    }
</script>

<style>
</style>
