<template>
    <!-- Votes block with vote -->
    <div class="col-12">
        <div id="accordion-event-voteblocks-votes" class="accordion">
            <div class="card">
                <div id="card-heading-event-voteblocks-votes-heading" class="card-header" data-bs-toggle="collapse" data-bs-target="#card-heading-event-voteblocks-votes-body" aria-expanded="true" aria-controls="card-heading-event-voteblocks-votes-body">
                    <h4 class="card-title">
                        {{ $t('com.event.votes') }}
                    </h4>
                    <span class="accicon"><i class="fas fa-angle-down rotate-icon"></i></span>
                </div>
                <div id="card-heading-event-voteblocks-votes-body" class="collapse show" aria-labelledby="card-heading-event-voteblocks-votes-heading" data-bs-parent="#accordion-event-voteblocks-votes">
                    <div class="card-body">
                        <!-- VoteBlock globalButton -->
                        <div class="row mb-3">
                            <div class="col-12">
                                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <div class="btn-group" role="group" aria-label="Basic example">
                                        <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#eventVoteBlockAdd">
                                            <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            {{ $t('com.event.voteblock.add') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /VoteBlock globalButton -->
                        <div class="row">
                            <div class="col-12">
                                <div id="accordion-event-vote-block-detail" class="accordion">


                                    <div class="card" v-for="(voteBlock, index) in voteBlockItems" :key="index">
                                        <div class="card-header" data-bs-toggle="collapse" :data-bs-target="getVoteBlockBSTarger(index)" aria-expanded="true" :aria-controls="getVoteBlockCardId(index)">
                                            <h5 class="card-title">
                                                {{ voteBlock.name }}
                                            </h5>
                                            <h6 class="card-subtitle text-muted">{{ voteBlock.description }}</h6>
                                            <span class="accicon"><i class="fas fa-angle-down rotate-icon"></i></span>
                                        </div>
                                        <div :id="getVoteBlockCardId(index)" class="collapse show" :aria-labelledby="getVoteBlockCardHeadId(index)" :data-bs-parent="getVoteBlockCardHeadBSTarget(index)">
                                            <div class="card-body">
                                                <!--VoteBlock  detaild globalButton-->
                                                <div class="row mb-3">
                                                    <div class="col-12">
                                                        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                                            <div class="btn-group" role="group" aria-label="Basic example">
                                                                <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" :data-bs-target="modal.voteBlock.delete.target" @click="setVoteBlockItem(voteBlock)">
                                                                    <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                    {{ $t('com.global.delete') }}
                                                                </button>
                                                                <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#eventVoteBlockUpdate" @click="setVoteBlockItem(voteBlock)">
                                                                    <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                    {{ $t('com.global.edit') }}
                                                                </button>
                                                                <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#eventVoteAdd" @click="setVoteBlockItem(voteBlock)">
                                                                    <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                    {{ $t('com.event.vote.add') }}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <!-- Vote details-->
                                                        <div id="accordion-event-vote-detial" class="accordion">
                                                            <div class="card" v-for="(vote, voteIndex) in voteBlock.votes" :key="voteIndex">
                                                                <div class="card-header" data-bs-toggle="collapse" :data-bs-target="getVoteBSTarger(vote.id)" aria-expanded="true" :aria-controls="getVoteCardId(vote.id)">
                                                                    <h5 class="card-title">
                                                                        {{ vote.name }}
                                                                    </h5>
                                                                    <h6 class="card-subtitle text-muted">{{ vote.description }}</h6>
                                                                    <span class="accicon"><i class="fas fa-angle-down rotate-icon"></i></span>
                                                                </div>
                                                                <div :id="getVoteCardId(vote.id)" class="collapse show" :aria-labelledby="getVoteCardHeadId(index)" :data-bs-parent="getVoteCardHeadBSTarget(vote.id)">
                                                                    <div class="card-body">
                                                                        <!--Questions detaild globalButton-->
                                                                        <div class="row mb-3">
                                                                            <div class="col-12">
                                                                                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                                                                    <div class="btn-group" role="group" aria-label="Basic example">
                                                                                        <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" :data-bs-target="modal.vote.delete.target" @click="setVoteItem(vote, voteBlock)">
                                                                                            <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                                            {{ $t('com.global.delete') }}
                                                                                        </button>
                                                                                        <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#eventVoteEdit" @click="setVoteItem(vote, voteBlock)">
                                                                                            <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                                            {{ $t('com.global.edit') }}
                                                                                        </button>
                                                                                        <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#eventQuestionAdd" @click="setVoteItem(vote, voteBlock)">
                                                                                            <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                                            {{ $t('com.event.question.add') }}
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="row">
                                                                                <div class="table-responsive">
                                                                                    <table class="table table-responsive table-hover">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>#</th>
                                                                                                <th>{{ $t('com.quiz.question.name') }}</th>
                                                                                                <th>{{ $t('com.quiz.question.points') }}</th>
                                                                                                <th></th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <tr v-for="(question, questionIndex) in vote.questions" :key="questionIndex">
                                                                                                <td>{{ questionIndex + 1 }}</td>
                                                                                                <td>{{ question.name }}</td>
                                                                                                <td>{{ question.maxPoints }}</td>
                                                                                                <td>
                                                                                                    <div class="dropdown">
                                                                                                        <button :id="getDropdownID(index)" type="button" class="btn btn-primary light sharp" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                                            <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                                                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                                                    <rect x="0" y="0" width="24" height="24" />
                                                                                                                    <circle fill="#000000" cx="5" cy="12" r="2" />
                                                                                                                    <circle fill="#000000" cx="12" cy="12" r="2" />
                                                                                                                    <circle fill="#000000" cx="19" cy="12" r="2" />
                                                                                                                </g>
                                                                                                            </svg>
                                                                                                        </button>
                                                                                                        <div class="dropdown-menu" :aria-labelledby="getDropdownID(index)">
                                                                                                            <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="offcanvas" data-bs-target="#eventQuestionEdit" aria-controls="eventQuestionEdit" @click="setQuestionItem(question, vote, voteBlock)">{{ $t('com.global.edit') }}</a>
                                                                                                            <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modalQuestionPrievew" @click="setQuestionItem(question, vote, voteBlock)">{{ $t('com.quiz.question.preview') }}</a>
                                                                                                            <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" :data-bs-target="modal.question.delete.target" @click="setQuestionItem(question, vote, voteBlock)">{{ $t('com.global.delete') }}</a>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- /Vote Details -->
                                                    </div>
                                                </div>
                                            </div>
                                            <!--/VoteBlock globalButton-->
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- /Votes block with vote -->
    <!-- Vote Block Modals -->
    <EventVoteBlockAdd :eventID="event.id"
                       :closeModal="close.voteBlockAdd"
                       @reload-event-voteblocks="fetchEventVoteBlocks" />

    <EventVoteBlockEdit :eventID="event.id"
                        :voteBlockItem="selectedVoteBlock"
                        :closeModal="close.voteBlockAdd"
                        @reload-event-voteblocks="fetchEventVoteBlocks()" />

    <!-- /Vote Block Modals-->
    <!-- Vote Modals -->
    <EventVoteAdd :voteBlockID="selectedVoteBlock.id"
                  @reload-event-votes="fetchVoteBlockVotes(voteBlockID)" />

    <EventVoteEdit :voteItem="selectedVote"
                   :voteBlockID="selectedVoteBlock.id"
                   :closeModal="modal.vote.edit.close" />
    <!-- /Vote Modals-->
    <!-- Question Modals-->

    <EventQuestionAdd :voteID="selectedVote.id"
                      :eventLanguage="event.language"
                      @reload-vote-questions="fetchQuestionsByVote()" />

    <EventQuestionEdit :question="selectedQuestion"
                       @reload-vote-questions="fetchQuestionsByVote()" />

    <ModalQuizQuestionPreview :question="selectedQuestion" />
    <!-- /Question Modals-->

    <ModalDelete :modalId="modal.voteBlock.delete.id"
                 :error="modal.voteBlock.delete.error"
                 :deletedItem="modal.voteBlock.delete.error.text"
                 :closeModal="modal.voteBlock.delete.close"
                 @unset-item="unsetVoteBlockItem()"
                 @confirm-delete="deleteVoteBlock()" />


    <ModalDelete :modalId="modal.vote.delete.id"
                 :error="modal.vote.delete.error"
                 :deletedItem="modal.vote.delete.error.text"
                 :closeModal="modal.vote.delete.close"
                 @unset-item="unsetVoteItem()"
                 @confirm-delete="deleteVote()" />

    <ModalDelete :modalId="modal.question.delete.id"
                 :error="modal.question.delete.error"
                 :deletedItem="modal.question.delete.error.text"
                 :closeModal="modal.question.delete.close"
                 @unset-item="unsetQuestionItem()"
                 @confirm-delete="deleteQuestion()" />

</template>

<script charset="utf-8">
    import EventService from "@/services/event/event.service";
    import QuizVoteBlockService from "@/services/quiz/quiz.voteblock.service";
    import QuizVoteService from "@/services/quiz/quiz.vote.service";
    import QuizService from "@/services/quiz/quiz.question.service";


    import ModalDelete from "@/components/common/modal/ModalDelete.vue"
    import EventVoteBlockAdd from "@/components/event/EventVoteBlockAdd.vue";
    import EventVoteBlockEdit from "@/components/event/EventVoteBlockEdit.vue";

    import EventVoteAdd from "@/components/event/EventVoteAdd.vue";
    import EventVoteEdit from "@/components/event/EventVoteEdit.vue";

    import EventQuestionAdd from "@/components/event/EventQuestionAdd.vue";
    import EventQuestionEdit from "@/components/event/EventQuestionEdit.vue";
    import ModalQuizQuestionPreview from "@/components/quiz/question/ModalQuizQuestionPreview.vue";

    export default {
        components: {
            //VueMultiselect

            EventVoteBlockAdd,
            ModalDelete,
            EventVoteBlockEdit,
            EventVoteAdd,
            EventVoteEdit,

            EventQuestionAdd,
            EventQuestionEdit,
            ModalQuizQuestionPreview
        },

        props: {
            event: {
                type: Object,
                require: true,
            },
            voteBlocks: {
                type: Array,
                require: true,
            }

        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                loading: false,

                close: {
                    voteBlockAdd: false,
                },

                voteBlockItems: [],

                selectedVoteBlock: {},
                selectedVote: {},
                selectedQuestion: {},

                modal: {
                    voteBlock: {
                        delete: {
                            id: "voteBlockDeleteItem",
                            target: "#voteBlockDeleteItem",
                            text: "jakis tam tekst",
                            close: false,
                            error: {
                                isShow: false,
                                header: "test",
                                message: "",
                                errors: []
                            }
                        }
                    },
                    vote: {
                        delete: {
                            id: "voteDeleteItem",
                            target: "#voteDeleteItem",
                            text: "jakis tam tekst",
                            close: false,
                            error: {
                                isShow: false,
                                header: "test",
                                message: "",
                                errors: []
                            }
                        },
                        edit: {
                            close: false,
                        }
                    },
                    question: {
                        delete: {
                            id: "questionDeleteItem",
                            target: "#questionDeleteItem",
                            text: "jakis tam tekst",
                            close: false,
                            error: {
                                isShow: false,
                                header: "test",
                                message: "",
                                errors: []
                            }
                        }
                    }

                }
            }
        },
        watch: {
            voteBlocks: {
                // the callback will be called immediately after the start of the observation
                immediate: true,
                handler(val) {
                    this.voteBlockItems = val;

                }
            }
        },
        methods: {

            async fetchEventVoteBlocks() {
                try {
                    this.loading = true;

                    let res = (await EventService.GetVoteBlocksByEvent(this.event.id))

                    if (res.status == 200) {
                        this.voteBlockItems = res.data;
                        //console.log(res.data);
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },

            async deleteVoteBlock() {
                try {
                    this.loading = true;


                    let res = (await QuizVoteBlockService.Delete(this.selectedVoteBlock.id))

                    console.log(res);
                    if (res.status == 200) {
                        this.fetchEventVoteBlocks();
                        this.modal.voteBlock.delete.close = true;

                        this.$toast.success(this.$t('com.vote.block.deletesuccess'));
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },

            async fetchVoteBlockVotes(voteBlockId) {
                try {

                    let res = (await QuizVoteService.GetByVoteBlock(this.selectedVoteBlock.id));

                    if (res.status == 200) {
                        let index = this.voteBlockItems.findIndex(x => x.id === this.selectedVoteBlock.id);
                        this.voteBlockItems[index].votes = res.data;
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }

            },
            async deleteVote() {
                try {
                    this.loading = true;

                    let res = (await QuizVoteService.Delete(this.selectedVote.id));

                    if (res.status == 200) {
                        this.modal.vote.delete.close = true;
                        this.fetchVoteBlockVotes(this.selectedVoteBlock.id);
                        this.$toast.success(this.$t('com.quiz.vote.deletesuccess'));
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },
            async fetchQuestionsByVote() {
                try {

                    let res = (await QuizService.GetByVoteId(this.selectedVote.id));

                    if (res.status == 200) {

                        this.updateQuestionList(this.selectedVoteBlock.id, this.selectedVote.id, res.data);
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },

            async deleteQuestion() {
                try {

                    let res = (await QuizService.Delete(this.selectedQuestion.id))

                    if (res.status == 200) {
                        this.modal.question.delete.close = true;
                        this.fetchQuestionsByVote();
                        this.$toast.success(this.$t('com.quiz.question.deletesuccess'));
                        
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },


            updateQuestionList(VoteBlockID, VoteID, questionList) {
                let voteBlokIndex = this.voteBlockItems.findIndex(x => x.id === VoteBlockID);
                let voteBlock = this.voteBlockItems[voteBlokIndex];
                let voteIndex = voteBlock.votes.findIndex(x => x.id === VoteID);
                this.voteBlockItems[voteBlokIndex].votes[voteIndex].questions = questionList;

            },

            setVoteBlockItem(item) {
                this.modal.voteBlock.delete.close = false;
                this.selectedVoteBlock = item;
            },
            unsetVoteBlockItem() {
                console.log("UnsetVoteBlock");s
                this.selectedVoteBlock = {};
            },

            setVoteItem(item, voteBlock) {
                this.modal.vote.delete.close = false;
                this.selectedVote = item;
                this.selectedVoteBlock = voteBlock;
            },

            setQuestionItem(question, vote, voteBlock) {
                console.log()
                this.selectedQuestion = question;
                this.selectedVote = vote;
                this.selectedVoteBlock = voteBlock;
                this.modal.question.delete.close = false;
            },

            unsetQuestionItem() {
                console.log("unsetQuestion");
                //this.selectedQuestion = {};
            },

            unsetVoteItem() {
                console.log("UnserVoteItem");
                this.selectedVote = {};
            },


            getVoteBlockCardHeadId(index) {
                return "card-heading-event-voteblock-" + index + "-head";
            },
            getVoteBlockCardHeadBSTarget(index) {
                return "#" + this.getVoteBlockCardHeadId(index);
            },
            getVoteBlockCardId(index) {
                return "card-heading-event-voteblock-" + index + "-body";
            },

            getVoteBlockBSTarger(index) {
                return "#" + this.getVoteBlockCardId(index);
            },


            getVoteCardHeadId(index) {
                return "card-heading-event-vote-" + index + "-head";
            },
            getVoteCardHeadBSTarget(index) {
                return "#" + this.getVoteCardHeadId(index);
            },
            getVoteCardId(index) {
                return "card-heading-event-vote-" + index + "-body";
            },

            getVoteBSTarger(index) {
                return "#" + this.getVoteCardId(index);
            },

            getDropdownID(str) {
                return "dropdownButtonEventQuestion" + str;
            },
        }
    }
</script>

<style type="text/css">
</style>