import api from "../api";
import url from "../url";

class QuizQuestionTypeService {
    GetPaged(Pages) {
        return api.get(url.quiz.question.type.getPaged, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage
            }
        })
            .then((res) => {
                return res;
            })
    }

    GetAll() {
        return api.get(url.quiz.question.type.getAll)
            .then((res) => {
                return res;
            })
    }

    GetById(TypeID) {
        return api.get(url.quiz.question.type.getById + "/" + TypeID)
            .then((res) => {
                return res;
            })
    }

    Create(request) {
        const req = {
            Name: request.name,
            Description: request.description,
        }

        return api.post(url.quiz.question.type.create, req)
            .then((response) => {
                return response;
            });
    }

    Update(request) {
        const req = {
            Id: request.id,
            Name: request.name,
            Description: request.description,
        }

        return api.post(url.quiz.question.type.update, req)
            .then((response) => {
                return response;
            });
    }

    Delete(TypeID) {
        return api.delete(url.quiz.question.type.delete + "/" + TypeID)
            .then((res) => {
                return res;
            })
    }

    GetBin(Pages) {
        return api.get(url.quiz.question.type.bin, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage
            }
        })
            .then((res) => {
                return res;
            })
    }

    DeletePermanently(TypeID) {
        return api.delete(url.quiz.question.type.deletePermanently + "/" + TypeID)
            .then((res) => {
                return res;
            })
    }

    Restore(TypeID) {
        return api.post(url.quiz.question.type.retore + "/" + TypeID)
            .then((res) => {
                return res;
            })
    }

}
export default new QuizQuestionTypeService;