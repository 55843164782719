<template>
    <div class="row mb-3">
        <div class="col-6">
            <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
        </div>
        <div class="col-6">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <UserButtonNavigation />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-5">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ $t('com.user.detail') }}</h4>
                </div>
                <div class="card-body">
                    <div class="row mb-3">
                        <div class="col-md-12">
                            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                <div class="btn-group" role="group" aria-label="Basic example">
                                    <router-link :to="{ name: 'UserEdit', params: { id: item.id } }" type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal">
                                        {{ $t('com.global.edit') }}
                                    </router-link>

                                    <button type="button" data-bs-toggle="modal" data-bs-target="#userChangePasswordModal" class="btn btn-primary btn-sm">
                                        {{ $t('com.user.changepassword') }}
                                    </button>

                                    <!--<button type="button" data-bs-toggle="modal" data-bs-target="#userChangePasswordModal" class="btn btn-primary btn-sm">
                                        {{ $t('com.user.assigncustomer') }}
                                    </button>-->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12">
                            <h5>{{ $t('com.user.email') }}</h5>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12 ">
                            {{ item.eMail }}
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12">
                            <h5>{{ $t('com.user.login') }}</h5>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12">
                            {{ item.login }}
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12">
                            <h5>{{ $t('com.user.firstandlastname') }}</h5>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12">
                            {{ item.firstname }} {{ item.lastname }}
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12">
                            <h5>{{ $t('com.user.roles') }}</h5>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12">
                            <ul>
                                <li v-for="(role, index) in item.roles" :key="index">{{ role.displayName }}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12">
                            <h5>{{ $t('com.user.customer') }}</h5>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12">
                            <div v-for="(customer, customerIndex) in item.customer" :key="customerIndex" class="row">
                                <div class="col-12">
                                    {{ customer.name }} ({{ customer.street }}, {{ customer.postalCode }} {{ customer.citty }})
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-7">

            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ $t('com.user.logs.lastlogins') }}</h4>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-responsive-md">
                            <thead>
                                <tr>
                                    <th style="width:80px;"><strong>#</strong></th>
                                    <th><strong>{{ $t('com.user.logs.timestamp') }}</strong></th>
                                    <th><strong>{{ $t('com.user.logs.status') }}</strong></th>
                                    <th><strong>{{ $t('com.user.logs.ipaddress') }}</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(loginItem, index) in lastLogins.userLogs" :key="index">
                                    <td><strong>{{ index +1 }}</strong></td>
                                    <td>{{ loginItem.timestamp }}</td>
                                    <td>{{ loginItem.status }}</td>
                                    <td>{{ loginItem.ipAddress }}</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalChangePassword :userID="item.id" @unset-item="unsetItem" />
</template>

<script charset="utf-8">
    import ModalChangePassword from "@/components/user/ModalChangePassword.vue"

    import UserButtonNavigation from "@/components/user/UserButtonNavigation.vue"
    import PageTitle from "@/components/common/PageTitle.vue";
    export default {
        components: {
            ModalChangePassword,
            UserButtonNavigation,
            PageTitle
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "com.user.list",
                subTitle: "com.user.subtitle",
                item: {
                    id: "-1",
                },
                lastLogin: {},
                lastFailedLogin: {},
                lastLogins: [],
            };
        },
        computed: {

        },

        mounted() {
            this.FetchUser();
            this.FetchLastLogin();
            this.FetchLastFailedLogin();
            this.FetchLastLogins();
        },
        methods: {
            async FetchUser() {
                this.$store.dispatch("user/getById", this.$route.params.id).then(
                    (res) => {
                        console.log(res);
                        this.item = res.data;
                    },
                    (error) => {
                        console.log(error)
                    }
                );
            },
            async FetchLastLogin() {
                this.$store.dispatch("user/getLastLoginByUserId", this.$route.params.id).then(
                    (res) => {
                        console.log(res);
                        this.lastLogin = res.data;
                    },
                    (error) => {
                        console.log(error)
                    }
                );
            },
            async FetchLastFailedLogin() {
                this.$store.dispatch("user/getLastFailedLoginByUserId", this.$route.params.id).then(
                    (res) => {
                        console.log(res);
                        this.lastFailedLogin = res.data;
                    },
                    (error) => {
                        console.log(error)
                    }
                );
            },
            async FetchLastLogins() {

                const req = {
                    userID: this.$route.params.id,
                    pageNumber: 1,
                    itemsOnPage: 10,
                }

                this.$store.dispatch("user/getLoginsByUserId", req).then(
                    (res) => {
                        console.log(res);
                        this.lastLogins = res.data;
                    },
                    (error) => {
                        console.log(error)
                    }
                );
            },
            unsetItem() {

            }
        }
    }</script>

<style>
</style>
