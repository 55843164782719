<template>
    <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Recent Payments Queue</h4>
                    <!--<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalItemCreate"><i>+</i> Add</button>-->
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-responsive-md">
                            <thead>
                                <tr>
                                    <th style="width:80px;"><strong>#</strong></th>
                                    <th><strong>{{ $t('com.translation.resource.name') }}</strong></th>
                                    <th><strong>{{ $t('com.transtation.language.name') }}</strong></th>
                                    <th><strong>{{ $t('com.translation.value') }}</strong></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="data in datas" :key="data.id">
                                    <td><strong>{{ data.id }}</strong></td>
                                    <td>{{ data.resource.name }}</td>
                                    <td>{{ data.language.name }} ({{ data.language.culture }})</td>
                                    <td>{{ data.translation }}</td>
                                    <td>
                                        <div class="dropdown">
                                            <button type="button" class="btn btn-primary light sharp" data-bs-toggle="dropdown">
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24" />
                                                        <circle fill="#000000" cx="5" cy="12" r="2" />
                                                        <circle fill="#000000" cx="12" cy="12" r="2" />
                                                        <circle fill="#000000" cx="19" cy="12" r="2" />
                                                    </g>
                                                </svg>
                                            </button>
                                            <div class="dropdown-menu">
                                                <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#editModalItem" @click="setItem(data)">{{ $t('com.global.edit') }}</a>
                                                <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#deleteTranslationModal" @click="setItem(data)">{{ $t('com.global.delete') }}</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row mt-3">
                        <Pagination :pages="pages" @page-request="pageRequest" />
                    </div>

                </div>
            </div>
        </div>
    </div>

    <ModalCreate @reload-items="FetchTranslations()" />
    <ModalEdit :selectedItem="selectedItem" :resourceID="1" @unset-item="unsetItem()" @reload-items="FetchTranslations()" />
    <ModalDelete :selectedItem="selectedItem" @unset-item="unsetItem()" @reload-items="FetchTranslations()" />

</template>

<script charset="utf-8">
    import PageTitle from "../../components/common/PageTitle.vue";
    import Pagination from "../../components/common/Pagination.vue"
    import ModalEdit from "../../components/translation/ModalEdit.vue";
    //import ModalCreate from "../../components/translation/ModalCreate.vue";
    import ModalDelete from "../../components/translation/ModalDelete.vue";

    export default {
        components: {
            PageTitle,
            Pagination,
            ModalEdit,
            //ModalCreate,
            ModalDelete
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "com.translation",
                subTitle: "com.translation.subtitle",
                datas: [],
                pages: {},
                selectedItem: {}
            };
        },
        computed: {

        },
        watch: {
            '$route'(to) {
                this.FetchTranslations(parseInt(to.params.page), parseInt(to.params.items))
            }
        },
        mounted() {
            this.FetchTranslations();
        },
        methods: {
            async FetchTranslations(page = 1, items = 10) {
                const req = {
                    PageNumber: page,
                    ItemsOnPage: items,
                }
                this.$store.dispatch("i18n/getTranslations", req).then(
                    (res) => {
                        this.datas = res.data.translations;
                        this.pages = res.data.page;
                    },
                    (error) => {
                        console.log(error)
                    }
                );
            },


            //async CreateLanguage() {

            //},
            pageRequest(page) {
                this.$router.push(
                    {
                        name: 'TranslationsPages',
                        params: {
                            page: page,
                            items: 10
                        }
                    })
            },
            setItem(item) {
                this.selectedItem = item
                console.log(this.selectedItem)
            },
            unsetItem() {
                this.selectedItem = {}
            }
        }
    }</script>

<style>
</style>
