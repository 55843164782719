<template>
    <select name="LeaveType" class="form-control" @change="onChange($event)">
        <option v-for="language in languages" :key="language.id" :value="language.id">
            {{ language.name }} ({{ language.culture }})
        </option>
    </select>
</template>

<script>
    import I18nService from '@/services/i18n.service';
    export default {
        components: {
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                key: "",
                languages: [],
                loading: false,
            };
        },
        computed: {
            userLang() {
                return this.$store.state.auth.user.language
            },
        },
        created() {
            //await this.FetchLocale(this.userLang)
        },
        mounted() {

            this.FetchLanguages()

        },
        methods: {
            onChange(event) {
                console.log(event);
                console.log(event.target.value)
                this.FetchLocale(event.target.value)
                return event;
            },
            async FetchLanguages(page = 1, items = 100) {

                try {
                    this.loading = true;
                    const req = {
                        PageNumber: page,
                        ItemsOnPage: items,
                    }

                    let res = (await I18nService.GetLanguages(req));

                    if (res.status == 200) {
                        this.languages = res.data.languageItems;
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
               
            },
            async FetchLocale(lanID) {
                try {
                    this.loading = true;

                    let res = (await I18nService.GetLocales(lanID))

                    if (res.status == 200) {
                        this.$i18n.setLocaleMessage(res.data.locale, res.data.items)
                        this.$i18n.locale = res.data.locale;
                        console.log(res.data);
                        this.$moment.locale(res.data.locale);
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },
        }
    }</script>

<style>
</style>
