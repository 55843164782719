<template>
    <router-view />
</template>
<script charset="utf-8">

    import EventSettingService from "@/services/event/event.setting.service"
    import FileManagerService from "@/services/file/file.service"

    export default {
        components: {

        },
        data() {
            return {
                publicPath: process.env.BASE_URL,

                loading: {
                    background: false,
                }
            };
        },
        computed: {
            eventCode() {
                return this.$route.params.eventCode;
            }
        },
        created() {

        },
        mounted() {
            this.setupBackground();
        },
        methods: {
            async setupBackground() {
                try {
                    this.loading.background = true;


                    let settingsValues = (await EventSettingService.GetValueByEventCode(this.eventCode));

                    if (settingsValues.status != 200)
                        return;



                    let backgroundSetting = settingsValues.data.find(obj => obj.key.id == 5);

                    if (backgroundSetting == null)
                        return;


                    if (backgroundSetting.key.settingType.provider == 10) //fileprovider
                    {
                        if (backgroundSetting.key.variableType != 25) //integer
                        {
                            return;
                        }
                        let fileID = backgroundSetting.key.defaultValue;
                        if (backgroundSetting.value != null)
                            fileID = backgroundSetting.value;

                        let res = (await FileManagerService.GetById(fileID))

                        if (res.status == 200) {
                            jQuery("#main-wrapper").css("background-image", "url(" + res.data.relativePath + ")");
                            jQuery("#main-wrapper").css("background-position", "center");
                            jQuery("#main-wrapper").css("background-repeat", "no-repeat");
                            jQuery("#main-wrapper").css("background-size", "cover");
                        }
                    }


                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.background = false;
                }
            }
        }
    }
</script>