<template>
    <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Recent Payments Queue</h4>
                    <QuizTagButtonNavigation />
                </div>
                <div class="card-body">
                    <form method="POST" class="needs-validation" novalidate="" autocomplete="off" @submit.prevent="updateTag">
                        <div class="row">
                            <div class="col-md-6 offset-md-3">
                                <div class="mb-3 row">
                                    <ErrorModalMessage v-if="error.isShow" :error="error" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.quiz.tag.name') }}</strong></label>
                                    <input v-model="tag.name" type="text" class="form-control" :placeholder="$t('com.quiz.tag.name')" :disabled="loading" />
                                </div>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <pre class="language-json"><code>{{ tag }}</code></pre>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <button type="submit" class="btn btn-primary" :disabled="loading">
                                        <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        {{ $t('com.global.save') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

</template>

<script charset="utf-8">
    import PageTitle from "@/components/common/PageTitle.vue";
    import QuizTagButtonNavigation from "@/components/quiz/tag/QuizTagButtonNavigation.vue";
    import QuizQuestionTagService from "@/services/quiz/quiz.question.tag.service"

    //import VueMultiselect from 'vue-multiselect'
    import ErrorModalMessage from "@/components/common/error/ErrorModalMessage";

    export default {
        components: {
            PageTitle,
            QuizTagButtonNavigation,
            ErrorModalMessage
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "App Setting Key Create",
                subTitle: "com.app.setting.key.subtitle",
                tag: {
                    name: "",
                },

                error: {
                    isShow: false,
                    header: "test",
                    message: "",
                    errors: []
                },
                loading: false,
            };
        },
        computed: {
            tagId() {
                return this.$route.params.id;
            }
        },
        watch: {

        },
        mounted() {
            this.fetchTag();
        },
        methods: {
            async fetchTag() {
                try {
                    this.clearError();
                    this.loading = true;
                    let res = (await QuizQuestionTagService.GetById(this.tagId))
                    if (res.status == 200) {
                        this.tag = res.data;
                    }
                }
                catch (e) {
                    //console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                    else if (e.response.status == 400) {
                        this.error.isShow = true;
                        this.error.message = e.response.data.message;
                        this.error.errors = e.response.data.errors;
                    }
                    else if (e.response.status == 404) {
                        this.$router.push({ name: 'NotFound404' })
                    }
                }
                finally {
                    this.loading = false;
                }
            },
            async updateTag() {
                try {
                    this.clearError();
                    this.loading = true;
                    let res = (await QuizQuestionTagService.Update(this.tag))
                    if (res.status == 200) {
                        this.$toast.success(this.$t("com.quiz.tag.createsuccess"));
                    }
                }
                catch (e) {
                    //console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                    else if (e.response.status == 400) {
                        this.error.isShow = true;
                        this.error.message = e.response.data.message;
                        this.error.errors = e.response.data.errors;
                    }
                }
                finally {
                    this.loading = false;
                }
            },
            clearError() {
                this.error.isShow = false;
                this.error.message = "";
                this.error.errors = "";
            },
            clearData() {
                this.tag = {
                    name: "",
                }
            },
            translatedLabel(obj) {
                if (obj === undefined)
                    return "test";

                return this.$t(obj.name)
            }

           
        }
    }</script>

<style>
</style>
