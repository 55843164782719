<template>
    <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Recent Payments Queue</h4>
                    <ButtonNavigation />

                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <div class="col-12">
                                    <h5>{{ $t('com.email.template.name') }}</h5>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12 ">
                                    {{ emailTemplate.name }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <h5>{{ $t('com.email.template.description') }}</h5>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12 ">
                                    {{ emailTemplate.description }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <h5>{{ $t('com.email.template.author') }}</h5>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12 ">
                                    {{ emailTemplate.author.firstname }} {{ emailTemplate.author.lastname }}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div v-html="emailTemplate.body"></div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script charset="utf-8">
    import PageTitle from "../../../components/common/PageTitle.vue";
    import ButtonNavigation from "../../../components/email/template/ButtonNavigation.vue";
    import EmailTemplateService from "../../../services/email.template.service";
    export default {
        components: {
            PageTitle,
            ButtonNavigation
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "Email Templates Create",
                subTitle: "com.emailtemplate.subtitle",
                emailTemplate: {
                    author: {}
                },
                loading: false,
            };
        },
        computed: {
            emailTemplateId() {
                return this.$route.params.id;
            }
        },
        watch: {

        },
        mounted() {
            this.fetchEmailTemplate();
        },
        methods: {
            async fetchEmailTemplate() {
                try {
                    this.loading = true;

                    let response = (await EmailTemplateService.GetEmailTemplateById(this.emailTemplateId));
                    console.log(response);
                    if (response.status == 200) {
                        this.emailTemplate = response.data;

                    }


                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }

                    else if (e.response.status == 400) {
                        this.error.isShow = true;
                        this.error.message = e.response.data.message
                    }
                }
                finally {
                    this.loading = false;
                }
            },
        }
    }</script>

<style>
</style>
