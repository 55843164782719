<template>
    <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Recent Payments Queue</h4>
                    <ButtonNavigation />
                </div>
                <div class="card-body">
                    <table class="table table-responsive-md">
                        <thead>
                            <tr>
                                <th style="width:80px;"><strong>#</strong></th>
                                <th><strong>{{ $t('com.email.template.name') }}</strong></th>
                                <th><strong>{{ $t('com.email.template.author') }}</strong></th>
                                <th><strong>{{ $t('com.email.template.language') }}</strong></th>
                                <th><strong>{{ $t('com.email.template.modifications') }}</strong></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(emailTemplate, index) in emailTemplates" :key="index">
                                <td><strong>{{ getIndex(index) }}</strong></td>
                                <td>{{ emailTemplate.name }}</td>
                                <td>{{ emailTemplate.author.firstname }} {{ emailTemplate.author.lastname }}</td>
                                <td>{{ emailTemplate.language.name }} ({{ emailTemplate.language.culture }})</td>
                                <td>{{ emailTemplate.modifications }} </td>
                                <td>
                                    <div class="dropdown">
                                        <button :id="getDropdownID(index)" type="button" class="btn btn-primary light sharp" data-bs-toggle="dropdown" aria-expanded="false">
                                            <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24" />
                                                    <circle fill="#000000" cx="5" cy="12" r="2" />
                                                    <circle fill="#000000" cx="12" cy="12" r="2" />
                                                    <circle fill="#000000" cx="19" cy="12" r="2" />
                                                </g>
                                            </svg>
                                        </button>
                                        <div class="dropdown-menu" :aria-labelledby="getDropdownID(index)">
                                            <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modalItemRestore" @click="setEmailTemplate(emailTemplate)">{{ $t('com.global.restore') }}</a>
                                            <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modalItemDelete" @click="setEmailTemplate(emailTemplate)">{{ $t('com.global.deletepermanently') }}</a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!--</div>-->
                    <div class="row mt-3">
                        <Pagination :pages="pages" @page-request="pageRequest" />
                    </div>

                </div>
            </div>
        </div>
    </div>

    <ModalRestore :error="errorModalEdit"
                  :restoreItem="restoreItem"
                  :closeModal="closeModal"
                  @unset-item="unsetEmailTemplate()"
                  @confirm-restore="confirmRestore()"
    />

    <ModalDelete :error="errorModalEdit"
                 :deletedItem="deletedItem"
                 :closeModal="closeDeleteModal"
                 @unset-item="unsetEmailTemplate()"
                 @confirm-delete="deleteEmailTemplate()"
    />
</template>

<script charset="utf-8">
    import PageTitle from "../../../components/common/PageTitle.vue";
    import ButtonNavigation from "../../../components/email/template/ButtonNavigation.vue";
    import Pagination from "../../../components/common/Pagination.vue"
    import EmailTemplateService from "../../../services/email.template.service";
    import ModalDelete from "../../../components/common/modal/ModalDelete.vue";
    import ModalRestore from "../../../components/common/modal/ModalRestore.vue"
    export default {
        components: {
            PageTitle,
            ButtonNavigation,
            Pagination,
            ModalDelete,
            ModalRestore
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "Email Templates List",
                subTitle: "com.emailtemplate.subtitle",
                emailTemplates: [],
                selectedEmailTemplate: {},
                pages: {},
                errorModalEdit: {
                    isShow: false,
                    header: "test",
                    message: "",
                },
                deletedItem: "",
                restoreItem: "",
                closeModal: false,
                closeDeleteModal: false,
            };
        },
        computed: {

        },
        watch: {

        },
        mounted() {
            this.fetchEmailTemplates(this.getCurrentPage(), this.getCurrentItemsOnPage());
        },
        methods: {
            async fetchEmailTemplates(page = 1, items = 10) {
                try {
                    const req = {
                        PageNumber: page,
                        ItemsOnPage: items,
                    }
                    let data = (await EmailTemplateService.GetEmailTemplatesBin(req)).data;
                    this.emailTemplates = data.emailTemplates;
                    this.pages = data.page
                }
                catch (e) {
                    if(e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
            },

            async deleteEmailTemplate() {
                try {
                    let response = (await EmailTemplateService.DeletePermanentlyEmailTemplate(this.selectedEmailTemplate.id));

                    if (response.status == 200) {
                        this.$toast.success(this.$t("com.email.template.deletesuccess"));
                        this.closeDeleteModal = true;
                        this.fetchEmailTemplates(this.getCurrentPage(), this.getCurrentItemsOnPage());
                    }
                }
                catch (e) {
                    if(e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    console.log("zakończenie pracy");
                }
            },
            async confirmRestore() {
                try {
                    let response = (await EmailTemplateService.RestoreEmailTemplate(this.selectedEmailTemplate.id));

                    if (response.status == 200) {
                        this.$toast.success(this.$t("com.email.template.restoresuccess"));
                        this.closeModal = true;
                        this.fetchEmailTemplates(this.getCurrentPage(), this.getCurrentItemsOnPage());
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    console.log("Zakończenie pracy");
                }
            },
            getCurrentPage() {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;
                return page;
            },
            getCurrentItemsOnPage() {
                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 10;
                if (itemsPerPage == 0)
                    itemsPerPage = 10;
                return itemsPerPage
            },
            getIndex(index) {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;

                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 0;

                return ((page - 1) * itemsPerPage) + index + 1
            },
            pageRequest(page) {
                this.$router.push(
                    {
                        name: 'EmailTemplatesListPages',
                        params: {
                            page: page,
                            items: this.getCurrentItemsOnPage()
                        }
                    })
            },

            getDropdownID(str) {
                return "dropdownButton" + str;
            },
            setEmailTemplate(EmailTemplate) {

                this.closeModal = false;
                this.closeDeleteModal = false;
                this.selectedEmailTemplate = EmailTemplate
            },
            unsetEmailTemplate() {
                this.selectedEmailTemplate = {}
            },
        }
    }</script>

<style>
</style>
