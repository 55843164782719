<template>
    <!-- Modal Add Translation -->
    <div id="modalQuestionPrievew" class="modal fade" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('com.quiz.question.preview') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <h2 v-html="question.description"></h2>
                        </div>

                    </div>
                    <div v-if="isFile(question)" class="row">
                        <div class="col-12">
                            <img :src="question.file.relativePath" :alt="question.file.caption" class="img-thumbnail" width="350" />
                        </div>
                    </div>

                    <div v-for="(answer, index) in question.answers" :key="index" class="row">
                        <div class="col-12">
                            <div class="form-check">
                                <input :id="getAnswerId(answer)" class="form-check-input" type="checkbox" />
                                <label class="form-check-label" :for="getAnswerId(answer)">
                                    <span v-if="isFile(answer)">
                                        <img :src="answer.file.relativePath" :alt="answer.file.caption" class="img-thumbnail" width="150" />
                                    </span>
                                    <span v-else>{{ answer.name }}</span>
                                </label>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="modal-footer">
                    <button id="createTranslationModalEmit" type="button" class="btn btn-danger light" data-bs-dismiss="modal">{{ $t('com.global.close') }}</button>
                </div>
            </div>
        </div>
    </div>
    <!-- /Modal Add Translation -->
</template>

<script charset="utf-8">
    export default {
        components: {
        },
        props: {
            question: {
                type: Object,
                require: true
            },
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
            };
        },
        computed: {

        },
        methods: {
            isFile(item) {
                console.log("object:", item);
                console.log("object.file:", item.file);
                
                if (item.file != null) {
                    console.log("object.file.lenght", Object.entries(item.file).length);
                    if (Object.entries(item.file).length > 0) {
                        return true;
                    }
                }

                return false;
            },
            getAnswerId(item) {
                return "answer-" + item.id;
            }
        }
    }</script>

<style>
</style>
