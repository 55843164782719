const url = {
    auth: {
        login: "/Users/Login",
        logout: "/Users/Logout",
        forgotPassword: "/Users/ForgotPassword",
        resetPassword: "/Users/ResetPassword",
    },
    i18n: {
        languages: {
            getAll: "/Languages/GetLanguages",
            update: "/Languages/Update",
            delete: "/Languages/Delete",
            create: "/Languages/CreateNew",
        },
        resources: {
            getAll: "/Resources/GetResources",
            searchByName: "/Resources/SearchByName",
            getById: "/Resources/GetResourceById",
            update: "/Resources/Update",
            delete: "/Resources/Delete",
            create: "/Resources/CreateNew",
        },
        translations: {
            getAll: "/Translations/GetTranslations",
            update: "/Translations/Update",
            delete: "/Translations/Delete",
            create: "/Translations/CreateNew",
        },
        locales: {
            getByLanguage: "/Locales/GetByLanguageId",
            getAuthBeLanguage: "/Locales/GetAuthByLanguageId"
        },
        countries: {
            getAll: "/Countries/GetAll",
            getById: "/Countries/GetById",
            getByAbbr: "/Countries/GetByAbbr",
            create: "/Countries/Create",
            update: "/Countries/Update",
            delete: "/Countries/Delete",
            bin: "/Countries/Bin",
            deletePermanently: "/Countries/DeletePermanently",
            retore: "/Countries/Restore"
        }
    },
    user: {
        getAll: "/Users/GetAllUsers",
        searchByLogin: "/Users/SearchByLogin",
        getById: "/Users/GetUserById",
        register: "/Users/RegisterUser",
        management: {
            changeUserPassword: "/Users/ChangeUserPassword",
            changeOwnPassword: "/Users/ChangeOwnPassword",
            updateUser: "/Users/UpdateUser",
            updateUserLowPermission: "/Users/UpdateUserLowPermission",
            checkLogin: "/Users/CheckLogin",
            checkEmail: "/Users/CheckEmail",
            checkPhone: "/Users/CheckPhone",
            createUser: "/Users/CreateUser",
            createUserFromEvent: "/Users/CreateUserFromEvent",
            createUserFromCsv: "/Users/CreateUserFromCsvRaw",
            createLowPermissionUser: "/Users/CreateUserLowPermission",
            delete: "/Users/Delete",
            sendConfirmationEmail: "/Users/SendConfirmationEmail",
            confirmEmail: "/Users/ConfirmUserEmail",
            updateUserPilot: "/Users/UpdateUserPilot"
        },
        userLogs: {
            getLastLoginCurrentUser: "/UserLogs/GetLastLoginCurrentUser",
            getLastFailedLoginCurrentUser: "/UserLogs/GetLastFailedLoginCurrentUser",
            getLoginsCurrentUser: "/UserLogs/GetLoginsCurrentUser",
            getLastLoginByUserId: "/UserLogs/GetLastLoginByUserId",
            getLastFailedLoginByUserId: "/UserLogs/GetLastFailedLoginByUserId",
            getLoginsByUserId: "/UserLogs/GetLoginsByUserId",
        }
    },
    variableType: {
        getAll: "/VariableTypes/GetAll"
    },
    common: {
        settingType: {
            getAllPaged: "/SettingTypes/GetAllPaged",
            getAll: "/SettingTypes/GetAll",
            getById: "/SettingTypes/GetById",
            create: "/SettingTypes/Create",
            update: "/SettingTypes/Update",
            delete: "/SettingTypes/Delete",
        },
    },
    app: {
        setting: {
            key: {
                getAll: "/AppSettingKeys/GetAll",
                getById: "/AppSettingKeys/GetById",
                getByAbbr: "/AppSettingKeys/GetByAbbr",
                create: "/AppSettingKeys/Create",
                update: "/AppSettingKeys/Update",
                delete: "/AppSettingKeys/Delete",
                bin: "/AppSettingKeys/Bin",
                deletePermanently: "/AppSettingKeys/DeletePermanently",
                retore: "/AppSettingKeys/Restore"
            },
            value: {
                getAll: "/AppSettingValues/GetAll",
                getById: "/AppSettingValues/GetById",
                create: "/AppSettingValues/Create",
                update: "/AppSettingValues/Update",
                delete: "/AppSettingValues/Delete",
                getAvailableOptionsByKeyId: "/AppSettingValues/GetAvailableSettingValueBySettingKeyId"
            },
            type: {
                getAll: "/AppSettingTypes/GetAll"
            },
            provider: {
                getAll: "/SettingTypeProviders/GetAll"
            }
        }
    },
    email: {
        template: {
            getAll: "/EmailTemplates/GetAll",
            getById: "/EmailTemplates/GetById",
            getByAbbr: "/EmailTemplates/GetByAbbr",
            create: "/EmailTemplates/Create",
            update: "/EmailTemplates/Update",
            delete: "/EmailTemplates/Delete",
            bin: "/EmailTemplates/Bin",
            deletePermanently: "/EmailTemplates/DeletePermanently",
            retore: "/EmailTemplates/Restore"
        },
    },
    role: {
        getAll: "/Roles/GetAll"
    },
    client: {
        customer: {
            getAll: "/Customers/GetAll",
            getById: "/Customers/GetById",
            getByAbbr: "/Customers/GetByAbbr",
            create: "/Customers/Create",
            update: "/Customers/Update",
            delete: "/Customers/Delete",
            bin: "/Customers/Bin",
            deletePermanently: "/Customers/DeletePermanently",
            retore: "/Customers/Restore"
        },
        group: {
            getAll: "/Groups/GetAll",
            getById: "/Groups/GetById",
            getByAbbr: "/Groups/GetByAbbr",
            create: "/Groups/Create",
            createWithoutCustomer: "/Groups/CreateWithoutCustomer",
            createFromEvent: "/Groups/CreateFromEvent",
            update: "/Groups/Update",
            updateWithoutCustomer: "/Groups/UpdateWithoutCustomer",
            delete: "/Groups/Delete",
            bin: "/Groups/Bin",
            deletePermanently: "/Groups/DeletePermanently",
            retore: "/Groups/Restore",
            getAvailableManagementUsers: "/Groups/GetAvailableManamgementUsers",
            assingUserManager: "/Groups/AssingUserManagement",
            getUsersManager: "/Groups/GetGroupUsersManager",
            deleteUserManager: "/Groups/DeleteUserManager",
            getAvailableUsers: "/Groups/GetAvailableUsers",
            assingUser: "/Groups/AssingUser",
            assignMultipleUsers: "/Groups/AssignMultipleUsers",
            getUsers: "/Groups/GetGroupUsers",
            deleteUser: "/Groups/DeleteUser",
            deleteMultipleUsers: "/Groups/DeleteMultipleUsers"
        }
    },
    file: {
        upload: "/FileManager/Upload",
        showMyFiles: "/FileManager/ShowMyFiles",
        showAllFiles: "/FileManager/ShowAllFiles",
        delete: "/FileManager/Delete",
        getById: "/FileManager/GetById"
    },
    dashboard: {
        userDashboard: "/Dashboard/UserDashboard",
    },
    quiz: {
        tag: {
            getAllPaged: "/Tags/GetAllPaged",
            getAll: "/Tags/GetAll",
            getById: "/Tags/GetById",
            create: "/Tags/Create",
            createFromQuestion: "/Tags/CreateFromQuestion",
            update: "/Tags/Update",
            delete: "/Tags/Delete",
            bin: "/Tags/Bin",
            deletePermanently: "/Tags/DeletePermanently",
            retore: "/Tags/Restore",
            getAvailableByQuestion: "/Tags/GetAvailableByQestion",
        },
        category: {
            getAllPaged: "/Categories/GetAllPaged",
            getAll: "/Categories/GetAll",
            getById: "/Categories/GetById",
            create: "/Categories/Create",
            createFromQuestion: "/Categories/CreateFromQuestion",
            update: "/Categories/Update",
            delete: "/Categories/Delete",
            bin: "/Categories/Bin",
            deletePermanently: "/Categories/DeletePermanently",
            retore: "/Categories/Restore",
            getAvailableByQuestion: "/Categories/GetAvailableByQestion",
        },
        question: {
            getAll: "/Questions/GetAll",
            getAllByAuthor: "/Questions/GetAllByAuthor",
            getById: "/Questions/GetById",
            getByVoteId: "/Questions/GetByVoteID",
            getByIdWithAnswers: "/Questions/GetByIdWithAnswers",
            getAnswersByQuestionId: "/Questions/GetAnswersByQuestionId",
            create: "/Questions/Create",
            createWithAnswers: "/Questions/CreateWithAnswers",
            remmoveFile: "/Questions/RemoveFile",
            addFile: "/Questions/AddFile",
            update: "/Questions/Update",
            updateWithoutCustomer: "/Questions/UpdateWithoutCustomer",
            delete: "/Questions/Delete",
            bin: "/Questions/Bin",
            deletePermanently: "/Questions/DeletePermanently",
            retore: "/Questions/Restore",
            assingFile: "/Questions/AssingImageToQuestion",
            unassingFile: "/Questions/RemoveImageFromQuestion",
            type: {
                bin: "/QuestionTypes/Bin",
                create: "/QuestionTypes/Create",
                delete: "/QuestionTypes/Delete",
                deletePermanently: "/QuestionTypes/DeletePermanently",
                getPaged: "/QuestionTypes/GetAll",
                getAll: "/QuestionTypes/GetAllItem",
                getById: "/QuestionTypes/GetById",
                retore: "/QuestionTypes/Restore",
                update: "/QuestionTypes/Update",
            }
        },
        answer: {
            create: "/Answers/Create",
            update: "/Answers/Update",
            getById: "/Answers/GetById",
            getByQuestionId: "/Answers/GetByQuestionId",
            delete: "/Answers/Delete",
        },
        setting: {
            question: {
                getAllPaged: "/QuestioSettingKeys/GetAllPaged",
                getAll: "/QuestioSettingKeys/GetAll",
                getById: "/QuestioSettingKeys/GetById",
                getAvailableSettingsByQuestionId: "/QuestioSettingKeys/GetAvailableSettingsByQuestionId",
                create: "/QuestioSettingKeys/Create",
                update: "/QuestioSettingKeys/Update",
                delete: "/QuestioSettingKeys/Delete",
                bin: "/QuestioSettingKeys/Bin",
                deletePermanently: "/QuestioSettingKeys/DeletePermanently",
                retore: "/QuestioSettingKeys/Restore",

                createSettingValue: "/QuestionSettingValues/Create",
                getAllValuesByQuestionId: "/QuestionSettingValues/GetAllByQuestionId",
                deleteSettingValue: "/QuestionSettingValues/Delete",
            }
        },
        vote: {
            getAll: "/Quizzes/GetAll",
            getMyAll: "/Quizzes/GetMy",
            getById: "/Quizzes/GetById",
            create: "/Quizzes/Create",
            createWithoutCustomer: "/Quizzes/CreateWithoutCustomer",
            update: "/Quizzes/Update",
            updateWithoutCustomer: "/Quizzes/UpdateWithoutCustomer",
            delete: "/Quizzes/Delete",
            bin: "/Quizzes/GetBin",
            deletePermanently: "/Quizzes/DeletePermanently",
            retore: "/Quizzes/Restore",
            getByVoteBlock: "/Quizzes/GetVotesByVoteBlock",
            type: {
                bin: "/VoteTypes/Bin",
                create: "/VoteTypes/Create",
                delete: "/VoteTypes/Delete",
                deletePermanently: "/VoteTypes/DeletePermanently",
                getPaged: "/VoteTypes/GetAll",
                getAll: "/VoteTypes/GetAllItem",
                getById: "/VoteTypes/GetById",
                retore: "/VoteTypes/Restore",
                update: "/VoteTypes/Update",
            }
        },
        voteBlock: {
            getAll: "/VoteBlocks/GetAll",
            getMyAll: "/VoteBlocks/GetMy",
            getById: "/VoteBlocks/GetById",
            getAvailableVoteBlockByVote: "/VoteBlocks/GetAvailableVoteBlockByVote",
            create: "/VoteBlocks/Create",
            createWithoutCustomer: "/VoteBlocks/CreateWithoutCustomer",
            update: "/VoteBlocks/Update",
            updateWithoutCustomer: "/VoteBlocks/UpdateWithoutCustomer",
            delete: "/VoteBlocks/Delete",
            bin: "/VoteBlocks/Bin",
            deletePermanently: "/VoteBlocks/DeletePermanently",
            retore: "/VoteBlocks/Restore",
            type: {
                bin: "/VoteBlockTypes/Bin",
                create: "/VoteBlockTypes/Create",
                delete: "/VoteBlockTypes/Delete",
                deletePermanently: "/VoteBlockTypes/DeletePermanently",
                getPaged: "/VoteBlockTypes/GetAll",
                getAll: "/VoteBlockTypes/GetAllItem",
                getById: "/VoteBlockTypes/GetById",
                retore: "/VoteBlockTypes/Restore",
                update: "/VoteBlockTypes/Update",
            }
        },
        interactive: {
            setVoteBlockActive: "/Interactive/SetVoteBlockActive",
            setVoteBlockDeactive: "/Interactive/SetVoteBlockDeactive",
            getCurrentActiveVoteBlock: "/Interactive/GetCurrentActiveVoteBlock",
            setVoteActive: "/Interactive/SetVoteActive",
            setVoteDeactive: "/Interactive/SetVoteDeactive",
            getCurrentActiveVote: "/Interactive/GetCurrentActiveVote",
            setQuestionActive: "/Interactive/SetQuestionActive",
            setQuestionDeactive: "/Interactive/SetQuestionDeactive",
            getCurrentActiveQuestion: "/Interactive/GetCurrentActiveQuestion",
            getCurrentActiveQuestionByUser: "/Interactive/GetCurrentActiveQuestionByUser",
            getCurrentUserStatusByQuestion: "/Interactive/GetCurrentUserStatusByQuestion",

        }
    },
    event: {
        getAll: "/Events/GetAll",
        getMyAll: "/Events/GetMyAll",
        getById: "/Events/GetById",
        getByCode: "/Events/GetByCode",
        create: "/Events/Create",
        createWithoutCustomer: "/Events/CreateWithoutCustomer",
        update: "/Events/Update",
        delete: "/Events/Delete",
        bin: "/Events/Bin",
        deletePermanently: "/Events/DeletePermanently",
        retore: "/Events/Restore",
        getAvailableGroups: "/Events/GetAvailableGroups",
        assignGroupToEvent: "/Events/AssingGroupToEvent",
        removeGroupFromEvent: "/Events/RemoveGroupFromEvent",
        getGroupsByEventId: "/Events/GetGroupsByEventId",
        getVoteBlocksByEventId: "/Events/GetVoteBlocksByEventId",
        getAvailableVoteBlocksByEventId: "/Events/GetAvailableVoteBlocks",
        getAvailableEventsByVoteBlock: "/Events/GetAvailableEventsByVoteBlock",

        setting: {
            getAll: "/EventSettingKeys/GetAll",
            getById: "/EventSettingKeys/GetById",
            create: "/EventSettingKeys/Create",
            update: "/EventSettingKeys/Update",
            delete: "/EventSettingKeys/Delete",
            bin: "/EventSettingKeys/Bin",
            deletePermanently: "/EventSettingKeys/DeletePermanently",
            retore: "/EventSettingKeys/Restore",
            getAvailableSettings: "/EventSettingKeys/GetAvailableSettingsByEvent",

            getAllValuesByEvent: "/EventSettingValues/GetByEventId",
            getAllValuesByEventCode: "/EventSettingValues/GetByEventCode",
            createEventValue: "/EventSettingValues/Create",
            updateEventValue: "/EventSettingValues/Update",
            deleteSettingValue: "/EventSettingValues/Delete",
        }
    },
    hubs: {
        quizLive: "/stream/event",
    },
};

export default url;