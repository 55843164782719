import api from "./api";
import url from "./url";

class VariableTypeService {
    GetAll() {
        return api.get(url.variableType.getAll)
            .then((res) => {
                return res;
            })
    }
}

export default new VariableTypeService;