<template>
    <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Recent Payments Queue</h4>
                    <QuizCategoryButtonNavigation />
                </div>
                <div class="card-body">

                    <div class="row">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-12">
                                    <h5><strong>{{ $t('com.quiz.category.name') }}</strong></h5>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-12 ">
                                        {{ category.name }}
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <h5><strong>{{ $t('com.quiz.category.description') }}</strong></h5>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-12 ">
                                        {{ category.description }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script charset="utf-8">
    import PageTitle from "@/components/common/PageTitle.vue";
    import QuizCategoryButtonNavigation from "@/components/quiz/category/QuizCategoryButtonNavigation.vue";
    import QuizQuestionCategoryService from "@/services/quiz/quiz.question.category.service"

    //import VueMultiselect from 'vue-multiselect'
    //import ErrorModalMessage from "../../../components/common/error/ErrorModalMessage";

    export default {
        components: {
            PageTitle,
            QuizCategoryButtonNavigation,
            //ErrorModalMessage
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "App Setting Key Create",
                subTitle: "com.app.setting.key.subtitle",
                category: {
                    id: -1,
                    name: "",
                    description: "",
                },

                error: {
                    isShow: false,
                    header: "test",
                    message: "",
                    errors: []
                },
                loading: false,
            };
        },
        computed: {
            cateogryId() {
                return this.$route.params.id;
            }
        },
        watch: {

        },
        mounted() {
            this.fetchCategory();
        },
        methods: {

            async fetchCategory() {
                try {
                    this.clearError();
                    this.loading = true;
                    let res = (await QuizQuestionCategoryService.GetById(this.cateogryId))
                    if (res.status == 200) {
                        this.category = res.data;
                    }
                }
                catch (e) {
                    //console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                    else if (e.response.status == 400) {
                        this.error.isShow = true;
                        this.error.message = e.response.data.message;
                        this.error.errors = e.response.data.errors;
                    }
                    else if (e.response.status == 404) {
                        this.$router.push({ name: 'NotFound404' })
                    }
                }
                finally {
                    this.loading = false;
                }
            },
            clearError() {
                this.error.isShow = false;
                this.error.message = "";
                this.error.errors = "";
            },
            clearData() {
                this.category = {
                    name: "",
                    description: "",
                }
            },
            translatedLabel(obj) {
                if (obj === undefined)
                    return "test";

                return this.$t(obj.name)
            }


        }
    }</script>

<style>
</style>
