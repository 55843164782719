import api from "./api";
import url from "./url";

class GroupService {
    GetGroups(Pages) {
        return api.get(url.client.group.getAll, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage
            }
        })
            .then((res) => {
                return res;
            })
    }

    GetGroupById(GroupID) {
        return api.get(url.client.group.getById + "/" + GroupID)
            .then((res) => {
                return res;
            })
    }

    GetGroubByAbbr(GroupAbbr) {
        return api.get(url.client.group.getByAbbr + "/" + GroupAbbr)
            .then((res) => {
                return res;
            })
    }

    CreateGroup(request) {
        const req = {
            Name: request.name,
            I18nName: request.i18nname,
            Description: request.description,
            I18nDescription: request.i18ndescription,
            CustomerID: request.customerid
        }
        return api.post(url.client.group.create, req)
            .then((response) => {
                return response;
            });
    }

    CreateGroupWithoutCustomer(request) {
        const req = {
            Name: request.name,
            I18nName: request.i18nname,
            Description: request.description,
            I18nDescription: request.i18ndescription,
        }
        return api.post(url.client.group.createWithoutCustomer, req)
            .then((response) => {
                return response;
            });
    }

    CreateFromEvent(request) {
        const req = {
            Name: request.name,
            EventID: request.eventID
        }
        return api.post(url.client.group.createFromEvent, req)
            .then((response) => {
                return response;
            });
    }

    UpdateGroup(request) {
        const req = {
            Id: request.id,
            Name: request.name,
            I18nName: request.i18nname,
            Description: request.description,
            I18nDescription: request.i18ndescription,
            CustomerID: request.customerid
        }
        return api.post(url.client.group.update, req)
            .then((response) => {
                return response;
            });
    }

    UpdateWithoutCustomer(request) {
        const req = {
            Id: request.id,
            Name: request.name,
            I18nName: request.i18nname,
            Description: request.description,
            I18nDescription: request.i18ndescription,
        }
        return api.post(url.client.group.updateWithoutCustomer, req)
            .then((response) => {
                return response;
            });

    }

    DeleteGroup(GroupID) {
        return api.delete(url.client.group.delete + "/" + GroupID)
            .then((res) => {
                return res;
            })
    }

    GetGroupsBin(Pages) {
        return api.get(url.client.group.bin, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage
            }
        })
            .then((res) => {
                return res;
            })
    }
    DeletePermanentlyGroup(GroupID) {
        return api.delete(url.client.group.deletePermanently + "/" + GroupID)
            .then((res) => {
                return res;
            })
    }

    RestoreGroup(GroupID) {
        return api.post(url.client.group.retore + "/" + GroupID)
            .then((res) => {
                return res;
            })
    }

    GetAvailableManagementUsers(GroupID) {
        return api.get(url.client.group.getAvailableManagementUsers + "/" + GroupID)
            .then((res) => {
                return res;
            })
    }

    AssingUserManager(request) {
        const req = {
            GroupID: request.groupID,
            UserID: request.userID,
        }
        return api.post(url.client.group.assingUserManager, req)
            .then((response) => {
                return response;
            });
    }
    GetUsersManager(GroupID) {
        return api.get(url.client.group.getUsersManager + "/" + GroupID)
            .then((res) => {
                return res;
            })
    }
    DeleteUserManager(request) {
        return api.delete(url.client.group.deleteUserManager, {
            params: {
                GroupID: request.groupID,
                UserID: request.userID,
            }
        })
            .then((res) => {
                return res;
            })
    }

    GetAvailableUsers(GroupID) {
        return api.get(url.client.group.getAvailableUsers + "/" + GroupID)
            .then((res) => {
                return res;
            })
    }

    AssingUser(request) {
        const req = {
            GroupID: request.groupID,
            UserID: request.userID,
        }
        return api.post(url.client.group.assingUser, req)
            .then((response) => {
                return response;
            });
    }

    AssignMultipleUsers(request) {
        const req = {
            GroupID: request.groupID,
            Users: request.users
        }

        return api.post(url.client.group.assignMultipleUsers, req)
            .then((response) => {
                return response;
            });
    }

    GetUsers(GroupID) {
        return api.get(url.client.group.getUsers + "/" + GroupID)
            .then((res) => {
                return res;
            })
    }

    DeleteUser(request) {
        return api.delete(url.client.group.deleteUser, {
            params: {
                GroupID: request.groupID,
                UserID: request.userID,
            }
        })
            .then((res) => {
                return res;
            })
    }

    DeleteMultipleUsers(request) {

        const req = {
            GroupID: request.groupID,
            Users: request.users
        }
        return api.post(url.client.group.deleteMultipleUsers, req).then((res) => {
            return res;
        })
    }
}

export default new GroupService;