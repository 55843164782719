import RoleService from '../../services/role.service';

export const role = {
    namespaced: true,
    state: null,
    actions: {
        getAll({ commit }) {
            commit('empty');
            return RoleService.GetAll().then(
                roles => {
                    return Promise.resolve(roles)
                },
                error => {
                    return Promise.reject(error.response)
                }
            )
        }
    },
    mutations: {
        empty() {

        },
    }
}