import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

import TokenService from "@/services/token.service";
import url from "../url";

class QuizLiveVoteHubService{

    constructor() {
        this.client = new HubConnectionBuilder()
            .withUrl(url.hubs.quizLive, {
                accessTokenFactory: () => TokenService.getLocalAccessToken()
            })
            .configureLogging(LogLevel.Debug)
            .build();

    }

    Connect(eventCode, Type = "") {
        return this.client.start()
            .then((res) => {
                if (Type === "interactive") {
                    this.SendObserveInteractiveMode(eventCode)
                }
                else if (Type === "visualisation") {
                    this.SendObserveVisualisationMode(eventCode)
                }
                else if (Type === "participant") {
                    this.SendObserveParticipantMode(eventCode)
                }
                return res;
            });
    }

    SendObserveInteractiveMode(eventCode) {
        return this.client.invoke("ObserveEvent", eventCode);
    }

    SendObserveVisualisationMode(eventCode) {
        return this.client.invoke("ObserveVisualisationEvent", eventCode);
    }

    SendObserveParticipantMode(eventCode) {
        return this.client.invoke("ObserveParticipant", eventCode);
    }


}

export default new QuizLiveVoteHubService;