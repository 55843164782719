<template>
    <div id="addManagementUser" class="modal fade" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <form method="POST" class="needs-validation" novalidate="" autocomplete="off" @submit.prevent="addManagementUser">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ $t('com.modal.areyousure') }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label class="form-label"><strong>{{ $t('com.app.setting.key.settingtype') }}</strong></label>
                            <VueMultiselect v-model="modalData.user"
                                            :options="availableOptions"
                                            :searchable="true"
                                            :placeholder="$t('com.app.setting.key.settingtype')"
                                            :multiple="false"
                                            :custom-label="customName"
                                            track-by="id"
                                            :disabled="loading"
                                            :showLabels="false"
                                            :allow-empty="false"
                            />
                            <pre class="language-json"><code>{{ modalData.user }}</code></pre>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button id="addManagementUserEmit" type="button" class="btn btn-danger light" data-bs-dismiss="modal">{{ $t('com.global.close') }}</button>
                        <button type="submit" class="btn btn-primary">{{ $t('com.global.add') }}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>


</template>

<script charset="utf-8">
    //import AppSettingValueService from "../../../../services/app.setting.value.service";
    import GroupService from "../../../services/group.service"
    import VueMultiselect from 'vue-multiselect'
    export default {
        components: {
            VueMultiselect
        },
        props: {
            error: {
                type: Object,
                require: true,
                default: function () {
                    return {
                        isShow: false,
                        header: "test",
                        message: "",
                    }
                },


            },
            groupID: {
                type: Number,
                require: true,
            },
            closeModal: {
                type: Boolean,
                require: true,
            }

        },
        emits: ["add-manager"],
        data() {
            return {
                publicPath: process.env.BASE_URL,
                availableOptions: [],
                modalData: {
                    user: "",
                }
            };
        },
        computed: {

        },
        watch: {
            closeModal: {
                immediate: true,
                handler(val) {
                        if (val == true) {
                            this.modalData= {
                                user: {},
                            }
                            this.availableOptions = []
                            this.fetchAvailableManagerUser(this.groupID);
                            document.getElementById('addManagementUserEmit').click();
                        }

                                }
            },
            groupID: {
                immediate: true,
                handler(val) {
                        let id = parseInt(val)
                        if (id > 0) {
                            this.fetchAvailableManagerUser(id);
                        }

                }
            }
        },
        mounted() {
        },
        methods: {

            async fetchAvailableManagerUser(groupID) {
                try {
                    let res = (await GroupService.GetAvailableManagementUsers(groupID))
                    if (res.status == 200) {
                        this.availableOptions = res.data
                    }
                }
                catch (e) {
                    //console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
            },
            customName(obj) {
                console.log(obj)

                if (obj == undefined)
                    return "Select";

                return obj.firstname + " " + obj.lastname + " (" + obj.eMail + ")";
            },

            addManagementUser() {
                this.$emit('add-manager', this.modalData)
            }

           
        }
    }</script>

<style>
</style>
