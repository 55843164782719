<template>
    <div class="row">

        <template v-if="internal.settingType.provider == 0">
            <h3>Wybrana none</h3>
        </template>
        <template v-else-if="internal.settingType.provider == 10">
            <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" :data-bs-target="getFileModalHashtag()">
                <span v-if="loading.global" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {{ $t('com.setting.type.provider.select') }}
            </button>
        </template>


    </div>
    <FileManager :fileManageModalID="fileManager.modalID" @selected-image="selectedImage" @deleted-file="fetchEventSetting" />
</template>

<script charset="utf-8">
    import FileManager from "@/components/file/FileManager.vue"

    export default {
        components: {
            FileManager,
        },

        props: {
            settingType: {
                type: Object,
                require: true,
            },

        },
        emits: ["selected-data"],
        data() {
            return {
                tableLoader: {
                    type: "spinner-border",
                    color: "text-primary",
                    size: "big"
                },

                fileManager: {
                    modalID: "settinyTypeSelectorModalID"
                },

                

                loading: {
                    global: false,
                },

                internal: {
                    settingType: {}
                },

            };
        },
        computed: {
           
        },
        watch: {
            settingType: {
                immediate: true,
                handler(val) {
                    this.internal.settingType = val;
                }
            }
        },
        mounted() {
            
        },
        methods: {
             selectedImage(data) {

                this.$emit("selected-data", data.id)
            },

            fetchEventSetting() {
                return;
            },
            getFileModalHashtag() {
                return "#" + this.fileManager.modalID;
            },
        }
    }
</script>
<style>

</style>