<template>
    <!-- Modal Event Group Details -->
    <div id="modalEventGroupDetail" class="modal fade" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('com.global.details') }} - {{ groupItem.name }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" @click="unsetItem()"></button>
                </div>
                <div class="modal-body">
                    <div class="row mb-3">
                        <div class="col-12">
                            <h4>{{ $t('com.group.users.participants') }}</h4>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                <div class="btn-group" role="group" aria-label="Basic example">
                                    <button type="button" class="btn btn-primary btn-sm" @click="selectAll()">
                                        <span v-if="loading.group" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        {{ $t('com.global.selectall') }}
                                    </button>
                                    <button type="button" class="btn btn-primary btn-sm" @click="deselectAll()">
                                        <span v-if="loading.group" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        {{ $t('com.global.deselectall') }}
                                    </button>
                                    <button type="button" class="btn btn-primary btn-sm" @click="deleteMultipleUsers()">
                                        <span v-if="loading.group" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        {{ $t('com.global.deleteselected') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            <label class="form-label">{{ $t('com.group.user.search') }}</label>
                            <div class="input-group mb-3">
                                <VueMultiselect v-model="selectedUser"
                                                :options="availableUser"
                                                :searchable="true"
                                                :placeholder="$t('com.group.user.search')"
                                                :multiple="true"
                                                :custom-label="customName"
                                                track-by="id"
                                                :disabled="loading"
                                                :showLabels="false"
                                                :allow-empty="true"
                                                :taggable="false" />
                                <div class="input-group-append">
                                    <button class="btn btn-primary" type="button" @click="assingSelectedUsers()">{{ $t('com.group.users.add') }}</button>
                                </div>
                            </div>

                        </div>
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table table-responsive-md">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th style="width:80px;"><strong>#</strong></th>
                                            <th><strong>{{ $t('com.user.firstname') }} {{ $t('com.user.lastname') }}</strong></th>
                                            <th><strong>{{ $t('com.user.email') }}</strong></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(user, index) in users" :key="index">
                                            <td class="align-middle">
                                                <div class="form-check">
                                                    <input type="checkbox" class="form-check-input" :value="user.id" v-model="checkedUsers" />
                                                </div>

                                            </td>
                                            <td>{{ getIndex(index) }}</td>
                                            <td>{{ user.firstname }} {{ user.lastname }}</td>
                                            <td>{{ user.eMail }}</td>
                                            <td>
                                                <div class="dropdown">
                                                    <button :id="getDropdownID(index)" type="button" class="btn btn-primary light sharp" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <rect x="0" y="0" width="24" height="24" />
                                                                <circle fill="#000000" cx="5" cy="12" r="2" />
                                                                <circle fill="#000000" cx="12" cy="12" r="2" />
                                                                <circle fill="#000000" cx="19" cy="12" r="2" />
                                                            </g>
                                                        </svg>
                                                    </button>
                                                    <div class="dropdown-menu" :aria-labelledby="getDropdownID(index)">
                                                        <a class="dropdown-item" href="javascript:void(0);" @click="removeUser(user)">{{ $t('com.global.delete') }}</a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <pre lang="text/json">{{ checkedUsers }}</pre>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="modalDetailEventGroupEmit" type="button" class="btn btn-danger light" data-bs-dismiss="modal" @click="unsetItem()">
                        {{ $t('com.global.close') }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- /Modal Event Group Details -->
</template>

<script charset="utf-8">
    //import ErrorModalMessage from "../common/error/ErrorModalMessage";
    import GroupService from "@/services/group.service"
    import VueMultiselect from 'vue-multiselect'
    export default {
        components: {
            //ErrorModalMessage
            VueMultiselect
        },
        props: {

            groupItem: {
                type: Object,
                required: true,
                default: function () {
                    return {
                        abbr: "",
                        description: "",
                        i18nDescription: "",
                        i18nName: "",
                        id: 0,
                        name: "",
                    }
                }
            }
            //
        },
        emits: ["unset-item"],
        data() {
            return {
                publicPath: process.env.BASE_URL,
                loading: false,
                selectedUser: [],
                availableUser: [],
                users: [],
                checkedUsers:[],
                //editItem: {
                //    id: 0,
                //    name: "",
                //    friendlyName: "",
                //    description: "",
                //},
                //error: {
                //    isShow: false,
                //    header: "test",
                //    message: "",
                //}
            };
        },
        computed: {

        },
        watch: {
            groupItem: {
                // the callback will be called immediately after the start of the observation
                immediate: true,
                handler() {
                    if (this.groupItem.id !== undefined)
                    {
                        this.fetchUsesrsByGroups();
                        this.fetchAvailableManagerUser(this.groupItem.id)
                    }

                }
            }
        },
        methods: {

            async fetchUsesrsByGroups() {

                try {
                    this.loading = true;
                    let res = (await GroupService.GetUsers(this.groupItem.id))


                    //this.closeModal.user = false;
                    //this.closeModal.delete = false;
                    if (res.status == 200) {
                        this.users = res.data;
                        console.log(this.users);
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },

            async removeUser(user) {
                try {
                    this.loading = false;

                    const req = {
                        groupID: this.groupItem.id,
                        userID: user.id
                    }

                    let res = (await GroupService.DeleteUser(req));

                    if (res.status == 200) {
                        this.fetchUsesrsByGroups();
                        this.fetchAvailableManagerUser(this.groupItem.id)
                    }

                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },

            async deleteMultipleUsers() {
                try {
                    this.loading = true;

                    const req = {
                        groupID: this.groupItem.id,
                        users: this.checkedUsers
                    };

                    let res = (await GroupService.DeleteMultipleUsers(req));

                    if (res.status == 200) {
                        this.deselectAll();
                        this.$toast.success(this.$t('com.group.deleteuserssuccess'));
                        this.fetchUsesrsByGroups();
                        this.fetchAvailableManagerUser(this.groupItem.id)
                    }

                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },


            async fetchAvailableManagerUser(groupID) {
                try {
                    let res = (await GroupService.GetAvailableUsers(groupID))
                    if (res.status == 200) {
                        this.availableUser = res.data
                    }
                }
                catch (e) {
                    //console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
            },

            async assingSelectedUsers() {
                try {
                    const req = {
                        groupID: this.groupItem.id,
                        users: this.selectedUser.map(x => x.id),
                    }

                    let res = (await GroupService.AssignMultipleUsers(req));

                    if (res.status == 200) {
                        this.$toast.success(this.$t('com.group.users.successassigned'));
                        this.selectedUser = [];
                        this.fetchUsesrsByGroups();
                        this.fetchAvailableManagerUser(this.groupItem.id)
                        
                    }
                }
                catch (e) {
                    //console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
            },

            customName(obj) {
                console.log(obj)

                if (obj == undefined)
                    return "Select";

                return obj.firstname + " " + obj.lastname + " (" + obj.eMail + ")";
            },

            selectAll() {
                this.checkedUsers = this.users.map(x=> x.id)
            },
            deselectAll() {
                this.checkedUsers = [];
            },
            unsetItem() {
                this.checkedUsers = [];
                this.$emit("unset-item")
            },

            getIndex(index) {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;

                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 0;

                return ((page - 1) * itemsPerPage) + index + 1
            },

            getDropdownID(str) {
                return "dropdownButtonModalGroupDetail" + str;
            },
            //async UpdateItem() {
            //    this.error.isShow = false;
            //    try {
            //        const req = {
            //            id: this.selectedItem.id,
            //            name: this.selectedItem.name,
            //            description: this.selectedItem.description,
            //            friendlyName: this.selectedItem.friendlyName,
            //        }
            //        console.log(this.selectedItem, req)
            //        this.$store.dispatch("i18n/updateResource", req).then(
            //            () => {
            //                this.reloadItems();
            //                document.getElementById('editModalEmit').click();
            //            },
            //            (error) => {
            //                this.error.isShow = true;
            //                this.error.header = "header";
            //                this.error.message = error.data.translatedMessage;
            //            }
            //        );
            //    }
            //    catch (err) {
            //        console.log(err)
            //    }
            //},
            //unsetItem() {
            //    this.$emit("unset-item")
            //},
            //reloadItems() {
            //    this.$emit("reload-items")
            //},
            //clearData() {
            //    this.error.isShow = false;
            //    this.error.header = "";
            //    this.error.message = ""
            //}
        }
    }</script>

<style>
</style>
