import api from "./api";
import url from "./url";

class EmailTemplateService {
    GetEmailTemplates(Pages) {
        return api.get(url.email.template.getAll, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage
            }
        })
            .then((res) => {
                return res;
            })
    }

    GetEmailTemplateById(EmailTemplateID) {
        return api.get(url.email.template.getById + "/" + EmailTemplateID)
            .then((res) => {
                return res;
            })
    }

    GetEmailTemplateByAbbr(EmailTemplateAbbr) {
        return api.get(url.email.template.getByAbbr + "/" + EmailTemplateAbbr)
            .then((res) => {
                return res;
            })
    }

    CreateEmailTemplate(request) {
        const req = {
            Name: request.name,
            Description: request.description,
            Body: request.body,
            LanguageID: request.languageID,
        }
        return api.post(url.email.template.create, req)
            .then((response) => {
                return response;
            });
    }

    UpdateEmailTemplate(request) {
        const req = {
            Id: request.id,
            Name: request.name,
            Description: request.description,
            Body: request.body,
            LanguageID: request.languageID,
        }
        return api.post(url.email.template.update, req)
            .then((response) => {
                return response;
            });
    }

    DeleteEmailTemplate(EmailTemplateID) {
        return api.delete(url.email.template.delete + "/" + EmailTemplateID)
            .then((res) => {
                return res;
            })
    }

    GetEmailTemplatesBin(Pages) {
        return api.get(url.email.template.bin, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage
            }
        })
            .then((res) => {
                return res;
            })
    }
    DeletePermanentlyEmailTemplate(EmailTemplateID) {
        return api.delete(url.email.template.deletePermanently + "/" + EmailTemplateID)
            .then((res) => {
                return res;
            })
    }

    RestoreEmailTemplate(EmailTemplateID) {
        return api.post(url.email.template.retore + "/" + EmailTemplateID)
            .then((res) => {
                return res;
            })
    }
}

export default new EmailTemplateService;