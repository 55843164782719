import api from "../api";
import url from "../url";

class QuizQuestionTagService {
    GetPaged(Pages) {
        return api.get(url.quiz.tag.getAllPaged, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage
            }
        })
            .then((res) => {
                return res;
            })
    }

    GetAll() {
        return api.get(url.quiz.tag.getAll)
            .then((res) => {
                return res;
            })
    }

    GetById(TagID) {
        return api.get(url.quiz.tag.getById + "/" + TagID)
            .then((res) => {
                return res;
            })
    }

    Create(request) {
        const req = {
            Name: request.name,
        }

        return api.post(url.quiz.tag.create, req)
            .then((response) => {
                return response;
            });
    }

    CreateFromQuestion(request) {
        const req = {
            Name: request.name,
        }

        return api.post(url.quiz.tag.createFromQuestion, req)
            .then((response) => {
                return response;
            });
    }

    Update(request) {
        const req = {
            Id: request.id,
            Name: request.name,
        }

        return api.post(url.quiz.tag.update, req)
            .then((response) => {
                return response;
            });
    }

    Delete(TagID) {
        return api.delete(url.quiz.tag.delete + "/" + TagID)
            .then((res) => {
                return res;
            })
    }

    GetBin(Pages) {
        return api.get(url.quiz.tag.bin, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage
            }
        })
            .then((res) => {
                return res;
            })
    }

    DeletePermanently(TagID) {
        return api.delete(url.quiz.tag.deletePermanently + "/" + TagID)
            .then((res) => {
                return res;
            })
    }

    Restore(TagID) {
        return api.post(url.quiz.tag.retore + "/" + TagID)
            .then((res) => {
                return res;
            })
    }

    GetAvailableByQuestionId(TagID) {
        return api.get(url.quiz.tag.getAvailableByQuestion + "/" + TagID)
            .then((res) => {
                return res;
            })
    }

}
export default new QuizQuestionTagService;