<template>
    <div class="row mb-3">
        <div class="col-6">
            <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
        </div>
        <div class="col-6">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <QuizQuestionButtonNavigation />
            </div>
        </div>
    </div>
    <div class="row">
        <!-- Question Detail -->
        <div class="col-4">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ $t('com.quiz.question.details') }}</h4>

                </div>
                <div class="card-body">
                    <div class="row mb-3">
                        <div class="col-md-12">
                            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                <div class="btn-group" role="group" aria-label="Basic example">
                                    <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" :data-bs-target="getFileModalHashtag()">
                                        <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        {{ $t('com.quiz.question.addquestionimage') }}
                                    </button>
                                    <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#modalQuestionPrievew">
                                        <span v-if="loading" class="spinner-border spinner-border-sm " role="status" aria-hidden="true"></span>
                                        {{ $t('com.quiz.question.preview') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <h5>{{ $t('com.quiz.question.name') }}</h5>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 ">
                            {{ quiz.name }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <h5>{{ $t('com.quiz.question.description') }}</h5>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 ">
                            <div v-html="quiz.description"></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <h5>{{ $t('com.quiz.question.author') }}</h5>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 ">
                            {{ quiz.author.firstname }} {{ quiz.author.lastname }}
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <h5>{{ $t('com.quiz.question.language') }}</h5>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 ">
                            {{ quiz.language.name }} ({{ quiz.language.culture }})
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <h5>{{ $t('com.quiz.question.type') }}</h5>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 ">
                            {{ $t(quiz.type.name) }}
                        </div>
                    </div>


                    <div v-if="isFile" class="row">
                        <div class="col-12">
                            <h5>{{ $t('com.quiz.question.image') }}</h5>
                        </div>
                    </div>
                    <div v-if="isFile" class="row mb-3">
                        <div class="col-12 ">
                            <div class="row mb-3">
                                <div class="col-12">
                                    <img :src="quiz.file.relativePath" :alt="quiz.file.caption" class="img-fluid" />
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12">
                                    <button class="btn btn-primary btn-sm" @click="deleteImage(quiz.file.id)">{{ $t('com.global.delete') }}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <h5>{{ $t('com.quiz.question.categories') }}</h5>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 ">
                            <a v-for="(category, catIndex) in quiz.categories" :key="catIndex" href="#" class="badge badge-rounded badge-outline-info">
                                {{ category.name }}
                            </a> <br />
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-12">
                            <h5>{{ $t('com.quiz.question.tags') }}</h5>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 ">
                            <a v-for="(tag, tagIndex) in quiz.tags" :key="tagIndex" href="#" class="badge badge-rounded badge-outline-info">
                                {{ tag.name }}
                            </a> <br />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Question Detail -->
        <!-- Qusetion Answer/Setting-->
        <div class="col-8">
            <div class="row">
                <!-- Answers -->
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">{{ $t('com.quiz.question.answers') }}</h4>
                        </div>
                        <div class="card-body">
                            <div class="row mb-3">
                                <div class="col-md-12">
                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <div class="btn-group" role="group" aria-label="Basic example">
                                            <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="offcanvas" data-bs-target="#addAnswer">
                                                <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                {{ $t('com.quiz.question.addanswer') }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="table-responsive">
                                        <table class="table table-responsive-md">
                                            <thead>
                                                <tr>
                                                    <th style="width:80px;"><strong>#</strong></th>
                                                    <th><strong>{{ $t('com.quiz.answer.name') }}</strong></th>
                                                    <th><strong>{{ $t('com.quiz.answer.image') }}</strong></th>
                                                    <th><strong>{{ $t('com.quiz.answer.iscorrect') }}</strong></th>
                                                    <th><strong>{{ $t('com.quiz.answer.points') }}</strong></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(answer, index) in quiz.answers" :key="index">
                                                    <td><strong>{{ getIndex(index) }}</strong></td>
                                                    <td>{{ answer.name }}</td>
                                                    <td>
                                                        <img v-if="answer.isFile" :src="answer.file.relativePath" :alt="answer.file.caption" class=" img-fluid img-thumbnail" />
                                                    </td>
                                                    <td>{{ answer.isCorrect }}</td>
                                                    <td>{{ answer.points }}</td>
                                                    <td>
                                                        <div class="dropdown">
                                                            <button :id="getDropdownID(index)" type="button" class="btn btn-success light sharp" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <rect x="0" y="0" width="24" height="24" />
                                                                        <circle fill="#000000" cx="5" cy="12" r="2" />
                                                                        <circle fill="#000000" cx="12" cy="12" r="2" />
                                                                        <circle fill="#000000" cx="19" cy="12" r="2" />
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                            <div class="dropdown-menu" :aria-labelledby="getDropdownID(index)">
                                                                <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="offcanvas" data-bs-target="#editAnswer" @click="setAnswerItem(answer)">{{ $t('com.global.edit') }}</a>
                                                                <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modalItemDelete" @click="setAnswerItem(answer)">{{ $t('com.global.delete') }}</a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /Answers -->
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">{{ $t('com.quiz.question.settings') }}</h4>

                        </div>
                        <div class="card-body">
                            <div class="row mb-3">
                                <div class="col-md-12">
                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <div class="btn-group" role="group" aria-label="Basic example">
                                            <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#addSettingQuestionValue">
                                                <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                {{ $t('com.quiz.question.addsetting') }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="table-responsive">
                                        <table class="table table-responsive-md table-hover">
                                            <thead>
                                                <tr>
                                                    <th style="width:80px;"><strong>#</strong></th>
                                                    <th><strong>{{ $t('com.quiz.setting.question.name') }}</strong></th>
                                                    <th><strong>{{ $t('com.quiz.setting.question.description') }}</strong></th>
                                                    <th><strong>{{ $t('com.quiz.setting.question.value') }}</strong></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(valueSetting, index) in quiz.settingValues" :key="index">
                                                    <td><strong>{{ getIndex(index) }}</strong></td>
                                                    <td>{{ valueSetting.setting.name }}</td>
                                                    <td>{{ valueSetting.setting.description }}</td>
                                                    <td>{{ valueSetting.value }}</td>
                                                    <td>
                                                        <div class="dropdown">
                                                            <button :id="getDropdownID(index)" type="button" class="btn btn-success light sharp" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <rect x="0" y="0" width="24" height="24" />
                                                                        <circle fill="#000000" cx="5" cy="12" r="2" />
                                                                        <circle fill="#000000" cx="12" cy="12" r="2" />
                                                                        <circle fill="#000000" cx="19" cy="12" r="2" />
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                            <div class="dropdown-menu" :aria-labelledby="getDropdownID(index)">
                                                                <!--<a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="offcanvas" data-bs-target="#editAnswer" @click="setSettingItem(valueSetting)">{{ $t('com.global.edit') }}</a>-->
                                                                <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modalQuestionValueDelete" @click="setSettingItem(valueSetting)">{{ $t('com.global.delete') }}</a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <QuizAnswerCreate :questionID="quiz.id" @answer-added="answerAdded" />
    <QuizAnswerEdit :answerID="selectedAnswerItem.id" @answer-edited="answerAdded" />
    <FileManager :fileManageModalID="questionModalId" @selected-image="selectedImage" @deleted-file="fetchQuestion" />
    <ModalQuestionAddSetting :questionID="quiz.id" :availableOptions="availableSettingItems" @reload-settings="reloadSettings" />
    <ModalDelete :error="errorModalDelete"
                 :deletedItem="deletedItem"
                 :closeModal="closeModal"
                 @unset-item="unsetAnswer()"
                 @confirm-delete="deleteAnswer()"
    />

    <ModalQuestionValueDelete :error="errorModalDelete"
                              :deletedItem="deletedItem"
                              :closeModal="closeModal"
                              @unset-item="unsetSettingValue()"
                              @confirm-delete="deleteSettingValue()"
    />

    <ModalQuizQuestionPreview :question="quiz" />
</template>

<script charset="utf-8">
    import PageTitle from "@/components/common/PageTitle.vue";
    import FileManager from "@/components/file/FileManager.vue"
    import QuizAnswerCreate from "@/components/quiz/answer/QuizAnswerCreate.vue";
    import QuizAnswerEdit from "@/components/quiz/answer/QuizAnswerEdit.vue";
    import QuizQuestionButtonNavigation from "@/components/quiz/question/QuizQuestionButtonNavigation.vue";
    import ModalQuizQuestionPreview from "@/components/quiz/question/ModalQuizQuestionPreview.vue";
    import ModalDelete from "@/components/common/modal/ModalDelete.vue"

    import ModalQuestionAddSetting from "@/components/quiz/setting/question/ModalQuestionAddSetting.vue"
    import ModalQuestionValueDelete from "@/components/quiz/setting/question/ModalQuestionValueDelete.vue"

    import QuizQuestionAnswerService from '@/services/quiz/quiz.question.answer.service';
    import QuizQuestionService from "@/services/quiz/quiz.question.service";
    import QuizSettingQuestionService from '@/services/quiz/quiz.setting.question.service';

    export default {
        components: {
            PageTitle,
            QuizQuestionButtonNavigation,
            QuizAnswerCreate,
            QuizAnswerEdit,
            FileManager,
            ModalDelete,
            ModalQuestionAddSetting,
            ModalQuestionValueDelete,
            ModalQuizQuestionPreview
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "Quiz Question Detail",
                subTitle: "com.quiz.question",

                quiz: {
                    id: 0,
                    name: "",
                    description: "",
                    categories: [],
                    tags: [],
                    author: {
                        firstname: "",
                        lastname: ""
                    },
                    language: {
                        name: "",
                        culture: "",
                    },
                    file: {
                        id: 0,
                        relativePath: "",
                        caption: "",
                    },
                    answers: [],
                    type: {
                        name: ""
                    }
                },
                isFile: false,
                loading: false,

                questionModalId: "questionModalID",

                selectedAnswerItem: {},

                selectedSettingItem: {},

                availableSettingItems: [],

                errorModalDelete: {
                    isShow: false,
                    header: "test",
                    message: "",
                },
                deletedItem: "",
                closeModal: false
            };
        },
        computed: {
            questionId() {
                return this.$route.params.id;
            }
        },
        watch: {

        },
        mounted() {
            this.fetchQuestion();
        },
        methods: {

            async fetchQuestion() {
                try {
                    this.loading = true;

                    let res = (await QuizQuestionService.GetByIdWithAnswers(this.questionId));

                    console.log(res);

                    if (res.status == 200) {
                        this.quiz = res.data;

                        this.fetchAvailableSettingQuestion();
                        if (this.quiz.file != null) {
                            if (Object.entries(this.quiz.file).length > 0) {
                                this.isFile = true;
                            }
                        }
                        else {
                            this.isFile == false;

                            this.quiz.file = {
                                id: 0,
                                relativePath: "",
                                caption: "",
                            }
                        }


                    }
                }
                catch (e) {
                    console.log("fetch err", e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },

            async answerAdded() {
                try {
                    this.loading = true;

                    let res = (await QuizQuestionService.GetAnswersByQuestionId(this.questionId));
                    if (res.status == 200) {
                        this.quiz.answers = res.data.answers;
                    }

                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },

            async deleteImage(fileID) {
                try {
                    this.loading = true;

                    if (fileID == this.quiz.file.id) {
                        let res = (await QuizQuestionService.UnassignFile(this.quiz.id, fileID));

                        console.log("deleteImage Res:", res);

                        if (res.status == 200) {
                            this.fetchQuestion();
                        }
                    }


                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },

            async selectedImage(data) {

                console.log("Wybrane zdjęcie pytanie: ", data);
                try {
                    this.loading = true;

                    let res = (await QuizQuestionService.AssignFile(this.quiz.id, data.id));

                    console.log("selectedImage Res:", res);

                    if (res.status == 200) {
                        this.fetchQuestion();
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }

                console.log("Wybrane zdjęcie: ", data);
            },


            async deleteAnswer() {
                try {
                    this.loading = true;

                    let res = (await QuizQuestionAnswerService.Delete(this.selectedAnswerItem.id));

                    if (res.status == 200) {
                        this.fetchQuestion();
                        this.closeModal = true;
                    }

                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },

            async deleteSettingValue() {
                try {
                    this.loading = true;

                    console.log("selectedSettingItem", this.selectedSettingItem);
                    let res = (await QuizSettingQuestionService.DeleteSettingValue(this.selectedSettingItem.id));

                    if (res.status == 200) {
                        this.reloadAvailableSettings();

                        this.reloadSettings();
                        this.closeModal = true;
                    }

                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },

            async reloadSettings() {
                try {

                    let res = (await QuizSettingQuestionService.GetAllValuesByQuestionId(this.quiz.id))

                    if (res.status == 200) {
                        this.quiz.settingValues = res.data;
                        this.fetchAvailableSettingQuestion();
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },

            async fetchAvailableSettingQuestion() {
                try {
                    this.loading = true;

                    let res = (await QuizSettingQuestionService.GetAvailableSettingsByQuestionId(this.quiz.id))

                    if (res.status == 200) {

                        this.availableSettingItems = res.data;
                    }

                }
                catch (e) {
                    console.log("fetch err", e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },

            unsetAnswer() {
                this.selectedAnswerItem = {};
            },

            unsetSettingValue() {
                this.selectedSettingItem = {};
            },
            setAnswerItem(item) {
                this.closeModal = false;
                this.selectedAnswerItem = item;
            },

            setSettingItem(item) {
                this.closeModal = false;
                this.selectedSettingItem = item;
            },


            reloadAvailableSettings() {
                var old = this.quiz.id;
                this.quiz.id = -1;
                this.quiz.id = old;
            },

            getFileModalHashtag() {
                return "#" + this.questionModalId
            },
            getIndex(index) {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;

                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 0;

                return ((page - 1) * itemsPerPage) + index + 1
            },

            getDropdownID(str) {
                return "dropdownButton" + str;
            },

        }
    }</script>

<style>
</style>
