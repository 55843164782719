<template>
    <!-- Modal Create-->
    <form method="POST" class="needs-validation" novalidate="" autocomplete="off" @submit.prevent="createLanguageMethod">
        <div id="modalLanugageCreate" class="modal fade" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Create</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3 row">
                            <ErrorModalMessage v-if="error.isShow" :error="error" />
                        </div>
                        <div class="mb-3 row">
                            <label class="col-sm-3 col-form-label">
                                <strong>
                                    {{ $t('com.language.name') }}
                                </strong>
                            </label>
                            <div class="col-sm-9">
                                <input v-model="createLanguage.name" type="text" class="form-control" :placeholder="$t('com.language.name')" />
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <label class="col-sm-3 col-form-label">
                                <strong>{{ $t('com.language.culture') }}</strong>
                            </label>
                            <div class="col-sm-9">
                                <input v-model="createLanguage.culture" type="text" class="form-control" :placeholder="$t('com.language.culture')" />
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <label class="col-sm-3 col-form-label">
                                <strong>{{ $t('com.language.culture.short') }}</strong>
                            </label>
                            <div class="col-sm-9">
                                <input v-model="createLanguage.shortCulture" type="text" class="form-control" :placeholder="$t('com.language.culture.short')" />
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <label class="col-sm-3 col-form-label">
                                <strong>{{ $t('com.language.description') }}</strong>
                            </label>
                            <div class="col-sm-9">
                                <input v-model="createLanguage.description" type="text" class="form-control" :placeholder="$t('com.language.description')" />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button id="createModalEmit" type="button" class="btn btn-danger light" data-bs-dismiss="modal">{{ $t('com.global.close') }}</button>
                        <button type="submit" class="btn btn-primary">{{ $t('com.global.save') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <!-- /Modal Create -->
</template>

<script charset="utf-8">
    import ErrorModalMessage from "../common/error/ErrorModalMessage";
    export default {
        components: {
            ErrorModalMessage
        },
        props: {

        },
        emits: ["reload-languages"],
        data() {
            return {
                publicPath: process.env.BASE_URL,
                createLanguage: {
                    name: "",
                    culture: "",
                    shortCulture: "",
                    description: ""
                },
                error: {
                    isShow: false,
                    header: "test",
                    message: "",
                }
            };
        },
        computed: {

        },
        methods: {
            async createLanguageMethod() {
                this.error.isShow = false;
                try {
                    this.$store.dispatch("i18n/createLanguage", this.createLanguage).then(
                        () => {
                            this.reloadLanguages()
                            this.clearData()
                            document.getElementById('createModalEmit').click();
                            //document.getElementById('modalLanugageCreate').modal('hide');
                            //$('#modalLanugageCreate').modal('hide')
                        },
                        (error) => {
                            this.error.isShow = true;
                            this.error.header = "header";
                            this.error.message = error.data.translatedMessage;
                            
                        }
                    );
                }
                catch (err) {
                    console.log(err)
                }
            },
            reloadLanguages() {
                this.$emit("reload-languages")
            },
            clearData() {
                this.createLanguage.name = "";
                this.createLanguage.culture = "";
                this.createLanguage.description = "";
                this.error.isShow = false;
                this.error.header = "";
                this.error.message = ""
            }
        }
    }
</script>

<style>
</style>
