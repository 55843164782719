import api from "./api";
import url from "./url";

class AppSettingTypeService {
    GetAll() {
        return api.get(url.app.setting.type.getAll)
            .then((res) => {
                return res;
            })
    }
}
export default new AppSettingTypeService;