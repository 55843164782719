import { createI18n } from 'vue-i18n';
import axios from "axios";
//import en from './locales/en';
//import fr from './locales/fr';

const loadedLanguages = ['en']

const i18n = createI18n({
    legacy: true,
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
        en: {
            'message.hello': 'Hello, {name}!',
            
        },
        pl: {
            'message.hello': 'Cześć, {name}!',
        }
    },
});

function setI18nLanguage(lang) {
    i18n.locale = lang
    axios.defaults.headers.common['Accept-Language'] = lang
    document.querySelector('html').setAttribute('lang', lang)
    return lang
}

export function loadLanguageAsync(lang) {
    if (typeof lang === 'undefined') {
        lang = window.navigator.userLanguage || window.navigator.language;
        lang = lang.slice(0, 2);
    }
    if (loadedLanguages.includes(lang)) {
        if (i18n.locale !== lang) setI18nLanguage(lang)
        return Promise.resolve()
    }
    //return fetch(`https://EXAMPLE.de/locales?lang=${lang}`)
    //    .then(response => {
    //        let msgs = response.data
    //        loadedLanguages.push(lang)
    //        i18n.setLocaleMessage(lang, msgs)
    //        return setI18nLanguage(lang)
    //    });
}



export default i18n;