import api from "../api";
import url from "../url";

class EventService {
    GetAll(Pages) {
        return api.get(url.event.getAll, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage
            }
        })
            .then((res) => {
                return res;
            })
    }

    GetMyAll(Pages) {
        return api.get(url.event.getMyAll, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage
            }
        })
            .then((res) => {
                return res;
            })
    }


    GetById(EventID) {
        return api.get(url.event.getById + "/" + EventID)
            .then((res) => {
                return res;
            })
    }

    GetByCode(EventCode) {
        return api.get(url.event.getByCode + "/" + EventCode)
            .then((res) => {
                return res;
            })
    }

    Create(request) {
        const req = {
            Name: request.name,
            Description: request.description,
            CustomerID: request.customerID,
            LanguageID: request.languageID,
            EventStart: request.eventStart,
            EventEnd: request.eventEnd,

        }

        return api.post(url.event.create, req)
            .then((response) => {
                return response;
            });
    }

    CreateWithoutCustomer(request) {
        const req = {
            Name: request.name,
            Description: request.description,
            LanguageID: request.languageID,
            EventStart: request.eventStart,
            EventEnd: request.eventEnd,

        }

        return api.post(url.event.createWithoutCustomer, req)
            .then((response) => {
                return response;
            });
    }

    Update(request) {
        const req = {
            Id: request.id,
            Name: request.name,
            Description: request.description,
            CustomerID: request.customerID,
            LanguageID: request.languageID,
            EventStart: request.eventStart,
            EventEnd: request.eventEnd,
        }
        return api.post(url.event.update, req)
            .then((response) => {
                return response;
            });
    }

    Delete(EventID) {
        return api.delete(url.event.delete + "/" + EventID)
            .then((res) => {
                return res;
            })
    }

    GetBin(Pages) {
        return api.get(url.event.bin, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage
            }
        })
            .then((res) => {
                return res;
            })
    }

    DeletePermanently(EventID) {
        return api.delete(url.event.deletePermanently + "/" + EventID)
            .then((res) => {
                return res;
            })
    }

    Restore(EventID) {
        return api.post(url.event.retore + "/" + EventID)
            .then((res) => {
                return res;
            })
    }

    GetAvailableGroups(EventID) {
        return api.get(url.event.getAvailableGroups + "/" + EventID)
            .then((res) => {
                return res;
            })
    }

    GetGroupsByEventId(EventID) {
        return api.get(url.event.getGroupsByEventId + "/" + EventID)
            .then((res) => {
                return res;
            });
    }

    AssignGroupToEvent(Request) {
        const req = {
            EventID: Request.eventID,
            GroupID: Request.groupID
        }

        return api.post(url.event.assignGroupToEvent, req)
            .then((response) => {
                return response;
            });
    }

    RemoveGroupFromEvent(Request) {

        return api.delete(url.event.removeGroupFromEvent, {
            params: {
                EventID: Request.eventID,
                GroupID: Request.groupID
            }
        })
            .then((response) => {
                return response;
            });
    }

    //VoteBlocks
    GetAvailableVoteBlocksByEvent(EventID) {
        return api.get(url.event.getAvailableVoteBlocksByEventId + "/" + EventID)
            .then((res) => {
                return res;
            });
    }

    GetVoteBlocksByEvent(EventID) {
        return api.get(url.event.getVoteBlocksByEventId + "/" + EventID)
            .then((res) => {
                return res;
            });
    }

    GetAvailableEventsByVoteBlock(VoteBlockID) {
        return api.get(url.event.getAvailableEventsByVoteBlock + "/" + VoteBlockID)
            .then((res) => {
                return res;
            });
    }


}

export default new EventService;