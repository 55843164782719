<template>
    <div id="eventGroupEdit" class="modal fade" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('com.event.group.edit') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="row mb-3">
                        <label class="form-label">
                            <strong>{{ $t('com.group.name') }}</strong>
                        </label>
                        <input type="text" class="form-control" :placeholder="$t('com.group.name')" v-model="groupModel.name" />
                    </div>
                    <div class="row mb-3">
                        <label class="form-label">
                            <strong>{{ $t('com.group.description') }}</strong>
                        </label>
                        <input type="text" class="form-control" :placeholder="$t('com.group.name')" v-model="groupModel.description" />
                    </div>
                    <pre type="text/javascript">{{ groupItem }}</pre>
                </div>
                <div class="modal-footer">
                    <button id="eventGroupEditEmit" type="button" class="btn btn-danger light" data-bs-dismiss="modal">{{ $t('com.global.close') }}</button>
                    <button type="button" class="btn btn-primary" @click="updateGroupe()">{{ $t('com.global.save') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script charset="utf-8">
    import GroupService from "@/services/group.service"
    export default {
        components: {
        },
        props: {
            groupItem: {
                type: Object,
                require: true,
            }
        },
        emits: [],
        data() {
            return {
                publicPath: process.env.BASE_URL,

                groupModel: {}
            }
        },
        watch: {
            groupItem: {
                immediate: true,
                handler(val) {
                    try {
                        this.groupModel = val;
                    }
                    catch {
                    }
                }
            }
        },
        mounted() {
        },
        methods: {

            async updateGroupe() {
                try {

                    const req = {
                        id: this.groupModel.id,
                        name: this.groupModel.name,
                        i18nname: this.groupModel.i18nName,
                        description: this.groupModel.description,
                        i18ndescription: this.groupModel.i18nDescription,
                    }

                    let res = (await GroupService.UpdateWithoutCustomer(req))

                    if (res.status == 200) {
                        this.$toast.success(this.$t('com.group.updatesuccess'));
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
            }
        }
    }
</script>

<style type="text/css">
</style>