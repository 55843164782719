import GroupService from '../../services/group.service'

export const group = {
    namespaced: true,
    state: null,
    actions: {
        getGroups({ commit }, request) {
            commit('empty');
            return GroupService.GetGroups(request).then(
                countries => {
                    return Promise.resolve(countries);
                },
                error => {
                    return Promise.reject(error.response);
                }
            );
        },
        getGroupByID({ commit }, request) {
            commit('empty');
            return GroupService.GetGroupById(request).then(
                countries => {
                    return Promise.resolve(countries);
                },
                error => {
                    return Promise.reject(error.response);
                }
            );
        },
        getGroupByAbbr({ commit }, request) {
            commit('empty');
            return GroupService.GetGroubByAbbr(request).then(
                countries => {
                    return Promise.resolve(countries);
                },
                error => {
                    return Promise.reject(error.response);
                }
            );
        },
        createGroup({ commit }, request) {
            commit('empty');
            return GroupService.CreateGroup(request).then(
                countries => {
                    return Promise.resolve(countries);
                },
                error => {
                    return Promise.reject(error.response);
                }
            );
        },
        updateGroup({ commit }, request) {
            commit('empty');
            return GroupService.UpdateGroup(request).then(
                countries => {
                    return Promise.resolve(countries);
                },
                error => {
                    return Promise.reject(error.response);
                }
            );
        },
        deleteGroup({ commit }, request) {
            commit('empty');
            return GroupService.DeleteGroup(request).then(
                countries => {
                    return Promise.resolve(countries);
                },
                error => {
                    return Promise.reject(error.response);
                }
            );
        },
        getGroupsBin({ commit }, request) {
            commit('empty');
            return GroupService.GetGroupsBin(request).then(
                countries => {
                    return Promise.resolve(countries);
                },
                error => {
                    return Promise.reject(error.response);
                }
            );
        },
        deletePermanentlyGroup({ commit }, request) {
            commit('empty');
            return GroupService.DeletePermanentlyGroup(request).then(
                countries => {
                    return Promise.resolve(countries);
                },
                error => {
                    return Promise.reject(error.response);
                }
            );
        },
        restoreGroup({ commit }, request) {
            commit('empty');
            return GroupService.RestoreGroup(request).then(
                countries => {
                    return Promise.resolve(countries);
                },
                error => {
                    return Promise.reject(error.response);
                }
            );
        },
    },
    mutations: {
        empty() {

        },
    }
}