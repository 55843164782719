<template>
    <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Recent Payments Queue</h4>
                    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalLanugageCreate"><i>+</i> {{ $t('com.global.add') }}</button>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-responsive-md">
                            <thead>
                                <tr class="align-middle align-content-center">
                                    <th style="width:80px;"><strong>#</strong></th>
                                    <th><strong>{{ $t('com.language.name') }}</strong></th>
                                    <th><strong>{{ $t('com.language.culture') }}</strong></th>
                                    <th><strong>{{ $t('com.language.culture.short') }}</strong></th>
                                    <th><strong>{{ $t('com.language.description') }}</strong></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="language in languages" :key="language.id">
                                    <td><strong>{{ language.id }}</strong></td>
                                    <td>{{ language.name }}</td>
                                    <td>{{ language.culture }}</td>
                                    <td>{{ language.shortCulture }}</td>
                                    <td>{{ language.description }}</td>
                                    <td>
                                        <div class="dropdown">
                                            <button type="button" class="btn btn-primary light sharp" data-bs-toggle="dropdown">
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24" /><circle fill="#000000" cx="5" cy="12" r="2" /><circle fill="#000000" cx="12" cy="12" r="2" /><circle fill="#000000" cx="19" cy="12" r="2" /></g></svg>
                                            </button>
                                            <div class="dropdown-menu">
                                                <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#exampleModalCenter" @click="setLanguage(language)">{{ $t('com.global.edit') }}</a>
                                                <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#exampleModalCenter2" @click="setLanguage(language)">{{ $t('com.global.delete') }}</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row mt-3">
                        <Pagination :pages="pages" @page-request="pageRequest" />
                    </div>

                </div>
            </div>
        </div>
    </div>

    <ModalCreate @reload-languages="FetchLanguages()" />
    <ModalDelete :selectedLanguage="selectedLanguage" @unset-language="unsetLanguage()" @reload-languages="FetchLanguages()" />
    <ModalEdit :selectedLanguage="selectedLanguage" @unset-language="unsetLanguage()" @reload-languages="FetchLanguages()" />


</template>

<script charset="utf-8">
    import PageTitle from "../../components/common/PageTitle.vue";
    import Pagination from "../../components/common/Pagination.vue"
    import ModalDelete from "../../components/language/ModalDelete.vue";
    import ModalEdit from "../../components/language/ModalEdit.vue";
    import ModalCreate from "../../components/language/ModalCreate.vue";

    export default {
        components: {
            PageTitle,
            ModalDelete,
            ModalEdit,
            ModalCreate,
            Pagination
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "Languages",
                subTitle: "com.language.subtitle",
                languages: [],
                pages: {},
                selectedLanguage: {}
            };
        },
        computed: {

        },
        watch: {
            '$route'(to) {
                this.FetchLanguages(parseInt(to.params.page), parseInt(to.params.items))
            }
        },
        mounted() {
            this.FetchLanguages();
        },
        methods: {
            async FetchLanguages(page = 1, items = 10) {
                const req = {
                    PageNumber: page,
                    ItemsOnPage: items,
                }
                this.$store.dispatch("i18n/getLanguages", req).then(
                    (res) => {
                        this.languages = res.data.languageItems;
                        this.pages = res.data.page;
                        console.log(res);
                    },
                    (error) => {
                        console.log(error)
                    }
                );
            },


            async CreateLanguage() {

            },
            pageRequest(page) {
                this.$router.push(
                    {
                        name: 'LanguagesPages',
                        params: {
                            page: page,
                            items: 10
                        }
                    })
                console.log("Otrzymano stronę", page);
            },
            setLanguage(language) {
                this.selectedLanguage = language
            },
            unsetLanguage() {
                this.selectedLanguage = {}
            }
        }
    }
</script>

<style>
</style>
