<template>
    <!-- Modal Create-->
    <form method="POST" class="needs-validation" novalidate="" autocomplete="off" @submit.prevent="createItemMethod">
        <div id="modalItemCreate" class="modal fade" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Create</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3 row">
                            <ErrorModalMessage v-if="error.isShow" :error="error" />
                        </div>
                        <div class="mb-3 row">
                            <label class="col-sm-3 col-form-label">Name</label>
                            <div class="col-sm-9">
                                <input v-model="createItem.name" type="text" class="form-control" placeholder="Name" />
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <label class="col-sm-3 col-form-label">Friendly Name</label>
                            <div class="col-sm-9">
                                <input v-model="createItem.friendlyName" type="text" class="form-control" placeholder="Culture" />
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <label class="col-sm-3 col-form-label">Description</label>
                            <div class="col-sm-9">
                                <input v-model="createItem.description" type="text" class="form-control" placeholder="Description" />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button id="createModalEmit" type="button" class="btn btn-danger light" data-bs-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-primary">Save changes</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <!-- /Modal Create -->
</template>

<script charset="utf-8">
    import ErrorModalMessage from "../common/error/ErrorModalMessage";
    export default {
        components: {
            ErrorModalMessage
        },
        props: {

        },
        emits: ["reload-items"],
        data() {
            return {
                publicPath: process.env.BASE_URL,
                createItem: {
                    name: "",
                    friendlyName: "",
                    description: "",
                },
                error: {
                    isShow: false,
                    header: "test",
                    message: "",
                }
            };
        },
        computed: {

        },
        methods: {
            async createItemMethod() {
                this.error.isShow = false;
                try {
                    this.$store.dispatch("i18n/createResource", this.createItem).then(
                        () => {
                            this.reloadItems()
                            this.clearData()
                            document.getElementById('createModalEmit').click();
                            //document.getElementById('modalLanugageCreate').modal('hide');
                            //$('#modalLanugageCreate').modal('hide')
                        },
                        (error) => {
                            this.error.isShow = true;
                            this.error.header = "header";
                            this.error.message = error.data.translatedMessage;

                        }
                    );
                }
                catch (err) {
                    console.log(err)
                }
            },
            reloadItems() {
                this.$emit("reload-items")
            },
            clearData() {
                this.createItem.name = "";
                this.createItem.culture = "";
                this.createItem.description = "";
                this.error.isShow = false;
                this.error.header = "";
                this.error.message = ""
            }
        }
    }
</script>

<style>
</style>
