import I18nService from '../../services/i18n.service';


export const i18n = {
    namespaced: true,
    state: null,
    actions: {
        getLanguages({ commit }, request) {
            commit('empty');
            return I18nService.GetLanguages(request).then(
                user => {
                    //commit('loginSuccess');
                    return Promise.resolve(user);
                },
                error => {
                    //commit('loginFailure');
                    return Promise.reject(error.response);
                }
            );
        },
        updateLanguage({ commit }, request) {
            commit('empty');
            return I18nService.UpdateLanguage(request).then(
                language => {
                    return Promise.resolve(language)
                },
                error => {
                    return Promise.reject(error.response)
                }
            );
        },
        deleteLanguage({ commit }, languageId) {
            commit('empty');
            return I18nService.DeleteLanguage(languageId).then(
                language => {
                    return Promise.resolve(language)
                },
                error => {
                    return Promise.reject(error.response)
                }
            );
        },
        createLanguage({ commit }, request) {
            commit('empty');
            return I18nService.CreateLanguage(request).then(
                language => {
                    return Promise.resolve(language)
                },
                error => {
                    return Promise.reject(error.response)
                }
            );
        },

        getResources({ commit }, request) {
            commit('empty');
            return I18nService.GetResources(request).then(
                user => {
                    return Promise.resolve(user);
                },
                error => {
                    return Promise.reject(error.response);
                }
            );
        },
        getResourceById({ commit }, resourceId) {
            commit('empty');
            return I18nService.GetResourceById(resourceId).then(
                language => {
                    return Promise.resolve(language)
                },
                error => {
                    return Promise.reject(error.response)
                }
            );
        },
        updateResource({ commit }, request) {
            commit('empty');
            return I18nService.UpdateResource(request).then(
                language => {
                    return Promise.resolve(language)
                },
                error => {
                    return Promise.reject(error.response)
                }
            );
        },
        deleteResource({ commit }, resourceId) {
            commit('empty');
            return I18nService.DeleteResource(resourceId).then(
                language => {
                    return Promise.resolve(language)
                },
                error => {
                    return Promise.reject(error.response)
                }
            );
        },
        createResource({ commit }, request) {
            commit('empty');
            return I18nService.CreateResource(request).then(
                language => {
                    return Promise.resolve(language)
                },
                error => {
                    return Promise.reject(error.response)
                }
            );
        },

        getTranslations({ commit }, request) {
            commit('empty');
            return I18nService.GetTranslations(request).then(
                user => {
                    //commit('loginSuccess');
                    return Promise.resolve(user);
                },
                error => {
                    //commit('loginFailure');
                    return Promise.reject(error.response);
                }
            );
        },
        updateTranslation({ commit }, request) {
            commit('empty');
            return I18nService.UpdateTranslation(request).then(
                language => {
                    return Promise.resolve(language)
                },
                error => {
                    return Promise.reject(error.response)
                }
            );
        },
        deleteTranslation({ commit }, translationId) {
            commit('empty');
            return I18nService.DeleteTranslation(translationId).then(
                language => {
                    return Promise.resolve(language)
                },
                error => {
                    return Promise.reject(error.response)
                }
            );
        },
        createTranslation({ commit }, request) {
            commit('empty');
            return I18nService.CreateTranslation(request).then(
                language => {
                    return Promise.resolve(language)
                },
                error => {
                    return Promise.reject(error.response)
                }
            );
        },
        getLocales({ commit }, translationId) {
            commit('empty');
            return I18nService.GetLocales(translationId).then(
                language => {
                    return Promise.resolve(language)
                },
                error => {
                    return Promise.reject(error.response)
                }
            );
        },
        //Country

        getCountries({ commit }, request) {
            commit('empty');
            return I18nService.GetCountries(request).then(
                countries => {
                    return Promise.resolve(countries);
                },
                error => {
                    return Promise.reject(error.response);
                }
            );
        },

        getCountryByID({ commit }, request) {
            commit('empty');
            return I18nService.GetCountryByID(request).then(
                countries => {
                    return Promise.resolve(countries);
                },
                error => {
                    return Promise.reject(error.response);
                }
            );
        },

        getCountryByAbbr({ commit }, request) {
            commit('empty');
            return I18nService.GetCountryByAbbr(request).then(
                countries => {
                    return Promise.resolve(countries);
                },
                error => {
                    return Promise.reject(error.response);
                }
            );
        },
        createCountry({ commit }, request) {
            commit('empty');
            return I18nService.CreateCountry(request).then(
                countries => {
                    return Promise.resolve(countries);
                },
                error => {
                    return Promise.reject(error.response);
                }
            );
        },
        updateCountry({ commit }, request) {
            commit('empty');
            return I18nService.UpdateCountry(request).then(
                countries => {
                    return Promise.resolve(countries);
                },
                error => {
                    return Promise.reject(error.response);
                }
            );
        },
        deleteCountry({ commit }, request) {
            commit('empty');
            return I18nService.DeleteCountry(request).then(
                countries => {
                    return Promise.resolve(countries);
                },
                error => {
                    return Promise.reject(error.response);
                }
            );
        },

        getCountriesBin({ commit }, request) {
            commit('empty');
            return I18nService.GetCountriesBin(request).then(
                countries => {
                    return Promise.resolve(countries);
                },
                error => {
                    return Promise.reject(error.response);
                }
            );
        },

        deletePermanentlyCountry({ commit }, request) {
            commit('empty');
            return I18nService.DeletePermanentlyCountry(request).then(
                countries => {
                    return Promise.resolve(countries);
                },
                error => {
                    return Promise.reject(error.response);
                }
            );
        },
        restoreCountry({ commit }, request) {
            commit('empty');
            return I18nService.RestoreCountry(request).then(
                countries => {
                    return Promise.resolve(countries);
                },
                error => {
                    return Promise.reject(error.response);
                }
            );
        },
    },
    
    mutations: {
        empty() {

        },
    }
};
    