<template>
    <div id="editAnswer" class="offcanvas offcanvas-start" tabindex="-1" aria-labelledby="offcanvasExampleLabel">
        <div class="offcanvas-header">
            <h5 id="offcanvasExampleLabel" class="offcanvas-title">{{ $t('com.quiz.answer.edit') }}</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <form method="POST" class="needs-validation" novalidate="" autocomplete="off" @submit.prevent="editAnswer">
                <div class="row">
                    <div class="col-md-12">
                        <div class="mb-3">
                            <label class="form-label"><strong>{{ $t('com.quiz.answer.name') }}</strong></label>
                            <input v-model="answer.name" type="text" class="form-control" :placeholder="$t('com.quiz.answer.name')" :disabled="loading" />
                            <pre class="language-json"><code>{{ answer.name }}</code></pre>
                        </div>
                        <div class="mb-3">
                            <label class="form-label"><strong>{{ $t('com.quiz.answer.points') }}</strong></label>
                            <input v-model="answer.points" type="number" class="form-control" :placeholder="$t('com.quiz.answer.name')" :disabled="loading" />
                            <pre class="language-json"><code>{{ answer.points }}</code></pre>
                        </div>
                        <div class="mb-3">
                            <label class="form-label"><strong>{{ $t('com.quiz.answer.iscorrect') }}</strong></label>
                            <VueMultiselect v-model="answer.isCorrect"
                                            :options="correctOptions"
                                            :searchable="true"
                                            :placeholder="$t('com.quiz.answer.iscorrect')"
                                            :multiple="false"
                                            :custom-label="translatedLabel"
                                            label="name"
                                            track-by="value"
                                            :disabled="loading"
                                            :showLabels="false"
                                            :allow-empty="false"
                            />
                            <pre class="language-json"><code>{{ answer.isCorrect }}</code></pre>
                        </div>
                        <div v-if="answer.isFile" class="row mb-3">
                            <div class="col-12 ">
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <img :src="answer.file.relativePath" :alt="answer.file.caption" class="img-fluid" />
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <button type="button" class="btn btn-primary btn-sm" @click="deleteImageEdit()">{{ $t('com.global.delete') }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <div class="col-md-12">
                                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <div class="btn-group" role="group" aria-label="Basic example">
                                        <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" :data-bs-target="getFileModalHashtag()">
                                            <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            {{ $t('com.quiz.answer.addimage') }}
                                        </button>
                                        <!--<button type="button" class="btn btn-primary btn-sm">
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                                            {{ $t('com.quiz.answer.deleteimage') }}
                                        </button>-->
                                        <button id="editAnswerOffcanvasDismiss" type="button" class="btn btn-danger light btn-sm" data-bs-dismiss="offcanvas">{{ $t('com.global.close') }}</button>
                                        <button type="submit" class="btn btn-primary btn-sm">{{ $t('com.global.save') }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <pre class="language-json"><code>{{ answer }}</code></pre>
                    </div>
                </div>
            </form>
        </div>
    </div>


    <FileManager :fileManageModalID="answerFileModalID" @selected-image="selectedImageForAnswerEdit" @deleted-file="deletedImageForAnswer" />
</template>

<script charset="utf-8">
    import VueMultiselect from 'vue-multiselect'
    import FileManager from "../../../components/file/FileManager.vue"
    import QuizQuestionAnswerService from '../../../services/quiz/quiz.question.answer.service';
    export default {
        components: {
            VueMultiselect,
            FileManager
        },
       
        props: {
            answerID: {
                type: Number,
                require: true
            }
        },
        emits: ["answer-edited"],
        data() {
            return {
                publicPath: process.env.BASE_URL,

                answerFileModalID: "editAnswerFileModalID",

                answer: {
                    name: "",
                    isCorrect: {
                        name: "com.global.no",
                        value: false
                    },
                    points: 0,
                    file: {
                        id: 0,
                        caption: "",
                        relativePath: "",
                    },
                    isFile: false,
                },
                correctOptions: [
                    { name: "com.global.yes", value: true },
                    { name: "com.global.no", value: false },
                ],
                loading: false,
                isFile: false,
                selectedImage: {},
            };
        },
        computed: {

        },
        watch: {
            answerID: {
                // the callback will be called immediately after the start of the observation
                immediate: true,
                handler(val) {
                    let id = parseInt(val)
                    if (id > 0) {
                        this.fetchAnswer(id)
                    }
                    
                }
            }
        },
        mounted() {

        },
        methods: {
            async fetchAnswer(answerID) {
                try {
                    this.loading = true;

                    let res = (await QuizQuestionAnswerService.GetById(answerID))

                    if (res.status == 200) {


                        this.answer = res.data;

                        this.answer.isCorrect = this.correctOptions.find(x => x.value == res.data.isCorrect);
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },

            async editAnswer() {
                try {
                    this.loading = true;

                    const req = {
                        id: this.answer.id,
                        name: this.answer.name,
                        isCorrect: this.answer.isCorrect.value,
                        points: this.answer.points,
                        fileID: this.answer.file.id
                    }

                    let res = (await QuizQuestionAnswerService.Update(req));

                    if (res.status == 200) {
                        document.getElementById('editAnswerOffcanvasDismiss').click();
                        this.$emit("answer-edited");
                    }

                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },

            deleteImageEdit() {
                this.answer.file = {
                    id: 0,
                    caption: "",
                    relativePath: "",
                }

            },
            getFileModalHashtag() {
                return "#" + this.answerFileModalID
            },

            selectedImageForAnswerEdit(data) {
                try {

                    this.answer.file = {
                        id: data.id,
                        caption: data.caption,
                        relativePath: data.relativePath
                    }
                    this.answer.isFile = true;

                }
                catch (e) {
                    console.log(e);
                }
            }
        }
    }</script>

<style>
</style>
