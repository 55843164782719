<template>
    <div class="row mb-3">
        <div class="col-6">
            <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
        </div>
        <div class="col-6">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <UserButtonNavigation />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ $t('com.user.list') }}</h4>
                </div>
                <div class="card-body overflow-hidden">
                    <TableSearcher :searchInputLabel="tableSearcher.searchInputLabel"
                                   :searchInputPlaceholder="tableSearcher.searchInputPlaceholder"
                                   :minChars="3"
                                   @search-request="searchRequest"
                                   @search-clear="FetchUsers(getCurrentPage(), getCurrentItemsOnPage(), getCurrentCustomer(), getCurrentRole())"
                    />
                    <div class="row">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table table-responsive-md">
                                    <thead>
                                        <tr>
                                            <th style="width:80px;"><strong>#</strong></th>
                                            <th><strong>{{ $t('com.user.email') }}</strong></th>
                                            <th><strong>{{ $t('com.user.login') }}</strong></th>
                                            <th><strong>{{ $t('com.user.nameandsurname') }}</strong></th>
                                            <th><strong>{{ $t('com.user.customer') }}</strong></th>
                                            <th><strong>{{ $t('com.user.pilot') }}</strong></th>
                                            <th><strong>{{ $t('com.language.name') }}</strong></th>
                                            <th><strong>{{ $t('com.user.roles') }}</strong></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(data, index) in datas" :key="index">
                                            <td><strong>{{ getIndex(index) }}</strong></td>
                                            <td>{{ data.eMail }}</td>
                                            <td>{{ data.login }}</td>
                                            <td>{{ data.firstname }} {{ data.lastname }}</td>
                                            <td>
                                                <router-link v-for="(customer, customerIndex) in data.customer" :key="customerIndex" class="badge badge-rounded badge-outline-info mb-1 mr-1" :to="{name:'UserListByCustomer', params:{customerID: customer.id}}">
                                                    {{ customer.name }}
                                                </router-link>
                                                <br />
                                            </td>
                                            <td>{{ data.pilotId }}</td>
                                            <td>{{ data.language.name }} ({{ data.language.culture }})</td>
                                            <td>
                                                <router-link v-for="(role, roleKey) in data.roles" :key="roleKey" class="badge badge-rounded badge-outline-info mb-1 mr-1" :to="{name:'UserListByRole', params:{roleID: role.id}}">
                                                    {{ role.displayName }}
                                                </router-link>
                                                <br />

                                            </td>
                                            <td>
                                                <div class="dropdown">
                                                    <button type="button" class="btn btn-primary light sharp" data-bs-toggle="dropdown">
                                                        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <rect x="0" y="0" width="24" height="24" />
                                                                <circle fill="#000000" cx="5" cy="12" r="2" />
                                                                <circle fill="#000000" cx="12" cy="12" r="2" />
                                                                <circle fill="#000000" cx="19" cy="12" r="2" />
                                                            </g>
                                                        </svg>
                                                    </button>
                                                    <div class="dropdown-menu">
                                                        <router-link :to="{ name: 'UserEdit', params: { id: data.id } }" class="dropdown-item">{{ $t('com.global.edit') }}</router-link>
                                                        <router-link :to="{ name: 'UserDetails', params: { id: data.id } }" class="dropdown-item">{{ $t('com.global.details') }}</router-link>
                                                        <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modalItemDelete" @click="setItem(data)">{{ $t('com.global.delete') }}</a>
                                                        <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#userPilotManagementModal" @click="setItem(data)">{{ $t('com.user.pilot.update') }}</a>
                                                        <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#userChangePasswordModal" @click="setItem(data)">{{ $t('com.user.changepassword') }}</a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                
                            </div>
                        </div>
                        <ElementLoader v-if="loading" :type="tableLoader.type" :color="tableLoader.color" :size="tableLoader.size" />
                    </div>
                    <div class="row mt-3">
                        <Pagination :pages="pages" @page-request="pageRequest" />
                    </div>

                </div>
            </div>
        </div>
    </div>
    <ModalChangePassword :userID="selectedItem.id" @unset-item="unsetItem" />
    <ModalUserPilotManagement :userID="selectedItem.id" :pilotID="selectedItem.pilotId" @unset-item="unsetItem" @reload-items="reloadItems" />

    <ModalDelete :error="errorModalEdit"
                 :deletedItem="deletedItem"
                 :closeModal="closeModal"
                 @unset-item="unsetItem()"
                 @confirm-delete="deleteUser()"
    />


</template>

<script charset="utf-8">
    import Pagination from "@/components/common/Pagination.vue"
    import ModalChangePassword from "@/components/user/ModalChangePassword.vue"
    import ModalDelete from "@/components/common/modal/ModalDelete.vue"
    import UserButtonNavigation from "@/components/user/UserButtonNavigation.vue"
    import ModalUserPilotManagement from "@/components/user/ModalUserPilotManagement.vue"
    import PageTitle from "@/components/common/PageTitle.vue";
    import TableSearcher from '@/components/common/TableSearcher.vue';
    import ElementLoader from '@/components/common/ElementLoader.vue';

    import UserService from "@/services/user.service";
    
    export default {
        components: {
            Pagination,
            ModalChangePassword,
            ModalDelete,
            UserButtonNavigation,
            PageTitle,
            TableSearcher,
            ElementLoader,
            ModalUserPilotManagement

        },
        //inheritAttrs: false,
        data() {
            return {
                publicPath: process.env.BASE_URL,
                datas: [],
                selectedItem: {
                    id:"-1",
                },
                mainTitle: "com.user.list",
                subTitle: "com.user.subtitle",
                errorModalEdit: {
                    isShow: false,
                    header: "test",
                    message: "",
                },
                pages: {},
                deletedItem: "",
                closeModal: false,

                tableSearcher: {
                    searchInputLabel: this.$t("com.user.searchbylogin"),
                    searchInputPlaceholder: this.$t("com.user.searchbylogin"),
                },
                tableLoader: {
                    type: "spinner-border",
                    color: "text-primary",
                    size: "big"
                },

                loading: true,
            };
        },
        computed: {

        },
        watch: {
            '$route'() {
                this.FetchUsers(this.getCurrentPage(), this.getCurrentItemsOnPage(), this.getCurrentCustomer(), this.getCurrentRole());
            }
        },
        mounted() {
            this.FetchUsers(this.getCurrentPage(), this.getCurrentItemsOnPage(), this.getCurrentCustomer(), this.getCurrentRole());
        },
        methods: {

            
            async FetchUsers(page = 1, items = 10, customer = -1, role = -1) {
                try {
                    this.loading = true;
                    const req = {
                        PageNumber: page,
                        ItemsOnPage: items,
                        CustomerID: customer,
                        RoleID: role
                    }

                    let res = (await UserService.GetAll(req));

                    if (res.status == 200) {
                        this.datas = res.data.userItems;
                        this.pages = res.data.page;
                    }

                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },

            async searchRequest(data) {

                try {
                    
                    this.loading = true;
                    const req = {
                        PageNumber: this.getCurrentPage(),
                        ItemsOnPage: this.getCurrentItemsOnPage(),
                        SearchLogin: data,
                    }

                    let res = (await UserService.SearchByLogin(req));

                    if (res.status == 200) {
                        this.datas = res.data.userItems;
                        this.pages = res.data.page;
                    }


                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }

                console.log(data);
            },
            pageRequest(page) {
                console.log("Request page: ", page);

                if (this.getCurrentCustomer() != -1) {
                    this.$router.push(
                        {
                            name: 'UserListByCustomerPaged',
                            params: {
                                page: page,
                                items: this.getCurrentItemsOnPage()
                            }
                        })
                }
                else if (this.getCurrentRole() != -1) {
                    this.$router.push(
                        {
                            name: 'UserListByRolePaged',
                            params: {
                                page: page,
                                items: this.getCurrentItemsOnPage()
                            }
                        })
                }
                else {
                    this.$router.push(
                        {
                            name: 'UsersListPaged',
                            params: {
                                page: page,
                                items: this.getCurrentItemsOnPage()
                            }
                        })
                }
                
            },

            reloadItems() {
                this.FetchUsers(this.getCurrentPage(), this.getCurrentItemsOnPage(), this.getCurrentCustomer(), this.getCurrentRole());
            },
            unsetItem() {
                this.selectedItem.id = "-1"
                this.closeModal = false;
            },
            setItem(data) {
                this.selectedItem = data;
                console.log(data);
            },
            async deleteUser() {
                try {
                    this.$store.dispatch("user/deleteUser", this.selectedItem.id).then(
                        (res) => {
                            console.log(res);
                            this.closeModal = true;
                            this.FetchUsers(this.getCurrentPage(), this.getCurrentItemsOnPage())
                            this.unsetItem();
                            this.$toast.success(this.$t('com.user.successdelete'))
                        },
                        (error) => {
                            console.log(error)
                        }
                    );
                }
                catch (err) {
                    console.log(err);
                }
            },
            getCurrentPage() {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;
                return page;
            },
            getCurrentItemsOnPage() {
                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 10;
                if (itemsPerPage == 0)
                    itemsPerPage = 10;
                return itemsPerPage
            },

            getCurrentRole() {
                let roleId = this.$route.params.roleID

                if (this.isEmpty(roleId))
                    roleId = -1

                return roleId;

            },

            getCurrentCustomer() {
                let customerID = parseInt(this.$route.params.customerID);

                if (isNaN(customerID))
                    customerID = -1;

                return customerID;
            },

            getIndex(index) {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;

                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 0;

                return ((page - 1) * itemsPerPage) + index + 1
            },

            isEmpty(str) {
                return (!str || str.length === 0);
            }

        }
    }</script>

<style>
</style>
