<template>
    <div class="vh-100">
        <div class="authincation h-100">
            <div class="container h-100">
                <div class="row justify-content-center h-100 align-items-center">
                    <div class="col-md-6">
                        <div class="authincation-content">
                            <div class="row no-gutters">
                                <div class="col-xl-12">
                                    <div class="auth-form">
                                        <div class="text-center mb-3">
                                            <img src="images/logo-full.png" alt="" />
                                        </div>
                                        <h4 class="text-center mb-4">{{ $t('com.user.auth.signin.your.account') }}</h4>
                                        <form method="POST" class="needs-validation" novalidate="" autocomplete="off" @submit.prevent="submit">
                                            <div class="form-group">
                                                <ErrorModalMessage v-if="error.isShow" :error="error" />
                                            </div>
                                            <div class="form-group">
                                                <label class="mb-1"><strong>{{ $t('com.user.auth.login') }}</strong></label>
                                                <input v-model="form.username" type="text" class="form-control" placeholder="hello@example.com" :disabled="loading.auth" required autofocus />
                                            </div>
                                            <div class="form-group">
                                                <label class="mb-1"><strong>{{ $t('com.user.auth.password') }}</strong></label>
                                                <input v-model="form.password" type="password" class="form-control" :disabled="loading.auth" />
                                            </div>
                                            <div class="form-group">
                                                <label class="mb-1"><strong>{{ $t('com.user.auth.language') }}</strong></label>
                                                <select v-model="form.languageId" class="form-control" @change="changeLoginLanguage" :disabled="loading.auth">
                                                    <option value="0" :selected="true">{{ $t('com.user.auth.language.choose') }}</option>
                                                    <option v-for="language in languages" :key="language.id" :value="language.id">{{ language.name }} ({{ language.culture }})</option>
                                                </select>
                                            </div>
                                            <div class="form-row d-flex justify-content-between mt-4 mb-2">
                                                <div class="form-group">
                                                    <router-link :to="{ name: 'ForgotPassword' }" class="text-primary">{{ $t('com.user.auth.forgotpassword') }}</router-link>
                                                </div>
                                            </div>
                                            <div class="text-center">
                                                <button type="submit" class="btn btn-primary btn-block" :disabled="loading.auth">
                                                    <span class="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true" v-if="loading.auth"></span>
                                                    {{ $t('com.user.auth.signmein') }}
                                                </button>
                                            </div>
                                        </form>
                                        <div class="new-account mt-3">
                                            <p>{{ $t('com.user.auth.donthaveaccount') }} <router-link :to="{ name: 'Register' }" class="text-primary">{{ $t('com.user.auth.singup') }}</router-link></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ErrorModalMessage from "@/components/common/error/ErrorModalMessage";
    import UserService from '@/services/user.service';
    import I18nService from '@/services/i18n.service';
    export default {

        components: {
            ErrorModalMessage
        },
        data() {
            return {
                form: {
                    username: "",
                    password: "",
                    languageId: 0,
                    rememberMe: false
                },
                showError: false,
                publicPath: process.env.BASE_URL,
                loading: {
                    auth: false,
                    language: false,
                    locale: false,
                },
                languages: [],
                error: {
                    isShow: false,
                    header: "test",
                    message: "",
                }

            };
        },
        computed: {

        },
        created() {
        },
        mounted() {
            this.fetchAuthLocales(1);
            this.FetchLanguages();
        },

        methods: {
            async FetchLanguages() {
                const req = {
                    PageNumber: 1,
                    ItemsOnPage: 100,
                }
                this.$store.dispatch("i18n/getLanguages", req).then(
                    (res) => {
                        this.languages = res.data.languageItems;
                        console.log(res);
                    },
                    (error) => {
                        console.log(error)
                    }
                );
            },
            async submit() {
                this.loading.auth = true;
                this.error.isShow = false;

                if (this.form.languageId == 0) {
                    this.error.isShow = true;
                    this.error.header = this.$t("com.user.auth.error.chooselanguage");
                    this.error.message = this.$t("com.user.auth.error.pleaschooselanguagemessage");
                    this.loading.auth = false;
                    return;
                }

                const User = {
                    Username: this.form.username,
                    Password: this.form.password,
                    LanguageID: this.form.languageId
                }

                this.$store.dispatch("auth/login", User).then(
                    () => {
                        this.loading.auth = false;
                        this.fetchLastLogin()
                    },
                    (error) => {
                        this.loading.auth = false;
                        this.error.isShow = true;
                        this.error.header = this.$t("com.user.login.failed");
                        this.error.message = error.data.translatedMessage;
                    }
                );
                this.loading.auth = false;
            },
            async fetchLastLogin() {
                try {
                    let res = (await UserService.GetLastLoginCurrentUser())
                    if (res.status == 200) {
                        this.$toast.info(this.$t('com.user.lastlogininfo', { status: res.data.status, ipaddress: res.data.ipAddress, date: res.data.timestamp }));
                        this.$router.push("/dashboard");
                    }

                }
                catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'LoginView' })


                }
            },

            async changeLoginLanguage(data) {
                let languageID = parseInt(data.target.value)
                if (languageID != 0)
                    this.fetchAuthLocales(data.target.value)
                else
                    this.fetchAuthLocales(1)
            },

            async fetchAuthLocales(languageID) {
                try {
                    this.loading.locale = false;

                    let res = (await I18nService.GetAuthLocales(languageID));

                    if (res.status == 200) {
                        this.$i18n.setLocaleMessage(res.data.locale, res.data.items)
                        this.$i18n.locale = res.data.locale;
                    }
                }
                catch (e) {
                    console.log(e);
                }
                finally {
                    this.loading.locale = true;
                }
            },
            clearData() {
                this.error.isShow = false;
                this.error.header = "";
                this.error.message = ""
            }
        }
    }
</script>

<style>
</style>
