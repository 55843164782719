import api from "../api";
import url from "../url";

class QuizSettingQuestionService {

    GetPaged(Pages) {
        return api.get(url.quiz.setting.question.getAllPaged, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage
            }
        })
            .then((res) => {
                return res;
            })
    }

    GetAll() {
        return api.get(url.quiz.setting.question.getAll)
            .then((res) => {
                return res;
            })
    }

    GetById(SettingQuestionID) {
        return api.get(url.quiz.setting.question.getById + "/" + SettingQuestionID)
            .then((res) => {
                return res;
            })
    }

    GetAvailableSettingsByQuestionId(QuestionID) {
        return api.get(url.quiz.setting.question.getAvailableSettingsByQuestionId + "/" + QuestionID)
            .then((res) => {
                return res;
            })
    }

    Create(request) {
        const req = {
            Name: request.name,
            Description: request.description,
            DefualtValue: request.defualtValue,
            IsRequired: request.isRequired,
            Type: request.type,
        }

        return api.post(url.quiz.setting.question.create, req)
            .then((response) => {
                return response;
            });
    }

    

    Update(request) {
        const req = {
            Id: request.id,
            Name: request.name,
            Description: request.description,
            DefaultValue: request.defualtValue,
            IsRequired: request.isRequired,
            Type: request.type,
        }

        return api.post(url.quiz.setting.question.update, req)
            .then((response) => {
                return response;
            });
    }

    Delete(TagID) {
        return api.delete(url.quiz.setting.question.delete + "/" + TagID)
            .then((res) => {
                return res;
            })
    }

    GetBin(Pages) {
        return api.get(url.quiz.setting.question.bin, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage
            }
        })
            .then((res) => {
                return res;
            })
    }

    DeletePermanently(TagID) {
        return api.delete(url.quiz.setting.question.deletePermanently + "/" + TagID)
            .then((res) => {
                return res;
            })
    }

    Restore(TagID) {
        return api.post(url.quiz.setting.question.retore + "/" + TagID)
            .then((res) => {
                return res;
            })
    }


    //settingValue

    CreateSettingValue(request) {
        const req = {
            QuestionID: request.questionId,
            SettingID: request.settingId,
            Value: request.value,
            DefaultValue: request.defualtValue,
            IsRequired: request.isRequired,
            Type: request.type,
        }

        return api.post(url.quiz.setting.question.createSettingValue, req)
            .then((response) => {
                return response;
            });
    }

    GetAllValuesByQuestionId(QuestionID) {
        return api.get(url.quiz.setting.question.getAllValuesByQuestionId + "/" + QuestionID)
            .then((res) => {
                return res;
            })
    }

    DeleteSettingValue(QuestionID) {
        return api.delete(url.quiz.setting.question.deleteSettingValue + "/" + QuestionID)
            .then((res) => {
                return res;
            })
    }



}
export default new QuizSettingQuestionService;