import api from "./api";
import url from "./url";

class CustomerService {
    GetCustomers(Pages) {
        return api.get(url.client.customer.getAll, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage
            }
        })
            .then((res) => {
                return res;
            })
    }

    GetCustomerById(CustomerID) {
        return api.get(url.client.customer.getById + "/" + CustomerID)
            .then((res) => {
                return res;
            })
    }

    GetCustomerByAbbr(CustomerAbbr) {
        return api.get(url.client.customer.getByAbbr + "/" + CustomerAbbr)
            .then((res) => {
                return res;
            })
    }

    CreateCustomer(request) {
        const req = {
            Name: request.name,
            Description: request.description,
            Phone: request.phone,
            EMail: request.email,
            City: request.city,
            Street: request.street,
            PostalCode: request.postalcode,
            CountryID: request.countryid,
            LanguageID: request.languageid
        }
        return api.post(url.client.customer.create, req)
            .then((response) => {
                return response;
            });
    }

    UpdateCustomer(request) {
        const req = {
            Id: request.id,
            Name: request.name,
            Description: request.description,
            Phone: request.phone,
            EMail: request.email,
            Citty: request.citty,
            Street: request.street,
            PostalCode: request.postalcode,
            CountryID: request.countryid,
            LanguageID: request.languageid
        }
        return api.post(url.client.customer.update, req)
            .then((response) => {
                return response;
            });
    }

    DeleteCustomer(CustomerID) {
        return api.delete(url.client.customer.delete + "/" + CustomerID)
            .then((res) => {
                return res;
            })
    }

    GetCustomersBin(Pages) {
        return api.get(url.client.customer.bin, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage
            }
        })
            .then((res) => {
                return res;
            })
    }

    DeletePermanentlyCustomer(CustomerID) {
        return api.delete(url.client.customer.deletePermanently + "/" + CustomerID)
            .then((res) => {
                return res;
            })
    }

    RestoreCustomer(CustomerID) {
        return api.post(url.client.customer.retore + "/" + CustomerID)
            .then((res) => {
                return res;
            })
    }

}

export default new CustomerService