import api from "../api";
import url from "../url";

class QuizVoteService {
    GetAll(Pages) {
        return api.get(url.quiz.vote.getAll, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage
            }
        })
            .then((res) => {
                return res;
            })
    }

    GetMy(Pages) {
        return api.get(url.quiz.vote.getMyAll, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage,
                VoteBlockID: Pages.VoteBlockID
            }
        })
            .then((res) => {
                return res;
            })
    }

    GetById(VoteID) {
        return api.get(url.quiz.vote.getById + "/" + VoteID)
            .then((res) => {
                return res;
            })
    }

    Create(request) {
        const req = {
            Name: request.Name,
            Description: request.Description,
            VoteBlockID: request.VoteBlockID,
            TypeID: request.TypeID,
            CustomerID: request.CustomerID
        }

        return api.post(url.quiz.vote.create, req)
            .then((response) => {
                return response;
            });
    }

    CreateWithoutCustomer(request) {
        const req = {
            Name: request.Name,
            Description: request.Description,
            VoteBlockID: request.VoteBlockID,
            TypeID: request.TypeID,
        }

        return api.post(url.quiz.vote.createWithoutCustomer, req)
            .then((response) => {
                return response;
            });
    }

    Update(request) {
        const req = {
            Id: request.Id,
            Name: request.Name,
            Description: request.Description,
            VoteBlockID: request.VoteBlockID,
            CustomerID: request.CustomerID,
            TypeID: request.TypeID,
        }

        return api.post(url.quiz.vote.update, req)
            .then((response) => {
                return response;
            });
    }

    UpdateWithoutCustomer(request) {
        const req = {
            Id: request.Id,
            Name: request.Name,
            Description: request.Description,
            VoteBlockID: request.VoteBlockID,
            TypeID: request.TypeID,
        }

        return api.post(url.quiz.vote.updateWithoutCustomer, req)
            .then((response) => {
                return response;
            });
    }

    Delete(VoteID) {
        return api.delete(url.quiz.vote.delete + "/" + VoteID)
            .then((res) => {
                return res;
            })
    }

    GetBin(Pages) {
        return api.get(url.quiz.vote.bin, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage
            }
        })
            .then((res) => {
                return res;
            })
    }

    DeletePermanently(VoteID) {
        return api.delete(url.quiz.vote.deletePermanently + "/" + VoteID)
            .then((res) => {
                return res;
            })
    }

    Restore(VoteID) {
        return api.post(url.quiz.vote.retore + "/" + VoteID)
            .then((res) => {
                return res;
            })
    }

    GetByVoteBlock(VoteBlockID) {
        return api.get(url.quiz.vote.getByVoteBlock + "/" + VoteBlockID)
            .then((res) => {
                return res
            });
    }
}
export default new QuizVoteService;