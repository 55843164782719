<template>
    <div class="btn-group">
        <router-link v-if="isSuperAdmin" :to="{ name: 'EventBin' }" type="button" class="btn btn-primary">
            {{ $t("com.global.bin") }}
        </router-link>
        <router-link v-if="isAdmin" :to="{ name: 'EventListAll' }" type="button" class="btn btn-primary">
            {{ $t("com.global.list.all") }}
        </router-link>
        <router-link :to="{ name: 'EventListMy' }" type="button" class="btn btn-primary">
            {{ $t("com.global.list.my") }}
        </router-link>


        <router-link v-if="isSuperAdmin" :to="{name: 'EventCreate'}" type="button" class="btn btn-primary">
            {{ $t('com.global.add') }}
        </router-link>
        <router-link v-else :to="{name: 'EventCreateWithoutCustomer'}" type="button" class="btn btn-primary">
            {{ $t('com.global.add') }}
        </router-link>
    </div>


</template>

<script charset="utf-8">export default {
        components: {
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
            };
        },
        computed: {
            isSuperAdmin() {
                return this.$store.state.auth.user.isUserIsSuperAdmin;
            },

            isAdmin() {
                return this.$store.state.auth.user.isUserIsAdmin;
            },

            isOperator() {
                return this.$store.state.auth.user.isUserIsOperator;
            }
        },
        watch: {

        },
        mounted() {
            this.test();
        },
        methods: {
            test() {
                console.log("storeAuth: ", this.$store.state.auth);
            }
        }
    }</script>

<style>
</style>
