<template>

    <div class="card" :class="{ 'card-vote-active': isActive() }">
        <div :id="getAccordionHeadingID()" class="card-header" data-bs-toggle="collapse" :data-bs-target="getAccordionBodyRef()" aria-expanded="false" :aria-controls="getAccordionBodyID()">
            <h4 class="card-title">
                {{ vote.name }}
            </h4>
            <span class="card-subtitle">
                {{ vote.description }}
            </span>
            <span class="accicon"><i class="fas fa-angle-down rotate-icon"></i></span>
        </div>
        <div :id="getAccordionBodyID()" class="accordion-collapse collapse" :aria-labelledby="getAccordionHeadingID(vote.id)" :data-bs-parent="accordionRef">
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <div class="btn-group" role="group">
                            <button type="button" class="btn btn-primary" :disabled="(!activeVoteBlock)" @click="activateVote()">{{ $t('com.interactive.activate.vote') }}</button>
                            <button type="button" class="btn btn-primary" :disabled="(!activeVoteBlock)" @click="deactivateVote()">{{ $t('com.interactive.deactivate.vote') }}</button>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="(vote.questions.length > 0) && isActive()">
                    <div class="col-12">
                        {{ currentQuestion }}
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(questionItem, questionIndex) in vote.questions" :key="questionIndex" :class="{ 'active': isQuestionActive(questionItem.id) }">
                                        <td>{{ questionItem.id }}</td>
                                        <td>{{ questionItem.name }}</td>
                                        <td>
                                            <div class="dropdown">
                                                <button :id="getDropdownID(questionIndex)" type="button" class="btn btn-primary light sharp" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <circle fill="#000000" cx="5" cy="12" r="2" />
                                                            <circle fill="#000000" cx="12" cy="12" r="2" />
                                                            <circle fill="#000000" cx="19" cy="12" r="2" />
                                                        </g>
                                                    </svg>
                                                </button>
                                                <div class="dropdown-menu" :aria-labelledby="getDropdownID(questionIndex)">
                                                    <a class="dropdown-item" @click="activateQuestion(questionItem.id)">{{ $t('com.interactive.question.activate') }}</a>
                                                    <a v-if="isQuestionActive(questionItem.id)" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#modalInteractiveCurrentQuestion">{{ $t('com.interactive.question.showcurrentstatus') }}</a>
                                                    <a class="dropdown-item" @click="deActivateQuestion(questionItem.id)">{{ $t('com.interactive.question.deactivate') }}</a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>

</template>
<script charset="utf-8">

    import QuizInteractiveService from "@/services/quiz/quiz.interactive.service"

    export default {
        components: {
        },
        props: {

            vote: {
                type: Object,
                required: true
            },
            currentVote: {
                type: Object,

            },
            currentQuestion: {
                type: Object,
                default: function () {
                    return {
                        name: "",
                        type: {
                            name: "",
                        }
                    }
                }
            },
            activeVoteBlock: {
                type: Boolean,
                required: true,
            },
            accordionRef: {
                type: String,
                required: true,
            },
        },
        emits: [""],

        data() {
            return {
                publicPath: process.env.BASE_URL,

                loading: {
                    setVoteActive: false,
                    setQuestionActive: false,
                }

            }
        },
        computed: {
            eventCode() {
                return this.$route.params.eventCode;
            }
        },
        watch: {

        },
        mounted() {
        },

        methods: {


            async activateVote() {
                try {
                    this.loading.setActiveVoteBlock = true;


                    const req = {
                        EventCode: this.eventCode,
                        VoteId: this.vote.id,
                    }

                    let res = (await QuizInteractiveService.SetVoteActive(req))

                    if (res.status == 200) {
                        this.$toast.success(this.$t('com.interactive.vote.success.activated'))
                    }

                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                    else if (e.response.status == 400) {
                        this.$toast.warning(this.$t(e.response.data.message))
                    }
                }
                finally {
                    this.loading.setActiveVoteBlock = false;
                }
            },

            async deactivateVote() {
                try {
                    this.loading.setActiveVoteBlock = true;


                    const req = {
                        EventCode: this.eventCode,
                        VoteId: this.vote.id,
                    }

                    let res = (await QuizInteractiveService.setVoteDeactive(req))

                    if (res.status == 200) {
                        this.$toast.success(this.$t('com.interactive.vote.success.deactivated'))
                    }

                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                    else if (e.response.status == 400) {
                        this.$toast.warning(this.$t(e.response.data.message))
                    }
                }
                finally {
                    this.loading.setActiveVoteBlock = false;
                }
            },

            async activateQuestion(questionID) {
                try {
                    this.loading.setQuestionActive = true;

                    const req = {
                        EventCode: this.eventCode,
                        QuestionID: questionID,
                    }

                    let res = (await QuizInteractiveService.SetQuestionActive(req))

                    if (res.status == 200) {
                        this.$toast.success(this.$t('com.interactive.question.activate.success'))
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                    else if (e.response.status == 400) {
                        this.$toast.warning(this.$t(e.response.data.message))
                    }
                }
                finally {
                    this.loading.setActiveVoteBlock = false;
                }
            },


            async deActivateQuestion(questionID) {
                try {
                    this.loading.setQuestionActive = true;

                    const req = {
                        EventCode: this.eventCode,
                        QuestionID: questionID,
                    }

                    let res = (await QuizInteractiveService.SetQuestionDeactive(req))

                    if (res.status == 200) {
                        this.$toast.success(this.$t('com.interactive.question.deactivate.success'))
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                    else if (e.response.status == 400) {
                        this.$toast.warning(this.$t(e.response.data.message))
                    }
                }
                finally {
                    this.loading.setActiveVoteBlock = false;
                }
            },


            isActive() {
                if (this.vote.id == this.currentVote.id)
                    return true;
                return false;
            },

            isQuestionActive(QuestionId) {

                if (this.currentQuestion.id == QuestionId)
                    return true;

                return false;
            },



            //accordionHeading
            getAccordionHeadingID() {
                return "accordion-header-vote-id" + this.vote.id.toString();
            },
            getAccordionHeadingRef() {
                return "#" + this.getAccordionHeadingID();
            },

            //accordionBody
            getAccordionBodyID() {
                return "accordion-body-vote-id" + this.vote.id.toString();
            },
            getAccordionBodyRef() {
                return "#" + this.getAccordionBodyID();
            },

            getDropdownID(str) {
                return "dropdownButton" + str;
            },

        }
    }
</script>
<style type="text/css">
    /*    #app-interactive .vote-block-interactive .card {
        border-style: solid;
        border-width: 10px;
        background-color: lightgray;
    }*/

    #app-interactive .vote-block-interactive .active .card-vote-block-wrapper .card-vote-active {
        border-color: lightgreen;
        background-color: white;
    }

    @-webkit-keyframes active {
        from {
            background-color: #ecfae4;
            color: #68CF29;
        }

        to {
            background-color: #fff;
            color: #000;
        }
    }

    @-moz-keyframes active {
        from {
            background-color: #ecfae4;
            color: #68CF29;
        }

        to {
            background-color: #fff;
            color: #000;
        }
    }

    @-o-keyframes active {
        from {
            background-color: #ecfae4;
            color: #68CF29;
        }

        to {
            background-color: #fff;
            color: #000;
        }
    }

    @keyframes active {
        from {
            background-color: #ecfae4;
            color: #68CF29;
        }

        to {
            background-color: #fff;
            color: #000;
        }
    }

    .active {
        -webkit-animation: active 1s infinite; /* Safari 4+ */
        -moz-animation: active 1s infinite; /* Fx 5+ */
        -o-animation: active 1s infinite; /* Opera 12+ */
        animation: active 1s infinite; /* IE 10+ */
    }
</style>