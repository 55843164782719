<template>

    <div id="eventSettingAdd" class="offcanvas offcanvas-start" tabindex="-1" aria-labelledby="canvasEventSettingAdd">
        <div class="offcanvas-header">
            <h5 class="modal-title">{{ $t('com.event.setting.add') }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas">
            </button>
        </div>
        <div class="offcanvas-body">
            <perfect-scrollbar>
                <div class="row mb-3">
                    <div class="col-12">
                        <label class="form-label"><strong>{{ $t('com.event.setting') }}</strong></label>
                        <VueMultiselect v-model="modalData.setting"
                                        :options="availableOptions"
                                        :searchable="true"
                                        :placeholder="$t('com.event.setting')"
                                        :multiple="false"
                                        :custom-label="translatedSettingName"
                                        track-by="id"
                                        :disabled="loading"
                                        :showLabels="false"
                                        :allow-empty="false"
                                        @select="eventSettingSelected" />
                    </div>

                </div>
                <div v-if="isBolean" class="row mb-3">

                    <div class="col-12">
                        <label class="form-label">
                            <strong>
                                {{ $t('com.event.setting.value') }}
                            </strong>
                        </label>
                        <VueMultiselect v-model="selectedBooleanOption"
                                        :options="availableBooleanOptions"
                                        :searchable="true"
                                        :placeholder="$t('com.event.setting.value')"
                                        :multiple="false"
                                        :custom-label="translatedOptions"
                                        track-by="value"
                                        :showLabels="false"
                                        :allow-empty="false"
                                        @select="eventSettingValueSelected" />
                    </div>
                </div>
                <div v-else class="mb-3">
                    <div class="row">
                        <div class="col-12">
                            <label class="form-label">
                                <strong>
                                    {{ $t('com.event.setting.value') }}
                                </strong>
                            </label>
                            <input v-model="modalData.value" type="text" class="form-control" />
                        </div>
                    </div>

                    <SettingTypeSelectorByProvider :settingType="modalData.setting.settingType" @selected-data="providerSelectedData" />
                </div>
                <div class="row mb-3">
                    <pre class="language-json"><code>{{ modalData }}</code></pre>
                </div>
                <div class="row mb-3">
                    <div class="col-12">
                        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                            <button id="eventGroupAddEmit" type="button" class="btn btn-danger light" data-bs-dismiss="offcanvas" @click="unsetItem()">{{ $t('com.global.close') }}</button>
                            <button type="submit" class="btn btn-primary" @click="eventSettingAdd">{{ $t('com.global.add') }}</button>
                        </div>
                    </div>
                </div>
            </perfect-scrollbar>
        </div>
    </div>


</template>

<script charset="utf-8">
    import SettingTypeSelectorByProvider from "@/components/common/settingType/SettingTypesSelectorByProvider.vue";


    import EventSettingService from "@/services/event/event.setting.service";
    import VueMultiselect from 'vue-multiselect'
    export default {
        components: {
            VueMultiselect,
            SettingTypeSelectorByProvider
        },
        props: {
            error: {
                type: Object,
                require: true,
                default: function () {
                    return {
                        isShow: false,
                        header: "test",
                        message: "",
                    }
                },


            },

            eventID: {
                type: Number,
                require: true,
            },
            closeModal: {
                type: Boolean,
                require: true,
            }

        },
        emits: ["event-setting-add"],
        data() {
            return {
                publicPath: process.env.BASE_URL,
                availableOptions: [],
                modalData: {
                    setting: {
                        settingType: {
                            provider: {}
                        }

                    },
                    value: "",
                },

                selectedBooleanOption: { name: "com.global.no", value: false },

                loading: false,
                isBolean: false,

                availableBooleanOptions: [
                    { name: "com.global.yes", value: true },
                    { name: "com.global.no", value: false },
                ],
            };
        },
        computed: {

        },
        watch: {
            closeModal: {
                immediate: true,
                handler(val, oldVal) {
                    if (val != oldVal) {
                        let id = parseInt(this.eventID)

                        if (id > 0) {
                            this.modalData = {
                                group: {},
                            }
                            this.availableOptions = []
                            this.fetchAvailableOptions();
                            document.getElementById('eventGroupAddEmit').click();
                        }
                    }
                }
            },
            eventID: {
                immediate: true,
                handler(val) {
                    let id = parseInt(val)
                    if (id > 0) {
                        this.fetchAvailableOptions();
                    }

                }
            }
        },
        mounted() {
        },
        methods: {
            async fetchAvailableOptions() {
                try {
                    this.loading = true;

                    let res = (await EventSettingService.GetAvailableSettingsByEvent(this.eventID))
                    if (res.status == 200) {
                        this.availableOptions = res.data
                    }
                }
                catch (e) {
                    //console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },
            eventSettingAdd() {
                this.$emit("event-setting-add", this.modalData);

            },

            eventSettingSelected(data) {
                if (data.variableType == 5) {
                    this.isBolean = true;
                    this.modalData.value = data.defaultValue.toString();
                }
                else {
                    this.isBolean = false;
                }
            },
            eventSettingValueSelected(data) {
                this.modalData.value = data.value.toString();
            },

            providerSelectedData(data) {
                this.modalData.value = data.toString();
            },

            translatedSettingName(obj) {
                if (Object.keys(obj).length === 0)
                    return "Select"

                if (obj.name === undefined)
                    return "Select"

                return this.$t(obj.name);
                //console.log(obj)
                //if (Object.keys(obj).length === 0)
                //    return "Select"

                //return this.$t(obj.name);
            },

            translatedOptions(obj) {
                if (Object.keys(obj).length === 0)
                    return "Select"

                return this.$t(obj.name);
            },
        }
    }</script>

<style>
</style>
