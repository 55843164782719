<template>
    <div class="row">
        <div class="col-12">
            <EventPageTitle :event="eventData" />
        </div>
    </div>
    <div class="row">
        <div class="col-md-8">
            <EventVoteBlock v-for="(voteBlockItem, voteBlockIndex) in eventData.voteBlocks" 
                            :key="voteBlockIndex" 
                            :voteBlock="voteBlockItem" 
                            :currentVoteBlock="currentActiveVoteBlock"
                            :currentVote="currentActiveVote"
                            :currentQuestion="currentActiveQuestion"/>
            <pre lang="text/json">
                {{ eventData }}
            </pre>
        </div>
        <div class="col-md-4">
            <OnlineUsers :users="usersOnline" />

        </div>
    </div>
    <button class="btn btn-primary" @click="normalMessage">
        Normal Message
    </button>

    <button class="btn btn-primary" @click="authorizedMessage">
        Authorized Message
    </button>

    <button class="btn btn-primary" @click="superAuthorizedMessage">
        SuperAuthorized Message
    </button>

    <EventCurrentQuestion :currentQuestion="currentActiveQuestion"
                          :showConstrols="true"/>
</template>
<script charset="utf-8">

    import OnlineUsers from "@/components/interactive/common/OnlineUsers.vue"
    //import EventDetail from "@/components/interactive/common/EventDetail.vue";
    import EventPageTitle from "@/components/interactive/common/EventPageTitle.vue";
    import EventVoteBlock from "@/components/interactive/common/EventVoteBlock.vue";
    import EventCurrentQuestion from "@/components/interactive/common/EventCurrentQuestion.vue"

    import EventSettingService from "@/services/event/event.setting.service"
    import FileManagerService from "@/services/file/file.service"
    import QuizLiveVoteHubService from "@/services/quiz/quiz.live.vote.hub.service"
    import EventService from "@/services/event/event.service";
    import QuizInteractiveService from "@/services/quiz/quiz.interactive.service"

    export default {
        components: {
            OnlineUsers,
            //EventDetail,
            EventPageTitle,
            EventVoteBlock,
            EventCurrentQuestion
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,

                loading: {
                    background: false,
                    event: false,
                    currentActiveVoteBlock: false,
                    currentActiveVote: false,
                    currentActiveQuestion: false,
                },

                eventData: {},

                currentActiveVoteBlock: {},
                currentActiveVote: {},
                currentActiveQuestion: {
                    name: "",
                    type: {
                        name: "",
                    }
                },

                usersOnline: [],
            };
        },
        computed: {
            eventCode() {
                return this.$route.params.eventCode;
            }
        },
        created() {

        },
        mounted() {
            this.startHubConnection();
            this.setupBackground();
            this.fetchEventData();
            this.fetchCurrentActiveVoteBlock();
            this.fetchCurrentActiveVote();
            this.fetchCurrentActiveQuestion();
        },
        methods: {

            async startHubConnection() {

                await QuizLiveVoteHubService.Connect(this.eventCode, "interactive");

                QuizLiveVoteHubService.client.on("ReceiveMessage", (user, message) => {
                    console.log(user, message);
                });

                QuizLiveVoteHubService.client.on("CurrentOnlineUsers", (data) => {
                    this.usersOnline = data;
                    console.log("CurrentOnlineUsers", data);
                });

                QuizLiveVoteHubService.client.on("CurrentActiveVoteBlock", (data) => {
                    this.currentActiveVoteBlock = data;
                    console.log("CurrentActiveVoteBlock", this.currentActiveVoteBlock);
                });

                QuizLiveVoteHubService.client.on("AuthorizedMessage", (data) => {
                    console.log(data);
                });

                QuizLiveVoteHubService.client.on("SuperAuthorizedMessage", (data) => {
                    console.log(data);
                });

                QuizLiveVoteHubService.client.on("CurrentActiveVote", (data) => {
                    this.currentActiveVote = data;
                    console.log("CurrentActiveVote", this.currentActiveVote);
                });

                QuizLiveVoteHubService.client.on("CurrentActiveQuestion", (data) => {
                    this.currentActiveQuestion = data;
                    console.log("CurrentActiveQuestion", this.currentActiveQuestion);
                });
                
            },

            async normalMessage() {
                await QuizLiveVoteHubService.client.invoke("SendMessage", "Użytkownik", "wiadomość");
            },

            async authorizedMessage() {
                await QuizLiveVoteHubService.client.invoke("Authorized", "Wiadomość authoryzowana");
            },

            async superAuthorizedMessage() {
                try {
                    await QuizLiveVoteHubService.client.invoke("SuperAuthorized", "Wiadomość super authoryzowana");
                }
                catch (e) {
                    console.log(e);
                }
            },

            async setupBackground() {
                try {
                    this.loading.background = true;


                    let settingsValues = (await EventSettingService.GetValueByEventCode(this.eventCode));

                    if (settingsValues.status != 200)
                        return;



                    let backgroundSetting = settingsValues.data.find(obj => obj.key.id == 5);

                    if (backgroundSetting == null)
                        return;


                    if (backgroundSetting.key.settingType.provider == 10) //fileprovider
                    {
                        if (backgroundSetting.key.variableType != 25) //integer
                        {
                            return;
                        }
                        let fileID = backgroundSetting.key.defaultValue;
                        if (backgroundSetting.value != null)
                            fileID = backgroundSetting.value;

                        let res = (await FileManagerService.GetById(fileID))

                        if (res.status == 200) {
                            jQuery("#main-wrapper").css("background-image", "url(" + res.data.relativePath + ")");
                            jQuery("#main-wrapper").css("background-position", "center");
                            jQuery("#main-wrapper").css("background-repeat", "no-repeat");
                            jQuery("#main-wrapper").css("background-size", "cover");
                        }
                    }


                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.background = false;
                }
            },

            async fetchEventData() {
                try {
                    this.loading.event = true;
                    let res = (await EventService.GetByCode(this.eventCode))

                    if (res.status == 200) {
                        this.eventData = res.data;
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.event = false;
                }


            },

            async fetchCurrentActiveVoteBlock() {
                try {
                    this.loading.currentActiveVoteBlock = true;
                    let res = (await QuizInteractiveService.GetCurrentActiveVoteBlock(this.eventCode))

                    if (res.status == 200) {
                        this.currentActiveVoteBlock = res.data;
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.currentActiveVoteBlock = false;
                }
            },

            async fetchCurrentActiveVote() {
                try {
                    this.loading.currentActiveVote = true;
                    let res = (await QuizInteractiveService.GetCurrentActiveVote(this.eventCode))

                    if (res.status == 200) {
                        this.currentActiveVote = res.data;
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.currentActiveVote = false;
                }
            },

            async fetchCurrentActiveQuestion() {
                try {
                    this.loading.currentActiveQuestion = true;

                    let res = (await QuizInteractiveService.GetCurrentActiveQuestion(this.eventCode));

                    if (res.status == 200) {
                        this.currentActiveQuestion = res.data;
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.currentActiveVote = false;
                }
            },

            isAnyQuestion() {
                if (this.currentActiveQuestion.id != 0)
                    return true;

                return false;
            }
        }
    }
</script>