<template>
    <div id="eventUsersAdd" class="modal fade" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered " role="document">

            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('com.event.users.add') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <nav class="navbar">
                                <div id="event-add-user-tab" class="nav nav-tabs default-tab" role="tablist">
                                    <button class="nav-link active" id="user-form-add" data-bs-toggle="tab" data-bs-target="#nav-user-add-form" type="button" role="tab" aria-controls="nav-user-add-form" aria-selected="true">
                                        {{ $t('com.event.user.form.add') }}
                                    </button>
                                    <button class="nav-link" id="user-csv-add" data-bs-toggle="tab" data-bs-target="#nav-user-add-csv" type="button" role="tab" aria-controls="nav-user-add-csv" aria-selected="false">
                                        {{ $t('com.event.user.csv.add') }}
                                    </button>
                                </div>
                            </nav>
                            <div id="event-add-user-tabContent" class="tab-content">
                                <div id="nav-user-add-form" class="tab-pane fade show active" role="tabpanel" aria-labelledby="nav-user-add-form-tab">
                                    <form method="POST" class="needs-validation" novalidate="" autocomplete="off" @submit.prevent="eventUsersFormAdd">

                                        <div class="row mb-3">
                                            <div class="col-12">
                                                <label class="form-label">{{ $t('com.user.firstname') }}</label>
                                                <input v-model="userModel.firstname" type="text" class="form-control" :placeholder="$t('com.user.firstname')" />
                                            </div>
                                        </div>

                                        <div class="row mb-3">
                                            <div class="col-12">
                                                <label class="form-label">{{ $t('com.user.lastname') }}</label>
                                                <input v-model="userModel.lastname" type="text" class="form-control" :placeholder="$t('com.user.lastname')" />
                                            </div>
                                        </div>

                                        <div class="row mb-3">
                                            <div class="col-12">
                                                <label class="form-label">{{ $t('com.user.email') }}</label>
                                                <input v-model="userModel.email" type="email" class="form-control" :class="{'is-invalid' : validation.email.isError, 'is-valid' : validation.email.isValid}" :placeholder="$t('com.user.email')" @keyup="checkEmail()" />
                                                <div class="invalid-feedback">
                                                    {{ $t(validation.email.message) }}
                                                </div>
                                                <pre class="language-json"><code>{{ userModel.email }}</code></pre>
                                            </div>
                                        </div>

                                        <div class="row mb-3">
                                            <div class="col-12">
                                                <label class="form-label">{{ $t('com.user.phone') }}</label>
                                                <input v-model="userModel.phone" type="tel" class="form-control" :class="{'is-invalid' : validation.phone.isError, 'is-valid' : validation.phone.isValid}" :placeholder="$t('com.user.phone')" @keyup="checkPhone()" />
                                                <div class="invalid-feedback">
                                                    {{ $t(validation.phone.message) }}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mb-3">
                                            <div class="col-12">
                                                <label class="form-label">{{ $t('com.user.groups') }}</label>
                                                <VueMultiselect v-model="userModel.groups"
                                                                :options="eventGroups"
                                                                :searchable="false"
                                                                :placeholder="$t('com.user.groups')"
                                                                :multiple="true"
                                                                label="name"
                                                                track-by="id"
                                                                :disabled="loading"
                                                                :showLabels="false"
                                                                :allow-empty="true"
                                                                :taggable="false" />
                                            </div>
                                        </div>


                                        <div class="row mb-3">
                                            <div class="col-12 float-end">
                                                <button type="submit" class="btn btn-primary float-end">{{ $t('com.global.add') }}</button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                                <div id="nav-user-add-csv" class="tab-pane fade" role="tabpanel" aria-labelledby="nav-user-add-csv-tab">
                                    <!--<form method="POST" class="needs-validation row mb-3 g-3 mt-3" novalidate="" autocomplete="off" @submit.prevent="eventUserFileCsvAdd">

                                        <label class="form-label col-3 col-form-label align-middle">{{ $t('com.event.user.add.selectfile') }}</label>
                                        <div class="col">
                                            <input type="file" class="form-control" :placeholder="$t('com.event.user.add.selectfile')" />
                                        </div>
                                        <div class="col-2">

                                            <button type="submit" class="btn btn-primary float-end align-middle">{{ $t('com.global.add') }}</button>
                                        </div>
                                    </form>-->
                                    <form method="POST" class="needs-validation  g-3" novalidate="" autocomplete="off" @submit.prevent="eventUserRawCsvAdd">
                                        <div class="row mb-3">
                                            <div class="col-12">
                                                <label class="form-label">{{ $t('com.event.user.add.paste') }}</label> <br />
                                                <span class="form-label text-muted">{{ $t('com.event.user.add.paste.format') }}</span>
                                                <textarea class="form-control" rows="4" v-model="csvModel.rawText"></textarea>
                                                <pre class="language-json"><code>{{ csvModel.rawText }}</code></pre>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-12">
                                                <label class="form-label">{{ $t('com.user.groups') }}</label>
                                                <VueMultiselect v-model="csvModel.groups"
                                                                :options="eventGroups"
                                                                :searchable="false"
                                                                :placeholder="$t('com.user.groups')"
                                                                :multiple="true"
                                                                label="name"
                                                                track-by="id"
                                                                :disabled="loading"
                                                                :showLabels="false"
                                                                :allow-empty="true"
                                                                :taggable="false" />
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-12 float-end">
                                                <button type="submit" class="btn btn-primary float-end">{{ $t('com.global.add') }}</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="eventGroupAddEmit" type="button" class="btn btn-danger light" data-bs-dismiss="modal" @click="unsetItem()">{{ $t('com.global.close') }}</button>
                </div>
            </div>

        </div>
    </div>
</template>
<script charset="utf-8">
    //import AppSettingValueService from "../../../../services/app.setting.value.service";
    //import EventService from "../../services/event/event.service";
    //import GroupService from "@/services/group.service";
    import VueMultiselect from 'vue-multiselect'

    import UserService from "@/services/user.service";
    export default {
        components: {
            VueMultiselect
        },
        props: {
            error: {
                type: Object,
                require: true,
                default: function () {
                    return {
                        isShow: false,
                        header: "test",
                        message: "",
                    }
                },


            },
            eventGroups: {
                type: Array,
                require: true,
                default: function () {
                    return [];
                }
            },
            closeModal: {
                type: Boolean,
                require: true,
            }

        },
        //emits: ["event-group-add", "reload-event-groups"],
        data() {
            return {
                publicPath: process.env.BASE_URL,
                availableOptions: [],
                modalData: {
                    group: {},
                },
                userModel: {
                    firstname: "",
                    lastname: "",
                    email: "",
                    phone: "",
                    groups: [],
                },

                csvModel: {
                    rawText: "",
                    groups: [],
                },
                validation: {
                    email: {
                        isError: false,
                        isValid: false,
                        message: "",
                    },
                    login: {
                        isError: false,
                        isValid: false,
                        message: "",
                    },
                    phone: {
                        isError: false,
                        isValid: false,
                        message: "",
                    },
                    password: {
                        isError: false,
                        isValid: false,
                        message: "",
                    }
                },
                loading: false,
            };
        },
        computed: {
        },
        watch: {
            closeModal: {
                immediate: true,
                handler(val, oldVal) {
                    if (val != oldVal) {
                        let id = parseInt(this.eventID)

                        if (id > 0) {
                            this.modalData = {
                                group: {},
                            }
                            this.availableOptions = []
                            this.fetchAvailableOptions();
                            document.getElementById('eventGroupAddEmit').click();
                        }
                    }
                }
            },
            eventGroups: {
                immediate: true,
                deep: true,
                handler(newVal, oldVal) {
                    console.log(newVal, oldVal);
                }
            }

            //eventID: {
            //    immediate: true,
            //    handler(val) {
            //        let id = parseInt(val)
            //        if (id > 0) {
            //            this.fetchAvailableGroups();
            //        }

            //    }
            //}
        },
        mounted() {
        },
        methods: {
            async eventUsersFormAdd() {
                try {
                    this.loading = true;
                    const req = {
                        Firstname: this.userModel.firstname,
                        Lastname: this.userModel.lastname,
                        Email: this.userModel.email,
                        Phone: this.userModel.phone,
                        Groups: this.userModel.groups.map(a => a.id)
                    };
                    console.log(req);

                    let res = (await UserService.CreateUserFromEvent(req));

                    if (res.status == 200) {
                        this.clearUserDataForm();
                        this.$toast.success(this.$t('com.event.user.add.success'));
                    }
                }
                catch (e) {
                    /*this.$toast.success(this.$t('com.event.user.add.success'));*/
                    console.log(e);
                }
                finally {
                    this.loading = false;
                }
            },

            clearUserDataForm() {
                this.userModel.firstname = "";
                this.userModel.lastname = "";
                this.userModel.email = "";
                this.userModel.phone = "";
                this.validation.phone.isError = false;
                this.validation.phone.isValid = false;
                this.validation.email.isError = false;
                this.validation.email.isValid = false;
            },


            async eventUserRawCsvAdd() {
                try {
                    this.loading = false;

                    const req = {
                        UsersCSV: this.csvModel.rawText,
                        Groups: this.csvModel.groups.map(a => a.id)

                    }

                    let res = (await UserService.CreateUserFromCsv(req))

                    if (res.status == 200) {
                        this.clearUserCSVData();
                        this.$toast.success(this.$t('com.event.user.add.success'))
                    }
                }
                catch (e) {
                    console.log(e);
                }
                finally {
                    this.loading = true;
                }
            },

            clearUserCSVData() {
                this.csvModel.rawText = "";
            },


            async checkPhone() {
                this.validation.phone.isError = false;
                this.validation.phone.isValid = false;
                this.validation.phone.message = "";

                let phoneLenght = this.userModel.phone.length
                if (phoneLenght < 9)
                    return;

                try {
                    this.$store.dispatch("user/checkPhone", this.userModel.phone).then(
                        () => {
                            this.validation.phone.isValid = true;
                        },
                        (error) => {
                            if (error.status == 400) {
                                this.validation.phone.isError = true;
                                this.validation.phone.message = error.data.message;
                            }
                        }
                    );
                }
                catch (err) {
                    console.log(err)
                }
            },

            async checkEmail() {
                this.validation.email.isError = false;
                this.validation.email.isValid = false;
                this.validation.email.message = "";


                if (!this.validateEmail(this.userModel.email))
                    return;


                try {
                    this.$store.dispatch("user/checkEmail", this.userModel.email).then(
                        () => {
                            this.validation.email.isValid = true;
                        },
                        (error) => {
                            if (error.status == 400) {
                                this.validation.email.isError = true;
                                this.validation.email.message = error.data.message;
                            }

                        }
                    );
                }
                catch (err) {
                    console.log(err)
                }
            },
            validateEmail(email) {
                var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },


            //async fetchAvailableOptions() {
            //    try {
            //        this.loading = true;

            //        let res = (await EventService.GetAvailableGroups(this.eventID))
            //        if (res.status == 200) {
            //            this.availableOptions = res.data
            //        }
            //    }
            //    catch (e) {
            //        //console.log(e);
            //        if (e.response.status == 401) {
            //            this.$store.dispatch("auth/logout").then(
            //                () => {
            //                    this.$router.push({ name: 'LoginView' })
            //                },
            //                (error) => {
            //                    console.log(error)
            //                }
            //            );
            //        }
            //    }
            //    finally {
            //        this.loading = false;
            //    }
            //},
            //eventGroupAdd() {
            //    this.$emit("event-group-add", this.modalData.group);

            //},
            //async addGroupFromSelect(GroupName) {
            //    try {
            //        this.loading = true;

            //        const req = {
            //            name: GroupName,
            //            eventID: this.eventID,
            //        }

            //        let res = (await GroupService.CreateFromEvent(req))

            //        if (res.status == 200) {
            //            this.$emit("reload-event-groups");
            //            this.$toast.success(this.$t("com.group.createsuccess"));
            //        }
            //    }
            //    catch (e) {

            //    }
            //    finally {
            //        this.loading = false;
            //    }
            //    console.log(GroupName);
            //}
        }
    }</script>

<style>
</style>