<template>
    <div class="vh-100">
        <div class="authincation h-100">
            <div class="container h-100">
                <div class="row justify-content-center h-100 align-items-center">
                    <div class="col-md-5">
                        <div class="form-input-content text-center error-page">
                            <h1 class="error-text  font-weight-bold">403</h1>
                            <h4><i class="fa fa-times-circle text-danger"></i> {{ $t('com.error.403.forbidenaccess') }}</h4>
                            <p>{{ $t('com.error.403.description') }}</p>
                            <div>
                                <router-link :to="{name: 'Dashboard'}" class="btn btn-primary">
                                    {{ $t('com.error.403.backtohome') }}
                                </router-link>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>

<script charset="utf-8">
    export default {
        components: {
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
            };
        },
        computed: {

        },

        mounted() {
            //this.FetchRerouces();
        },
        methods: {

        }
    }</script>

<style>
</style>
