<template>
    <div class="row mb-3">
        <div class="col-6">
            <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
        </div>
        <div class="col-6">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <QuizNavigation />
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-md-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ $t(subTitle) }}</h4>
                </div>
                <div class="card-body">
                    <!-- Vote.Name -->
                    <div class="row">
                        <div class="col-12">
                            <h5>{{ $t('com.quiz.vote.name') }}</h5>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            {{ vote.name }}
                        </div>
                    </div>
                    <!-- /Vote.Name -->
                    <!-- Vote.Type -->
                    <div class="row">
                        <div class="col-12">
                            <h5>{{ $t('com.quiz.vote.type') }}</h5>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            {{ $t(vote.voteType.name) }}
                        </div>
                    </div>
                    <!-- /Vote.Type -->
                    <!-- Vote.Description -->
                    <div class="row">
                        <div class="col-12">
                            <h5>{{ $t('com.quiz.vote.description') }}</h5>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            {{ vote.description }}
                        </div>
                    </div>
                    <!-- /Vote.Description -->
                    <!-- Vote.Author -->
                    <div class="row">
                        <div class="col-12">
                            <h5>{{ $t('com.quiz.vote.author') }}</h5>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            {{ vote.author.firstname }} {{ vote.author.lastname }} ({{ vote.author.eMail }})
                        </div>
                    </div>
                    <!-- /Vote.Author -->
                    <!-- Vote.Customer -->
                    <div class="row">
                        <div class="col-12">
                            <h5>{{ $t('com.quiz.vote.customer') }}</h5>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            {{ vote.customer.name }} ({{ vote.customer.eMail }}) <br />
                            {{ vote.customer.postalCode }} {{ vote.customer.citty }}, {{ vote.customer.street }}
                        </div>
                    </div>
                    <!-- /Vote.Customer -->
                    <!-- Vote.VoteBlock -->
                    <div class="row">
                        <div class="col-12">
                            <h5>{{ $t('com.quiz.vote.voteblock') }}</h5>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            {{ vote.voteBlock.name }} <br />
                            {{ vote.voteBlock.description }}
                        </div>
                    </div>
                    <!-- /Vote.VoteBlock -->
                </div>
            </div>
        </div>

        <div class="col-lg-8 col-md-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ $t('com.quiz.vote.question.list') }}</h4>
                    <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#eventQuestionAdd">{{ $t('com.global.add') }}</button>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table table-hover">
                                    <thead>
                                        <tr class="align-middle align-content-center">
                                            <th>#</th>
                                            <th>{{ $t('com.quiz.question.name') }}</th>
                                            <th>{{ $t('com.quiz.question.tags') }}</th>
                                            <th>{{ $t('com.quiz.question.categories') }}</th>
                                            <th>{{ $t('com.quiz.question.type') }}</th>
                                            <th>{{ $t('com.quiz.question.points') }}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="align-middle" v-for="(item, index) in questions" :key="index">
                                            <td>{{ getIndex(index) }}</td>
                                            <td>{{ item.name }}</td>
                                            <td>
                                                <span class="badge badge-rounded badge-outline-info mb-1 mr-5" v-for="(tagItem, tagIndex) in item.tags" :key="tagIndex">
                                                    {{ tagItem.name }}
                                                </span>
                                            </td>
                                            <td>
                                                <span class="badge badge-rounded badge-outline-info mb-1 mr-5" v-for="(catItem, catIndex) in item.categories" :key="catIndex">
                                                    {{ catItem.name }}
                                                </span>
                                            </td>
                                            <td>
                                                {{ $t(item.type.name) }}
                                            </td>
                                            <td>
                                                {{ item.maxPoints }}
                                            </td>
                                            <td>
                                                <div class="dropdown">
                                                    <button :id="getDropdownID(index)" type="button" class="btn btn-primary light sharp" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <rect x="0" y="0" width="24" height="24" />
                                                                <circle fill="#000000" cx="5" cy="12" r="2" />
                                                                <circle fill="#000000" cx="12" cy="12" r="2" />
                                                                <circle fill="#000000" cx="19" cy="12" r="2" />
                                                            </g>
                                                        </svg>
                                                    </button>
                                                    <div class="dropdown-menu" :aria-labelledby="getDropdownID(index)">
                                                        <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="offcanvas" data-bs-target="#eventQuestionEdit" @click="setSelectedQuestion(item)">{{ $t('com.global.edit') }}</a>
                                                        <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modalQuestionPrievew" @click="setSelectedQuestion(item)">{{ $t('com.quiz.question.preview') }}</a>
                                                        <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" :data-bs-target="modal.question.delete.target" @click="setSelectedQuestion(item)">{{ $t('com.global.delete') }}</a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <EventQuestionAdd :voteID="vote.id"
                      :eventLanguage="vote.voteBlock.event.language"
                      @reload-vote-questions="fetchVoteQuestion()" />

    <EventQuestionEdit :question="selectedQuestion"
                       @reload-vote-questions="fetchVoteQuestion()" />

    <ModalQuizQuestionPreview :question="selectedQuestion" />

    <ModalDelete :modalId="modal.question.delete.id"
                 :error="modal.question.delete.error"
                 :deletedItem="modal.question.delete.error.text"
                 :closeModal="modal.question.delete.close"
                 @unset-item="unsetQuestionItem()"
                 @confirm-delete="deleteQuestion()" />

</template>

<script charset="utf-8">
    //import PageTitle from "../../../components/common/PageTitle.vue";
    import PageTitle from "@/components/common/PageTitle.vue";
    import QuizNavigation from "@/components/quiz/QuizNavigation.vue";
    //import QuizQuestionService from "../../../services/quiz/quiz.question.service"
    import Pagination from "@/components/common/Pagination.vue"
    //import ModalDelete from "../../../components/common/modal/ModalDelete.vue"
    import ModalDelete from "@/components/common/modal/ModalDelete.vue"
    //import ModalRestore from "../../../components/common/modal/ModalRestore.vue"

    import QuizVoteService from "@/services/quiz/quiz.vote.service"
    import QuizQuestionService from "@/services/quiz/quiz.question.service"
    import EventQuestionEdit from "@/components/event/EventQuestionEdit.vue";
    import ModalQuizQuestionPreview from "@/components/quiz/question/ModalQuizQuestionPreview.vue";
    import EventQuestionAdd from "@/components/event/EventQuestionAdd.vue";

    export default {
        components: {
            PageTitle,
            QuizNavigation,
            ModalDelete,
            EventQuestionEdit,
            ModalQuizQuestionPreview,
            EventQuestionAdd
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "com.quiz.vote.detail",
                subTitle: "com.quiz.vote.detail",
                loading: {
                    global: false,
                    question: false,
                },

                vote: {
                    author: {},
                    customer: {},
                    voteType: {
                        name: "",
                    },
                    voteBlock: {
                        event: {
                            language: { }
                        }
                    },
                },
                questions: [],
                pages: {},

                selectedQuestion: {},


                errorModalEdit: {
                    isShow: false,
                    header: "test",
                    message: "",
                },

                modal: {
                    question: {
                        delete: {
                            id: "questionDeleteItem",
                            target: "#questionDeleteItem",
                            text: "jakis tam tekst",
                            close: false,
                            error: {
                                isShow: false,
                                header: "test",
                                message: "",
                                errors: []
                            }
                        }
                    }

                },

                deletedItem: "",
                restoreItem: "",
                closeModal: false,
                closeDeleteModal: false,
            };
        },
        computed: {
            getVoteID() {
                return this.$route.params.id;
            }
        },
        watch: {

        },
        mounted() {

            this.fetchVote();
            this.fetchVoteQuestion();
            //this.fetchQuestionBin(this.getCurrentPage(), this.getCurrentItemsOnPage());
        },
        methods: {

            async fetchVote() {
                try {
                    this.loading.global = true;

                    let res = (await QuizVoteService.GetById(this.getVoteID));

                    if (res.status == 200) {
                        this.vote = res.data;
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.global = false;
                }
            },
            
            async fetchVoteQuestion() {
                try {
                    this.loading.question = true;

                    let res = (await QuizQuestionService.GetByVoteId(this.getVoteID))

                    if (res.status == 200) {

                        this.questions = res.data;
                    }

                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.question = false;
                }
            },  

            setSelectedQuestion(item) {
                this.selectedQuestion = item;
            },

            unsetQuestionItem() {
                this.selectedQuestion = {}
            },
            async deleteQuestion() {

                try {
                    this.loading.question = false;

                    let res = (await QuizQuestionService.Delete(this.selectedQuestion.id))

                    if (res.status == 200) {
                        this.$toast.success(this.$t('com.quiz.question.success.delete'));
                        this.fetchVoteQuestion();
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.question = false;
                }

                //try {
                //    this.loading = true;
                //    let res = (await QuizQuestionService.DeletePermanently(this.selectedQuestion.id))

                //    if (res.status == 200) {
                //        this.closeModal = true;
                //        this.fetchQuestionBin(this.getCurrentPage(), this.getCurrentItemsOnPage());
                //        this.$toast.success(this.$t("com.quiz.question.successdelete"));

                //    }
                //}
                //catch (e) {
                //    if (e.response.status == 401) {
                //        this.$store.dispatch("auth/logout").then(
                //            () => {
                //                this.$router.push({ name: 'LoginView' })
                //            },
                //            (error) => {
                //                console.log(error)
                //            }
                //        );
                //    }
                //}
                //finally {
                //    this.loading = false;
                //}
            },

            async confirmRestore() {
                this.loading = false;
                //try {
                //    this.loading = true;
                //    let res = (await QuizQuestionService.Restore(this.selectedQuestion.id))

                //    if (res.status == 200) {
                //        this.closeDeleteModal = true;
                //        this.fetchQuestionBin(this.getCurrentPage(), this.getCurrentItemsOnPage());
                //        this.$toast.success(this.$t("com.quiz.question.successrestored"));

                //    }
                //}
                //catch (e) {
                //    if (e.response.status == 401) {
                //        this.$store.dispatch("auth/logout").then(
                //            () => {
                //                this.$router.push({ name: 'LoginView' })
                //            },
                //            (error) => {
                //                console.log(error)
                //            }
                //        );
                //    }
                //}
                //finally {
                //    this.loading = false;
                //}
            },

            getCurrentPage() {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;
                return page;
            },
            getCurrentItemsOnPage() {
                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 10;
                if (itemsPerPage == 0)
                    itemsPerPage = 10;
                return itemsPerPage
            },
            getIndex(index) {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;

                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 0;

                return ((page - 1) * itemsPerPage) + index + 1
            },
            pageRequest(page) {
                this.$router.push(
                    {
                        name: 'QuizQuestionTagListPages',
                        params: {
                            page: page,
                            items: this.getCurrentItemsOnPage()
                        }
                    })
            },

            getDropdownID(str) {
                return "dropdownButton" + str;
            },

            setQuestion(question) {
                this.closeModal = false;
                this.closeDeleteModal = false;
                this.selectedQuestion = question;
            },
            unsetQuestion() {
                this.selectedQuestion = {};
            }
        }
    }</script>

<style>
</style>
