import api from "../api";
import url from "../url";

class SettingTypeService {
    GetAllPaged(Pages) {
        return api.get(url.common.settingType.getAllPaged, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage
            }
        })
            .then((res) => {
                return res;
            })
    }

    GetAll() {
        return api.get(url.common.settingType.getAll)
            .then((res) => {
                return res;
            })
    }



    GetById(SettingTypeID) {
        return api.get(url.common.settingType.getById + "/" + SettingTypeID)
            .then((res) => {
                return res;
            })
    }

    Create(request) {
        const req = {
            Name: request.name,
            Description: request.description,
            Provider: request.provider

        }

        return api.post(url.common.settingType.create, req)
            .then((response) => {
                return response;
            });
    }

    

    Update(request) {
        const req = {
            Id: request.id,
            Name: request.name,
            Description: request.description,
            Provider: request.provider
        }
        return api.post(url.common.settingType.update, req)
            .then((response) => {
                return response;
            });
    }

    Delete(SettingTypeID) {
        return api.delete(url.common.settingType.delete + "/" + SettingTypeID)
            .then((res) => {
                return res;
            })
    }
}

export default new SettingTypeService;