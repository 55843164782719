import api from "../api";
import url from "../url";

class FileManagerService {
    Upload(Request) {

        return api.post(url.file.upload, Request)
            .then((response) => {
                return response;
            });
    }
    ShowMyFiles() {
        return api.get(url.file.showMyFiles)
            .then((res) => {
                return res;
            })
    }
    ShowAllFiles() {
        return api.get(url.file.showAllFiles)
            .then((res) => {
                return res;
            })
    }
    Delete(FileID) {
        return api.delete(url.file.delete + "/" + FileID)
            .then((res) => {
                return res;
            })
    }
    GetById(FileID) {
        return api.get(url.file.getById + "/" + FileID)
            .then((res) => {
                return res;
            })
    }
}

export default new FileManagerService;