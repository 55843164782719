<template>
    <div class="row mb-3">
        <div class="col-6">
            <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
        </div>
        <div class="col-6">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <QuizNavigation />
            </div>
        </div>
    </div>
    
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ $t(subTitle) }}</h4>
                </div>
                <div class="card-body">
                    <table class="table table-responsive-md">
                        <thead>
                            <tr>
                                <th style="width:80px;"><strong>#</strong></th>
                                <th><strong>{{ $t('com.quiz.question.name') }}</strong></th>
                                <th><strong>{{ $t('com.quiz.question.language') }}</strong></th>
                                <th><strong>{{ $t('com.quiz.question.customers') }}</strong></th>
                                <th><strong>{{ $t('com.quiz.question.categories') }}</strong></th>
                                <th><strong>{{ $t('com.quiz.question.tags') }}</strong></th>
                                <th><strong>{{ $t('com.quiz.question.answers') }}</strong></th>
                                <th><strong>{{ $t('com.quiz.question.points') }}</strong></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                    <div class="row mt-3">
                        <Pagination :pages="pages" @page-request="pageRequest" />
                    </div>

                </div>
            </div>
        </div>
    </div>

    <ModalDelete :error="errorModalEdit"
                 :deletedItem="deletedItem"
                 :closeModal="closeModal"
                 @unset-item="unsetQuestion()"
                 @confirm-delete="deleteQuestion()"
    />

    <ModalRestore :error="errorModalEdit"
                  :restoreItem="restoreItem"
                  :closeModal="closeDeleteModal"
                  @unset-item="unsetQuestion()"
                  @confirm-restore="confirmRestore()"
    />
</template>

<script charset="utf-8">
    //import PageTitle from "../../../components/common/PageTitle.vue";
    import PageTitle from "@/components/common/PageTitle.vue";
    import QuizNavigation from "@/components/quiz/QuizNavigation.vue";
    //import QuizQuestionService from "../../../services/quiz/quiz.question.service"
    import Pagination from "@/components/common/Pagination.vue"
    //import ModalDelete from "../../../components/common/modal/ModalDelete.vue"
    import ModalDelete from "@/components/common/modal/ModalDelete.vue"
    //import ModalRestore from "../../../components/common/modal/ModalRestore.vue"
    import ModalRestore from "@/components/common/modal/ModalRestore.vue"

    export default {
        components: {
            PageTitle,
            QuizNavigation,
            Pagination,
            ModalDelete,
            ModalRestore
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "Quiz List All",
                subTitle: "com.quiz.question",
                loading: false,

                questions: [],
                pages: {},

                errorModalEdit: {
                    isShow: false,
                    header: "test",
                    message: "",
                },

                selectedQuestion: {},
                deletedItem: "",
                restoreItem: "",
                closeModal: false,
                closeDeleteModal: false,
            };
        },
        computed: {

        },
        watch: {

        },
        mounted() {
            //this.fetchQuestionBin(this.getCurrentPage(), this.getCurrentItemsOnPage());
        },
        methods: {
            //async fetchQuestionBin(page = 1, items = 10) {
            //    try {
            //        this.loading = true;
            //        const req = {
            //            PageNumber: page,
            //            ItemsOnPage: items,
            //        }
            //        let res = (await QuizQuestionService.GetBin(req));
            //        console.log(res);
            //        if (res.status == 200) {
            //            this.questions = res.data.items;
            //            this.pages = res.data.page;
            //        }
            //    }
            //    catch (e) {
            //        if (e.response.status == 401) {
            //            this.$store.dispatch("auth/logout").then(
            //                () => {
            //                    this.$router.push({ name: 'LoginView' })
            //                },
            //                (error) => {
            //                    console.log(error)
            //                }
            //            );
            //        }
            //    }
            //    finally {
            //        this.loading = false;
            //    }
            //},

            async deleteQuestion() {
                this.loading = false;
                //try {
                //    this.loading = true;
                //    let res = (await QuizQuestionService.DeletePermanently(this.selectedQuestion.id))

                //    if (res.status == 200) {
                //        this.closeModal = true;
                //        this.fetchQuestionBin(this.getCurrentPage(), this.getCurrentItemsOnPage());
                //        this.$toast.success(this.$t("com.quiz.question.successdelete"));

                //    }
                //}
                //catch (e) {
                //    if (e.response.status == 401) {
                //        this.$store.dispatch("auth/logout").then(
                //            () => {
                //                this.$router.push({ name: 'LoginView' })
                //            },
                //            (error) => {
                //                console.log(error)
                //            }
                //        );
                //    }
                //}
                //finally {
                //    this.loading = false;
                //}
            },

            async confirmRestore() {
                this.loading = false;
                //try {
                //    this.loading = true;
                //    let res = (await QuizQuestionService.Restore(this.selectedQuestion.id))

                //    if (res.status == 200) {
                //        this.closeDeleteModal = true;
                //        this.fetchQuestionBin(this.getCurrentPage(), this.getCurrentItemsOnPage());
                //        this.$toast.success(this.$t("com.quiz.question.successrestored"));

                //    }
                //}
                //catch (e) {
                //    if (e.response.status == 401) {
                //        this.$store.dispatch("auth/logout").then(
                //            () => {
                //                this.$router.push({ name: 'LoginView' })
                //            },
                //            (error) => {
                //                console.log(error)
                //            }
                //        );
                //    }
                //}
                //finally {
                //    this.loading = false;
                //}
            },

            getCurrentPage() {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;
                return page;
            },
            getCurrentItemsOnPage() {
                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 10;
                if (itemsPerPage == 0)
                    itemsPerPage = 10;
                return itemsPerPage
            },
            getIndex(index) {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;

                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 0;

                return ((page - 1) * itemsPerPage) + index + 1
            },
            pageRequest(page) {
                this.$router.push(
                    {
                        name: 'QuizQuestionTagListPages',
                        params: {
                            page: page,
                            items: this.getCurrentItemsOnPage()
                        }
                    })
            },

            getDropdownID(str) {
                return "dropdownButton" + str;
            },

            setQuestion(question) {
                this.closeModal = false;
                this.closeDeleteModal = false;
                this.selectedQuestion = question;
            },
            unsetQuestion() {
                this.selectedQuestion = {};
            }
        }
    }</script>

<style>
</style>
