<template>
    <div id="addAnswer" class="offcanvas offcanvas-start" tabindex="-1" aria-labelledby="offcanvasExampleLabel">
        <div class="offcanvas-header">
            <h5 id="offcanvasExampleLabel" class="offcanvas-title">{{ $t('com.modal.areyousure') }}</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <form method="POST" class="needs-validation" novalidate="" autocomplete="off" @submit.prevent="addAnswer">
                <div class="row">
                    <div class="col-md-12">
                        <div class="mb-3">
                            <label class="form-label"><strong>{{ $t('com.quiz.answer.name') }}</strong></label>
                            <input v-model="answer.name" type="text" class="form-control" :placeholder="$t('com.quiz.answer.name')" :disabled="loading" />
                            <pre class="language-json"><code>{{ answer.name }}</code></pre>
                        </div>
                        <div class="mb-3">
                            <label class="form-label"><strong>{{ $t('com.quiz.answer.points') }}</strong></label>
                            <input v-model="answer.points" type="number" class="form-control" :placeholder="$t('com.quiz.answer.name')" :disabled="loading" />
                            <pre class="language-json"><code>{{ answer.points }}</code></pre>s
                        </div>
                        <div class="mb-3">
                            <label class="form-label"><strong>{{ $t('com.quiz.answer.iscorrect') }}</strong></label>
                            <VueMultiselect v-model="answer.isCorrect"
                                            :options="correctOptions"
                                            :searchable="true"
                                            :placeholder="$t('com.quiz.answer.iscorrect')"
                                            :multiple="false"
                                            :custom-label="translatedLabel"
                                            label="name"
                                            track-by="value"
                                            :disabled="loading"
                                            :showLabels="false"
                                            :allow-empty="false"
                            />
                            <pre class="language-json"><code>{{ answer.isCorrect }}</code></pre>
                        </div>
                        <div v-if="isFile" class="row mb-3">
                            <div class="col-12 ">
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <img :src="selectedImage.relativePath" :alt="selectedImage.caption" class="img-fluid" />
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <button class="btn btn-primary btn-sm" @click="deleteImage(selectedImage.id)">{{ $t('com.global.delete') }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <div class="col-md-12">
                                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <div class="btn-group" role="group" aria-label="Basic example">
                                        <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" :data-bs-target="getFileModalHashtag()">
                                            <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            {{ $t('com.quiz.answer.addimage') }}
                                        </button>
                                        <!--<button type="button" class="btn btn-primary btn-sm">
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                                            {{ $t('com.quiz.answer.deleteimage') }}
                                        </button>-->
                                        <button id="addSettingValueEmit" type="button" class="btn btn-danger light btn-sm" data-bs-dismiss="modal">{{ $t('com.global.close') }}</button>
                                        <button type="submit" class="btn btn-primary btn-sm">{{ $t('com.global.add') }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <pre class="language-json"><code>{{ answer }}</code></pre>
                    </div>
                </div>
            </form>
        </div>
    </div>


    <FileManager :fileManageModalID="answerFileModalID" @selected-image="selectedImageForAnswer" @deleted-file="deletedImageForAnswer" />
</template>

<script charset="utf-8">

    import VueMultiselect from 'vue-multiselect'
    import FileManager from "../../../components/file/FileManager.vue"
    import QuizQuestionAnswerService from '../../../services/quiz/quiz.question.answer.service';

    export default {
        components: {
            VueMultiselect,
            FileManager
        },
        props: {
            questionID: {
                type: Number,
                require: true,
            },

        },
        emits: ["answer-added", "search-clear"],
        data() {
            return {
                publicPath: process.env.BASE_URL,

                answer: {
                    name: "",
                    isCorrect: {
                        name: "com.global.no",
                        value: false
                    },
                    points: 0,
                    questionID: 0,
                    fileID: 0,
                },
                correctOptions: [
                    { name: "com.global.yes", value: true },
                    { name: "com.global.no", value: false },
                ],
                loading: false,
                isFile: false,
                selectedImage: {},

                answerFileModalID: "answerFileModalID",
            };
        },
        computed: {

        },
        watch: {
            questionID: {
                immediate: true,
                handler(val) {
                    let id = parseInt(val)
                    if (id > 0) {
                        this.answer.questionID = id;
                    }

                }
            }
        },
        mounted() {

        },
        methods: {
            async addAnswer() {
                try {
                    this.loading = true;

                    const req = {
                        name: this.answer.name,
                        isCorrect: this.answer.isCorrect.value,
                        points: this.answer.points,
                        questionID: this.answer.questionID,
                        fileID: this.answer.fileID
                    }

                    let res = (await QuizQuestionAnswerService.Create(req));

                    if (res.status == 200) {
                        this.$emit("answer-added");
                    }


                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },
            async selectedImageForAnswer(data) {

                console.log("Wybrane zdjęcie odpowiedź: ", data);
                try {
                    this.loading = true;
                    this.selectedImage = data;
                    this.isFile = true;
                    this.answer.fileID = data.id

                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }

               
            },
            getFileModalHashtag() {
                return "#" + this.answerFileModalID
            },
            async deletedImageForAnswer() {
                console.log("deleted image")
            },
            translatedLabel(obj) {
                if (obj === undefined)
                    return "test";

                return this.$t(obj.name)
            }
        }
    }</script>

<style>
</style>
