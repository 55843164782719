////import "bootstrap";
////import 'bootstrap/dist/css/bootstrap.css'
import { createApp } from 'vue'

import VueMeta from 'vue-meta'
import moment from 'moment'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import CKEditor from '@ckeditor/ckeditor5-vue';
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import VueCryptojs from 'vue-cryptojs';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'



require('metismenu');

/*require('@/vendor/deznav/deznav.min.js');*/
//import { library } from '@fortawesome/fontawesome-svg-core'
//import { faFontAwesome } from '@fortawesome/free-brands-svg-icons'
//import { faUserSecret, faUser, faKey, faDownload } from '@fortawesome/free-solid-svg-icons'
//import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import App from './App.vue'
import router from './router'
import store from "./store";
import i18n from "./config/i18n.config";
import setupInterceptors from './services/setupInterceptors';
setupInterceptors(store, router);

moment.locale('pl');
//library.add(faFontAwesome)
//library.add(faUser)
//library.add(faKey)
//library.add(faDownload)
//library.add(faUserSecret)

let app = createApp(App)
//let app = createApp({}
app.config.globalProperties.$moment = moment;
app.use(VueToast, {
    position: 'bottom-right'
});
app.use(router);
app.use(store);
app.use(moment);
app.use(CKEditor)
app.use(VueMeta)
app.use(i18n);
app.use(PerfectScrollbar);
app.use(VueCryptojs);
//app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')
