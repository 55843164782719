<template>
    <div class="row mb-3">
        <div class="col-6">
            <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
        </div>
        <div class="col-6">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <SettingTypeButonNavigation />
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ $t(subTitle) }}</h4>
                </div>
                <div class="card-body">
                    
                    <div class="row mt-3">
                        <Pagination :pages="pages" @page-request="pageRequest" />
                    </div>
                </div>
            </div>

        </div>
    </div>

    <ModalDelete :error="errorModalEdit"
                 :deletedItem="deletedItem"
                 :closeModal="closeModal"
                 @unset-item="unsetEventSetting()"
                 @confirm-delete="deleteEventSetting()" />
</template>

<script charset="utf-8">
    import PageTitle from "@/components/common/PageTitle.vue";
    import SettingTypeButonNavigation from "@/components/common/settingType/SettingTypeButonNavigation.vue"
    import Pagination from "@/components/common/Pagination.vue"
    import ModalDelete from "@/components/common/modal/ModalDelete.vue"
    export default {
        components: {
            PageTitle,
            SettingTypeButonNavigation,
            Pagination,
            ModalDelete
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "Event Setting",
                subTitle: "com.event.setting",
                loading: true,
                eventSettings: [],
                selectedEventSetting: {},
                pages: {},

                errorModalEdit: {
                    isShow: false,
                    header: "test",
                    message: "",
                },

                selectedQuestion: {},
                deletedItem: "",
                closeModal: false
            };
        },
        computed: {

        },
        watch: {
            '$route'(to) {
                
            }
        },
        mounted() {
            
        },
        methods: {
           
            getCurrentPage() {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;
                return page;
            },
            getCurrentItemsOnPage() {
                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 10;
                if (itemsPerPage == 0)
                    itemsPerPage = 10;
                return itemsPerPage
            },
            getIndex(index) {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;

                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 0;

                return ((page - 1) * itemsPerPage) + index + 1
            },
            pageRequest(page) {
                this.$router.push(
                    {
                        name: 'EventSettingListPages',
                        params: {
                            page: page,
                            items: this.getCurrentItemsOnPage()
                        }
                    })
            },
            getDropdownID(str) {
                return "dropdownButton" + str;
            },

            setEventSetting(item) {
                this.closeModal = false;
                this.selectedEventSetting = item;
            },
            unsetEventSetting() {
                this.selectedEventSetting = {};
            }
        }
    }</script>

<style>
</style>
