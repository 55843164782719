import api from "../api";
import url from "../url";

class QuizQuestionAnswerService{

    Create(request) {
        const req = {
            Name: request.name,
            IsCorrect: request.isCorrect,
            Points: request.points,
            QuestionID: request.questionID,
            FileID: request.fileID,
        }

        return api.post(url.quiz.answer.create, req)
            .then((response) => {
                return response;
            });
    }

    Update(request) {
        const req = {
            Id: request.id,
            Name: request.name,
            IsCorrect: request.isCorrect,
            Points: request.points,
            FileID: request.fileID,
        }

        return api.post(url.quiz.answer.update, req)
            .then((response) => {
                return response;
            });
    }

    GetById(AnswerID) {
        return api.get(url.quiz.answer.getById + "/" + AnswerID)
            .then((res) => {
                return res;
            })
    }

    GetByQuestionId(AnswerID) {
        return api.get(url.quiz.answer.getByQuestionId + "/" + AnswerID)
            .then((res) => {
                return res;
            })
    }

    Delete(AnswerID) {
        return api.delete(url.quiz.answer.delete + "/" + AnswerID)
            .then((res) => {
                return res;
            })
    }
}

export default new QuizQuestionAnswerService;