<template>
    <div class="row g-3 align-items-center">
        <div class="col-auto">
            <label for="searchLabel" class="col-form-label">{{ searchInputLabel }}</label>
        </div>
        <div class="col-auto">
            <input id="searchLabel" v-model="searchValue" type="text" class="form-control" :placeholder="searchInputPlaceholder" @keyup="searchResource" />
        </div>
        <div class="col-auto">
            <button class="btn btn-primary" @click="clearFilter">{{ $t('com.tablesearcher.clear') }}</button>
        </div>
    </div>
    <div v-if="isDebug" class="row">
        <div class="col-12">
            {{ searchValue }}
        </div>
    </div>
</template>


<script charset="utf-8">


    export default {
        components: {

        },
        props: {
            searchInputLabel: {
                type: String,
                require: true,
                default: function () {
                    return "com.default.search";
                }
            },
            searchInputPlaceholder: {
                type: String,
                require: true,
                default: function () {
                    return "com.default.search.input";
                }
            },
            minChars: {
                type: Number,
                require: true,
                default: function () {
                    return 3
                }
            }
        },
        emits: ["search-request", "search-clear"],
        data() {
            return {
                publicPath: process.env.BASE_URL,
                searchValue: "",
                isDebug: true,
            };
        },
        computed: {

        },
        watch: {

        },
        mounted() {

        },
        methods: {
            searchResource() {
                if (this.searchValue.length >= this.minChars)
                    this.$emit("search-request", this.searchValue);
            },
            clearFilter() {
                this.searchValue = "";
                this.$emit("search-clear");
            }
        }
    }</script>

<style>
</style>
