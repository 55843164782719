<template>
    <div id="eventQuestionEdit" ref="eventQuestionEditOffCanvas" class="offcanvas offcanvas-start" tabindex="-1" aria-labelledby="canvasEventQuestionEdit">
        <div class="offcanvas-header">
            <h5 class="offcanvas-title">{{ $t('com.event.question.edit') }}</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" :aria-close="$t('com.global.close')"></button>
        </div>
        <div class="offcanvas-body">
            <perfect-scrollbar>
                <div class="row mb-3">
                    <div class="col-12">
                        <label class="form-label">
                            <strong>
                                {{ $t('com.quiz.question.name') }}
                            </strong>
                        </label>
                        <input type="text" class="form-control" :placeholder="$t('com.quiz.question.name')" v-model="questionModel.name" :disabled="loading.global" />
                    </div>
                </div>
                <div class="row mb-3" v-if="questionModel.file != null">
                    <div class="col-12">
                        <img :src="questionModel.file.relativePath" :alt="questionModel.file.caption" class="img-thumbnail" />
                    </div>
                    <div class="col-12">
                        <button class="btn btn-primary" @click="removeQuestionImage()">{{ $t('com.global.deleteimage') }}</button>
                    </div>
                </div>
                <div class="row mb-3" v-else>
                    <div class="col-12">
                        <button class="btn btn-primary" data-bs-toggle="modal" :data-bs-target="questionFileModal.target">{{ $t('com.global.chooseimage') }}</button>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-12">
                        <label class="form-label">
                            <strong>
                                {{ $t('com.quiz.question.description') }}
                            </strong>
                        </label>
                        <editor v-model="questionModel.description" :disabled="loading.global" />
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-12">
                        <label class="form-label">
                            <strong>
                                {{ $t('com.quiz.question.language') }}
                            </strong>
                        </label>
                        <VueMultiselect v-model="questionModel.language"
                                        :options="languages"
                                        :searchable="true"
                                        :placeholder="$t('com.quiz.question.language')"
                                        :multiple="false"
                                        :custom-label="nameWithLang"
                                        track-by="id"
                                        :disabled="loading.languages"
                                        :showLabels="false"
                                        :allow-empty="false" />
                    </div>
                </div>
                <div class="mb-3">
                    <label class="form-label"><strong>{{ $t('com.quiz.question.type') }}</strong></label>
                    <VueMultiselect v-model="questionModel.type"
                                    :options="questionTypes"
                                    :searchable="true"
                                    :placeholder="$t('com.quiz.question.type')"
                                    :multiple="false"
                                    :custom-label="translatedType"
                                    track-by="id"
                                    :disabled="loading.types"
                                    :showLabels="false"
                                    :allow-empty="false" />
                </div>
                <div class="row mb-3">
                    <div class="col-12">
                        <label class="form-label"><strong>{{ $t('com.quiz.question.categories') }}</strong></label>
                        <VueMultiselect v-model="questionModel.categories"
                                        :options="categories"
                                        :searchable="true"
                                        :placeholder="$t('com.quiz.question.categories')"
                                        :multiple="true"
                                        label="name"
                                        track-by="id"
                                        :disabled="loading.categories"
                                        :showLabels="false"
                                        :taggable="true"
                                        :allow-empty="false"
                                        @tag="addQuestionCategory" />
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-12">
                        <label class="form-label"><strong>{{ $t('com.quiz.question.tags') }}</strong></label>
                        <VueMultiselect v-model="questionModel.tags"
                                        :options="tags"
                                        :searchable="true"
                                        :placeholder="$t('com.quiz.question.tags')"
                                        :multiple="true"
                                        track-by="id"
                                        label="name"
                                        :disabled="loading.tags"
                                        :showLabels="false"
                                        :taggable="true"
                                        :allow-empty="false"
                                        @tag="addQuestionTag" />
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-12">
                        <div class="row">
                            <label class="form-label"><strong>{{ $t('com.quiz.question.answer.add') }}</strong></label>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" :placeholder="$t('com.quiz.answer.name')" v-model="answer.name" @keyup.enter.prevent="addQuestionAnwer()" />
                                <div class="input-group-addon">
                                    <button type="button" class="btn btn-primary" @click="addQuestionAnwer()">{{ $t('com.quiz.question.add') }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <table class="table table-hover table-responsive">
                            <thead>
                                <tr class="align-content-center align-middle">
                                    <th>#</th>
                                    <th><strong>{{ $t('com.quiz.answer.name') }}</strong></th>
                                    <th><strong>{{ $t('com.quiz.answer.image') }}</strong></th>
                                    <th><strong>{{ $t('com.quiz.answer.points') }}</strong></th>
                                    <th><strong>{{ $t('com.quiz.answer.iscorrect') }}</strong></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(answerItem, index) in questionModel.answers" :key="index">
                                    <td>{{ index +1 }}</td>
                                    <td @dblclick="setAnswerNameToEdit(index)">
                                        <div v-if="answerItem.editing.name">
                                            <input type="text" class="form-control" v-model="answerItem.name" @keyup.enter.prevent="updateAnswerItem(index)" />
                                        </div>
                                        <span v-else>
                                            {{ answerItem.name }}
                                        </span>
                                    </td>
                                    <td>
                                        <img :src="answerItem.file.relativePath" :alt="answerItem.file.caption" class="img-thumbnail" v-if="answerItem.isFile" />
                                    </td>
                                    <td @dblclick="setAnswerPointToEdit(index)">
                                        <div v-if="answerItem.editing.points">
                                            <input type="text" class="form-control" v-model="answerItem.points" @keyup.enter.prevent="updateAnswerItem(index)" />
                                        </div>
                                        <span v-else>
                                            {{ answerItem.points }}
                                        </span>
                                    </td>
                                    <td @dblclick="setAnswerIsCorrectToEdit(index)">
                                        <div v-if="answerItem.editing.isCorrect">
                                            <VueMultiselect v-model="correctSelection"
                                                            :options="correctOptions"
                                                            :searchable="true"
                                                            :placeholder="$t('com.quiz.answer.iscorrect')"
                                                            :multiple="false"
                                                            :custom-label="translatedLabel"
                                                            label="name"
                                                            track-by="value"
                                                            :value="answerItem.isCorrect"
                                                            :showLabels="false"
                                                            :allow-empty="false"
                                                            @close="selecteAnserCorrectOption(index)" />
                                        </div>
                                        <span v-else>
                                            {{ answerItem.isCorrect }}
                                        </span>
                                    </td>
                                    <td>
                                        <div class="dropdown">
                                            <button :id="getDropdownID(index)" type="button" class="btn btn-primary light sharp" data-bs-toggle="dropdown" aria-expanded="false">
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24" />
                                                        <circle fill="#000000" cx="5" cy="12" r="2" />
                                                        <circle fill="#000000" cx="12" cy="12" r="2" />
                                                        <circle fill="#000000" cx="19" cy="12" r="2" />
                                                    </g>
                                                </svg>
                                            </button>
                                            <div class="dropdown-menu" :aria-labelledby="getDropdownID(index)">
                                                <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" :data-bs-target="answerFileModalID.target" @click="setSelectedAnswer(answerItem)">{{ $t('com.global.chooseimage') }}</a>
                                                <a class="dropdown-item" href="javascript:void(0);" @click="deleteAnswerImage(answerItem)" v-if="answerItem.isFile">{{ $t('com.global.deleteimage') }}</a>
                                                <a class="dropdown-item" href="javascript:void(0);" @click="deleteAnswer(answerItem)">{{ $t('com.global.delete') }}</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-12">
                        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                            <input type="button" class="btn btn-primary" :value="$t('com.global.save')" @click="eventQuestionUpdate()" />
                        </div>
                    </div>
                </div>
            </perfect-scrollbar>

        </div>
    </div>


    <FileManager :fileManageModalID="answerFileModalID.id"
                 @selected-image="selectedImageForAnswer" />

    <FileManager :fileManageModalID="questionFileModal.id"
                 @selected-image="selectedImageForQuestion" />

</template>

<script charset="utf-8">
 

    import I18nService from "@/services/i18n.service";
    import QuizQuestionCategoryService from "@/services/quiz/quiz.question.category.service"
    import QuizQuestionTagService from "@/services/quiz/quiz.question.tag.service"
    import QuizQuestionAnswerService from "@/services/quiz/quiz.question.answer.service"
    import QuizQuestionService from "@/services/quiz/quiz.question.service"
    import QuizQuestionTypeService from "@/services/quiz/quiz.question.type.service"

    import FileManager from "@/components/file/FileManager.vue"
    import Editor from "@/components/common/editor/Editor.vue";
    import VueMultiselect from 'vue-multiselect'


    export default {
        components: {
            Editor,
            VueMultiselect,
            FileManager
        },
        props: {
            question: {
                type: Object,
                require: true,
            }
            //voteBlockID: {
            //    type: Number,
            //    require: true,
            //},
            ////voteBlocks: {
            ////    type: Array,
            ////    require: true,
            ////}

        },
        emits: ["reload-vote-questions"],
        data() {
            return {
                publicPath: process.env.BASE_URL,
                loading: {
                    global: false,
                    languages: false,
                    categories: false,
                    tags: false,
                    answer: false,
                    type: false,
                },

                questionModel: {
                    name: "",
                    description: "",
                    language: {},
                    categories: [],
                    tags: [],
                    answers: [],
                    type: {},
                },

                tags: [],
                languages: [],
                categories: [],
                questionTypes: [],

                answer: {
                    name: "",
                },

                selectedAnswer: {},

                correctSelection: {},
                correctOptions: [
                    { name: "com.global.yes", value: true },
                    { name: "com.global.no", value: false },
                ],

                answerFileModalID: {
                    id: "eventAnswerChooseFile",
                    target: "#eventAnswerChooseFile"
                },
                questionFileModal: {
                    id: "eventQuestionChooseFile",
                    target: "#eventQuestionChooseFile"
                }
            }
        },
        watch: {
            question: {
                immediate: true,
                handler(val) {
                    try {
                        this.questionModel = val;
                        this.addEditingStatusToAnswers();
                    }
                    catch {
                    }
                }
            }
        },
        mounted() {
            this.fetchLanguages();
            this.fetchTags();
            this.fetchCategories();
            this.fetchQuestionTypes();

            jQuery(this.$refs.eventQuestionEditOffCanvas).on('hidden.bs.offcanvas', this.closeEventQuestionEdit());
        },
        methods:
        {

            closeEventQuestionEdit() {
                console.log("zamykanie okna");
            },

            async eventQuestionEditMethod() {
                console.log("test");
            },

            async fetchLanguages() {
                try {
                    const req = {
                        PageNumber: 1,
                        ItemsOnPage: 100,
                    }
                    this.languages = (await I18nService.GetLanguages(req)).data.languageItems

                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
            },

            async fetchTags() {
                try {
                    this.tags = (await QuizQuestionTagService.GetAll()).data
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
            },
            async addQuestionTag(newTag) {
                try {
                    this.loading.tags = true;
                    const req = {
                        name: newTag
                    }

                    let res = (await QuizQuestionTagService.CreateFromQuestion(req))
                    if (res.status == 200) {
                        this.tags.push(res.data);
                        this.questionModel.tags.push(res.data);
                        this.$toast.success(this.$t("com.quiz.tag.createsuccess"));
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.tags = false;
                }
            },

            async fetchCategories() {
                try {
                    this.categories = (await QuizQuestionCategoryService.GetAll()).data
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
            },
            async addQuestionCategory(newCateogry) {
                try {
                    this.loading.categories = true;

                    const req = {
                        name: newCateogry
                    };

                    let res = (await QuizQuestionCategoryService.CreateFromQuestion(req));
                    if (res.status == 200) {
                        this.categories.push(res.data);
                        this.questionModel.categories.push(res.data);
                        this.$toast.success(this.$t("com.quiz.category.createsuccess"));
                    }

                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.categories = false;
                }
            },

            async fetchQuestionTypes() {
                try {
                    this.loading.type = true;

                    let res = (await QuizQuestionTypeService.GetAll());
                    if (res.status == 200) {
                        this.questionTypes = res.data;

                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.type = false;
                }
            },

            nameWithLang(obj) {
                if (obj === undefined)
                    return "test";

                return obj.name + " (" + obj.culture + ")"
            },

            setAnswerPointToEdit(index) {
                try {
                    this.questionModel.answers[index].editing.points = true;
                }
                catch (e) {
                    console.log(e);
                }
            },

            setAnswerNameToEdit(index) {
                try {
                    this.questionModel.answers[index].editing.name = true;
                }
                catch (e) {
                    console.log(e);
                }
            },

            setAnswerIsCorrectToEdit(index) {
                try {
                    this.correctSelection = this.correctOptions.find(x => x.value == this.questionModel.answers[index].isCorrect);
                    console.log(this.correctSelection);
                    this.questionModel.answers[index].editing.isCorrect = true;
                }
                catch (e) {
                    console.log(e);
                }
            },

            updateAnswerItem(index) {
                try {
                    this.questionModel.answers[index].editing.points = false;
                    this.questionModel.answers[index].editing.name = false;
                    this.questionModel.answers[index].editing.isCorrect = false;
                    this.sendUpdateAnswer(index);
                }
                catch (e) {
                    console.log(e);
                }
            },

            translatedLabel(obj) {
                if (obj === undefined)
                    return "test";

                return this.$t(obj.name)
            },
            selecteAnserCorrectOption(index) {
                console.log(this.questionModel.answers[index], this.correctSelection);
                this.questionModel.answers[index].isCorrect = this.correctSelection.value;
                this.questionModel.answers[index].editing.isCorrect = false;
                this.sendUpdateAnswer(index);
            },

            async sendUpdateAnswer(index) {
                try {
                    this.loading.answer = true;

                    const req = {
                        id: this.questionModel.answers[index].id,
                        name: this.questionModel.answers[index].name,
                        isCorrect: this.questionModel.answers[index].isCorrect,
                        points: this.questionModel.answers[index].points,
                        fileID: 0
                    };

                    if (this.questionModel.answers[index].isFile) {
                        req.fileID = this.questionModel.answers[index].file.id;
                    }

                    let res = (await QuizQuestionAnswerService.Update(req))

                    if (res.status == 200) {
                        this.$emit("reload-vote-questions");
                        this.$toast.success(this.$t("com.quiz.answer.updatesuccess"));
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.answer = false;
                }
            },

            async addQuestionAnwer() {

                try {
                    this.loading.answer = false;
                    const req = {
                        name: this.answer.name,
                        isCorrect: false,
                        points: 0,
                        questionID: this.questionModel.id,
                        fileID: 0
                    };

                    let res = (await QuizQuestionAnswerService.Create(req))

                    if (res.status) {
                        this.answer.name = "";
                        this.fetchAnswerByQuestion();
                    }

                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.answer = false;
                }
            },

            async fetchAnswerByQuestion() {
                try {
                    this.loading.answer = false;

                    let res = (await QuizQuestionAnswerService.GetByQuestionId(this.questionModel.id))

                    if (res.status == 200) {
                        this.$emit("reload-vote-questions");
                        this.questionModel.answers = res.data.answers;
                        this.addEditingStatusToAnswers();
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.answer = false;
                }
            },

            async deleteAnswer(item) {
                try {
                    this.loading.answer = false;

                    let res = (await QuizQuestionAnswerService.Delete(item.id))

                    if (res.status == 200) {
                        this.$toast.success(this.$t("com.quiz.answer.deletesuccess"));
                        this.fetchAnswerByQuestion();
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.answer = false;
                }
            },


            async selectedImageForAnswer(data) {

                try {

                    this.loading.answer = true;

                    const req = {
                        id: this.selectedAnswer.id,
                        name: this.selectedAnswer.name,
                        isCorrect: this.selectedAnswer.isCorrect,
                        points: this.selectedAnswer.points,
                        fileID: data.id
                    };

                    let res = (await QuizQuestionAnswerService.Update(req))

                    if (res.status == 200) {
                       
                        this.$toast.success(this.$t("com.quiz.answer.updatesuccess"));  
                        this.fetchAnswerByQuestion();
                    }

                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.answer = false;
                }


            },

            async deleteAnswerImage(item) {
                try {
                    this.loading.answer = true;

                    const req = {
                        id: item.id,
                        name: item.name,
                        isCorrect: item.isCorrect,
                        points: item.points,
                        fileID: 0
                    };

                    let res = (await QuizQuestionAnswerService.Update(req))

                    if (res.status == 200) {
                        this.$toast.success(this.$t("com.quiz.answer.updatesuccess"));
                        this.fetchAnswerByQuestion();
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.answer = false;
                }
            },


            async eventQuestionUpdate() {
                try {
                    this.loading.global = true;

                    const req = {
                        id: this.questionModel.id,
                        name: this.questionModel.name,
                        description: this.questionModel.description,
                        languageID: this.questionModel.language.id,
                        typeID: this.questionModel.type.id,
                        tags: this.questionModel.tags.map(i => i.id),
                        categories: this.questionModel.categories.map(i => i.id),
                        fileID: 0,
                    }

                    if (this.questionModel.file != null) {
                        req.fileID = this.questionModel.file.id;
                    }

                    let res = (await QuizQuestionService.UpdateWithoutCustomer(req));

                    if (res.status == 200) {
                        this.fetchQuestion();
                        this.$toast.success(this.$t('com.quiz.question.updatesuccess'));
                    }



                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.global = false;
                }
            },


            async selectedImageForQuestion(data) {
                try {
                    this.loading.global = true;

                    let res = (await QuizQuestionService.AssignFile(this.questionModel.id, data.id));

                    if (res.status == 200) {
                        this.fetchQuestion();
                        this.$toast.success(this.$t('com.quiz.question.assignfilesuccess'));
                    }



                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.global = false;
                }
            },

            async removeQuestionImage() {
                try {
                    this.loading.global = true;

                    let res = (await QuizQuestionService.UnassignFile(this.questionModel.id, this.questionModel.file.id));

                    if (res.status == 200) {
                        this.fetchQuestion();
                        this.$toast.success(this.$t('com.quiz.question.assignfilesuccess'));
                    }



                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.global = false;
                }
            },


            async fetchQuestion() {
                try {
                    this.loading.global = true;

                    let res = (await QuizQuestionService.GetByIdWithAnswers(this.questionModel.id));

                    if (res.status == 200) {
                        this.$emit("reload-vote-questions");
                        this.questionModel = res.data;
                        this.addEditingStatusToAnswers();
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.global = false;
                }
            },

            setSelectedAnswer(item) {
                this.selectedAnswer = item;
            },

            addEditingStatusToAnswers() {
                try {
                    for (let i = 0; this.questionModel.answers.length; i++) {
                        this.questionModel.answers[i].editing = {
                            name: false,
                            points: false,
                            isCorrect: false,
                        };
                    }
                }
                catch { }
            },

            getDropdownID(str) {
                return "dropdownButtonUpdateQuestionEvent" + str;
            },

            translatedType(obj) {

                if (Object.keys(obj).length === 0)
                    return "Select"

                return this.$t(obj.name);
            },
            
        }
    }
</script>

<style>
    #eventQuestionEdit {
        width: 70%;
    }
</style>