<template>
    <div class="row">
        <div class="col-12">
            <div class="alert alert-danger fade show ">
                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="me-2">
                    <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2" />
                    <line x1="15" y1="9" x2="9" y2="15" />
                    <line x1="9" y1="9" x2="15" y2="15" />
                </svg>
                <strong>{{ $t('com.global.error') }}</strong> {{ accountErr.name }} {{ accountErr.description }}
            </div>
        </div>
    </div>
   
</template>

<script>
    export default {
        components: {
        },
        props: {
            accountErr: {
                type: Object,
                require: true,
                default: function () {
                    return {
                        errorType: 0,
                        name: "",
                        message: "",
                        description: "",
                    }
                }
            }
        },
        data() {
            return {
                publicPath: process.env.BASE_URL
            };
        },
        computed: {

        },
        methods: {
        }
    }</script>

<style>
</style>
