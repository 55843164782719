<template>
    <div class="row mb-3">
        <div class="col-6">
            <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
        </div>
        <div class="col-6">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <QuizNavigation />
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ $t(subTitle) }}</h4>
                    <!--<div class="btn-group" role="group" aria-label="Basic example">
                        <button type="button" class="btn btn-primary" @click="selectAll()">{{ $t('com.global.selectall') }}</button>
                        <button type="button" class="btn btn-primary" @click="deSelectAll()">{{ $t('com.global.deselectall') }}</button>
                        <button type="button" class="btn btn-danger light">{{ $t('com.global.deleteselected') }}</button>
                        <button type="button" class="btn btn-primary">{{ $t('com.global.restoreselected') }}</button>
                    </div>-->
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table table-responsive-md table-hover">
                                    <thead>
                                        <tr class="align-content-center align-middle">
                                            <!--<th></th>-->
                                            <th style="width:80px;"><strong>#</strong></th>
                                            <th>
                                                <strong>
                                                    {{ $t('com.quiz.vote.name') }}
                                                </strong>
                                            </th>
                                            <th><strong>{{ $t('com.quiz.vote.voteblock.name') }}</strong></th>
                                            <th><strong>{{ $t('com.quiz.vote.questions') }}</strong></th>
                                            <th><strong>{{ $t('com.quiz.vote.availablepoints') }}</strong></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(vote, index) in quizzes" :key="index">
                                            <!--<td>
                                                <input type="checkbox" class="form-check-input" :value="vote.id" v-model="checkedVotes" />
                                            </td>-->
                                            <td>{{ getIndex(index) }}</td>
                                            <td>{{ vote.name }}</td>
                                            <td>
                                                <span class="badge badge-rounded badge-outline-info">
                                                    {{ vote.voteBlock.name }}
                                                </span>
                                            </td>
                                            <td>{{ vote.questions }}</td>
                                            <td>{{ vote.availablePoints }}</td>
                                            <td>
                                                <div class="dropdown">
                                                    <button :id="getDropdownID(index)" type="button" class="btn btn-primary light sharp" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <rect x="0" y="0" width="24" height="24" />
                                                                <circle fill="#000000" cx="5" cy="12" r="2" />
                                                                <circle fill="#000000" cx="12" cy="12" r="2" />
                                                                <circle fill="#000000" cx="19" cy="12" r="2" />
                                                            </g>
                                                        </svg>
                                                    </button>
                                                    <div class="dropdown-menu" :aria-labelledby="getDropdownID(index)">
                                                        <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modalItemRestore" @click="setVote(vote)">{{ $t('com.global.restore') }}</a>
                                                        <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modalItemDelete" @click="setVote(vote)">{{ $t('com.global.delete') }}</a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <ElementLoader v-if="loading" :type="tableLoader.type" :color="tableLoader.color" :size="tableLoader.size" />
                    </div>
                    <div class="row">
                        <pre>{{ checkedVotes }}</pre>
                    </div>
                    <div class="row mt-3">
                        <Pagination :pages="pages" @page-request="pageRequest" />
                    </div>

                </div>
            </div>
        </div>
    </div>

    <ModalDelete :error="errorModalEdit"
                 :deletedItem="deletedItem"
                 :closeModal="closeModal"
                 @unset-item="unsetVote()"
                 @confirm-delete="confimDelete()" />

    <ModalRestore :error="errorModalEdit"
                  :restoreItem="restoreItem"
                  :closeModal="closeDeleteModal"
                  @unset-item="unsetVote()"
                  @confirm-restore="confirmRestore()" />
</template>

<script charset="utf-8">
    import PageTitle from "@/components/common/PageTitle.vue";
    import QuizNavigation from "@/components/quiz/QuizNavigation.vue";
    import Pagination from "@/components/common/Pagination.vue"
    import ModalDelete from "@/components/common/modal/ModalDelete.vue"
    import ModalRestore from "@/components/common/modal/ModalRestore.vue"
    import ElementLoader from '@/components/common/ElementLoader.vue';
    import QuizVoteService from "@/services/quiz/quiz.vote.service"

    export default {
        components: {
            PageTitle,
            QuizNavigation,
            Pagination,
            ModalDelete,
            ModalRestore,
            ElementLoader
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "Quiz Bin",
                subTitle: "com.quiz.question",
                loading: false,

                questions: [],
                quizzes: [],
                pages: {},

                checkedVotes: [],

                errorModalEdit: {
                    isShow: false,
                    header: "test",
                    message: "",
                },

                tableLoader: {
                    type: "spinner-border",
                    color: "text-primary",
                    size: "big"
                },

                selectedVote: {},
                deletedItem: "",
                restoreItem: "",
                closeModal: false,
                closeDeleteModal: false,
            };
        },
        computed: {

        },
        watch: {
            '$route'() {
                this.fetchQuizzes(this.getCurrentPage(), this.getCurrentItemsOnPage());
            }
        },
        mounted() {
            this.fetchQuizzes(this.getCurrentPage(), this.getCurrentItemsOnPage());
        },
        methods: {

            async fetchQuizzes(page = 1, items = 10) {
                try {
                    this.loading = true;

                    const req = {
                        PageNumber: page,
                        ItemsOnPage: items,
                    }

                    let res = (await QuizVoteService.GetBin(req))
                    if (res.status == 200) {
                        this.pages = res.data.pages;
                        this.quizzes = res.data.items;
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },


            async confimDelete() {
                try {
                    this.loading = true;

                    let res = (await QuizVoteService.DeletePermanently(this.selectedVote.id));

                    if (res.status == 200) {
                        this.checkedVotes = this.checkedVotes.filter(x => x != this.selectedVote.id);
                        this.closeModal = true;
                        this.closeDeleteModal = true;
                        this.$toast.success(this.$t('com.quiz.vote.successdeete'));
                        this.fetchQuizzes(this.getCurrentPage(), this.getCurrentItemsOnPage());
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },

            async confirmRestore() {
                try {
                    this.loading = true;

                    let res = (await QuizVoteService.Restore(this.selectedVote.id));

                    if (res.status == 200) {

                        this.checkedVotes = this.checkedVotes.filter(x => x != this.selectedVote.id);

                        this.closeModal = true;
                        this.closeDeleteModal = true;
                        this.$toast.success(this.$t('com.quiz.vote.successrestore'));
                        this.fetchQuizzes(this.getCurrentPage(), this.getCurrentItemsOnPage());
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }

            },

            selectAll() {
                this.checkedVotes = this.quizzes.map(x => x.id);
            },
            deSelectAll() {
                this.checkedVotes = [];
            },
            getCurrentPage() {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;
                return page;
            },
            getCurrentItemsOnPage() {
                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 10;
                if (itemsPerPage == 0)
                    itemsPerPage = 10;
                return itemsPerPage
            },
            getIndex(index) {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;

                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 0;

                return ((page - 1) * itemsPerPage) + index + 1
            },
            pageRequest(page) {
                this.$router.push(
                    {
                        name: 'QuizBinPages',
                        params: {
                            page: page,
                            items: this.getCurrentItemsOnPage()
                        }
                    })
            },

            getDropdownID(str) {
                return "dropdownButton" + str;
            },

            setVote(item) {
                this.closeModal = false;
                this.closeDeleteModal = false;
                this.selectedVote = item;
            },
            unsetVote() {
                this.selectedVote = {};
            }
        }
    }</script>

<style>
</style>
