<template>
    <div id="modalQuestionValueDelete" class="modal fade" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('com.modal.areyousure') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" @click="unsetItem()">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="mb-3 row">
                        <ErrorModalMessage v-if="error.isShow" :error="error" />
                    </div>
                    <div class="mb-3 row">
                        {{ $t('com.modal.areyousure_modalbody') }} <strong>{{ deletedItem }}</strong>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="deleteModalEmit" type="button" class="btn btn-danger light" data-bs-dismiss="modal" @click="unsetItem()">{{ $t('com.global.no') }}</button>
                    <button type="button" class="btn btn-primary" @click="confirmDelete()">{{ $t('com.global.yes') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script charset="utf-8">
    import ErrorModalMessage from "../../../common/error/ErrorModalMessage";
    export default {
        components: {
            ErrorModalMessage
        },
        props: {
            error: {
                type: Object,
                require: true,
                default: function () {
                    return {
                        isShow: false,
                        header: "test",
                        message: "",
                    }
                }
                
            },
            deletedItem: {
                type: String,
                require: true
            },
            closeModal: {
                type: Boolean,
                require: true,
            }
            
        },
        emits: ["unset-item", "confirm-delete"],
        data() {
            return {
                publicPath: process.env.BASE_URL,

            };
        },
        computed: {

        },
        watch: {
            closeModal: {
                // the callback will be called immediately after the start of the observation
                immediate: true,
                handler(val, oldVal) {
                    if (val != oldVal) {
                        if (val == true) {
                            document.getElementById('deleteModalEmit').click();
                        }
                    }
                }
            }
        },
        methods: {
            unsetItem() {
                this.$emit("unset-item")
            },
            confirmDelete() {
                this.$emit("confirm-delete")
            }
        }
    }</script>

<style>
</style>
