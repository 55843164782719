<template>
    <div class="row mb-3">
        <div class="col-6">
            <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
        </div>
        <div class="col-6">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <EventButtonNavigation />
            </div>
        </div>
    </div>

    <div class="row">
        <!-- Event Details-->
        <div class="col-lg-4 col-xl-3">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">
                        {{ $t('com.event.details') }}
                    </h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h5>
                                {{ $t('com.event.name') }}
                            </h5>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            {{ event.name }}
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <h5>
                                {{ $t('com.event.description') }}
                            </h5>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            {{ event.description }}
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <h5>
                                {{ $t('com.event.language') }}
                            </h5>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            {{ event.language.name }} ({{ event.language.culture }})
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <h5>
                                {{ $t('com.event.customer') }}
                            </h5>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            {{ event.customer.name }} <br />
                            {{ event.customer.postalCode }} {{ event.customer.citty }}, {{ event.customer.street }}
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <h5>
                                {{ $t('com.event.date.start') }}
                            </h5>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            {{ event.eventStart }}
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <h5>
                                {{ $t('com.event.date.end') }}
                            </h5>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            {{ event.eventEnd }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Event Details -->
        <!-- Event Settings-->
        <div class="col-lg-8 col-xl-9">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">
                        {{ $t('com.event.settings') }}
                    </h4>
                </div>
                <div class="card-body">
                    <div class="row mb-3">
                        <div class="col-md-12">
                            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                <div class="btn-group" role="group" aria-label="Basic example">
                                    <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="offcanvas" data-bs-target="#eventSettingAdd">
                                        <span v-if="loading.setting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        {{ $t('com.event.setting.add') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <table class="table table-responsive">
                                <thead>
                                    <tr>
                                        <th style="width:80px;"><strong>#</strong></th>
                                        <th><strong>{{ $t('com.event.setting.key.name') }}</strong></th>
                                        <th><strong>{{ $t('com.event.setting.key.type') }}</strong></th>
                                        <th><strong>{{ $t('com.event.setting.value') }}</strong></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(settingValue, index) in event.settingValues" :key="index">
                                        <td><strong>{{ getIndex(index) }}</strong></td>
                                        <td>{{ $t(settingValue.key.name) }}</td>
                                        <td>{{ settingValue.key.variableTypeName }}</td>
                                        <td>{{ settingValue.value }}</td>
                                        <td>
                                            <div class="dropdown">
                                                <button :id="getDropdownID(index)" type="button" class="btn btn-primary light sharp" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <circle fill="#000000" cx="5" cy="12" r="2" />
                                                            <circle fill="#000000" cx="12" cy="12" r="2" />
                                                            <circle fill="#000000" cx="19" cy="12" r="2" />
                                                        </g>
                                                    </svg>
                                                </button>
                                                <div class="dropdown-menu" :aria-labelledby="getDropdownID(index)">
                                                    <!--<a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#editAnswer" @click="setSettingItem(settingValue)">{{ $t('com.global.edit') }}</a>-->
                                                    <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" :data-bs-target="getDeleteId(eventSettingDeleteModal)" @click="setSettingItem(settingValue)">{{ $t('com.global.delete') }}</a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Event Settings-->
        <!-- Groups Participats-->
        <div class="col-12">
            <div id="accordion-event-groups-participants" class="accordion">
                <div class="card">
                    <div id="card-heading-event-groups-participants-heading" class="card-header" data-bs-toggle="collapse" data-bs-target="#card-heading-event-groups-participants-body" aria-expanded="true" aria-controls="card-heading-event-groups-participants-body">
                        <h4 class="card-title">
                            {{ $t('com.event.group.participants') }}
                        </h4>
                        <span class="accicon"><i class="fas fa-angle-down rotate-icon"></i></span>
                    </div>
                    <div id="card-heading-event-groups-participants-body" class="collapse show" aria-labelledby="card-heading-event-groups-participants-heading" data-bs-parent="#accordion-event-groups-participants">
                        <div class="card-body">
                            <div class="row mb-3">
                                <div class="col-12">
                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <div class="btn-group" role="group" aria-label="Basic example">
                                            <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#eventGroupAdd">
                                                <span v-if="loading.group" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                {{ $t('com.event.group.add') }}
                                            </button>
                                            <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#eventUsersAdd">
                                                <span v-if="loading.group" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                {{ $t('com.event.users.add') }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <table class="table table-responsive table-hover">
                                        <thead>
                                            <tr>
                                                <th style="width:80px;"><strong>#</strong></th>
                                                <th><strong>{{ $t('com.group.name') }}</strong></th>
                                                <th><strong>{{ $t('com.group.description') }}</strong></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(group, index) in event.groups" :key="index">
                                                <td><strong>{{ getIndex(index) }}</strong></td>
                                                <td>{{ group.name }}</td>
                                                <td>{{ group.description }}</td>
                                                <td>
                                                    <div class="dropdown">
                                                        <button :id="getDropdownID(index)" type="button" class="btn btn-primary light sharp" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24" />
                                                                    <circle fill="#000000" cx="5" cy="12" r="2" />
                                                                    <circle fill="#000000" cx="12" cy="12" r="2" />
                                                                    <circle fill="#000000" cx="19" cy="12" r="2" />
                                                                </g>
                                                            </svg>
                                                        </button>
                                                        <div class="dropdown-menu" :aria-labelledby="getDropdownID(index)">
                                                            <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#eventGroupEdit" @click="setGroupItem(group)">{{ $t('com.global.edit') }}</a>
                                                            <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modalEventGroupDetail" @click="setGroupItem(group)">{{ $t('com.global.details') }}</a>
                                                            <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modalItemDelete" @click="setGroupItem(group)">{{ $t('com.global.delete') }}</a>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <EventVoteBlock :event="event"
                        :voteBlocks="event.voteBlocks" />

    </div>




    <EventGroupAdd :error="error"
                   :eventID="event.id"
                   :closeModal="close.eventGroupAdd"
                   @event-group-add="eventGroupAdd"
                   @reload-event-groups="fetchEventGroups" />

    <EventGroupDetails :groupItem="selected.group"
                       @unset-item="unsetGroupItem()" />
    <EventGroupEdit :groupItem="selected.group" />


    <EventUsersAdd :error="error"
                   :eventGroups="event.groups"
                   :closeModal="close.eventGroupAdd" />

    <EventSettingAdd :error="error.addSetting"
                     :eventID="event.id"
                     :closeModal="close.addSetting"
                     @event-setting-add="eventSettingAdd" />




    <ModalDelete :error="modalError.deleteGroup"
                 :deletedItem="modalText.modalGroupDelete"
                 :closeModal="close.modalGroupDelete"
                 @unset-item="unsetGroupItem()"
                 @confirm-delete="deleteGroup()" />


    <ModalDelete :modalId="eventSettingDeleteModal"
                 :error="modalError.deleteSetting"
                 :deletedItem="modalText.modalSettingDelete"
                 :closeModal="close.modalSettingDelete"
                 @unset-item="unsetSettingItem()"
                 @confirm-delete="deleteSetting()" />

</template>

<script charset="utf-8">
    import PageTitle from "../../components/common/PageTitle.vue";
    import EventButtonNavigation from "../../components/event/EventButtonNavigation.vue"
    import EventService from "../../services/event/event.service";
    import EventGroupAdd from "../../components/event/EventGroupAdd.vue"
    import EventUsersAdd from "@/components/event/EventUsersAdd.vue"
    import EventSettingAdd from "../../components/event/EventSettingAdd.vue"
    import EventSettingService from "../../services/event/event.setting.service";
    import EventGroupDetails from "@/components/event/EventGroupDetails.vue";
    import EventGroupEdit from "@/components/event/EventGroupEdit.vue";



    import EventVoteBlock from "@/components/event/EventVoteBlock.vue";


    import ModalDelete from "../../components/common/modal/ModalDelete.vue"
    export default {
        components: {
            PageTitle,
            EventButtonNavigation,
            EventGroupAdd,
            ModalDelete,
            EventSettingAdd,
            EventUsersAdd,
            EventGroupDetails,
            EventVoteBlock,
            EventGroupEdit
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "Event",
                subTitle: "com.event",

                event: {
                    id: 0,
                    name: "",
                    description: "",
                    language: {},
                    customer: {},
                    groups: [{}],
                    settingValues: [],
                    voteBlocks: [],
                    eventStart: "",
                    eventEnd: "",
                },

                eventSettingDeleteModal: "eventSettingDeleteModal",

                error: {
                    isShow: false,
                    header: "test",
                    message: "",
                    errors: []
                },

                modalError: {
                    deleteGroup: {
                        isShow: false,
                        header: "test",
                        message: "",
                    },
                    addSetting: {
                        isShow: false,
                        header: "test",
                        message: "",
                    },
                    deleteSetting: {
                        isShow: false,
                        header: "test",
                        message: "",
                    },
                },

                selected: {
                    group: {},
                    settingValue: {}
                },

                close: {
                    addSetting: false,
                    eventGroupAdd: false,
                    modalGroupDelete: false,
                    modalSettingDelete: false,
                },

                modalText: {
                    modalGroupDelete: "",
                    modalSettingDelete: "",
                },

                loading: {
                    global: false,
                    customers: false,
                    group: false,
                    setting: false,
                }
            };
        },
        computed: {
            eventId() {
                return parseInt(this.$route.params.id);
            },
            eventGroupsList() {
                return this.event.groups;
            }
        },
        watch: {

        },
        created() {

        },
        mounted() {
            this.fetchEvent();
        },
        methods: {
            async fetchEvent() {
                try {
                    this.loading.global = true;

                    let res = (await EventService.GetById(this.eventId));

                    if (res.status == 200) {
                        console.log(res.data);
                        this.event = res.data;
                        //this.event.groups = JSON.parse(JSON.stringify(this.event.groups));

                        console.log(this.event);
                        //this.event.customer = this.customers.find(obj => { return obj.id })
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.global = false;
                }
            },


            async fetchEventGroups() {
                try {
                    this.loading.group = false;

                    let res = (await EventService.GetGroupsByEventId(this.eventId))

                    if (res.status == 200) {
                        this.event.groups = res.data;
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.group = false;
                }
            },

            async eventGroupAdd(data) {

                try {
                    this.loading.group = true;

                    const req = {
                        eventID: this.eventId,
                        groupID: data.id
                    };

                    let res = (await EventService.AssignGroupToEvent(req));

                    if (res.status == 200) {
                        this.close.eventGroupAdd = true;
                        this.$toast.success(this.$t('com.event.group.assign.success'));
                        this.fetchEventGroups();
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.group = false;
                }
            },




            async deleteGroup() {
                try {
                    const req = {
                        eventID: this.eventId,
                        groupID: this.selected.group.id
                    };

                    let res = (await EventService.RemoveGroupFromEvent(req));

                    if (res.status == 200) {
                        this.close.modalGroupDelete = true;
                        this.$toast.success(this.$t('com.event.group.delete.success'));
                        this.fetchEventGroups();
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.group = false;
                }
            },

            async fetchEventSettings() {

                try {
                    this.loading.setting = true;

                    let res = (await EventSettingService.GetValueByEventID(this.event.id))

                    if (res.status == 200) {
                        this.event.settingValues = res.data;
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.setting = false;
                }

            },

            async eventSettingAdd(data) {

                try {
                    this.loading.setting = true;

                    const req = {
                        value: data.value,
                        settingID: data.setting.id,
                        eventID: this.event.id
                    }

                    let res = (await EventSettingService.CreateSettingValue(req));

                    if (res.status == 200) {
                        this.close.addSetting = true;
                        this.$toast.success(this.$t('com.event.setting.value.create.success'))
                        this.fetchEventSettings();

                    }


                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.setting = false;
                }

            },

            async deleteSetting() {
                try {
                    this.loading.setting = true;


                    let res = (await EventSettingService.DeleteSettingValue(this.selected.settingValue.id))

                    if (res.status == 200) {
                        this.fetchEventSettings();
                        this.$toast.success(this.$t('com.event.setting.value.delete.success'));
                        this.close.modalSettingDelete = true;

                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.setting = false;
                }
            },

            setGroupItem(item) {
                this.close.eventGroupAdd = false;
                this.close.modalGroupDelete = false;
                this.selected.group = item;
            },
            unsetGroupItem() {
                this.selected.group = {}
            },

            setSettingItem(item) {
                this.close.addSetting = false;
                this.close.modalSettingDelete = false;
                this.selected.settingValue = item;

            },
            unsetSettingItem() {
                this.selected.settingValue = {}
            },
            getIndex(index) {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;

                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 0;

                return ((page - 1) * itemsPerPage) + index + 1
            },

            getDropdownID(str) {
                return "dropdownButton" + str;
            },

            getDeleteId(modalID) {
                return "#" + modalID;
            },



        }
    }</script>

<style type="text/css">
    .card-header .accicon {
        float: right;
        font-size: 20px;
        width: 1.2em;
    }

    .card-header {
        cursor: pointer;
        border-bottom: none;
    }

        .card-header:not(.collapsed) .rotate-icon {
            transform: rotate(180deg);
        }

    #accordion-event-vote-block-detail .card {
        border: 1px solid #eee;
    }
</style>
