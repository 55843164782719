import { createRouter, createWebHashHistory } from 'vue-router'

import Layout from '@/components/layout/Layout.vue'
import Dashboard from '../views/Dashboard.vue'
//i18n
import Language from '../views/i18n/Language.vue'
import Resource from '../views/i18n/resource/Resource.vue'
import ResourceDetail from '../views/i18n/resource/ResourceDetail.vue'
import ResourceLists from '../views/i18n/resource/ResourceLists.vue'
import Translation from '../views/i18n/Translation.vue'

import Country from '../views/i18n/countries/Country.vue'
import CountryList from '../views/i18n/countries/CountryList.vue'
import CountryBin from '../views/i18n/countries/CountryBin.vue'

import Customer from '../views/client/customer/Customer.vue'
import CustomerCreate from '../views/client/customer/CustomerCreate.vue'
import CustomerEdit from '../views/client/customer/CustomerEdit.vue'
import CustomerDetail from '../views/client/customer/CustomerDetail.vue'
import CustomerList from '../views/client/customer/CustomerList.vue'
import CustomerBin from '../views/client/customer/CustomerBin.vue'

import Group from '../views/client/group/Group.vue'
import GroupCreate from '../views/client/group/GroupCreate.vue'
import GroupEdit from '../views/client/group/GroupEdit.vue'
import GroupDetail from '../views/client/group/GroupDetail.vue'
import GroupList from '../views/client/group/GroupList.vue'
import GroupBin from '../views/client/group/GroupBin.vue'

//users
import User from '../views/user/User.vue'
import UserList from '../views/user/UserList.vue'
import UserDetails from '../views/user/UserDetails.vue'
import UserEdit from '../views/user/UserEdit.vue'
import UserCreate from '@/views/user/UserCreate.vue'
import UserAdminCreate from '@/views/user/UserAdminCreate.vue'

//account

import Account from '../views/account/Account.vue'
import AccountProfile from '../views/account/AccountProfile.vue'

import LoginView from '../views/auth/Login.vue'
import Register from '../views/auth/Register.vue'
import ForgotPassword from '../views/auth/ForgotPassword.vue'
import ResetPassword from '../views/auth/ResetPassword.vue'
import EmailConfirmation from '../views/auth/EmailConfirmation.vue';

//Email
//EmailTemplate
import EmailTemplate from '../views/email/template/EmailTemplate.vue'
import EmailTemplateBin from '../views/email/template/EmailTemplateBin.vue'
import EmailTemplateCreate from '../views/email/template/EmailTemplateCreate.vue'
import EmailTemplateDetail from '../views/email/template/EmailTemplateDetail.vue'
import EmailTemplateEdit from '../views/email/template/EmailTemplateEdit.vue'
import EmailTemplateList from '../views/email/template/EmailTemplateList.vue'

//App
import AppCustom from "@/views/app/AppCustom.vue"

//AppSettings
import AppSetting from "@/views/app/setting/AppSetting.vue"

//AppSettingsKeys
import AppSettingKey from '@/views/app/setting/key/AppSettingKey.vue'
import AppSettingKeyBin from '@/views/app/setting/key/AppSettingKeyBin.vue'
import AppSettingKeyCreate from '@/views/app/setting/key/AppSettingKeyCreate.vue'
import AppSettingKeyDetail from '@/views/app/setting/key/AppSettingKeyDetail.vue'
import AppSettingKeyEdit from '@/views/app/setting/key/AppSettingKeyEdit.vue'
import AppSettingKeyList from '@/views/app/setting/key/AppSettingKeyList.vue'

//AppSettingTypes
import AppSettingType from '@/views/app/setting/type/AppSettingType.vue'
import AppSettingTypeCreate from '@/views/app/setting/type/AppSettingTypeCreate.vue'
import AppSettingTypeDetail from '@/views/app/setting/type/AppSettingTypeDetail.vue'
import AppSettingTypeEdit from '@/views/app/setting/type/AppSettingTypeEdit.vue'
import AppSettingTypeList from '@/views/app/setting/type/AppSettingTypeList.vue'

//Quiz
import QuizConfig from '@/views/quiz/QuizConfig.vue';

//Vote
import QuizVote from "@/views/quiz/vote/QuizVote.vue";
import QuizBin from '@/views/quiz/vote/QuizBin.vue';
import QuizList from '@/views/quiz/vote/QuizList.vue';
import QuizListMy from '@/views/quiz/vote/QuizListMy.vue';
import QuizListAll from '@/views/quiz/vote/QuizListAll.vue';
import QuizCreate from '@/views/quiz/vote/QuizCreate.vue';
import QuizDetail from '@/views/quiz/vote/QuizDetail.vue';
import QuizEdit from '@/views/quiz/vote/QuizEdit.vue';

//VoteBlock
import QuizVoteBlock from '@/views/quiz/voteblock/QuizVoteBlock.vue';
import QuizVoteBlockList from '@/views/quiz/voteblock/QuizVoteBlockList.vue';
import QuizVoteBlockListMy from '@/views/quiz/voteblock/QuizVoteBlockListMy.vue';
import QuizVoteBlockListAll from '@/views/quiz/voteblock/QuizVoteBlockListAll.vue';
import QuizVoteBlockCreate from '@/views/quiz/voteblock/QuizVoteBlockCreate.vue';
import QuizVoteBlockEdit from '@/views/quiz/voteblock/QuizVoteBlockEdit.vue';
import QuizVoteBlockDetail from '@/views/quiz/voteblock/QuizVoteBlockDetail.vue';
import QuizVoteBlockBin from '@/views/quiz/voteblock/QuizVoteBlockBin.vue';

//QuestionCategory
import QuizQuestionCategory from '@/views/quiz/question/category/QuizQuestionCategory.vue';
import QuizQuestionCategoryList from '@/views/quiz/question/category/QuizQuestionCategoryList.vue';
import QuizQuestionCategoryCreate from '@/views/quiz/question/category/QuizQuestionCategoryCreate.vue';
import QuizQuestionCategoryBin from '@/views/quiz/question/category/QuizQuestionCategoryBin.vue';
import QuizQuestionCategoryEdit from '@/views/quiz/question/category/QuizQuestionCategoryEdit.vue';
import QuizQuestionCategoryDetail from '@/views/quiz/question/category/QuizQuestionCategoryDetail.vue';
//QuestionTag
import QuizQuestionTag from '@/views/quiz/question/tag/QuizQuestionTag.vue';
import QuizQuestionTagList from '@/views/quiz/question/tag/QuizQuestionTagList.vue';
import QuizQuestionTagCreate from '@/views/quiz/question/tag/QuizQuestionTagCreate.vue';
import QuizQuestionTagBin from '@/views/quiz/question/tag/QuizQuestionTagBin.vue';
import QuizQuestionTagEdit from '@/views/quiz/question/tag/QuizQuestionTagEdit.vue';
import QuizQuestionTagDetail from '@/views/quiz/question/tag/QuizQuestionTagDetail.vue';
//Question
import QuizQuestion from '../views/quiz/question/QuizQuestion.vue';
import QuizQuestionList from '../views/quiz/question/QuizQuestionList.vue';
import QuizQuestionListMy from '../views/quiz/question/QuizQuestionListMy.vue';
import QuizQuestionCreate from '../views/quiz/question/QuizQuestionCreate.vue';
import QuizQuestionBin from '../views/quiz/question/QuizQuestionBin.vue';
import QuizQuestionEdit from '../views/quiz/question/QuizQuestionEdit.vue';
import QuizQuestionDetail from '../views/quiz/question/QuizQuestionDetail.vue';

//Quiz Settings
import QuizSetting from '../views/quiz/setting/QuizSetting.vue';

//Quiz Setting Question
import QuizSettingQuestion from '../views/quiz/setting/question/QuizSettingQuestion.vue'
import QuizSettingQuestionBin from '../views/quiz/setting/question/QuizSettingQuestionBin.vue'
import QuizSettingQuestionCreate from '../views/quiz/setting/question/QuizSettingQuestionCreate.vue'
import QuizSettingQuestionDetail from '../views/quiz/setting/question/QuizSettingQuestionDetail.vue'
import QuizSettingQuestionEdit from '../views/quiz/setting/question/QuizSettingQuestionEdit.vue'
import QuizSettingQuestionList from '../views/quiz/setting/question/QuizSettingQuestionList.vue'

//Events

import Event from '../views/event/Event.vue'
import EventBin from '../views/event/EventBin.vue'
import EventCreate from '../views/event/EventCreate.vue'
import EventCreateWithoutCustomer from '../views/event/EventCreateWithoutCustomer.vue'
import EventDetail from '../views/event/EventDetail.vue'
import EventEdit from '../views/event/EventEdit.vue'
import EventList from '../views/event/EventList.vue'
import EventListAll from '../views/event/EventListAll.vue'
import EventListMy from '../views/event/EventListMy.vue'

//Event 
import EventSetting from '../views/event/setting/EventSetting.vue'
import EventSettingBin from '../views/event/setting/EventSettingBin.vue'
import EventSettingCreate from '../views/event/setting/EventSettingCreate.vue'
import EventSettingDetail from '../views/event/setting/EventSettingDetail.vue'
import EventSettingEdit from '../views/event/setting/EventSettingEdit.vue'
import EventSettingList from '../views/event/setting/EventSettingList.vue'

//Visualisation
import VisualisationLayout from '@/components/visualisation/VisualisationLayout.vue'
//EventVisualisation
import EventVisualisation from "@/views/visualisation/event/EventVisualisation.vue"
import EventVisualisationLive from "@/views/visualisation/event/EventVisualisationLive.vue"

//Interactive
import InteractiveLayout from "@/components/interactive/InteractiveLayout.vue"
//EventInteractive
import EventInteractive from "@/views/interactive/event/EventInteractive.vue"

//Participant
import ParticipantLayout from "@/components/participant/ParticipantLayout.vue"
import EventParticipant from "@/views/participant/event/EventParticipant.vue"

//errors
import BadRequest400 from '@/views/error/BadRequeset400.vue';
import Forbidden403 from '@/views/error/Forbidden403.vue';
import NotFound404 from '@/views/error/NotFound404.vue';
import InternalServerError500 from '@/views/error/InternalServerError500.vue';
import ServiceUnavailable503 from '@/views/error/ServiceUnavailable503.vue';

import store from '@/store/'


const routes = [
    {
        path: '/',
        name: 'Layout',
        component: Layout,
        meta: {
            requiresAuth: true,
            title: "Layout"
        },
        children: [
            {
                path: 'dashboard',
                name: 'Dashboard',
                component: Dashboard,
                meta: {
                    title: "Dashboard"
                    //title: computed(() => $t('pages.unauthorized.metaTitle')),
                },
            },
            {
                path: 'languages',
                name: 'Languages',
                component: Language,
            },
            {
                path: 'languages/page/:page/items/:items',
                name: 'LanguagesPages',
                component: Language,
            },
            {
                path: 'account',
                name: 'Account',
                component: Account,
                children: [
                    {
                        path: 'profile',
                        name: 'AccountProfile',
                        component: AccountProfile,
                    }
                ]
            },
            {
                path: 'resources',
                name: 'Resources',
                component: Resource,
                children: [
                    {
                        path: 'lists',
                        name: 'ResourcesList',
                        component: ResourceLists,
                        children: [
                            {
                                path: 'page/:page/items/:items',
                                name: 'ResourcesListPaged',
                                component: ResourceLists,
                            }
                        ]
                    },
                    {
                        path: 'detail/:id',
                        name: 'ResourceDetails',
                        component: ResourceDetail,
                    },
                ]
            },
            {
                path: 'users',
                name: 'Users',
                component: User,
                children: [
                    {
                        path: 'lists',
                        name: 'UsersList',
                        component: UserList,
                        children: [
                            {
                                path: 'page/:page/items/:items',
                                name: 'UsersListPaged',
                                component: UserList,
                            },
                            {
                                path: 'by-customer/:customerID',
                                name: 'UserListByCustomer',
                                component: UserList,
                                children: [
                                    {
                                        path: 'page/:page/items/:items',
                                        name: 'UserListByCustomerPaged',
                                        component: UserList,
                                    }
                                ]
                            },
                            {
                                path: 'by-role/:roleID',
                                name: 'UserListByRole',
                                component: UserList,
                                children: [
                                    {
                                        path: 'page/:page/items/:items',
                                        name: 'UserListByRolePaged',
                                        component: UserList,
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'create',
                        name: 'UserCreate',
                        component: UserCreate,
                    },
                    {
                        path: 'admin-create',
                        name: 'UserAdminCreate',
                        component: UserAdminCreate,
                    },
                    {
                        path: 'edit/:id',
                        name: 'UserEdit',
                        component: UserEdit,
                    },
                    {
                        path: 'detail/:id',
                        name: 'UserDetails',
                        component: UserDetails,
                    },

                ]
            },
            {
                path: 'translations',
                name: 'Translations',
                component: Translation,
            },
            {
                path: 'translations/page/:page/items/:items',
                name: 'TranslationsPages',
                component: Translation,
            },
            {
                path: 'countries',
                name: 'Countries',
                component: Country,
                children: [
                    {
                        path: 'list',
                        name: 'CountriesList',
                        component: CountryList,
                        children: [
                            {
                                path: 'page/:page/items/:items',
                                name: 'CountriesListPages',
                                component: CountryList,
                            },
                        ]
                    },
                    {
                        path: 'bin',
                        name: 'CountriesBin',
                        component: CountryBin,
                        children: [
                            {
                                path: 'page/:page/items/:items',
                                name: 'CountriesBinPages',
                                component: CountryBin,
                            },
                        ]
                    },
                ]
            },
            {
                path: 'customers',
                name: 'Customers',
                component: Customer,
                children: [
                    {
                        path: 'create',
                        name: 'CustomerCreate',
                        component: CustomerCreate,
                    },
                    {
                        path: 'edit/:id',
                        name: 'CustomerEdit',
                        component: CustomerEdit,
                    },
                    {
                        path: 'details/:id',
                        name: 'CustomerDetail',
                        component: CustomerDetail,
                    },
                    {
                        path: 'list',
                        name: 'CustomersList',
                        component: CustomerList,
                        children: [
                            {
                                path: 'page/:page/items/:items',
                                name: 'CustomersListPages',
                                component: CustomerList,
                            },
                        ]
                    },
                    {
                        path: 'bin',
                        name: 'CustomersBin',
                        component: CustomerBin,
                        children: [
                            {
                                path: 'page/:page/items/:items',
                                name: 'CustomersBinPages',
                                component: CustomerBin,
                            },
                        ]
                    },
                ]
            },
            {
                path: 'groups',
                name: 'Groups',
                component: Group,
                children: [
                    {
                        path: 'create',
                        name: 'GroupCreate',
                        component: GroupCreate,
                    },
                    {
                        path: 'edit/:id',
                        name: 'GroupEdit',
                        component: GroupEdit,
                    },
                    {
                        path: 'details/:id',
                        name: 'GroupDetail',
                        component: GroupDetail,
                    },
                    {
                        path: 'list',
                        name: 'GroupsList',
                        component: GroupList,
                        children: [
                            {
                                path: 'page/:page/items/:items',
                                name: 'GroupsListPages',
                                component: GroupList,
                            },
                        ]
                    },
                    {
                        path: 'bin',
                        name: 'GroupsBin',
                        component: GroupBin,
                        children: [
                            {
                                path: 'page/:page/items/:items',
                                name: 'GroupsBinPages',
                                component: GroupBin,
                            },
                        ]
                    },
                ]
            },
            {
                path: 'email-templates',
                name: 'EmailTemplates',
                component: EmailTemplate,
                children: [
                    {
                        path: 'create',
                        name: 'EmailTemplateCreate',
                        component: EmailTemplateCreate,
                    },
                    {
                        path: 'edit/:id',
                        name: 'EmailTemplateEdit',
                        component: EmailTemplateEdit,
                    },
                    {
                        path: 'details/:id',
                        name: 'EmailTemplateDetail',
                        component: EmailTemplateDetail,
                    },
                    {
                        path: 'list',
                        name: 'EmailTemplatesList',
                        component: EmailTemplateList,
                        children: [
                            {
                                path: 'page/:page/items/:items',
                                name: 'EmailTemplatesListPages',
                                component: EmailTemplateList,
                            },
                        ]
                    },
                    {
                        path: 'bin',
                        name: 'EmailTemplatesBin',
                        component: EmailTemplateBin,
                        children: [
                            {
                                path: 'page/:page/items/:items',
                                name: 'EmailTemplatesBinPages',
                                component: EmailTemplateBin,
                            },
                        ]
                    },
                ]
            },
            {
                path: "app",
                name: "AppCustom",
                component: AppCustom,
                children: [
                    {
                        path: "setting",
                        name: "AppSetting",
                        component: AppSetting,
                        children: [
                            {
                                path: "keys",
                                name: "AppSettingsKeys",
                                component: AppSettingKey,
                                children: [
                                    {
                                        path: 'create',
                                        name: 'AppSettingKeyCreate',
                                        component: AppSettingKeyCreate,
                                    },
                                    {
                                        path: 'edit/:id',
                                        name: 'AppSettingKeyEdit',
                                        component: AppSettingKeyEdit,
                                    },
                                    {
                                        path: 'details/:id',
                                        name: 'AppSettingKeyDetail',
                                        component: AppSettingKeyDetail,
                                    },
                                    {
                                        path: 'list',
                                        name: 'AppSettingsKeysList',
                                        component: AppSettingKeyList,
                                        children: [
                                            {
                                                path: 'page/:page/items/:items',
                                                name: 'AppSettingsKeysListPages',
                                                component: AppSettingKeyList,
                                            },
                                        ]
                                    },
                                    {
                                        path: 'bin',
                                        name: 'AppSettingsKeysBin',
                                        component: AppSettingKeyBin,
                                        children: [
                                            {
                                                path: 'page/:page/items/:items',
                                                name: 'AppSettingsKeysBinPages',
                                                component: AppSettingKeyBin,
                                            },
                                        ]
                                    },
                                ]
                            },
                            {
                                path: "type",
                                name: "AppSettingType",
                                component: AppSettingType,
                                children: [
                                    {
                                        path: 'create',
                                        name: 'AppSettingTypeCreate',
                                        component: AppSettingTypeCreate,
                                    },
                                    {
                                        path: 'edit/:id',
                                        name: 'AppSettingTypeEdit',
                                        component: AppSettingTypeEdit,
                                    },
                                    {
                                        path: 'details/:id',
                                        name: 'AppSettingTypeDetail',
                                        component: AppSettingTypeDetail,
                                    },
                                    {
                                        path: 'list',
                                        name: 'AppSettingTypeList',
                                        component: AppSettingTypeList,
                                        children: [
                                            {
                                                path: 'page/:page/items/:items',
                                                name: 'AppSettingTypeListPages',
                                                component: AppSettingTypeList,
                                            },
                                        ]
                                    },
                                ]
                            }

                        ]
                    }
                ]
            },
            //{
            //    path: "app-setting-keys",
            //    name: "AppSettingsKeys",
            //    component: AppSettingKey,
            //    children: [
            //        {
            //            path: 'create',
            //            name: 'AppSettingKeyCreate',
            //            component: AppSettingKeyCreate,
            //        },
            //        {
            //            path: 'edit/:id',
            //            name: 'AppSettingKeyEdit',
            //            component: AppSettingKeyEdit,
            //        },
            //        {
            //            path: 'details/:id',
            //            name: 'AppSettingKeyDetail',
            //            component: AppSettingKeyDetail,
            //        },
            //        {
            //            path: 'list',
            //            name: 'AppSettingsKeysList',
            //            component: AppSettingKeyList,
            //            children: [
            //                {
            //                    path: 'page/:page/items/:items',
            //                    name: 'AppSettingsKeysListPages',
            //                    component: AppSettingKeyList,
            //                },
            //            ]
            //        },
            //        {
            //            path: 'bin',
            //            name: 'AppSettingsKeysBin',
            //            component: AppSettingKeyBin,
            //            children: [
            //                {
            //                    path: 'page/:page/items/:items',
            //                    name: 'AppSettingsKeysBinPages',
            //                    component: AppSettingKeyBin,
            //                },
            //            ]
            //        },
            //    ]
            //},
            //quiz
            {
                path: 'quiz',
                name: 'Quiz',
                component: QuizConfig,
                children: [
                    {
                        path: 'vote',
                        name: 'QuizVote',
                        component: QuizVote,
                        children: [
                            {
                                path: 'bin',
                                name: 'QuizBin',
                                component: QuizBin,
                                children: [
                                    {
                                        path: 'page/:page/items/:items',
                                        name: 'QuizBinPages',
                                        component: QuizBin,
                                    }
                                ]
                            },
                            {
                                path: 'list',
                                name: 'QuizList',
                                component: QuizList,
                                children: [
                                    {
                                        path: 'all',
                                        name: 'QuizListAll',
                                        component: QuizListAll,
                                        children: [
                                            {
                                                path: 'by-voteblock/:voteBlockID',
                                                name: 'QuizListAllByVoteBlock',
                                                component: QuizListMy,
                                                children: [
                                                    {
                                                        path: 'page/:page/items/:items',
                                                        name: 'QuizListAllByVoteBlockPages',
                                                        component: QuizListMy,
                                                    },
                                                ]
                                            },
                                            {
                                                path: 'page/:page/items/:items',
                                                name: 'QuizListAllPages',
                                                component: QuizListMy,
                                            },
                                        ]
                                    },
                                    {
                                        path: 'my',
                                        name: 'QuizListMy',
                                        component: QuizListMy,
                                        children: [
                                            {
                                                path: 'by-voteblock/:voteBlockID',
                                                name: 'QuizListMyByVoteBlock',
                                                component: QuizListMy,
                                                children: [
                                                    {
                                                        path: 'page/:page/items/:items',
                                                        name: 'QuizListMyByVoteBlockPages',
                                                        component: QuizListMy,
                                                    },
                                                ]
                                            },
                                            {
                                                path: 'page/:page/items/:items',
                                                name: 'QuizListMyPages',
                                                component: QuizListMy,
                                            },
                                        ]
                                    }
                                ]
                            },
                            {
                                path: 'create',
                                name: 'QuizCreate',
                                component: QuizCreate,
                            },
                            {
                                path: 'edit/:id',
                                name: 'QuizEdit',
                                component: QuizEdit
                            },
                            {
                                path: 'detail/:id',
                                name: 'QuizDetail',
                                component: QuizDetail,
                            },
                        ]
                    },
                    {
                        path: 'vote-block',
                        name: 'QuizVoteBlock',
                        component: QuizVoteBlock,
                        children: [
                            {
                                path: 'list',
                                name: 'QuizVoteBlockList',
                                component: QuizVoteBlockList,
                                children: [
                                    {
                                        path: 'my',
                                        name: 'QuizVoteBlockListMy',
                                        component: QuizVoteBlockListMy,
                                        children: [
                                            {
                                                path: 'by-event/:eventID',
                                                name: 'QuizVoteBlockListMyByEvent',
                                                component: QuizVoteBlockListMy,
                                                children: [
                                                    {
                                                        path: 'page/:page/items/:items',
                                                        name: 'QuizVoteBlockListMyByEventPages',
                                                        component: QuizVoteBlockListMy,
                                                    }
                                                ]
                                            },
                                            {
                                                path: 'page/:page/items/:items',
                                                name: 'QuizVoteBlockListMyPages',
                                                component: QuizVoteBlockListMy,
                                            }
                                        ]
                                    },
                                    {
                                        path: 'all',
                                        name: 'QuizVoteBlockListAll',
                                        component: QuizVoteBlockListAll,
                                        children: [
                                            {
                                                path: 'by-event/:eventID',
                                                name: 'QuizVoteBlockListAllByEvent',
                                                component: QuizVoteBlockListAll,
                                                children: [
                                                    {
                                                        path: 'page/:page/items/:items',
                                                        name: 'QuizVoteBlockListAllByEventPages',
                                                        component: QuizVoteBlockListAll,
                                                    }
                                                ]
                                            },
                                            {
                                                path: 'page/:page/items/:items',
                                                name: 'QuizVoteBlockListAllPages',
                                                component: QuizVoteBlockListAll,
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                path: 'bin',
                                name: 'QuizVoteBlockBin',
                                component: QuizVoteBlockBin,
                                children: [
                                    {
                                        path: 'page/:page/items/:items',
                                        name: 'QuizVoteBlockBinPages',
                                        component: QuizVoteBlockBin,
                                    }
                                ]
                            },
                            {
                                path: 'create',
                                name: 'QuizVoteBlockCreate',
                                component: QuizVoteBlockCreate,
                            },
                            {
                                path: 'edit/:id',
                                name: 'QuizVoteBlockEdit',
                                component: QuizVoteBlockEdit,
                            },
                            {
                                path: 'detail/:id',
                                name: 'QuizVoteBlockDetail',
                                component: QuizVoteBlockDetail,
                            }
                        ]
                    },
                    {
                        path: 'question',
                        name: 'QuizQuestion',
                        component: QuizQuestion,
                        children: [
                            {
                                path: 'category',
                                name: 'QuizQuestionCategory',
                                component: QuizQuestionCategory,
                                children: [
                                    {
                                        path: 'list',
                                        name: 'QuizQuestionCategoryList',
                                        component: QuizQuestionCategoryList,
                                        children: [
                                            {
                                                path: 'page/:page/items/:items',
                                                name: 'QuizQuestionCategoryListPages',
                                                component: QuizQuestionCategoryList,
                                            },
                                        ]
                                    },
                                    {
                                        path: 'bin',
                                        name: 'QuizQuestionCategoryBin',
                                        component: QuizQuestionCategoryBin,
                                        children: [
                                            {
                                                path: 'page/:page/items/:items',
                                                name: 'QuizQuestionCategoryBinPages',
                                                component: QuizQuestionCategoryBin,
                                            },
                                        ]
                                    },
                                    {
                                        path: 'create',
                                        name: 'QuizQuestionCategoryCreate',
                                        component: QuizQuestionCategoryCreate,
                                    },
                                    {
                                        path: 'edit/:id',
                                        name: 'QuizQuestionCategoryEdit',
                                        component: QuizQuestionCategoryEdit,
                                    },
                                    {
                                        path: 'detail/:id',
                                        name: 'QuizQuestionCategoryDetail',
                                        component: QuizQuestionCategoryDetail,
                                    },
                                ]

                            },
                            {
                                path: 'tag',
                                name: 'QuizQuestionTag',
                                component: QuizQuestionTag,
                                children: [
                                    {
                                        path: 'list',
                                        name: 'QuizQuestionTagList',
                                        component: QuizQuestionTagList,
                                        children: [
                                            {
                                                path: 'page/:page/items/:items',
                                                name: 'QuizQuestionTagListPages',
                                                component: QuizQuestionTagList,
                                            },
                                        ]
                                    },
                                    {
                                        path: 'bin',
                                        name: 'QuizQuestionTagBin',
                                        component: QuizQuestionTagBin,
                                        children: [
                                            {
                                                path: 'page/:page/items/:items',
                                                name: 'QuizQuestionTagBinPages',
                                                component: QuizQuestionTagBin,
                                            },
                                        ]
                                    },
                                    {
                                        path: 'create',
                                        name: 'QuizQuestionTagCreate',
                                        component: QuizQuestionTagCreate,
                                    },
                                    {
                                        path: 'edit/:id',
                                        name: 'QuizQuestionTagEdit',
                                        component: QuizQuestionTagEdit,
                                    },
                                    {
                                        path: 'detail/:id',
                                        name: 'QuizQuestionTagDetail',
                                        component: QuizQuestionTagDetail,
                                    },
                                ]
                            },
                            {

                                path: 'list',
                                name: 'QuizQuestionList',
                                component: QuizQuestionList,
                                children: [
                                    {
                                        path: 'by-customer/:customerID',
                                        name: 'QuizQuestionListByCustomer',
                                        component: QuizQuestionList,
                                        children: [
                                            {
                                                path: 'page/:page/items/:items',
                                                name: 'QuizQuestionListByCustomerPages',
                                                component: QuizQuestionList,
                                            }
                                        ]
                                    },
                                    {
                                        path: 'by-tag/:tagID',
                                        name: 'QuizQuestionListByTag',
                                        component: QuizQuestionList,
                                        children: [
                                            {
                                                path: 'page/:page/items/:items',
                                                name: 'QuizQuestionListByTagPages',
                                                component: QuizQuestionList,
                                            }
                                        ]
                                    },
                                    {
                                        path: 'by-category/:categoryID',
                                        name: 'QuizQuestionListByCategory',
                                        component: QuizQuestionList,
                                        children: [
                                            {
                                                path: 'page/:page/items/:items',
                                                name: 'QuizQuestionListByCategoryPages',
                                                component: QuizQuestionList,
                                            }
                                        ]
                                    },
                                    {
                                        path: 'page/:page/items/:items',
                                        name: 'QuizQuestionListPages',
                                        component: QuizQuestionList,
                                    },
                                ]
                            },
                            {

                                path: 'my',
                                name: 'QuizQuestionListMy',
                                component: QuizQuestionListMy,
                                children: [
                                    {
                                        path: 'page/:page/items/:items',
                                        name: 'QuizQuestionListMyPages',
                                        component: QuizQuestionListMy,
                                    },
                                ]
                            },
                            {
                                path: 'bin',
                                name: 'QuizQuestionBin',
                                component: QuizQuestionBin,
                                children: [
                                    {
                                        path: 'page/:page/items/:items',
                                        name: 'QuizQuestionBinPages',
                                        component: QuizQuestionBin,
                                    },
                                ]
                            },
                            {
                                path: 'create',
                                name: 'QuizQuestionCreate',
                                component: QuizQuestionCreate,
                            },
                            {
                                path: 'edit/:id',
                                name: 'QuizQuestionEdit',
                                component: QuizQuestionEdit,
                            },
                            {
                                path: 'detail/:id',
                                name: 'QuizQuestionDetail',
                                component: QuizQuestionDetail,
                            },
                        ]
                    },
                    {
                        path: 'setting',
                        name: 'QuizSetting',
                        component: QuizSetting,
                        children: [
                            {
                                path: 'question',
                                name: 'QuizSettingQuestion',
                                component: QuizSettingQuestion,
                                children: [
                                    {
                                        path: 'list',
                                        name: 'QuizSettingQuestionList',
                                        component: QuizSettingQuestionList,
                                        children: [
                                            {
                                                path: 'page/:page/items/:items',
                                                name: 'QuizSettingQuestionListPages',
                                                component: QuizSettingQuestionList,
                                            },
                                        ]
                                    },
                                    {
                                        path: 'bin',
                                        name: 'QuizSettingQuestionBin',
                                        component: QuizSettingQuestionBin,
                                        children: [
                                            {
                                                path: 'page/:page/items/:items',
                                                name: 'QuizSettingQuestionBinPages',
                                                component: QuizSettingQuestionBin,
                                            },
                                        ]
                                    },
                                    {
                                        path: 'create',
                                        name: 'QuizSettingQuestionCreate',
                                        component: QuizSettingQuestionCreate,
                                    },
                                    {
                                        path: 'edit/:id',
                                        name: 'QuizSettingQuestionEdit',
                                        component: QuizSettingQuestionEdit,
                                    },
                                    {
                                        path: 'detail/:id',
                                        name: 'QuizSettingQuestionDetail',
                                        component: QuizSettingQuestionDetail,
                                    },
                                ]
                            }
                        ]
                    }
                ]
            },
            //event
            {
                path: 'event',
                name: 'Event',
                component: Event,
                children: [
                    {
                        path: 'admin-create',
                        name: 'EventCreate',
                        component: EventCreate,
                    },
                    {
                        path: 'create',
                        name: 'EventCreateWithoutCustomer',
                        component: EventCreateWithoutCustomer,
                    },
                    {
                        path: 'edit/:id',
                        name: 'EventEdit',
                        component: EventEdit,
                    },
                    {
                        path: 'details/:id',
                        name: 'EventDetail',
                        component: EventDetail,
                    },
                    {
                        path: 'list',
                        name: 'EventList',
                        component: EventList,
                        children: [
                            {
                                path: 'all',
                                name: 'EventListAll',
                                component: EventListAll,
                                children: [
                                    {
                                        path: 'page/:page/items/:items',
                                        name: 'EventListAllPages',
                                        component: EventListAll,
                                    },
                                ]
                            },
                            {
                                path: 'my',
                                name: 'EventListMy',
                                component: EventListMy,
                                children: [
                                    {
                                        path: 'page/:page/items/:items',
                                        name: 'EventListMyPages',
                                        component: EventListMy,
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        path: 'bin',
                        name: 'EventBin',
                        component: EventBin,
                        children: [
                            {
                                path: 'page/:page/items/:items',
                                name: 'EventBinPages',
                                component: EventBin,
                            },
                        ]
                    },
                    {
                        path: 'setting',
                        name: 'EventSetting',
                        component: EventSetting,
                        children: [
                            {
                                path: 'create',
                                name: 'EventSettingCreate',
                                component: EventSettingCreate,
                            },
                            {
                                path: 'edit/:id',
                                name: 'EventSettingEdit',
                                component: EventSettingEdit,
                            },
                            {
                                path: 'details/:id',
                                name: 'EventSettingDetail',
                                component: EventSettingDetail,
                            },
                            {
                                path: 'list',
                                name: 'EventSettingList',
                                component: EventSettingList,
                                children: [

                                    {
                                        path: 'page/:page/items/:items',
                                        name: 'EventSettingListPages',
                                        component: EventSettingList,
                                    }

                                ]
                            },
                            {
                                path: 'bin',
                                name: 'EventSettingBin',
                                component: EventSettingBin,
                                children: [
                                    {
                                        path: 'page/:page/items/:items',
                                        name: 'EventSettingBinPages',
                                        component: EventSettingBin,
                                    },
                                ]
                            },
                        ]
                    }
                ]
            }

        ]
    },
    {
        path: '/login',
        name: 'LoginView',
        component: LoginView,
        meta: {
            requiresAuth: false,
            title: "Logowanie"
        },
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
        meta: {
            requiresAuth: false,
            title: "Rejestracja"
        },
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: {
            requiresAuth: false,
            title: "Rejestracja"
        },
    },
    {
        path: '/reset-password/:token',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
            requiresAuth: false,
            title: "Rejestracja"
        },
    },
    {
        path: '/email-confirmation/:token/:user',
        name: 'EmailConfirmation',
        component: EmailConfirmation
    },
    //live
    {
        path: '/visualisation',
        name: 'VisualisationLayout',
        component: VisualisationLayout,
        children: [
            {
                path: "event/:eventCode",
                name: "EventVisualisation",
                component: EventVisualisation,
                children: [
                    {
                        path: "live",
                        name: "EventVisualisationLive",
                        component: EventVisualisationLive,
                    }
                ]
            }
        ]
    },
    {
        path: '/interactive',
        name: 'InteractiveLayout',
        component: InteractiveLayout,
        meta: {
            requiresAuth: true,
            title: "Obsługa interaktywna wydarzenia"
        },
        children: [
            {
                path: "event/:eventCode",
                name: "EventInteractive",
                component: EventInteractive,
                
            }
        ]
    },
    {
        path: '/participant',
        name: 'ParticipantLayout',
        component: ParticipantLayout,
        meta: {
            requiresAuth: true,
            title: "Wydarzenie"
        },
        children: [
            {
                path: "event/:eventCode",
                name: "EventParticipant",
                component: EventParticipant,

            }
        ]
    },
    //errors
    {
        path: '/400',
        name: 'BadRequest400',
        component: BadRequest400,
        meta: {
            requiresAuth: false,
            title: "400"
        },
    },
    {
        path: '/403',
        name: 'Forbidden403',
        component: Forbidden403,
        meta: {
            requiresAuth: false,
            title: "403"
        },
    },
    {
        path: '/404',
        name: 'NotFound404',
        component: NotFound404,
        meta: {
            requiresAuth: false,
            title: "404"
        },
    },
    {
        path: '/500',
        name: 'InternalServerError500',
        component: InternalServerError500,
        meta: {
            requiresAuth: false,
            title: "500"
        },
    },
    {
        path: '/503',
        name: 'ServiceUnavailable503',
        component: ServiceUnavailable503,
        meta: {
            requiresAuth: false,
            title: "503"
        },
    },
    { path: "/:catchAll(.*)", redirect: '/404' },

]
const router = createRouter({
    //mode: 'history',
    history: createWebHashHistory(),
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = "Quizowanie"


    let redirect;

    if (to.meta.title)
        document.title = to.meta.title

    if (to.meta.requiresAuth) {
        if (store.state.auth.status.loggedIn) {
            if (to.path == '/') {
                redirect = '/Dashboard'
            }
        }
        else {
            redirect = '/Login'
        }

    }
    else {
        if (to.name == "LoginView") {
            if (store.state.auth.status.loggedIn) {
                redirect = '/Dashboard'
            }

        }
    }

    next(redirect)

});


export default router