<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Recent Payments Queue</h4>
                    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalItemCreate"><i>+</i> {{ $t('com.global.add') }}</button>
                </div>
                <div class="card-body">
                    <TableSearcher :searchInputLabel="tableSearcher.searchInputLabel"
                                   :searchInputPlaceholder="tableSearcher.searchInputPlaceholder"
                                   :minChars="3"
                                   @search-request="searchRequest"
                                   @search-clear="FetchRerouces(getCurrentPage(), getCurrentItemsOnPage())"
                    />
                    <div class="row">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table table-responsive-md">
                                    <thead>
                                        <tr>
                                            <th style="width:80px;"><strong>#</strong></th>
                                            <th><strong>{{ $t('com.resource.name') }}</strong></th>
                                            <th><strong>{{ $t('com.resource.friendlyname') }}</strong></th>
                                            <th><strong>{{ $t('com.resource.description') }}</strong></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(data, dataKey) in datas" :key="dataKey">
                                            <td><strong>{{ getIndex(dataKey) }}</strong></td>
                                            <td>{{ data.name }}</td>
                                            <td>{{ data.friendlyName }}</td>
                                            <td>{{ data.description }}</td>
                                            <td>
                                                <div class="dropdown">
                                                    <button type="button" class="btn btn-primary light sharp" data-bs-toggle="dropdown">
                                                        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <rect x="0" y="0" width="24" height="24" />
                                                                <circle fill="#000000" cx="5" cy="12" r="2" />
                                                                <circle fill="#000000" cx="12" cy="12" r="2" />
                                                                <circle fill="#000000" cx="19" cy="12" r="2" />
                                                            </g>
                                                        </svg>
                                                    </button>
                                                    <div class="dropdown-menu">
                                                        <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modalItemEdit" @click="setItem(data)">{{ $t('com.global.edit') }}</a>
                                                        <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modalItemDelete" @click="setItem(data)">{{ $t('com.global.delete') }}</a>
                                                        <router-link :to="{ name: 'ResourceDetails', params: { id: data.id } }" class="dropdown-item">{{ $t('com.global.details') }}</router-link>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <ElementLoader v-if="loading" :type="tableLoader.type" :color="tableLoader.color" :size="tableLoader.size" />
                    </div>

                    <div class="row mt-3">
                        <Pagination :pages="pages" @page-request="pageRequest" />
                    </div>

                </div>
            </div>
        </div>
    </div>

    <ModalCreate @reload-items="FetchRerouces(getCurrentPage(), getCurrentItemsOnPage())" />
    <ModalEdit :selectedItem="selectedItem" 
               @unset-item="unsetItem()" 
               @reload-items="FetchRerouces(getCurrentPage(), getCurrentItemsOnPage())" />
    <ModalDelete :selectedItem="selectedItem" 
                 @unset-item="unsetItem()" 
                 @reload-items="FetchRerouces(getCurrentPage(), getCurrentItemsOnPage())" />

</template>

<script charset="utf-8">
    import Pagination from "@/components/common/Pagination.vue"
    import ModalEdit from "@/components/resource/ModalEdit.vue";
    import ModalCreate from "@/components/resource/ModalCreate.vue";
    import ModalDelete from "@/components/resource/ModalDelete.vue";
    import TableSearcher from '@/components/common/TableSearcher.vue';
    import ElementLoader from '@/components/common/ElementLoader.vue';

    import I18nService from '@/services/i18n.service'

    export default {
        components: {
            Pagination,
            ModalEdit,
            ModalCreate,
            ModalDelete,
            TableSearcher,
            ElementLoader
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "com.resource",
                subTitle: "com.resource.subtitle",
                datas: [],
                pages: {},
                selectedItem: {},
                searchValue: "",
                tableSearcher: {
                    searchInputLabel: this.$t("com.resource.searchbysystemname"),
                    searchInputPlaceholder: this.$t("com.resource.searchbysystemname"),
                },
                tableLoader: {
                    type: "spinner-border",
                    color: "text-primary",
                    size: "big"
                },

                loading: false,
            };
        },
        computed: {

        },
        watch: {
            '$route'() {
                this.FetchRerouces(this.getCurrentPage(), this.getCurrentItemsOnPage());
            }
        },
        mounted() {
            this.FetchRerouces();
        },
        methods: {
            async FetchRerouces(page = 1, items = 10) {

                try {
                    this.loading = true;
                    const req = {
                        PageNumber: page,
                        ItemsOnPage: items,
                    }

                    let res = (await I18nService.GetResources(req));

                    if (res.status == 200) {
                        this.datas = res.data.items;
                        this.pages = res.data.page;
                    }
                }
                catch (e) {
                    console.log("fetch err", e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }


            },


            async searchRequest(data) {

                try {
                    this.loading = true;

                    const req = {
                        PageNumber: this.getCurrentPage(),
                        ItemsOnPage: this.getCurrentItemsOnPage(),
                        SearchName: data
                    };

                    let res = (await I18nService.SearchByName(req));

                    if (res.status == 200) {
                        this.datas = res.data.items;
                        this.pages = res.data.page;
                    }

                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },

            pageRequest(page) {
                console.log(page)
                this.$router.push(
                    {
                        name: 'ResourcesListPaged',
                        params: {
                            page: page,
                            items: 10
                        }
                    })
            },
            setItem(item) {
                this.selectedItem = item
            },
            unsetItem() {
                this.selectedItem = {}
            },
            getCurrentPage() {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;
                return page;
            },
            getCurrentItemsOnPage() {
                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 10;
                if (itemsPerPage == 0)
                    itemsPerPage = 10;
                return itemsPerPage
            },
            getIndex(index) {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;

                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 0;

                return ((page - 1) * itemsPerPage) + index + 1
            },
        }
    }</script>

<style>
</style>
