<template>
    <div class="row mb-3">
        <div class="col-6">
            <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
        </div>
        <div class="col-6">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <QuizNavigation />
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ $t(subTitle) }}</h4>
                </div>
                <div class="card-body">
                    <form method="post" class="needs-valivation" novalidate="novalidate" autocomplete="off" @submit.prevent="createVote()">
                        <div class="row mb-3">
                            <div class="col-12">
                                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <button type="submit" class="btn btn-primary">{{ $t('com.global.save') }}</button>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-6">
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <label class="form-label">
                                            <strong>{{ $t('com.quiz.vote.name') }}</strong>
                                        </label>
                                        <input type="text" class="form-control" :placeholder="$t('com.quiz.vote.name')" v-model="vote.name" />
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <label class="form-label">
                                            <strong>{{ $t('com.quiz.vote.description') }}</strong>
                                        </label>
                                        <input type="text" class="form-control" :placeholder="$t('com.quiz.vote.description')" v-model="vote.description" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <label class="form-label">
                                            <strong>{{ $t('com.quiz.vote.vote.block.name') }}</strong>
                                        </label>
                                        <VueMultiselect v-model="vote.voteBlock"
                                                        :options="availableVoteBlocks"
                                                        :searchable="true"
                                                        :placeholder="$t('com.quiz.vote.vote.block.name')"
                                                        :multiple="false"
                                                        label="name"
                                                        track-by="id"
                                                        :disabled="loading.global"
                                                        :showLabels="false"
                                                        :allow-empty="false" />
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.quiz.vote.type') }}</strong></label>
                                    <VueMultiselect v-model="vote.type"
                                                    :options="voteTypes"
                                                    :searchable="true"
                                                    :placeholder="$t('com.quiz.vote.type')"
                                                    :multiple="false"
                                                    :custom-label="translatedType"
                                                    track-by="id"
                                                    :disabled="loading.types"
                                                    :showLabels="false"
                                                    :allow-empty="false" />
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-12">
                                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <button type="submit" class="btn btn-primary">{{ $t('com.global.save') }}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

</template>

<script charset="utf-8">
    import PageTitle from "@/components/common/PageTitle.vue";
    import QuizNavigation from "@/components/quiz/QuizNavigation.vue";
    import VueMultiselect from 'vue-multiselect'

    import QuizVoteService from "@/services/quiz/quiz.vote.service"
    import QuizVoteBlockService from "@/services/quiz/quiz.voteblock.service"
    import QuizVoteTypeSertvice from "@/services/quiz/quiz.vote.type.service";

    export default {
        components: {
            PageTitle,
            QuizNavigation,
            VueMultiselect
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "Quiz My",
                subTitle: "com.quiz.vote.create",
                loading: {
                    global: false,
                    type: false,
                    voteBlocks: false
                },

                vote: {
                    name: "",
                    description: "",
                    voteBlock: {},
                    type: {},
                },
                availableVoteBlocks: [],

                voteTypes: [],

                errorModalEdit: {
                    isShow: false,
                    header: "test",
                    message: "",
                },

                selectedQuestion: {},
                deletedItem: "",
                restoreItem: "",
                closeModal: false,
                closeDeleteModal: false,
            };
        },
        computed: {

        },
        watch: {

        },
        mounted() {
            this.fetchAvailableVoteBlock();
            this.fetchVoteType();
        },
        methods: {
            async createVote() {
                try {
                    this.loading.global = true;

                    const req = {
                        Name: this.vote.name,
                        Description: this.vote.description,
                        VoteBlockID: this.vote.voteBlock.id,
                        TypeID: this.vote.type.id,
                    };

                    let res = (await QuizVoteService.CreateWithoutCustomer(req))

                    if (res.status == 200) {
                        this.$toast.success(this.$t('com.quiz.vote.create.success'));
                        this.$router.push({
                            name: "QuizEdit",
                            params: {
                                id: res.data
                            }
                        });
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.global = false;
                }
            },
            async fetchAvailableVoteBlock() {
                try {
                    this.loading.voteBlocks = true;

                    let res = (await QuizVoteBlockService.GetAvailableVoteBlockByVote(-1))

                    if (res.status == 200) {
                        this.availableVoteBlocks = res.data;
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.voteBlocks = false;

                }
            },

            async fetchVoteType() {
                try {
                    this.loading.type = true;

                    let res = (await QuizVoteTypeSertvice.GetAll())

                    if (res.status == 200) {
                        this.voteTypes = res.data;
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.type = false;

                }
            },

            translatedType(obj) {

                if (Object.keys(obj).length === 0)
                    return "Select"

                return this.$t(obj.name);
            },
        }
    }</script>

<style>
</style>
