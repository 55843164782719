<template>
    <div id="eventVoteBlockUpdate" class="modal fade" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <form method="post" class="needs-valivation" novalidate="" autocomplete="off" @submit.prevent="eventVoteBlockUpdateMethod">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ $t('com.event.voteblock.add') }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-3">
                            <label class="form-label">
                                <strong>{{ $t('com.vote.block.name') }}</strong>
                            </label>
                            <input type="text" class="form-control" :placeholder="$t('com.vote.block.name')" v-model="voteBlock.name" />
                        </div>
                        <div class="row mb-3">
                            <label class="form-label">
                                <strong>{{ $t('com.vote.block.description') }}</strong>
                            </label>
                            <input type="text" class="form-control" :placeholder="$t('com.vote.block.description')" v-model="voteBlock.description" />
                        </div>
                        <div class="row mb-3">
                            <div class="col-12">
                                <label class="form-label"><strong>{{ $t('com.quiz.vote.block.type') }}</strong></label>
                                <VueMultiselect v-model="voteBlock.voteBlockType"
                                                :options="voteBlockTypes"
                                                :searchable="true"
                                                :placeholder="$t('com.quiz.vote.block.type')"
                                                :multiple="false"
                                                :custom-label="translatedType"
                                                track-by="id"
                                                :disabled="loading.types"
                                                :showLabels="false"
                                                :allow-empty="false" />
                            </div>
                        </div>
                        <div class="row">
                            <pre lang="text/json">{{ voteBlock }}</pre>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button id="eventVoteBlockUpdateEmit" type="button" class="btn btn-danger light" ref="closeEventVoteBlockUpdate" data-bs-dismiss="modal" @click="unsetItem()">
                            {{ $t('com.global.close') }}
                        </button>
                        <button type="submit" class="btn btn-primary">
                            {{ $t('com.global.save') }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script charset="utf-8">
    import VueMultiselect from 'vue-multiselect'

    import QuizVoteBlockService from "@/services/quiz/quiz.voteblock.service";
    import QuizVoteBlockTypeService from "@/services/quiz/quiz.voteblock.type.service";
    export default {
        components: {
            VueMultiselect
        },
        props: {
           

            eventID: {
                type: Number,
                require: true,
            },
            voteBlockItem: {
                type: Object,
                require: true,
            },
            closeModal: {
                type: Boolean,
                require: true,
            }

        },
        emits: ["reload-event-voteblocks"],
        data() {
            return {
                publicPath: process.env.BASE_URL,

                voteBlock: {
                    name: "",
                    description: "",
                    voteBlockType: {},
                },

                voteBlockTypes: [],

                loading: false,
            };
        },
        computed: {

        },
        watch: {
            voteBlockItem: {
                immediate: true,
                handler(val) {
                    this.voteBlock.name = val.name;
                    this.voteBlock.description = val.description;
                    this.voteBlock.voteBlockType = val.voteBlockType;
                }
            },
            
        },
        mounted() {
            this.fetchVoteBlockTypes();
        },
        methods: {


            async eventVoteBlockUpdateMethod() {
                try {
                    this.loading = true;

                    const req = {
                        Id: this.voteBlockItem.id,
                        Name: this.voteBlock.name,
                        Description: this.voteBlock.description,
                        EventID: this.eventID,
                        TypeID: this.voteBlock.voteBlockType.id,
                    };

                    console.log(req);

                    let res = (await QuizVoteBlockService.UpdateWithoutCustomer(req))

                    if (res.status == 200) {
                        this.$refs.closeEventVoteBlockUpdate.click();
                        this.$emit("reload-event-voteblocks");
                        this.$toast.success(this.$t("com.vote.block.updatesuccess"));
                    }

                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },

            async fetchVoteBlockTypes() {
                try {

                    let res = (await QuizVoteBlockTypeService.GetAll())

                    if (res.status == 200) {
                        this.voteBlockTypes = res.data;

                        this.voteBlock.voteBlockType = this.voteBlockTypes[0]
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
            },

            translatedType(obj) {

                if (Object.keys(obj).length === 0)
                    return "Select"

                return this.$t(obj.name);
            },

            clearFormData() {
                this.voteBlock.name = "";
                this.voteBlock.description = ""
            }
        }
    }
</script>

<style>
</style>