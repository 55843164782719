<template>
    <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Recent Payments Queue</h4>
                    <QuizCategoryButtonNavigation />
                </div>
                <div class="card-body">
                    <table class="table table-responsive-md">
                        <thead>
                            <tr>
                                <th style="width:80px;"><strong>#</strong></th>
                                <th><strong>{{ $t('com.quiz.question.category.name') }}</strong></th>
                                <th><strong>{{ $t('com.quiz.question.category.description') }}</strong></th>
                                <th><strong>{{ $t('com.quiz.question.category.questioncount') }}</strong></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(category, index) in categories" :key="index">
                                <td><strong>{{ getIndex(index) }}</strong></td>
                                <td>{{ category.name }}</td>
                                <td>{{ category.description }}</td>
                                <td>{{ category.questionsCount }}</td>
                                <td>
                                    <div class="dropdown">
                                        <button :id="getDropdownID(index)" type="button" class="btn btn-primary light sharp" data-bs-toggle="dropdown" aria-expanded="false">
                                            <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24" />
                                                    <circle fill="#000000" cx="5" cy="12" r="2" />
                                                    <circle fill="#000000" cx="12" cy="12" r="2" />
                                                    <circle fill="#000000" cx="19" cy="12" r="2" />
                                                </g>
                                            </svg>
                                        </button>
                                        <div class="dropdown-menu" :aria-labelledby="getDropdownID(index)">
                                            <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modalItemRestore" @click="setAppSetting(category)">{{ $t('com.global.restore') }}</a>
                                            <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modalItemDelete" @click="setAppSetting(category)">{{ $t('com.global.deletepermanently') }}</a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="row mt-3">
                        <Pagination :pages="pages" @page-request="pageRequest" />
                    </div>

                </div>
            </div>
        </div>
    </div>

    <ModalDelete :error="errorModalEdit"
                 :deletedItem="deletedItem"
                 :closeModal="closeModal"
                 @unset-item="unsetAppSetting()"
                 @confirm-delete="deleteAppSetting()"
    />

    <ModalRestore :error="errorModalEdit"
                  :restoreItem="restoreItem"
                  :closeModal="closeDeleteModal"
                  @unset-item="unsetAppSetting()"
                  @confirm-restore="confirmRestore()"
    />
</template>

<script charset="utf-8">
    //import PageTitle from "../../../components/common/PageTitle.vue";
    import PageTitle from "@/components/common/PageTitle.vue";
    //import QuizCategoryButtonNavigation from "../../../components/quiz/category/QuizCategoryButtonNavigation.vue";
    import QuizCategoryButtonNavigation from "@/components/quiz/category/QuizCategoryButtonNavigation.vue";
    //import Pagination from "../../../components/common/Pagination.vue"
    import Pagination from "@/components/common/Pagination.vue"
    //import ModalDelete from "../../../components/common/modal/ModalDelete.vue"
    import ModalDelete from "@/components/common/modal/ModalDelete.vue"
    //import ModalRestore from "../../../components/common/modal/ModalRestore.vue"
    import ModalRestore from "@/components/common/modal/ModalRestore.vue"
    //import QuizQuestionCategoryService from "../../../services/quiz/quiz.question.category.service"
    import QuizQuestionCategoryService from "@/services/quiz/quiz.question.category.service"
    export default {
        components: {
            PageTitle,
            QuizCategoryButtonNavigation,
            Pagination,
            ModalDelete,
            ModalRestore
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "Quiz Question Category List",
                subTitle: "com.quiz.question.category",
                categories: [],
                appSettingKeys: [],
                selectedAppSetting: {},
                pages: {},
                errorModalEdit: {
                    isShow: false,
                    header: "test",
                    message: "",
                },
                deletedItem: "",
                restoreItem: "",
                closeModal: false,
                closeDeleteModal: false,
                loading: false,
            };
        },
        computed: {

        },
        watch: {

        },
        mounted() {
            //this.fetchAppSettingKeys(this.getCurrentPage(), this.getCurrentItemsOnPage());
            this.fetchQuestionCategories(this.getCurrentPage(), this.getCurrentItemsOnPage());
        },
        methods: {
            async fetchQuestionCategories(page = 1, items = 10) {
                try {
                    const req = {
                        PageNumber: page,
                        ItemsOnPage: items,
                    }
                    let res = (await QuizQuestionCategoryService.GetBin(req));
                    console.log(res);
                    if (res.status == 200) {
                        this.categories = res.data.items;
                        this.pages = res.data.page;
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
            },

            async deleteAppSetting() {
                try {
                    let response = (await QuizQuestionCategoryService.DeletePermanently(this.selectedAppSetting.id))
                    if (response.status == 200) {
                        this.fetchQuestionCategories(this.getCurrentPage(), this.getCurrentItemsOnPage());
                        this.closeModal = true;
                        this.$toast.success(this.$t("com.quiz.category.successdelete"));
                    }
                }
                catch (e) {
                    //console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }

                }
            },
            async confirmRestore() {
                try {
                    let response = (await QuizQuestionCategoryService.Restore(this.selectedAppSetting.id))
                    if (response.status == 200) {
                        this.fetchQuestionCategories(this.getCurrentPage(), this.getCurrentItemsOnPage());
                        this.closeDeleteModal = true;
                        this.$toast.success(this.$t("com.quiz.category.successrestore"));
                    }
                }
                catch (e) {
                    console.log("Błas", e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }

                }
            },
            getCurrentPage() {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;
                return page;
            },
            getCurrentItemsOnPage() {
                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 10;
                if (itemsPerPage == 0)
                    itemsPerPage = 10;
                return itemsPerPage
            },
            getIndex(index) {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;

                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 0;

                return ((page - 1) * itemsPerPage) + index + 1
            },
            pageRequest(page) {
                this.$router.push(
                    {
                        name: 'QuizQuestionCategoryBinPages',
                        params: {
                            page: page,
                            items: this.getCurrentItemsOnPage()
                        }
                    })
            },

            getDropdownID(str) {
                return "dropdownButton" + str;
            },
            setAppSetting(appSetting) {
                this.closeModal = false;
                this.selectedAppSetting = appSetting;
            },
            unsetAppSetting() {
                this.selectedAppSetting = {}
            },
        }
    }</script>

<style>
</style>
