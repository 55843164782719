<template>
    <div class="row page-titles mx-0">
        <div class="col-sm-6 p-md-0">
            <div class="welcome-text">
                <h4>{{ $t(mainTitle) }}</h4>
                <span>{{ $t(subTitle) }}</span>
            </div>
        </div>
        <!--<div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Table</a></li>
                <li class="breadcrumb-item active"><a href="javascript:void(0)">Datatable</a></li>
            </ol>
        </div>-->
    </div>
</template>

<script charset="utf-8">export default {
        components: {
        },
        props: {
            mainTitle: {
                type: String,
                required: true
            },
            subTitle: {
                type: String,
                required: false
            },
        },
        data() {
            return {
                publicPath: process.env.BASE_URL
            };
        },
        computed: {

        },
        methods: {
        }
    }</script>

<style>
</style>
