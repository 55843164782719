<template>
    <div class="btn-group">
        <!--<router-link :to="{ name: 'EventBin' }" type="button" class="btn btn-primary">
        {{ $t("com.global.bin") }}
    </router-link>-->
        <router-link :to="{ name: 'UsersList' }" type="button" class="btn btn-primary">
            {{ $t("com.global.list") }}
        </router-link>

        <router-link v-if="isSuperAdmin" :to="{name: 'UserAdminCreate'}" type="button" class="btn btn-primary">
            {{ $t('com.global.add') }}
        </router-link>
        <router-link v-else :to="{name: 'UserCreate'}" type="button" class="btn btn-primary">
            {{ $t('com.global.add') }}
        </router-link>
    </div>


</template>

<script charset="utf-8">export default {
        components: {
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
            };
        },
        computed: {
            isSuperAdmin() {
                return this.$store.state.auth.user.isUserIsSuperAdmin;
            },

            isAdmin() {
                return this.$store.state.auth.user.isUserIsAdmin;
            },

            isOperator() {
                return this.$store.state.auth.user.isUserIsOperator;
            }
        },
        watch: {

        },
        mounted() {

        },
        methods: {
            "vue/no-use-v-if-with-v-for": "off"
        }
    }</script>

<style>
</style>
