import api from "../api";
import url from "../url";

class QuizInteractiveService {

    //voteblock
    SetVoteBlockActive(request) {
        const req = {
            EventCode: request.EventCode,
            VoteBlockId: request.VoteBlockId,
        }

        return api.post(url.quiz.interactive.setVoteBlockActive, req)
            .then((response) => {
                return response;
            });
    }

    SetVoteBlockDeactive(request) {
        const req = {
            EventCode: request.EventCode,
            VoteBlockId: request.VoteBlockId,
        }

        return api.post(url.quiz.interactive.setVoteBlockDeactive, req)
            .then((response) => {
                return response;
            });
    }


    GetCurrentActiveVoteBlock(eventCode) {
        return api.get(url.quiz.interactive.getCurrentActiveVoteBlock + "/" + eventCode)
            .then((res) => {
                return res;
            })
    }
    //vote
    SetVoteActive(request) {
        const req = {
            EventCode: request.EventCode,
            VoteId: request.VoteId,
        }

        return api.post(url.quiz.interactive.setVoteActive, req)
            .then((response) => {
                return response;
            });
    }

    setVoteDeactive(request) {
        const req = {
            EventCode: request.EventCode,
            VoteId: request.VoteId,
        }

        return api.post(url.quiz.interactive.setVoteDeactive, req)
            .then((response) => {
                return response;
            });
    }


    GetCurrentActiveVote(eventCode) {
        return api.get(url.quiz.interactive.getCurrentActiveVote + "/" + eventCode)
            .then((res) => {
                return res;
            })
    }


    //question
    SetQuestionActive(request) {
        const req = {
            EventCode: request.EventCode,
            QuestionID: request.QuestionID,
        }

        return api.post(url.quiz.interactive.setQuestionActive, req)
            .then((response) => {
                return response;
            });
    }

    SetQuestionDeactive(request) {
        const req = {
            EventCode: request.EventCode,
            QuestionID: request.QuestionID,
        }

        return api.post(url.quiz.interactive.setQuestionDeactive, req)
            .then((response) => {
                return response;
            });
    }

    GetCurrentActiveQuestion(eventCode) {
        return api.get(url.quiz.interactive.getCurrentActiveQuestion + "/" + eventCode)
            .then((res) => {
                return res;
            })
    }

    GetCurrentActiveQuestionByUser(eventCode) {
        return api.get(url.quiz.interactive.getCurrentActiveQuestionByUser + "/" + eventCode)
            .then((res) => {
                return res;
            })
    }

    GetCurrentUserStatusByQuestion(request) {
        return api.post(url.quiz.interactive.getCurrentUserStatusByQuestion, request)
            .then((res) => {
                return res;
            })
    }

}

export default new QuizInteractiveService;
