<template>
    <!-- Modal Add Translation -->
    <form method="POST" class="needs-validation" novalidate="" autocomplete="off" @submit.prevent="AddTranslation">
        <div id="modalAddTranslation" class="modal fade" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">com.resource.addtranslation</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3 row">
                            <ErrorModalMessage v-if="error.isShow" :error="error" />
                        </div>
                        <div class="mb-3 row">
                            <label class="col-sm-3 col-form-label">Name</label>
                            <div class="col-sm-9">
                                <select v-model="form.languageID" class="form-control">
                                    <option v-for="language in languages" :key="language.id" :value="language.id">{{ language.name }} ({{ language.culture }})</option>
                                </select>
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <label class="col-sm-3 col-form-label">Translation</label>
                            <div class="col-sm-9">
                                <input v-model="form.translation" type="text" class="form-control" placeholder="Translation" />
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button id="createTranslationModalEmit" type="button" class="btn btn-danger light" data-bs-dismiss="modal">No</button>
                        <button type="submit" class="btn btn-primary">com.global.yes</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <!-- /Modal Add Translation -->
</template>

<script charset="utf-8">
    import ErrorModalMessage from "../common/error/ErrorModalMessage";
    export default {
        components: {
            ErrorModalMessage
        },
        props: {
            languages: {
                type: Object,
                require: true
            },
            resource: {
                type: Object,
                require: true
            }
        },
        emits: ["reload-items"],
        data() {
            return {
                publicPath: process.env.BASE_URL,
                error: {
                    isShow: false,
                    header: "test",
                    message: "",
                },
                form: {
                    languageID: 0,
                    resourceID: 0,
                    translation: "",
                }
            };
        },
        computed: {

        },
        methods: {
            async AddTranslation() {
                this.error.isShow = false;
                console.log(this.resource)
                console.log(this.languages)
                this.form.resourceID = this.resource.id;

                console.log(this.form);

                try {
                    this.$store.dispatch("i18n/createTranslation", this.form).then(
                        () => {
                            this.reloadItems();
                            document.getElementById('createTranslationModalEmit').click();
                        },
                        (error) => {
                            this.error.isShow = true;
                            this.error.header = "header";
                            this.error.message = error.data.translatedMessage;
                        }
                    );
                }
                catch (err) {
                   console.log(err)
                }
            },
            //unsetItem() {
            //    this.$emit("unset-item")
            //},
            reloadItems() {
                this.$emit("reload-items")
            }
        }
    }</script>

<style>
</style>
