<template>
    <router-view />
</template>

<script charset="utf-8">export default {
        components: {
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "Quiz Question Category",
                subTitle: "com.quiz.category",
            };
        },
        computed: {

        },
        watch: {

        },
        mounted() {

        },
        methods: {

        }
    }</script>

<style>
</style>
