<template>
    <div :id="fileManageModalID" class="modal fade" aria-hidden="true" role="dialog">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('com.filemanager') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-8">
                            <h4>Lista zdjec</h4>
                            <div id="lightgallery" class="row">
                                <a v-for="(image, index) in myFiles" :key="index" data-exthumbimage="image.relativePath" data-src="image.relativePath" class="col-lg-3 col-md-6 mb-4" @click.stop="selectedFile(image)">
                                    <img :src="image.relativePath" :alt="image.caption" class="img-thumbnail" />
                                </a>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <div class="col-md-12">
                                    <form method="POST" class="needs-validation" novalidate="" autocomplete="off" enctype="multipart/form-data" @submit.prevent="addAnswer">
                                        <div class="row mb-3">
                                            <div class="mb-3">
                                                <label for="formFile" class="form-label">Default file input example</label>
                                                <input id="formFile" class="form-control" name="file" type="file" multiple :disabled="saving" @change="onFileChange" />
                                            </div>
                                           
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-12 centered">
                                            <img :src="selectedItem.relativePath" :alt="selectedItem.caption" class="img-thumbnail" />
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-12">
                                            <button class="btn btn-primary btn-sm" @click="deleteFile(selectedItem.id)">{{ $t('com.global.delete') }}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="modalSelectedImage" type="button" class="btn btn-danger light" data-bs-dismiss="modal">{{ $t('com.global.close') }}</button>
                    <button type="button" class="btn btn-primary" @click="setImage">{{ $t('com.global.select') }}</button>
                </div>
            </div>
        </div>
    </div>

</template>

<script charset="utf-8">
    import FileManagerService from "../../services/file/file.service"
    export default {
        components: {
        },
        props: {
            fileManageModalID: {
                type: String,
                require: true,
            }
        },
        emits: ["selected-image", "deleted-file"],
        data() {
            return {
                publicPath: process.env.BASE_URL,
                data: new FormData(),

                myFiles: [],

                selectedItem: {},

                loading: false,
                uploadLoading: false,
            };
        },
        computed: {

        },
        watch: {

        },
        mounted() {
            this.getMyFiles();
        },
        methods: {
            async onFileChange(e) {
                try {
                    this.uploadLoading = true;
                    console.log(e.target.files);
                    this.data = new FormData();
                    this.data.append("File", e.target.files[0]);
                    this.data.append("Caption", e.target.files[0].name);
                    console.log(this.data);

                    let res = (await FileManagerService.Upload(this.data));
                    if (res.status == 200) {
                        this.getMyFiles();
                    }
                    console.log(res);
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.uploadLoading = false;
                }


            },

            async getMyFiles() {
                try {
                    this.loading = true;
                    let res = (await FileManagerService.ShowMyFiles());

                    if (res.status == 200) {
                        this.myFiles = res.data;
                        console.log("Pliki: {0}", res);
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },
            async deleteFile(fileID) {
                try {
                    this.loading = true;

                    if (fileID == this.selectedItem.id) {
                        let res = (await FileManagerService.Delete(fileID))

                        if (res.status == 200) {
                            this.$emit("deleted-file");
                            this.selectedItem = {};
                            this.getMyFiles();
                        }
                    }


                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },
            selectedFile(item) {
                this.selectedItem = item
            },

            setImage() {
                if (Object.entries(this.selectedItem).length > 0) {
                    this.$emit("selected-image", this.selectedItem);
                    this.selectedItem = {};
                    document.getElementById('modalSelectedImage').click();
                    
                }
                else {
                    alert(this.$t("com.file.pleaseselectimage"));
                }
            }
        }
    }</script>

<style>
</style>
