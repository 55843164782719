<template>
    <!-- Modal Edit-->
    <form method="POST" class="needs-validation" novalidate="" autocomplete="off" @submit.prevent="UpdateItem">
        <div id="modalItemEdit" class="modal fade" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ $t('com.country.edit') }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" @click="unsetItem()">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3 row">
                            <ErrorModalMessage v-if="error.isShow" :error="error" />
                        </div>
                        <div class="mb-3 row">
                            <label class="col-sm-3 col-form-label">{{ $t('com.country.name') }}</label>
                            <div class="col-sm-9">
                                <input v-model="editItem.name" type="text" class="form-control" :placeholder="$t('com.country.name')" />
                            </div>
                        </div>
                       
                    </div>
                    <div class="modal-footer">
                        <button id="editModalEmit" type="button" class="btn btn-danger light" data-bs-dismiss="modal" @click="unsetItem()">{{ $t('com.global.close') }}</button>
                        <button type="submit" class="btn btn-primary">{{ $t('com.global.save') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <!-- /Modal Edit -->
</template>

<script charset="utf-8">
    import ErrorModalMessage from "../common/error/ErrorModalMessage";
    export default {
        components: {
            ErrorModalMessage
        },
        props: {
            selectedItem: {
                type: Object,
                require: true
            }
        },
        emits: ["unset-item", "reload-items"],
        data() {
            return {
                publicPath: process.env.BASE_URL,
                editItem: {
                    id: 0,
                    name: "",
                },
                error: {
                    isShow: false,
                    header: "test",
                    message: "",
                }
            };
        },

        computed: {

        },
        watch: {
            selectedItem: {
                // the callback will be called immediately after the start of the observation
                immediate: true,
                handler(val, oldVal) {
                    if(val != oldVal)
                        this.editItem = val
                }
            }
        },
        methods: {
            async UpdateItem() {
                this.error.isShow = false;
                try {
                    const req = {
                        id: this.selectedItem.id,
                        name: this.editItem.name,
                    }
                    console.log(this.selectedItem, req)
                    this.$store.dispatch("i18n/updateCountry", req).then(
                        () => {
                            this.reloadItems();
                            document.getElementById('editModalEmit').click();
                        },
                        (error) => {
                            this.error.isShow = true;
                            this.error.header = "header";
                            this.error.message = error.data.translatedMessage;
                        }
                    );
                }
                catch (err) {
                    console.log(err)
                }
            },
            unsetItem() {
                this.$emit("unset-item")
            },
            reloadItems() {
                this.$emit("reload-items")
            },
            clearData() {
                this.error.isShow = false;
                this.error.header = "";
                this.error.message = ""
            }
        }
    }</script>

<style>
</style>
