import api from "../api";
import url from "../url";

class QuizVoteBlockService {
    GetAll(Pages) {
        return api.get(url.quiz.voteBlock.getAll, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage,
                EventID: Pages.EventID
            }
        })
            .then((res) => {
                return res;
            })
    }

    GetMy(Pages) {
        return api.get(url.quiz.voteBlock.getMyAll, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage,
                EventID: Pages.EventID
            }
        })
            .then((res) => {
                return res;
            })
    }

    GetById(VoteBlockID) {
        return api.get(url.quiz.voteBlock.getById + "/" + VoteBlockID)
            .then((res) => {
                return res;
            })
    }

    GetAvailableVoteBlockByVote(VoteID) {
        return api.get(url.quiz.voteBlock.getAvailableVoteBlockByVote + "/" + VoteID)
            .then((res) => {
                return res;
            })
    }

    Create(request) {
        const req = {
            Name: request.Name,
            Description: request.Description,
            EventID: request.EventID,
            TypeID: request.TypeID,
            CustomerID: request.CustomerID,
        }

        return api.post(url.quiz.voteBlock.create, req)
            .then((response) => {
                return response;
            });
    }

    CreateWithoutCustomer(request) {
        const req = {
            Name: request.Name,
            Description: request.Description,
            TypeID: request.TypeID,
            EventID: request.EventID,
        }

        return api.post(url.quiz.voteBlock.createWithoutCustomer, req)
            .then((response) => {
                return response;
            });
    }

    Update(request) {
        const req = {
            Id: request.Id,
            Name: request.Name,
            Description: request.Description,
            EventID: request.EventID,
            TypeID: request.TypeID,
            CustomerID: request.CustomerID,
        }

        return api.post(url.quiz.voteBlock.update, req)
            .then((response) => {
                return response;
            });
    }

    UpdateWithoutCustomer(request) {
        const req = {
            Id: request.Id,
            Name: request.Name,
            Description: request.Description,
            TypeID: request.TypeID,
            EventID: request.EventID,
        }

        return api.post(url.quiz.voteBlock.updateWithoutCustomer, req)
            .then((response) => {
                return response;
            });
    }

    Delete(VoteBlockID) {
        return api.delete(url.quiz.voteBlock.delete + "/" + VoteBlockID)
            .then((res) => {
                return res;
            })
    }

    GetBin(Pages) {
        return api.get(url.quiz.voteBlock.bin, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage
            }
        })
            .then((res) => {
                return res;
            })
    }

    DeletePermanently(VoteBlockID) {
        return api.delete(url.quiz.voteBlock.deletePermanently + "/" + VoteBlockID)
            .then((res) => {
                return res;
            })
    }

    Restore(VoteBlockID) {
        return api.post(url.quiz.voteBlock.retore + "/" + VoteBlockID)
            .then((res) => {
                return res;
            })
    }
}
export default new QuizVoteBlockService;