import AuthService from '../../services/auth.service';
import TokenService from '../../services/token.service';

const user = TokenService.getUser();
const initialState = user
    ? { status: { loggedIn: true }, user }
    : { status: { loggedIn: false }, user: null };

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ commit }, user) {
            return AuthService.Login(user).then(
                user => {
                    commit('loginSuccess');
                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error.response);
                }
            );
        },
        logout({ commit }) {
            return AuthService.Logout().then(
                user => {
                    commit('logout');
                    return Promise.resolve(user)
                },
                error => {
                    return Promise.reject(error.response)
                }
            )

        },
        register({ commit }, user) {
            return AuthService.register(user).then(
                response => {
                    commit('registerSuccess');
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        },
        refreshToken({ commit }) {
            commit('refreshToken',);
        }
    },
    mutations: {
        loginSuccess(state) {
            state.status.loggedIn = true;
            //state.user = JSON.parse(localStorage.getItem('user'));;
            state.user = TokenService.getUser();
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        registerSuccess(state) {
            state.status.loggedIn = false;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
        },
        refreshToken(state) {
            state.status.loggedIn = true;
            state.user = TokenService.getUser();
        }
    }
};