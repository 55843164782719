<template>
    <div class="row mb-3">
        <div class="col-6">
            <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
        </div>
        <div class="col-6">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <SettingTypeButonNavigation />
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ $t(subTitle) }}</h4>
                </div>
                <div class="card-body">
                    <form method="post" class="needs-validation" novalidate="novalidate" @submit.prevent="updateSettingType">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="row mb-3">
                                    <label class="form-label">
                                        <strong>{{ $t('com.app.setting.type.name') }}</strong>
                                    </label>
                                    <input type="text" class="form-control" :placeholder="$t('com.app.setting.type.name')" v-model="settingTypeModel.name" />
                                </div>
                                <div class="row mb-3">
                                    <label class="form-label">
                                        <strong>{{ $t('com.app.setting.type.description') }}</strong>
                                    </label>
                                    <input type="text" class="form-control" :placeholder="$t('com.app.setting.type.description')" v-model="settingTypeModel.description" />
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.app.setting.type.provider') }}</strong></label>
                                    <VueMultiselect v-model="settingTypeModel.provider"
                                                    :options="providersList"
                                                    :searchable="true"
                                                    :placeholder="$t('com.app.setting.type.provider')"
                                                    :multiple="false"
                                                    label="name"
                                                    track-by="value"
                                                    :disabled="loading.provider || loading.global"
                                                    :showLabels="false"
                                                    :allow-empty="false" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <button type="submit" class="btn btn-primary" :disabled="loading.global">
                                            <span v-if="loading.global" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            {{ $t('com.global.save') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>

</template>

<script charset="utf-8">
    import PageTitle from "@/components/common/PageTitle.vue";
    import VueMultiselect from 'vue-multiselect'
    import SettingTypeButonNavigation from "@/components/common/settingType/SettingTypeButonNavigation.vue"

    import SettingTypeProviderService from "@/services/common/setting.type.provider.service.js"
    import SettingTypeService from "@/services/common/setting.type.service.js"

    export default {
        components: {
            PageTitle,
            SettingTypeButonNavigation,
            VueMultiselect
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "com.app.setting.type",
                subTitle: "com.app.setting.type.edit",
                loading: {
                    global: false,
                    provider: false,
                },

                settingTypeModel: {
                    id: -1,
                    name: "",
                    description: "",
                    provider: {},
                },

                providersList: [],
            };
        },
        computed: {
            settingTypeID() {
                return this.$route.params.id;
            }
        },
       
        mounted() {
            this.getSettingTypeProviders();
            this.getSettingType();
        },
        methods: {
            async getSettingTypeProviders() {
                try {
                    this.loading.provider = true;

                    let res = (await SettingTypeProviderService.GetAll());

                    if (res.status == 200) {
                        this.providersList = res.data;
                    }

                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.provider = false;
                }
            },

            async getSettingType() {
                try {
                    this.loading.global = true;

                    let res = (await SettingTypeService.GetById(this.settingTypeID))

                    if (res.status == 200) {
                        this.settingTypeModel.id = res.data.id;
                        this.settingTypeModel.name = res.data.name;
                        this.settingTypeModel.description = res.data.description;
                        if (!this.loading.provider) {
                            this.settingTypeModel.provider = this.providersList.filter(obj => { return obj.value === res.data.provider })
                        }
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.global = false;
                }
            },

            async updateSettingType() {
                try {
                    this.loading.global = true;

                    const req = {
                        id: this.settingTypeModel.id,
                        name: this.settingTypeModel.name,
                        description: this.settingTypeModel.description,
                        provider: this.settingTypeModel.provider.value,
                    }

                    let res = (await SettingTypeService.Update(req))

                    if (res.status == 200) {
                        this.$toast.success(this.$t('com.app.setting.type.update.success'));
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.global = false;
                }
            }

        }
    }</script>

<style>
</style>
