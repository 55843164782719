<template>
    <div class="vh-100">
        <div class="authincation h-100">
            <div class="container h-100">
                <div class="row justify-content-center h-100 align-items-center">
                    <div class="col-md-8">
                        <div class="authincation-content">
                            <div class="row no-gutters">
                                <div class="col-md-12">
                                    <form method="POST" class="needs-validation" novalidate="" autocomplete="off" @submit.prevent="registerUser">
                                        <div class="auth-form">
                                            <div class="row">
                                                <div class="col-xl-12">
                                                    <div class="text-center mb-3">
                                                        <img src="images/logo-full.png" alt="" />
                                                    </div>
                                                    <div class="text-center mb-3">
                                                        <h4 class="text-center mb-4">{{ $t('com.user.signup') }}</h4>
                                                    </div>
                                                    <div class="text-center mb-3">
                                                        <div class="form-group">
                                                            <ErrorModalMessage v-if="error.isShow" :error="error" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="mb-1"><strong>{{ $t('com.user.login') }}</strong></label>
                                                        <input v-model="userCreate.login" type="text" class="form-control" :class="{'is-invalid' : validation.login.isError, 'is-valid' : validation.login.isValid}" :placeholder="$t('com.user.login')" @keyup="checkLogin()" />
                                                    </div>
                                                    <div class="form-group">
                                                        <label class="mb-1"><strong>{{ $t('com.user.email') }}</strong></label>
                                                        <input v-model="userCreate.email" type="email" class="form-control" :class="{'is-invalid' : validation.email.isError, 'is-valid' : validation.email.isValid}" :placeholder="$t('com.user.email')" @keyup="checkEmail()" />
                                                    </div>
                                                    <div class="form-group">
                                                        <label class="mb-1"><strong>{{ $t('com.user.password') }}</strong></label>
                                                        <div class="input-group">
                                                            <input v-model="userCreate.password" :type="inputPasswordType" class="form-control" :class="{'is-invalid' : validation.password.isError, 'is-valid' : validation.password.isValid}" :placeholder="$t('com.user.password')" @keyup="validatePassowrd()" />
                                                            <button class="btn btn-outline-primary" type="button" @mousedown="showPassowrd()" @mouseup="hidePassword()">{{ $t('com.user.viewpassword') }}</button>
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label class="mb-1"><strong>{{ $t('com.user.passwordconfirm') }}</strong></label>

                                                        <input v-model="userCreate.passwordconfirm" :type="inputPasswordType" class="form-control" :class="{'is-invalid' : validation.password.isError, 'is-valid' : validation.password.isValid}" :placeholder="$t('com.user.passwordconfirm')" @keyup="validatePassowrd()" />
                                                        <div class="invalid-feedback">
                                                            {{ $t(validation.password.message) }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">


                                                    <div class="form-group">
                                                        <label class="mb-1"><strong>{{ $t('com.user.firstname') }}</strong></label>
                                                        <input v-model="userCreate.firstname" type="text" class="form-control" :placeholder="$t('com.user.firstname')" />
                                                    </div>
                                                    <div class="form-group">
                                                        <label class="mb-1"><strong>{{ $t('com.user.lastname') }}</strong></label>
                                                        <input v-model="userCreate.lastname" type="email" class="form-control" :placeholder="$t('com.user.lastname')" />
                                                    </div>
                                                    <div class="form-group">
                                                        <label class="mb-1"><strong>{{ $t('com.user.phone') }}</strong></label>
                                                        <input v-model="userCreate.phone" type="tel" class="form-control" :class="{'is-invalid' : validation.phone.isError, 'is-valid' : validation.phone.isValid}" :placeholder="$t('com.user.phone')" @keyup="checkPhone()" />
                                                        <div class="invalid-feedback">
                                                            {{ $t(validation.phone.message) }}
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label class="mb-1"><strong>{{ $t('com.user.language') }}</strong></label>
                                                        <VueMultiselect v-model="userCreate.language"
                                                                        :options="languages"
                                                                        :searchable="true"
                                                                        :placeholder="$t('com.user.language')"
                                                                        :multiple="false"
                                                                        label="name"
                                                                        track-by="id"
                                                                        :showLabels="false"
                                                                        :allow-empty="false"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-8 offset-md-2">
                                                    <div class="text-center mt-4">
                                                        <button type="submit" class="btn btn-primary btn-block">{{ $t('com.user.signmeup') }}</button>
                                                    </div>

                                                    <div class="new-account mt-3">
                                                        <p>{{ $t('com.user.alreadyregistered') }} <router-link :to="{ name: 'LoginView' }" class="text-primary">{{ $t('com.user.signin') }}</router-link></p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script charset="utf-8">
    import VueMultiselect from 'vue-multiselect'
    import ErrorModalMessage from "../../components/common/error/ErrorModalMessage";
    export default {
        components: {
            VueMultiselect,
            ErrorModalMessage
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                languages: [],
                inputPasswordType: 'password',
                validation: {
                    email: {
                        isError: false,
                        isValid: false,
                        message: "",
                    },
                    login: {
                        isError: false,
                        isValid: false,
                        message: "",
                    },
                    phone: {
                        isError: false,
                        isValid: false,
                        message: "",
                    },
                    password: {
                        isError: false,
                        isValid: false,
                        message: "",
                    }
                },
                userCreate: {
                    login: "",
                    email: "",
                    firstname: "",
                    passwordconfirm: "",
                    lastname: "",
                    password: "",
                    phone: "",
                    roles: [],
                    language: {},
                },
                error: {
                    isShow: false,
                    header: "test",
                    message: "",
                    errors: []
                }
            };
        },
        computed: {

        },

        mounted() {
            this.fetchLanguages();
        },
        methods: {
            async fetchLanguages(page = 1, items = 100) {
                try {
                    const req = {
                        PageNumber: page,
                        ItemsOnPage: items,
                    }
                    this.$store.dispatch("i18n/getLanguages", req).then(
                        (res) => {
                            this.languages = res.data.languageItems;
                            console.log(res);
                            console.log(this.languages);
                        },
                        (err) => {
                            console.log(err);
                        }
                    );
                }
                catch (err) {
                    console.log(err);
                }
            },
            async checkPhone() {
                this.validation.phone.isError = false;
                this.validation.phone.isValid = false;
                this.validation.phone.message = "";

                let phoneLenght = this.userCreate.phone.length
                if (phoneLenght < 9)
                    return;

                try {
                    this.$store.dispatch("user/checkPhone", this.userCreate.phone).then(
                        () => {
                            this.validation.phone.isValid = true;
                        },
                        (error) => {
                            if (error.status == 400) {
                                this.validation.phone.isError = true;
                                this.validation.phone.message = error.data.message;
                            }
                        }
                    );
                }
                catch (err) {
                    console.log(err)
                }
            },
            async checkLogin() {

                let loginLenght = this.userCreate.login.length
                if (loginLenght < 3)
                    return;

                try {
                    this.$store.dispatch("user/checkLogin", this.userCreate.login).then(
                        () => {
                            this.validation.login.isValid = true;
                        },
                        (error) => {
                            if (error.status == 400) {
                                this.validation.login.isError = true;
                                this.validation.login.message = error.data.message;
                            }
                        }
                    );
                }
                catch (err) {
                    console.log(err)
                }

            },
            async checkEmail() {
                this.validation.email.isError = false;
                this.validation.email.isValid = false;
                this.validation.email.message = "";


                if (!this.validateEmail(this.userCreate.email))
                    return;


                try {
                    this.$store.dispatch("user/checkEmail", this.userCreate.email).then(
                        () => {
                            this.validation.email.isValid = true;
                        },
                        (error) => {
                            if (error.status == 400) {
                                this.validation.email.isError = true;
                                this.validation.email.message = error.data.message;
                            }

                        }
                    );
                }
                catch (err) {
                    console.log(err)
                }
            },
            async registerUser() {
                try {
                    this.error.isShow = false;
                    this.error.message = "";
                    this.error.errors = [];
                    const req = {
                        login: this.userCreate.login,
                        password: this.userCreate.password,
                        passwordConfirm: this.userCreate.passwordconfirm,
                        firstname: this.userCreate.firstname,
                        lastname: this.userCreate.lastname,
                        email: this.userCreate.email,
                        telephone: this.userCreate.phone,
                        languageID: this.userCreate.language.id
                    }
                    console.log(req);
                    this.$store.dispatch("user/register", req).then(
                        () => {
                            this.$toast.success("com.user.registersuccess")
                            this.clearData();
                        },
                        (err) => {
                            if (err.status == 400) {
                                this.error.isShow = true;
                                this.error.message = err.data.message;
                                this.error.errors = err.data.errors
                            }

                        }
                    );

                }
                catch (err) {
                    console.log(err)
                }
            },
            validateEmail(email) {
                var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },
            showPassowrd() {
                this.inputPasswordType = 'text';
            },
            hidePassword() {
                this.inputPasswordType = 'password';
            },
            clearData() {
                this.userCreate.login = "";
                this.userCreate.email = "";
                this.userCreate.firstname = "";
                this.userCreate.passwordconfirm = "";
                this.userCreate.lastname = "";
                this.userCreate.password = "";
                this.userCreate.phone = "";
                this.userCreate.roles = [];
                this.userCreate.language = {};
                this.validation.email.isValid = false;
                this.validation.login.isValid = false;
                this.validation.password.isValid = false;
                this.validation.phone.isValid = false;
            },
            validatePassowrd() {
                this.validation.password.isValid = false;
                this.validation.password.isError = false;
                this.validation.password.message = '';

                if (this.userCreate.password === this.userCreate.passwordconfirm) {
                    this.validation.password.isValid = true;
                }
                else {
                    this.validation.password.isError = true;
                    this.validation.password.message = this.$t('com.user.passwordsdontmatch');

                }
            }
        }
    }</script>

<style>
</style>
