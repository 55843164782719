<template>
    <div class="row mb-3">
        <div class="col-6">
            <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
        </div>
        <div class="col-6">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <UserButtonNavigation />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Recent Payments Queue</h4>
                </div>
                <div class="card-body">
                    <form method="POST" class="needs-validation" novalidate="" autocomplete="off" @submit.prevent="createUser">
                        <div class="row">
                            <div class="col-md-6 offset-md-3">
                                <div class="mb-3 row">
                                    <ErrorModalMessage v-if="error.isShow" :error="error" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.user.login') }}</strong></label>
                                    <input v-model="userCreate.login" type="text" class="form-control" :class="{'is-invalid' : validation.login.isError, 'is-valid' : validation.login.isValid}" :placeholder="$t('com.user.login')" @keyup="checkLogin()" />
                                    <div class="invalid-feedback">
                                        {{ $t(validation.login.message) }}
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.user.email') }}</strong></label>
                                    <input v-model="userCreate.email" type="email" class="form-control" :class="{'is-invalid' : validation.email.isError, 'is-valid' : validation.email.isValid}" :placeholder="$t('com.user.email')" @keyup="checkEmail()" />
                                    <div class="invalid-feedback">
                                        {{ $t(validation.email.message) }}
                                    </div>
                                    <pre class="language-json"><code>{{ userCreate.email }}</code></pre>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.user.password') }}</strong></label>
                                    <div class="input-group">
                                        <input v-model="userCreate.password" :type="inputPasswordType" class="form-control" :class="{'is-invalid' : validation.password.isError, 'is-valid' : validation.password.isValid}" :placeholder="$t('com.user.password')" @keyup="validatePassowrd()" />
                                        <button class="btn btn-outline-primary" type="button" @click="generateUserPassword()">{{ $t('com.user.generatepassword') }}</button>
                                        <button class="btn btn-outline-primary" type="button" @mousedown="showPassowrd()" @mouseup="hidePassword()">{{ $t('com.user.viewpassword') }}</button>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.user.passwordconfirm') }}</strong></label>

                                    <input v-model="userCreate.passwordconfirm" :type="inputPasswordType" class="form-control" :class="{'is-invalid' : validation.password.isError, 'is-valid' : validation.password.isValid}" :placeholder="$t('com.user.passwordconfirm')" @keyup="validatePassowrd()" />
                                    <div class="invalid-feedback">
                                        {{ $t(validation.password.message) }}
                                    </div>

                                </div>
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.user.language') }}</strong></label>
                                    <VueMultiselect v-model="userCreate.language"
                                                    :options="languages"
                                                    :searchable="true"
                                                    :placeholder="$t('com.user.language')"
                                                    :multiple="false"
                                                    label="name"
                                                    track-by="id"
                                                    :showLabels="false"
                                                    :allow-empty="false"
                                    />
                                    <pre class="language-json"><code>{{ userCreate.language }}</code></pre>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.user.customer') }}</strong></label>
                                    <VueMultiselect v-model="userCreate.customer"
                                                    :options="customers"
                                                    :searchable="true"
                                                    :placeholder="$t('com.user.customers')"
                                                    :multiple="false"
                                                    label="name"
                                                    track-by="id"
                                                    :showLabels="false"
                                                    :allow-empty="false"
                                    />
                                    <pre class="language-json"><code>{{ userCreate.customer }}</code></pre>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.user.roles') }}</strong></label>
                                    <VueMultiselect v-model="userCreate.roles"
                                                    :options="roles"
                                                    :searchable="true"
                                                    :placeholder="$t('com.user.roles')"
                                                    :multiple="true"
                                                    label="name"
                                                    track-by="id"
                                                    :showLabels="false"
                                                    :allow-empty="false"
                                    />
                                    <pre class="language-json"><code>{{ userCreate.roles }}</code></pre>
                                </div>


                            </div>
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.user.firstname') }}</strong></label>
                                    <input v-model="userCreate.firstname" type="text" class="form-control" :placeholder="$t('com.user.firstname')" />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.user.lastname') }}</strong></label>
                                    <input v-model="userCreate.lastname" type="text" class="form-control" :placeholder="$t('com.user.lastname')" />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.user.phone') }}</strong></label>
                                    <input v-model="userCreate.phone" type="tel" class="form-control" :class="{'is-invalid' : validation.phone.isError, 'is-valid' : validation.phone.isValid}" :placeholder="$t('com.user.phone')" @keyup="checkPhone()" />
                                    <div class="invalid-feedback">
                                        {{ $t(validation.phone.message) }}
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.user.pilot') }}</strong></label>
                                    <input v-model="userCreate.pilot" type="number" class="form-control" :placeholder="$t('com.user.pilot')" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <pre class="language-json"><code>{{ userCreate }}</code></pre>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <button type="submit" class="btn btn-primary">{{ $t('com.global.save') }}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script charset="utf-8">
    import VueMultiselect from 'vue-multiselect'
    import ErrorModalMessage from "@/components/common/error/ErrorModalMessage";
    import UserButtonNavigation from "@/components/user/UserButtonNavigation.vue"
    import PageTitle from "@/components/common/PageTitle.vue";
    import CustomerService from "@/services/customer.service";
    import UserService from "@/services/user.service";
    export default {
        components: {
            VueMultiselect,
            ErrorModalMessage,
            PageTitle,
            UserButtonNavigation
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                languages: [],
                roles: [],
                customers: [],
                inputPasswordType: 'password',
                mainTitle: "com.user.create",
                subTitle: "com.user.subtitle",
                validation: {
                    email: {
                        isError: false,
                        isValid: false,
                        message: "",
                    },
                    login: {
                        isError: false,
                        isValid: false,
                        message: "",
                    },
                    phone: {
                        isError: false,
                        isValid: false,
                        message: "",
                    },
                    password: {
                        isError: false,
                        isValid: false,
                        message: "",
                    }
                },
                userCreate: {
                    login: "",
                    email: "",
                    firstname: "",
                    passwordconfirm: "",
                    lastname: "",
                    password: "",
                    phone: "",
                    pilot: 0,
                    roles: [],
                    language: {},
                    customer: {},
                },
                error: {
                    isShow: false,
                    header: "test",
                    message: "",
                    errors: []
                },

                loading: {
                    language: false,
                    roles: false,
                    customer: false,
                    user: false,
                }

            };
        },
        computed: {

        },

        mounted() {
            this.fetchLanguages();
            this.fetchRoles();
            this.fetchCustomers();
        },
        methods: {
            async fetchLanguages(page = 1, items = 100) {
                try {
                    const req = {
                        PageNumber: page,
                        ItemsOnPage: items,
                    }
                    this.$store.dispatch("i18n/getLanguages", req).then(
                        (res) => {
                            this.languages = res.data.languageItems;
                            console.log(res);
                            console.log(this.languages);
                        },
                        (error) => {
                            console.log(error)
                        }
                    );
                }
                catch (err) {
                    console.log(err);
                }
            },
            async fetchRoles() {
                try {
                    this.$store.dispatch("role/getAll").then(
                        (res) => {
                            this.roles = res.data;

                            //this.editUser.roles = this.roles.filter(o1 => this.item.roles.some(o2 => o1.id === o2.id));
                        },
                        (err) => {
                            
                            console.log(err)
                        }
                    );
                }
                catch (err) {
                    console.log(err)
                }
            },
            async fetchCustomers() {
                try {
                    this.loading.customer = true;

                    const req = {
                        PageNumber:1,
                        ItemsOnPage:3000,
                    }
                    let res = (await CustomerService.GetCustomers(req));

                    if (res.status == 200) {
                        this.customers = res.data.customers;

                    }
                }
                catch (e) {
                    switch (e.response.status) {
                        case 401: {
                            this.$store.dispatch("auth/logout").then(
                                () => {
                                    this.$router.push({ name: 'LoginView' })
                                },
                                (error) => {
                                    console.log(error)
                                }
                            );
                        } break;
                        case 403: {
                            this.$router.push({ name: 'Forbidden403' })
                        }
                    }
                }
                finally {
                    this.loading.customer = true;
                }
            },

            async checkPhone() {
                this.validation.phone.isError = false;
                this.validation.phone.isValid = false;
                this.validation.phone.message = "";

                let phoneLenght = this.userCreate.phone.length
                if (phoneLenght < 9)
                    return;

                try {
                    this.$store.dispatch("user/checkPhone", this.userCreate.phone).then(
                        () => {
                            this.validation.phone.isValid = true;
                        },
                        (error) => {
                            if (error.status == 400) {
                                this.validation.phone.isError = true;
                                this.validation.phone.message = error.data.message;
                            }
                        }
                    );
                }
                catch (err) {
                    console.log(err)
                }
            },
            async checkLogin() {
                this.validation.login.isError = false;
                this.validation.login.isValid = false;
                this.validation.login.message = "";

                let loginLenght = this.userCreate.login.length
                if (loginLenght < 3)
                    return;

                try {
                    this.$store.dispatch("user/checkLogin", this.userCreate.login).then(
                        () => {
                            this.validation.login.isValid = true;
                        },
                        (error) => {
                            if (error.status == 400) {
                                this.validation.login.isError = true;
                                this.validation.login.message = error.data.message;
                            }
                        }
                    );
                }
                catch (err) {
                    console.log(err)
                }

            },
            async checkEmail() {
                this.validation.email.isError = false;
                this.validation.email.isValid = false;
                this.validation.email.message = "";


                if (!this.validateEmail(this.userCreate.email))
                    return;


                try {
                    this.$store.dispatch("user/checkEmail", this.userCreate.email).then(
                        () => {
                            this.validation.email.isValid = true;
                        },
                        (error) => {
                            if (error.status == 400) {
                                this.validation.email.isError = true;
                                this.validation.email.message = error.data.message;
                            }

                        }
                    );
                }
                catch (err) {
                    console.log(err)
                }
            },
            validateEmail(email) {
                var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },

            randomPassword(length) {
                var randomchars = "abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890";
                var pass = "";
                for (var x = 0; x < length; x++) {
                    var i = Math.floor(Math.random() * randomchars.length);
                    pass += randomchars.charAt(i);
                }
                return pass;
            },
            generateUserPassword() {
                let password = this.randomPassword(Math.floor(Math.random() * 10) + 8);
                this.userCreate.password = password;
                this.userCreate.passwordconfirm = password;
                this.validatePassowrd();
            },
            showPassowrd() {
                this.inputPasswordType = 'text';
            },
            hidePassword() {
                this.inputPasswordType = 'password';
            },
            async createUser() {
                try {
                    this.error.isError = false;
                    this.error.message = ''
                    this.error.errors = []


                    const req = {
                        login: this.userCreate.login,
                        password: this.userCreate.password,
                        confirmPassword: this.userCreate.passwordconfirm,
                        firstname: this.userCreate.firstname,
                        lastname: this.userCreate.lastname,
                        email: this.userCreate.email,
                        telephone: this.userCreate.phone,
                        pilotID: this.userCreate.pilot,
                        languageID: this.userCreate.language.id,
                        customerID: this.userCreate.customer.id,
                        roles: this.userCreate.roles
                    }
                    console.log(req);

                    let res = (await UserService.CreateUser(req))


                    if (res.status == 200) {
                        this.$toast.success(this.$t('com.user.successcreate'))
                    }

                    //this.$store.dispatch("user/createUser", req).then(
                    //    () => {
                    //        this.$toast.success(this.$t('com.user.successcreate'))
                    //        //this.editUser.roles = this.roles.filter(o1 => this.item.roles.some(o2 => o1.id === o2.id));
                    //    },
                    //    (err) => {
                    //        this.error.isError = true;
                    //        this.error.message = err.data.message
                    //        this.error.errors = err.data.errors
                    //        console.log(err)
                    //    }
                    //);

                }
                catch (err) {
                    console.log(err);
                }
            },
            validatePassowrd() {
                this.validation.password.isValid = false;
                this.validation.password.isError = false;
                this.validation.password.message = '';

                if (this.userCreate.password === this.userCreate.passwordconfirm) {
                    this.validation.password.isValid = true;
                }
                else {
                    this.validation.password.isError = true;
                    this.validation.password.message = this.$t('com.user.passwordsdontmatch');
                    
                }
            }
        }
    }</script>

<style>
</style>
