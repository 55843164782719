<template>
    <div class="row mb-3">
        <div class="col-6">
            <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
        </div>
        <div class="col-6">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <QuizVoteBlockNavigation />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ $t(subTitle) }}</h4>
                </div>
                <div class="card-body">
                    <form method="post" class="needs-valivation" novalidate="novalidate" autocomplete="off" @submit.prevent="updateVoteBlock()">
                        <div class="row">
                            <div class="col-md-12 col-lg-6">
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <label class="form-label">
                                            <strong>
                                                {{ $t('com.quiz.vote.block.name') }}
                                            </strong>
                                        </label>
                                        <input type="text" class="form-control" :placeholder="$t('com.quiz.vote.block.name')" v-model="voteBlock.name" />
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <label class="form-label">
                                            <strong>
                                                {{ $t('com.quiz.vote.block.description') }}
                                            </strong>
                                        </label>
                                        <input type="text" class="form-control" :placeholder="$t('com.quiz.vote.block.description')" v-model="voteBlock.description" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-6">
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <label class="form-label">
                                            <strong>{{ $t('com.quiz.vote.block.event.name') }}</strong>
                                        </label>
                                        <VueMultiselect v-model="voteBlock.event"
                                                        :options="availableEvents"
                                                        :searchable="true"
                                                        :placeholder="$t('com.quiz.vote.block.event.name')"
                                                        :multiple="false"
                                                        label="name"
                                                        track-by="id"
                                                        :disabled="loading.event"
                                                        :showLabels="false"
                                                        :allow-empty="false" />
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <label class="form-label"><strong>{{ $t('com.quiz.vote.block.type') }}</strong></label>
                                        <VueMultiselect v-model="voteBlock.voteBlockType"
                                                        :options="voteBlockTypes"
                                                        :searchable="true"
                                                        :placeholder="$t('com.quiz.vote.block.type')"
                                                        :multiple="false"
                                                        :custom-label="translatedType"
                                                        track-by="id"
                                                        :disabled="loading.types"
                                                        :showLabels="false"
                                                        :allow-empty="false" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <button type="submit" class="btn btn-primary">{{ $t('com.global.save') }}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script charset="utf-8">
    //views
    import PageTitle from "@/components/common/PageTitle.vue";
    import QuizVoteBlockNavigation from "@/components/quiz/voteblock/QuizVoteBlockNavigation.vue"
    import VueMultiselect from 'vue-multiselect'

    //services
    import QuizVoteBlockService from "@/services/quiz/quiz.voteblock.service";
    import EventService from "@/services/event/event.service";
    import QuizVoteBlockTypeService from "@/services/quiz/quiz.voteblock.type.service";

    export default {
        components: {
            QuizVoteBlockNavigation,
            PageTitle,
            VueMultiselect
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "com.quiz.voteblock.edit.maintitle",
                subTitle: "com.quiz.voteblock.edit.subtitle",

                loading: {
                    global: false,
                    event: false,
                    types: false,
                },

                voteBlock: {
                    customer: {},
                    event: {},
                    voteBlockType: {},
                },

                availableEvents: [],
                voteBlockTypes: [],
            };
        },
        computed: {
            getVoteBlockID() {
                return this.$route.params.id;
            },
        },
        watch: {

        },
        mounted() {
            this.fetchVoteBlock();
            this.fetchAvailableEvents();
            this.fetchVoteBlockTypes();
        },
        methods: {
            async fetchVoteBlock() {
                try {
                    this.loading.global = true;

                    let res = (await QuizVoteBlockService.GetById(this.getVoteBlockID))

                    if (res.status == 200) {
                        this.voteBlock = res.data;
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.global = false;
                }
            },

            async fetchAvailableEvents() {
                try {
                    this.loading.event = true;

                    let res = (await EventService.GetAvailableEventsByVoteBlock(this.getVoteBlockID));

                    if (res.status == 200) {
                        this.availableEvents = res.data;
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.event = false;
                }
            },

            async fetchVoteBlockTypes() {
                try {
                    this.loading.types = true;

                    let res = (await QuizVoteBlockTypeService.GetAll())

                    if (res.status == 200) {
                        this.voteBlockTypes = res.data;
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.types = false;
                }
            },

            async updateVoteBlock() {
                try {
                    this.loading.global = true;
                    const req = {
                        Id: this.voteBlock.id,
                        Name: this.voteBlock.name,
                        Description: this.voteBlock.description,
                        EventID: this.voteBlock.event.id,
                        TypeID: this.voteBlock.voteBlockType.id,
                    };

                    let res = (await QuizVoteBlockService.UpdateWithoutCustomer(req))

                    if (res.status == 200) {
                        this.$toast.success(this.$t('com.quiz.vote.block.update.success'));
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.global = false;
                }
            },

            translatedType(obj) {

                if (Object.keys(obj).length === 0)
                    return "Select"

                return this.$t(obj.name);
            },
        }
    }</script>

<style>
</style>
