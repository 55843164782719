<template>
    <!-- Modal Edit-->
    <form method="POST" class="needs-validation" novalidate="" autocomplete="off" @submit.prevent="addSettingQuestionValue">
        <div id="addSettingQuestionValue" class="modal fade" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Add setting value</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" @click="unsetItem()">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3 row">
                            <ErrorModalMessage v-if="error.isShow" :error="error" />
                        </div>
                        <div class="mb-3 row">
                            <label class="col-sm-3 col-form-label">{{ $t('com.quiz.setting.question.name') }}</label>
                            <div class="col-sm-9">
                                <VueMultiselect v-model="selectedSettingItem"
                                                :options="availableOptions"
                                                :searchable="true"
                                                :placeholder="$t('com.quiz.setting.question.name')"
                                                :multiple="false"
                                                track-by="id"
                                                label="name"
                                                :disabled="loading"
                                                :showLabels="false"
                                                :taggable="false"
                                                :allow-empty="false"
                                                @select="onSelect"
                                />
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <label class="col-sm-3 col-form-label">{{ $t('com.quiz.setting.question.description') }}</label>
                            <div class="col-sm-9">
                                <div class="form-text">{{ selectedSettingItem.description }}</div>
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <label class="col-sm-3 col-form-label">{{ $t('com.quiz.setting.question.value') }}</label>
                            <div class="col-sm-9">
                                <input v-model="valueSetting.value" type="text" class="form-control" :placeholder="$t('com.quiz.setting.question.value')" />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button id="addSettingQuestionValueEmit" type="button" class="btn btn-danger light" data-bs-dismiss="modal" @click="unsetItem()">Close</button>
                        <button type="submit" class="btn btn-primary">Save changes</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <!-- /Modal Edit -->
</template>

<script charset="utf-8">


    import ErrorModalMessage from "../../../common/error/ErrorModalMessage";
    import VueMultiselect from 'vue-multiselect'

    import QuizSettingQuestionService from '../../../../services/quiz/quiz.setting.question.service';

    export default {
        components: {
            ErrorModalMessage,
            VueMultiselect
        },
        props: {
            questionID: {
                type: Number,
                require: true
            },
            availableOptions: {
                type: Object,
                require: true
            }
        },
        emits: ["reload-settings", "search-clear"],
        data() {
            return {
                publicPath: process.env.BASE_URL,
                error: {
                    isShow: false,
                    header: "test",
                    message: "",
                },
                loading: false,

                selectedSettingItem: {},
                availableSettingItems: [],

                questionId: 0,

                valueSetting: {
                    settingId: 0,
                    questionId: 0,
                    value: "",
                }
            };
        },
        computed: {

        },
        watch: {
            questionID: {
                // the callback will be called immediately after the start of the observation
                immediate: true,
                handler(val) {
                    var id = parseInt(val)
                    if (id > 0) {
                        this.questionId = id;
                    }
                }
            },

        },
        mounted() {
           
        },
        methods: {
            

            async addSettingQuestionValue() {
                
                try {
                    this.loading = true;
                    this.valueSetting.questionId = this.questionId
                    let res = (await QuizSettingQuestionService.CreateSettingValue(this.valueSetting));

                    if (res.status == 200) {

                        this.selectedSettingItem = {};

                        //this.fetchAvailableSettingQuestion();
                        this.$emit("reload-settings");

                        document.getElementById('addSettingQuestionValueEmit').click();
                    }

                }
                catch (e) {
                    console.log("fetch err", e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },

            onSelect(option) {
                this.valueSetting.value = option.defaultValue;
                this.valueSetting.settingId = option.id;
            }
        }
    }</script>

<style>
</style>
