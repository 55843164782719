<template>
    <div class="row mb-3">
        <div class="col-6">
            <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
        </div>
        <div class="col-6">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <UserButtonNavigation />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ $t('com.user.edit') }}</h4>
                    <!--<button type="button" data-bs-toggle="modal" data-bs-target="#userChangePasswordModal" class="btn btn-primary">{{ $t('com.user.changepassword') }}</button>-->
                </div>
                <div class="card-body">
                    <form method="POST" class="needs-validation" novalidate="" autocomplete="off" @submit.prevent="UpdateUser">
                        <div class="row mb-2">
                            <div class="col-12">
                                <h5>{{ $t('com.user.login') }}</h5>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12">
                                {{ item.login }}
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="mb-1"><strong>{{ $t('com.user.email') }}</strong></label>
                            <input v-model="item.eMail" type="text" class="form-control" :placeholder="$t('com.user.email')" />
                        </div>
                        <div class="form-group">
                            <label class="mb-1"><strong>{{ $t('com.user.firstname') }}</strong></label>
                            <input v-model="item.firstname" type="text" class="form-control" :placeholder="$t('com.user.firstname')" />
                        </div>
                        <div class="form-group">
                            <label class="mb-1"><strong>{{ $t('com.user.lastname') }}</strong></label>
                            <input v-model="item.lastname" type="text" class="form-control" :placeholder="$t('com.user.lastname')" />
                        </div>

                        <div class="form-group">
                            <label class="mb-1"><strong>{{ $t('com.user.telephone') }}</strong></label>
                            <input v-model="item.telephone" type="tel" class="form-control" :placeholder="$t('com.user.telephone')" />
                        </div>
                        <div class="mb-3">
                            <label class="form-label"><strong>{{ $t('com.user.pilot') }}</strong></label>
                            <input v-model="item.pilotId" type="number" class="form-control" :placeholder="$t('com.user.pilot')" />
                        </div>
                        <div class="form-group">
                            <label class="mb-1"><strong>{{ $t('com.user.language') }}</strong></label>
                            <select v-model="item.language.id" class="form-control" :required="true">
                                <option v-for="(language, index) in languages"
                                        :key="index"
                                        :value="language.id"
                                        :selected="language.id == item.language.id">
                                    {{ language.name }} ({{ language.culture }})
                                </option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label class="mb-1"><strong>{{ $t('com.user.roles') }}</strong></label>
                            <VueMultiselect v-model="editUser.roles"
                                            :options="roles"
                                            :searchable="false"
                                            :multiple="true"
                                            :custom-label="nameWithLang"
                                            label="displayName"
                                            track-by="name"
                                            :allow-empty="false" />
                            <pre class="language-json"><code>{{ editUser.roles }}</code></pre>
                            <button class="btn btn-primary" type="submit">Zapisz</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script charset="utf-8">
    import VueMultiselect from 'vue-multiselect'
    import UserButtonNavigation from "@/components/user/UserButtonNavigation.vue"
    import PageTitle from "@/components/common/PageTitle.vue";
    import UserService from '@/services/user.service.js';

    export default {
        components: {
            VueMultiselect,
            UserButtonNavigation,
            PageTitle
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "com.user.list",
                subTitle: "com.user.subtitle",
                item: {
                    id: "-1",
                    language: {
                        id: 0
                    },
                    roles: []
                },
                languages: [],
                roles: [],
                selectedRoles: null,

                editUser: {
                    id:"",
                    email: "",
                    firstname: "",
                    lastname: "",
                    telephone: "",
                    language: 0,
                    pilotID: 0,
                    roles: null
                },

                loading: {
                    user: false,
                    roles: false,
                    language: false,
                }
            };
        },
        computed: {

        },

        mounted() {
            this.FetchUser();
            this.FetchLanguages();
        },
        methods: {
            async FetchUser() {

                try {
                    this.loading.user = true;

                    let res = (await UserService.GetById(this.$route.params.id))

                    if (res.status == 200) {
                        this.item = res.data;
                        this.FetchRoles();
                    }
                }
                catch (e) {

                    switch (e.response.status) {
                        case 401: {
                            this.$store.dispatch("auth/logout").then(
                                () => {
                                    this.$router.push({ name: 'LoginView' })
                                },
                                (error) => {
                                    console.log(error)
                                }
                            );
                        } break;
                        case 403: {
                            this.$router.push({ name: 'Forbidden403'})
                        }
                    }

                    

                }
                finally {
                    this.loading.user = false;
                }


                //this.$store.dispatch("user/getById", this.$route.params.id).then(
                //    (res) => {
                //        this.item = res.data;
                //        this.FetchRoles();
                //    },
                //    (error) => {
                //        console.log(error)
                //    }
                //);
            },
            async FetchLanguages(page = 1, items = 100) {
                const req = {
                    PageNumber: page,
                    ItemsOnPage: items,
                }
                this.$store.dispatch("i18n/getLanguages", req).then(
                    (res) => {
                        this.languages = res.data.languageItems;
                        console.log(res);
                        console.log(this.languages);
                    },
                    (error) => {
                        console.log(error)
                    }
                );
            },
            async FetchRoles() {
                this.$store.dispatch("role/getAll").then(
                    (res) => {
                        this.roles = res.data;
                        
                        this.editUser.roles = this.roles.filter(o1 => this.item.roles.some(o2 => o1.id === o2.id));
                    },
                    (error) => {
                        console.log(error)
                    }
                );
            },
            async UpdateUser() {


                try {
                    this.loading.user = true;
                    this.editUser.id = this.item.id;
                    this.editUser.email = this.item.eMail;
                    this.editUser.firstname = this.item.firstname;
                    this.editUser.lastname = this.item.lastname;
                    this.editUser.telephone = this.item.telephone;
                    this.editUser.language = this.item.language.id;
                    this.editUser.pilotID = this.item.pilotId;

                    let res = (await UserService.UpdateUserLowPermission(this.editUser))

                    if (res.status == 200) {
                        this.$toast.success("com.user.update.success");
                    }
                    
                }
                catch (e) {
                    switch (e.response.status) {
                        case 401: {
                            this.$store.dispatch("auth/logout").then(
                                () => {
                                    this.$router.push({ name: 'LoginView' })
                                },
                                (error) => {
                                    console.log(error)
                                }
                            );
                        } break;
                        case 403: {
                            this.$router.push({ name: 'Forbidden403' })
                        }
                    }
                }
                finally {
                    this.loading.user = true;
                }
            },
            userHasRole(requestRoleId) {

                if (this.item.roles.some(e => e.id === requestRoleId)) {
                    return true;
                }
                return false;
            },
            nameWithLang(obj) {
                return obj.displayName
            }
        }
    }</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
