<template>
    <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Recent Payments Queue</h4>
                    <div class="btn-group">
                        <router-link :to="{ name: 'CustomersBin' }" type="button" class="btn btn-primary">
                            {{ $t("com.global.bin") }}
                        </router-link>
                        <router-link :to="{ name: 'CustomersList' }" type="button" class="btn btn-primary">
                            {{ $t("com.global.list") }}
                        </router-link>
                        <router-link :to="{name: 'CustomerCreate'}" type="button" class="btn btn-primary">
                            {{ $t('com.global.add') }}
                        </router-link>
                    </div>

                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table table-responsive-md">
                                    <thead>
                                        <tr>
                                            <th style="width:80px;"><strong>#</strong></th>
                                            <th><strong>{{ $t('com.customer.name') }}</strong></th>
                                            <th><strong>{{ $t('com.customer.phone') }}</strong></th>
                                            <th><strong>{{ $t('com.customer.email') }}</strong></th>
                                            <th><strong>{{ $t('com.customer.address') }}</strong></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(customer, index) in customers" :key="index">
                                            <td><strong>{{ getIndex(index) }}</strong></td>
                                            <td>{{ customer.name }}</td>
                                            <td>{{ customer.phone }}</td>
                                            <td>{{ customer.eMail }}</td>
                                            <td>{{ customer.postalCode }} {{ customer.citty }}, {{ customer.street }}</td>
                                            <td>
                                                <div class="dropdown">
                                                    <button :id="getDropdownID(index)" type="button" class="btn btn-primary light sharp" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <rect x="0" y="0" width="24" height="24" />
                                                                <circle fill="#000000" cx="5" cy="12" r="2" />
                                                                <circle fill="#000000" cx="12" cy="12" r="2" />
                                                                <circle fill="#000000" cx="19" cy="12" r="2" />
                                                            </g>
                                                        </svg>
                                                    </button>
                                                    <div class="dropdown-menu" :aria-labelledby="getDropdownID(index)">
                                                        <router-link :to="{ name: 'CustomerEdit', params: { id: customer.id } }" class="dropdown-item">{{ $t('com.global.edit') }}</router-link>
                                                        <router-link :to="{ name: 'CustomerDetail', params: { id: customer.id } }" class="dropdown-item">{{ $t('com.global.details') }}</router-link>
                                                        <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modalItemDelete" @click="setCustomer(customer)">{{ $t('com.global.delete') }}</a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <!--<div class="table-responsive"> -->
                    
                    <!--</div>-->
                    <div class="row mt-3">
                        <Pagination :pages="pages" @page-request="pageRequest" />
                    </div>

                </div>
            </div>
        </div>
    </div>

    <ModalDelete :error="errorModalEdit"
                 :deletedItem="deletedItem"
                 :closeModal="closeModal"
                 @unset-item="unsetCustomer()"
                 @confirm-delete="deleteCustomer()"
    />
</template>

<script charset="utf-8">
    import PageTitle from "../../../components/common/PageTitle.vue";
    import Pagination from "../../../components/common/Pagination.vue"
    import ModalDelete from "../../../components/common/modal/ModalDelete.vue"
    export default {
        components: {
            PageTitle,
            Pagination,
            ModalDelete
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "Groups List",
                subTitle: "com.groups.subtitle",
                customers: [],
                pages: {},
                selectedCustomer: {},
                errorModalEdit: {
                    isShow: false,
                    header: "test",
                    message: "",
                },
                deletedItem: "",
                closeModal: false
            };
        },
        computed: {
            
        },
        watch: {
            '$route'(to) {
                let page = parseInt(to.params.page);
                if (isNaN(page))
                    page = 1;

                let items = parseInt(to.params.items)
                if (isNaN(items))
                    items = 10
                this.fetchCustomers(page, items)
            }
        },
        mounted() {
            this.fetchCustomers(this.getCurrentPage(), this.getCurrentItemsOnPage());
        },
        methods: {
            async fetchCustomers(page = 1, items = 10) {
                try {
                    const req = {
                        PageNumber: page,
                        ItemsOnPage: items,
                    }
                    this.$store.dispatch("customer/getCustomers", req).then(
                        (res) => {
                            this.customers = res.data.customers;
                            this.pages = res.data.page;
                            console.log(res);
                        },
                        (error) => {
                            console.log(error)
                        }
                    );
                }
                catch (err) {
                    console.log(err)
                }
            },
            getCurrentPage() {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;
                return page;
            },
            getCurrentItemsOnPage() {
                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 10;
                if (itemsPerPage == 0)
                    itemsPerPage = 10;
                return itemsPerPage
            },
            getIndex(index) {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;

                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 0;

                return ((page - 1) * itemsPerPage) + index + 1
            },
            pageRequest(page) {
                this.$router.push(
                    {
                        name: 'CustomersListPages',
                        params: {
                            page: page,
                            items: this.getCurrentItemsOnPage()
                        }
                    })
            },

            getDropdownID(str) {
                return "dropdownButton" + str;
            },
            setCustomer(customer) {
                
                this.closeModal = false;
                this.selectedCustomer = customer
            },
            unsetCustomer() {
                this.selectedCustomer = {}
            },
            async deleteCustomer() {
                try {
                    this.$store.dispatch("customer/deleteCustomer", this.selectedCustomer.id).then(
                        () => {
                            this.closeModal = true;
                            this.$toast.success(this.$t('com.customer.successdeleted'));
                            this.unsetCustomer();
                            this.fetchCustomers(this.getCurrentPage(), this.getCurrentItemsOnPage());
                        },
                        (error) => {
                            console.log(error)
                        }
                    );
                }
                catch (err) {
                    console.log(err);
                }
                
            }

        }
    }</script>

<style>
</style>
