<template>
    <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Recent Payments Queue</h4>
                    <QuizSettingQuestionButtonNavigation />
                </div>
                <div class="card-body">


                </div>
            </div>
        </div>
    </div>
</template>

<script charset="utf-8">
    import PageTitle from "../../../../components/common/PageTitle.vue";
    import QuizSettingQuestionButtonNavigation from "../../../../components/quiz/setting/question/QuizSettingQuestionButtonNavigation.vue";
    export default {
        components: {
            PageTitle,
            QuizSettingQuestionButtonNavigation
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "Quiz Setting Question Detail",
                subTitle: "com.quiz.setting.question",
            };
        },
        computed: {

        },
        watch: {

        },
        mounted() {

        },
        methods: {

        }
    }</script>

<style>
</style>
