<template>
    <div class="row">
        <div class="col-12">
            <EventPageTitle :event="eventData" />
        </div>
    </div>
    <div class="row" v-if="currentVoteBlock.id != -1">
        <div class="col-12">
            <EventVoteBlock :item="currentVoteBlock" :curentVote="currentVote" />
        </div>
    </div>
    <EventCurrentQuestion :currentQuestion="currentActiveQuestion" 
                          :enititledUses="entitledUsers"
                          :showUsers="showUsers"
                          :alreadyAnsweredUsers="alreadyAnsweredUsers"/>
</template>
<script charset="utf-8">
    import EventVoteBlock from "@/components/visualisation/event/EventVoteBlock.vue";
    import EventPageTitle from "@/components/interactive/common/EventPageTitle.vue";
    import EventCurrentQuestion from "@/components/interactive/common/EventCurrentQuestion.vue"

    import EventService from "@/services/event/event.service";
    import QuizLiveVoteHubService from "@/services/quiz/quiz.live.vote.hub.service"
    import QuizInteractiveService from "@/services/quiz/quiz.interactive.service"

    export default {
        components: {
            EventVoteBlock,
            EventPageTitle,
            EventCurrentQuestion
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,

                eventData: {
                    settingValues: [],
                    voteBlocks: [],
                },

                loading: {
                    global: false,
                    background: false,
                    currentVoteBlock: false,
                    currentActiveVote: false,
                    currentActiveQuestion: false,
                },

                currentVoteBlock: {
                    id: -1,
                    name: "",
                },

                currentVote: {
                    id: -1,
                    name: "",
                },
                currentActiveQuestion: {
                    name: "",
                    type: {
                        name: "",
                    }
                },

                showUsers: true,
                entitledUsers: [],
                alreadyAnsweredUsers: [],

            };
        },
        computed: {
            eventCode() {
                return this.$route.params.eventCode;
            }
        },
        created() {

        },
        mounted() {
            this.startHubConnection();
            this.getEventByCode();
            this.fetchCurrentVoteBlockActive();
            this.fetchCurrentActiveVote();
            this.fetchCurrentActiveQuestion();
        },
        methods: {
            async startHubConnection() {
                await QuizLiveVoteHubService.Connect(this.eventCode, "visualisation");


                QuizLiveVoteHubService.client.on("TestowyEndpoint", (message) => {
                    console.log("TestowyEndpoint", message);
                });
                QuizLiveVoteHubService.client.on("CurrentActiveVoteBlock", (data) => {
                    this.currentVoteBlock = data;
                    console.log("CurrentActiveVoteBlock", data);
                });
                QuizLiveVoteHubService.client.on("CurrentActiveVote", (data) => {
                    this.currentVote = data;
                    console.log("CurrentActiveVote", this.currentVote);
                });
                QuizLiveVoteHubService.client.on("CurrentActiveQuestion", (data) => {
                    this.currentActiveQuestion = data;
                    console.log("CurrentActiveQuestion", this.currentActiveQuestion);
                });

                //QuizLiveVoteHubService.client.on("UsersAlreadyAnswered", (data) => {
                //    console.log("UsersAlreadyAnswered", data);
                //})

                QuizLiveVoteHubService.client.on("CurrentStatusUsersAnswered", (entitledUsers, alreadeAnsweredUsers) => {
                    this.entitledUsers = entitledUsers;
                    this.alreadyAnsweredUsers = alreadeAnsweredUsers;
                    console.log("CurrentStatusUsersAnswered", entitledUsers, alreadeAnsweredUsers);
                })
                
            },
            async getEventByCode() {
                try {
                    this.loading.global = true;

                    let res = (await EventService.GetByCode(this.eventCode))

                    if (res.status == 200) {
                        this.eventData = res.data;
                        //this.setupBackground();
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.global = false;
                }
            },

            async fetchCurrentVoteBlockActive() {
                try {
                    this.loading.currentVoteBlock = true;

                    let res = (await QuizInteractiveService.GetCurrentActiveVoteBlock(this.eventCode))

                    if (res.status == 200) {
                        this.currentVoteBlock = res.data;
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.currentVoteBlock = false;
                }
            },

            async fetchCurrentActiveVote() {
                try {
                    this.loading.currentActiveVote = true;
                    let res = (await QuizInteractiveService.GetCurrentActiveVote(this.eventCode))

                    if (res.status == 200) {
                        this.currentVote = res.data;
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.currentActiveVote = false;
                }
            },

            async fetchCurrentActiveQuestion() {
                try {
                    this.loading.currentActiveQuestion = true;

                    let res = (await QuizInteractiveService.GetCurrentActiveQuestion(this.eventCode));

                    if (res.status == 200) {
                        this.currentActiveQuestion = res.data;

                        if (this.currentActiveQuestion.id != 0) {
                            this.fetchCurrentUserStatus();
                        }
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.currentActiveVote = false;
                }
            },

            async fetchCurrentUserStatus() {
                try {

                    const req = {
                        EventCode: this.eventCode,
                        QuestionID: this.currentActiveQuestion.id
                    };
                    
                    let res = (await QuizInteractiveService.GetCurrentUserStatusByQuestion(req));

                    if (res.status == 200) {
                        this.entitledUsers = res.data.entitledUsers;
                        this.alreadyAnsweredUsers = res.data.alreadyAnsweredUsers;
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading.currentActiveVote = false;
                }
            }
        }
    }
</script>