<template>
    <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Recent Payments Queue</h4>
                    <ButtonNavigation />
                </div>
                <div class="card-body">
                    
                    <form method="POST" class="needs-validation" novalidate="" autocomplete="off" @submit.prevent="updateAppSettingKey">
                        <div class="row">
                            <div class="col-md-6 offset-md-3">
                                <div class="mb-3 row">
                                    <ErrorModalMessage v-if="error.isShow" :error="error" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.app.setting.key.name') }}</strong></label>
                                    <input v-model="appSetting.name" type="text" class="form-control" :placeholder="$t('com.app.setting.key.name')" :disabled="loading" />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.app.setting.key.description') }}</strong></label>
                                    <input v-model="appSetting.description" type="text" class="form-control" :placeholder="$t('com.app.setting.key.description')" :disabled="loading" />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.app.setting.key.defaultValue') }}</strong></label>
                                    <input v-model="appSetting.defaultValue" type="text" class="form-control" :placeholder="$t('com.app.setting.key.defaultvalue')" :disabled="loading" />
                                </div>

                            </div>
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.app.setting.key.settingtype') }}</strong></label>
                                    <VueMultiselect v-model="appSetting.settingType"
                                                    :options="settingTypes"
                                                    :searchable="true"
                                                    :placeholder="$t('com.app.setting.key.settingtype')"
                                                    :multiple="false"
                                                    label="name"
                                                    track-by="id"
                                                    :disabled="loading"
                                                    :showLabels="false"
                                                    :allow-empty="false"
                                    />
                                    <pre class="language-json"><code>{{ appSetting.settingType }}</code></pre>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.app.setting.key.variabletype') }}</strong></label>
                                    <VueMultiselect v-model="appSetting.variableType"
                                                    :options="variableTypes"
                                                    :searchable="true"
                                                    :placeholder="$t('com.app.setting.key.variabletype')"
                                                    :multiple="false"
                                                    label="name"
                                                    track-by="id"
                                                    :disabled="loading"
                                                    :showLabels="false"
                                                    :allow-empty="false"
                                    />
                                    <pre class="language-json"><code>{{ appSetting.variableType }}</code></pre>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.app.setting.key.isrequied') }}</strong></label>
                                    <VueMultiselect v-model="appSetting.isRequired"
                                                    :options="requiedOptions"
                                                    :searchable="true"
                                                    :placeholder="$t('com.app.setting.key.isrequied')"
                                                    :multiple="false"
                                                    :custom-label="translatedLabel"
                                                    label="name"
                                                    track-by="value"
                                                    :disabled="loading"
                                                    :showLabels="false"
                                                    :allow-empty="false"
                                    />
                                    <pre class="language-json"><code>{{ appSetting.isRequired }}</code></pre>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.app.setting.key.allowmultiple') }}</strong></label>
                                    <VueMultiselect v-model="appSetting.allowMultiple"
                                                    :options="requiedOptions"
                                                    :searchable="true"
                                                    :placeholder="$t('com.app.setting.key.allowmultiple')"
                                                    :multiple="false"
                                                    :custom-label="translatedLabel"
                                                    label="name"
                                                    track-by="value"
                                                    :disabled="loading"
                                                    :showLabels="false"
                                                    :allow-empty="false"
                                    />
                                    <pre class="language-json"><code>{{ appSetting.allowMultiple }}</code></pre>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <pre class="language-json"><code>{{ appSetting }}</code></pre>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <button type="submit" class="btn btn-primary" :disabled="loading">
                                        <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        {{ $t('com.global.save') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>


</template>

<script charset="utf-8">
    import PageTitle from "../../../../components/common/PageTitle.vue";
    import ButtonNavigation from "../../../../components/app/setting/key/ButtonNavigation.vue";
    import VueMultiselect from 'vue-multiselect'
    import VariableTypeService from "../../../../services/variabletype.service";
    
    import ErrorModalMessage from "../../../../components/common/error/ErrorModalMessage";
    import AppSettingTypeService from "../../../../services/app.setting.type.service";
    import AppSettingKeyService from "../../../../services/app.setting.key.service";
    export default {
        components: {
            PageTitle,
            ButtonNavigation,
            ErrorModalMessage,
            VueMultiselect
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "App Setting Key Edit",
                subTitle: "com.app.setting.key.subtitle",
                variableTypes: [],
                settingTypes: [],
                requiedOptions: [
                    { name: "com.global.yes", value: true },
                    { name: "com.global.no", value: false },
                ],
                errorModalEdit: {
                    isShow: false,
                    header: "test",
                    message: "",
                },
                appSetting: {
                    name: "",
                    description: "",
                    defaultValue: "",
                    isRequired: "",
                    allowMultiple: "",
                    variableType: "",
                    settingType: "",
                },
                error: {
                    isShow: false,
                    header: "test",
                    message: "",
                    errors: []
                },
                deletedItem: "",
                closeModal: false,
                loading: false,
            };
        },
        computed: {
            appSettingKeyId() {
                return this.$route.params.id;
            }
        },
        watch: {

        },
        mounted() {
            this.fetchAppSettingKey();

        },
        methods: {
            async fetchAppSettingKey() {
                try {
                    this.loading = true;

                    let res = (await AppSettingKeyService.GetById(this.appSettingKeyId))
                    if (res.status == 200) {
                        this.closeModal = false;
                        this.appSetting = res.data;
                        let isRequired = this.appSetting.isRequired;
                        let allowMultiple = this.appSetting.allowMultiple;

                        this.appSetting.isRequired = this.requiedOptions.find(x => x.value == isRequired)
                        this.appSetting.allowMultiple = this.requiedOptions.find(x => x.value == allowMultiple)
                        this.fetchVariableTypes();
                        this.fetchSettingTypes();
                    }

                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                    else if (e.response.status == 404) {
                        this.$router.push({ name: 'NotFound404' })
                    }
                }
                finally {
                    this.loading = false;
                }
            },
            async updateAppSettingKey() {
                try {
                    this.loading = true;

                    let res = (await AppSettingKeyService.Update(this.appSetting))

                    if (res.status == 200) {
                        this.$toast.success(this.$t('com.app.setting.key.updatesuccess'));
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                    else if (e.response.status == 400) {
                        this.error.isShow = true;
                        this.error.message = e.response.data.message;
                        this.error.errors = e.response.data.errors;
                    }
                }
                finally {
                    this.loading = false;
                }
            },
            async fetchVariableTypes() {
                try {
                    let res = (await VariableTypeService.GetAll());
                    if (res.status == 200) {
                        this.variableTypes = res.data;
                        console.log(this.variableTypes);
                        this.appSetting.variableType = this.variableTypes.find(x => x.value == this.appSetting.type)
                    }
                }
                catch (e) {
                    console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
            },

            async fetchSettingTypes() {
                try {
                    let res = (await AppSettingTypeService.GetAll());
                    if (res.status == 200) {
                        this.settingTypes = res.data;
                        let keyID = this.appSetting.settingType;

                        this.appSetting.settingType = this.settingTypes.find(x => x.value == keyID);
                        //this.clearData();
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }

                }
            },
            translatedLabel(obj) {
                if (obj === undefined)
                    return "test";

                return this.$t(obj.name)
                //return "ok"
            },
             getCurrentPage() {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;
                return page;
            },
            getCurrentItemsOnPage() {
                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 10;
                if (itemsPerPage == 0)
                    itemsPerPage = 10;
                return itemsPerPage
            },
            getIndex(index) {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;

                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 0;

                return ((page - 1) * itemsPerPage) + index + 1
            },
            pageRequest(page) {
                this.$router.push(
                    {
                        name: 'EmailTemplatesListPages',
                        params: {
                            page: page,
                            items: this.getCurrentItemsOnPage()
                        }
                    })
            },

            getDropdownID(str) {
                return "dropdownButton" + str;
            },
            setEmailTemplate(EmailTemplate) {

                this.closeModal = false;
                this.selectedEmailTemplate = EmailTemplate
            },
            unsetEmailTemplate() {
                this.selectedEmailTemplate = {}
            },
        }
    }</script>

<style>
</style>
