import api from "./api";
import url from "./url";

class RoleService {
    GetAll() {
        return api.get(url.role.getAll)
            .then((res) => {
                return res;
            })
    }
}

export default new RoleService