<template>
    <!--<PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />-->
    <!--<div class="row">
        <router-link :to="{ name: 'UsersList' }" class="col-2">
            <p class="text-center">
                <i class="flaticon-381-list"></i>
            </p>
            <p class="text-center">
                {{ $t('com.user.lists') }}
            </p>
        </router-link>
        <router-link :to="{ name: 'UserCreate' }" class="col-2">
            <p class="text-center">
                +
            </p>
            <p class="text-center">
                {{ $t('com.user.create') }}
            </p>
        </router-link>
    </div>-->
    <router-view />



</template>

<script charset="utf-8">
    //import PageTitle from "../../components/common/PageTitle.vue";

    export default {
        components: {
            //PageTitle,
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "com.user",
                subTitle: "com.user.subtitle",
            };
        },
        computed: {

        },

        mounted() {
            //this.FetchRerouces();
        },
        methods: {

        }
    }</script>

<style>
</style>
