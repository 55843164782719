import api from "./api";
import url from "./url";

import TokenService from "./token.service";
class AuthService {
    Login(User) {
        const req = {
            Login: User.Username,
            Password: User.Password,
            LanguageID: User.LanguageID
        }
        return api.post(url.auth.login, req)
            .then((response) => {
                console.log(response)
                if (response.data.token) {
                    TokenService.setUser(response.data)
                }
            })
    }
    Logout() {
        return api.get(url.auth.logout)
            .then((res) => {
                TokenService.removeUser();
                return res;
            })

    }

    ForgotPassword(request) {
        const req = {
            eMail: request.email
        }
        return api.post(url.auth.forgotPassword, req)
            .then((response) => {
                return response;
            })
    }

    ResetPassword(request) {
        const req = {
            Email: request.email,
            Token: request.token,
            Password: request.password,
            ConfirmPassword: request.confirmPassword,
        }
        return api.post(url.auth.resetPassword, req)
            .then((response) => {
                return response;
            })
    }
}

export default new AuthService();