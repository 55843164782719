import api from "./api";
import url from "./url";

class AppSettingKeyService {
    GetAll(Pages) {
        return api.get(url.app.setting.key.getAll, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage
            }
        })
            .then((res) => {
                return res;
            })
    }
    GetById(AppSettingID) {
        return api.get(url.app.setting.key.getById + "/" + AppSettingID)
            .then((res) => {
                return res;
            })
    }
    GetByAbbr(AppSettingAbbr) {
        return api.get(url.app.setting.key.getByAbbr + "/" + AppSettingAbbr)
            .then((res) => {
                return res;
            })
    }
    Create(request) {
        const req = {
            Name: request.name,
            DefaultValue: request.defaultValue,
            Description: request.description,
            Type: request.variableType.value,
            SettingType: request.settingType.value,
            AllowMultiple: request.allowMultiple.value,
            IsRequired: request.isRequired.value,
        }
        return api.post(url.app.setting.key.create, req)
            .then((response) => {
                return response;
            });
    }
    Update(request) {
        const req = {
            Id: request.id,
            Name: request.name,
            DefaultValue: request.defaultValue,
            Description: request.description,
            Type: request.type.value,
            SettingType: request.settingType.value,
            AllowMultiple: request.allowMultiple.value,
            IsRequired: request.isRequired.value,
        }
        return api.post(url.app.setting.key.update, req)
            .then((response) => {
                return response;
            });
    }
    Delete(AppSettingID) {
        return api.delete(url.app.setting.key.delete + "/" + AppSettingID)
            .then((res) => {
                return res;
            })
    }
    GetBin(Pages) {
        return api.get(url.app.setting.key.bin, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage
            }
        })
            .then((res) => {
                return res;
            })
    }
    DeletePermanently(AppSettingID) {
        return api.delete(url.app.setting.key.deletePermanently + "/" + AppSettingID)
            .then((res) => {
                return res;
            })
    }
    Restore(AppSettingID) {
        return api.post(url.app.setting.key.retore + "/" + AppSettingID)
            .then((res) => {
                return res;
            })
    }
}
export default new AppSettingKeyService;