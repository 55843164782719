<template>
    <div class="row">
        <div class="col-4">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ $t('com.resource.detail') }}</h4>
                </div>
                <div class="card-body">
                    <div class="row mb-2">
                        <div class="col-12">
                            <h5>{{ $t('com.resource.name') }}</h5>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12 ">
                            {{ item.resource.name }}
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12">
                            <h5>{{ $t('com.resource.friendlyname') }}</h5>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12">
                            {{ item.resource.friendlyName }}
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12">
                            <h5>{{ $t('com.resource.description') }}</h5>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12">
                            {{ item.resource.description }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-8">

            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ $t('com.resouce.translation.list') }}</h4>
                    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalAddTranslation"><i>+</i> {{ $t('com.global.add') }}</button>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-responsive-md">
                            <thead>
                                <tr>
                                    <th style="width:80px;"><strong>#</strong></th>
                                    <th>
                                        <strong>{{ $t('com.resource.language.name') }}</strong>
                                    </th>
                                    <th>
                                        <strong>
                                            {{ $t('com.resource.translation.value') }}
                                        </strong>
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(translationItem, itemKey) in item.translations" :key="itemKey">
                                    <td><strong>{{ itemKey + 1 }}</strong></td>
                                    <td>{{ translationItem.language.name }} ({{ translationItem.language.culture }})</td>
                                    <td>{{ translationItem.translation }}</td>
                                    <td>
                                        <div class="dropdown">
                                            <button type="button" class="btn btn-primary light sharp" data-bs-toggle="dropdown">
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24" />
                                                        <circle fill="#000000" cx="5" cy="12" r="2" />
                                                        <circle fill="#000000" cx="12" cy="12" r="2" />
                                                        <circle fill="#000000" cx="19" cy="12" r="2" />
                                                    </g>
                                                </svg>
                                            </button>
                                            <div class="dropdown-menu">
                                                <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#editModalItem" @click="selectedItem(translationItem)">{{ $t('com.global.edit') }}</a>
                                                <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#deleteTranslationModal" @click="selectedItem(translationItem)">{{ $t('com.global.delete') }}</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ModalAddTranslation :languages="item.missingLanguageTranslations" :resource="item.resource" @reload-items="FetchRerouce" />
    <ModalEdit :selectedItem="selectedTranslation" :resourceID="item.resource.id" @unset-item="unsetItem()" @reload-items="FetchRerouce" />
    <ModalDelete :selectedItem="selectedTranslation" @unset-item="unsetItem()" @reload-items="FetchRerouce" />

</template>

<script charset="utf-8">
    import ModalAddTranslation from "../../../components/resource/ModalAddTranslation.vue";
    import ModalDelete from "../../../components/translation/ModalDelete.vue";
    import ModalEdit from "../../../components/translation/ModalEdit.vue";
    export default {
        components: {
            ModalAddTranslation,
            ModalDelete,
            ModalEdit
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                item: {
                    resource: {
                        id: 0,
                        name: "",
                        friendlyName: "",
                        description: "",
                    },
                    translations: [],
                    missingLanguageTranslations: []

                },
                selectedTranslation: {}
            };
        },
        computed: {

        },
        mounted() {
            
        },
        created() {
            this.FetchRerouce();
        },
        methods: {
            async FetchRerouce() {
                console.log("ResID: ", this.$route.params.id)
                this.$store.dispatch("i18n/getResourceById", this.$route.params.id).then(
                    (res) => {
                        this.item = res.data;
                    },
                    (error) => {
                        console.log(error)
                    }
                );
            },
            unsetItem() {
                this.selectedTranslation = {}
            },
            selectedItem(selected) {
                this.selectedTranslation = selected;
            }
        }
    }</script>

<style>
</style>
