import api from "../api";
import url from "../url";

class QuizQuestionCategoryService {
    GetPaged(Pages) {
        return api.get(url.quiz.category.getAllPaged, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage
            }
        })
            .then((res) => {
                return res;
            })
    }

    GetAll() {
        return api.get(url.quiz.category.getAll)
            .then((res) => {
                return res;
            })
    }

    GetById(CategoryID) {
        return api.get(url.quiz.category.getById + "/" + CategoryID)
            .then((res) => {
                return res;
            })
    }

    Create(request) {
        const req = {
            Name: request.name,
            Description: request.description,
        }

        return api.post(url.quiz.category.create, req)
            .then((response) => {
                return response;
            });
    }

    CreateFromQuestion(request) {
        const req = {
            Name: request.name,
        }

        return api.post(url.quiz.category.createFromQuestion, req)
            .then((response) => {
                return response;
            });
    }

    Update(request) {
        const req = {
            Id: request.id,
            Name: request.name,
            Description: request.description,
        }

        return api.post(url.quiz.category.update, req)
            .then((response) => {
                return response;
            });
    }

    Delete(CategoryID) {
        return api.delete(url.quiz.category.delete + "/" + CategoryID)
            .then((res) => {
                return res;
            })
    }

    GetBin(Pages) {
        return api.get(url.quiz.category.bin, {
            params: {
                PageNumber: Pages.PageNumber,
                ItemsOnPage: Pages.ItemsOnPage
            }
        })
            .then((res) => {
                return res;
            })
    }

    DeletePermanently(CategoryID) {
        return api.delete(url.quiz.category.deletePermanently + "/" + CategoryID)
            .then((res) => {
                return res;
            })
    }

    Restore(CategoryID) {
        return api.post(url.quiz.category.retore + "/" + CategoryID)
            .then((res) => {
                return res;
            })
    }

    GetAvailableByQuestionId(QuestionID) {
        return api.get(url.quiz.category.getAvailableByQuestion + "/" + QuestionID)
            .then((res) => {
                return res;
            })
    }

}
export default new QuizQuestionCategoryService;