import CustomerService from '../../services/customer.service'

export const customer = {
    namespaced: true,
    state: null,
    actions: {
        getCustomers({ commit }, request) {
            commit('empty');
            return CustomerService.GetCustomers(request).then(
                countries => {
                    return Promise.resolve(countries);
                },
                error => {
                    return Promise.reject(error.response);
                }
            );
        },
        getCustomerById({ commit }, request) {
            commit('empty');
            return CustomerService.GetCustomerById(request).then(
                countries => {
                    return Promise.resolve(countries);
                },
                error => {
                    return Promise.reject(error.response);
                }
            );
        },
        getCustomerByAbbr({ commit }, request) {
            commit('empty');
            return CustomerService.GetCustomerByAbbr(request).then(
                countries => {
                    return Promise.resolve(countries);
                },
                error => {
                    return Promise.reject(error.response);
                }
            );
        },
        createCustomer({ commit }, request) {
            commit('empty');
            return CustomerService.CreateCustomer(request).then(
                countries => {
                    return Promise.resolve(countries);
                },
                error => {
                    return Promise.reject(error.response);
                }
            );
        },
        updateCustomer({ commit }, request) {
            commit('empty');
            return CustomerService.UpdateCustomer(request).then(
                countries => {
                    return Promise.resolve(countries);
                },
                error => {
                    return Promise.reject(error.response);
                }
            );
        },
        deleteCustomer({ commit }, request) {
            commit('empty');
            return CustomerService.DeleteCustomer(request).then(
                countries => {
                    return Promise.resolve(countries);
                },
                error => {
                    return Promise.reject(error.response);
                }
            );
        },
        getCustomersBin({ commit }, request) {
            commit('empty');
            return CustomerService.GetCustomersBin(request).then(
                countries => {
                    return Promise.resolve(countries);
                },
                error => {
                    return Promise.reject(error.response);
                }
            );
        },
        deletePermanentlyCustomer({ commit }, request) {
            commit('empty');
            return CustomerService.DeletePermanentlyCustomer(request).then(
                countries => {
                    return Promise.resolve(countries);
                },
                error => {
                    return Promise.reject(error.response);
                }
            );
        },
        restoreCustomer({ commit }, request) {
            commit('empty');
            return CustomerService.RestoreCustomer(request).then(
                countries => {
                    return Promise.resolve(countries);
                },
                error => {
                    return Promise.reject(error.response);
                }
            );
        },
    },
    mutations: {
        empty() {

        },
    }
}