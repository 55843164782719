<template>
    <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Recent Payments Queue</h4>
                    <QuizSettingQuestionButtonNavigation />
                </div>
                <div class="card-body">
                    <form method="POST" class="needs-validation" novalidate="" autocomplete="off" @submit.prevent="saveSettingQuestionKey">
                        <div class="row">
                            <div class="col-md-6 offset-md-3">
                                <div class="mb-3 row">
                                    <ErrorModalMessage v-if="error.isShow" :error="error" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.quiz.setting.question.name') }}</strong></label>
                                    <input v-model="settingQuestion.name" type="text" class="form-control" :placeholder="$t('com.app.setting.key.name')" :disabled="loading" />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.quiz.setting.question.description') }}</strong></label>
                                    <input v-model="settingQuestion.description" type="text" class="form-control" :placeholder="$t('com.app.setting.key.description')" :disabled="loading" />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.quiz.setting.question.defaultValue') }}</strong></label>
                                    <input v-model="settingQuestion.defaultValue" type="text" class="form-control" :placeholder="$t('com.app.setting.key.defaultvalue')" :disabled="loading" />
                                </div>

                            </div>
                            <div class="col-md-6">

                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.quiz.setting.question.variabletype') }}</strong></label>
                                    <VueMultiselect v-model="settingQuestion.variableType"
                                                    :options="variableTypes"
                                                    :searchable="true"
                                                    :placeholder="$t('com.quiz.setting.question.variabletype')"
                                                    :multiple="false"
                                                    label="name"
                                                    track-by="value"
                                                    :disabled="loading"
                                                    :showLabels="false"
                                                    :allow-empty="false"
                                    />
                                    <pre class="language-json"><code>{{ settingQuestion.variableType }}</code></pre>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label"><strong>{{ $t('com.quiz.setting.question.isrequied') }}</strong></label>
                                    <VueMultiselect v-model="settingQuestion.isRequired"
                                                    :options="requiedOptions"
                                                    :searchable="true"
                                                    :placeholder="$t('com.quiz.setting.question.isrequied')"
                                                    :multiple="false"
                                                    :custom-label="translatedLabel"
                                                    label="name"
                                                    track-by="value"
                                                    :disabled="loading"
                                                    :showLabels="false"
                                                    :allow-empty="false"
                                    />
                                    <pre class="language-json"><code>{{ settingQuestion.isRequired }}</code></pre>
                                </div>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <pre class="language-json"><code>{{ settingQuestion }}</code></pre>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <button type="submit" class="btn btn-primary" :disabled="loading">
                                        <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        {{ $t('com.global.save') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
</template>

<script charset="utf-8">
    import PageTitle from "../../../../components/common/PageTitle.vue";
    import QuizSettingQuestionButtonNavigation from "../../../../components/quiz/setting/question/QuizSettingQuestionButtonNavigation.vue";
    import ErrorModalMessage from "../../../../components/common/error/ErrorModalMessage";
    import VueMultiselect from 'vue-multiselect'
    import VariableTypeService from "../../../../services/variabletype.service";
    import QuizSettingQuestionService from "../../../../services/quiz/quiz.setting.question.service";
    export default {
        components: {
            PageTitle,
            QuizSettingQuestionButtonNavigation,
            ErrorModalMessage,
            VueMultiselect

        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "Quiz Setting Question Create",
                subTitle: "com.quiz.setting.question",
                variableTypes: [],
                requiedOptions: [
                    { name: "com.global.yes", value: true },
                    { name: "com.global.no", value: false },
                ],
                settingQuestion: {
                    name: "",
                    description: "",
                    defaultValue: "",
                    variableType: {
                        name: "String",
                        value: 50
                    },
                    isRequired: {
                        name: "com.global.yes",
                        value: true,
                    },

                },

                error: {
                    isShow: false,
                    header: "test",
                    message: "",
                    errors: []
                },
                loading: false,
            };
        },
        computed: {

        },
        watch: {

        },
        mounted() {
            this.fetchVariableTypes();
        },
        methods: {
            async fetchVariableTypes() {
                try {
                    let res = (await VariableTypeService.GetAll());
                    if (res.status == 200) {
                        this.variableTypes = res.data;
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
            },

            async saveSettingQuestionKey() {
                try {
                    this.loading = true;

                    const req = {
                        name: this.settingQuestion.name,
                        description: this.settingQuestion.description,
                        defualtValue: this.settingQuestion.defaultValue,
                        isRequired: this.settingQuestion.isRequired.value,
                        type: this.settingQuestion.variableType.value
                    };

                    let res = (await QuizSettingQuestionService.Create(req));

                    if (res.status == 200) {
                        this.$toast.success(this.$t("com.quiz.setting.question.createsuccess"));
                    }
                }
                catch (e) {
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
                finally {
                    this.loading = false;
                }
            },

            translatedLabel(obj) {
                console.log(obj.name);
                if (obj == undefined)
                    return "test";

                return obj.name;
                //return this.$t(obj.name)
            }
        }
    }</script>

<style>
</style>
