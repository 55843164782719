<template>
    <PageTitle :mainTitle="mainTitle" :subTitle="subTitle" />
    <div id="customer-group-details" class="row">
        <div class="col-xl-4 col-lg-5">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ $t('com.global.details') }}</h4>
                    <div class="btn-group">
                        <router-link :to="{ name: 'GroupsBin' }" type="button" class="btn btn-primary">
                            {{ $t("com.global.bin") }}
                        </router-link>
                        <router-link :to="{ name: 'GroupsList' }" type="button" class="btn btn-primary">
                            {{ $t("com.global.list") }}
                        </router-link>
                        <router-link :to="{name: 'GroupCreate'}" type="button" class="btn btn-primary">
                            {{ $t('com.global.add') }}
                        </router-link>
                    </div>

                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-12">
                                    <h5>{{ $t('com.group.name') }}</h5>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12 ">
                                    {{ detailGroup.name }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <h5>{{ $t('com.group.i18nname') }}</h5>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12 ">
                                    {{ detailGroup.i18nName }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <h5>{{ $t('com.group.description') }}</h5>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12 ">
                                    {{ detailGroup.description }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <h5>{{ $t('com.group.i18ndescription') }}</h5>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12 ">
                                    {{ detailGroup.i18nDescription }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <h5>{{ $t('com.group.customer') }}</h5>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12 ">
                                    {{ detailGroup.customer.name }}
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

        <div class="col-xl-8 col-lg-7 ">
            <!-- Users Manager -->
            <div v-if="isUserCanSetGroupManager" class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ $t('com.group.usermanager') }}</h4>
                    <div class="btn-group">
                        <button type="submit" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addManagementUser">
                            <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            {{ $t('com.group.addmanageruser') }}
                        </button>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <table class="table table-responsive-md">
                                <thead>
                                    <tr>
                                        <th style="width:80px;"><strong>#</strong></th>
                                        <th><strong>{{ $t('com.app.setting.value.name') }}</strong></th>
                                        <th><strong>{{ $t('com.app.setting.value.author') }}</strong></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(user, index) in detailGroup.usersManager" :key="index">
                                        <td>{{ getIndex(index) }}</td>
                                        <td>{{ user.firstname }} {{ user.lastname }}</td>
                                        <td>{{ user.eMail }}</td>
                                        <td>
                                            <div class="dropdown">
                                                <button :id="getDropdownID(index)" type="button" class="btn btn-primary light sharp" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <circle fill="#000000" cx="5" cy="12" r="2" />
                                                            <circle fill="#000000" cx="12" cy="12" r="2" />
                                                            <circle fill="#000000" cx="19" cy="12" r="2" />
                                                        </g>
                                                    </svg>
                                                </button>
                                                <div class="dropdown-menu" :aria-labelledby="getDropdownID(index)">
                                                    <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#deleteManagerUser" @click="setUserManager(user)">{{ $t('com.global.delete') }}</a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
            <!-- /Users Manager -->
            <!-- Users -->
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ $t('com.group.users') }}</h4>
                    <div class="btn-group">
                        <button type="submit" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addUser">
                            <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            {{ $t('com.group.adduser') }}
                        </button>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <table class="table table-responsive-md">
                                <thead>
                                    <tr>
                                        <th style="width:80px;"><strong>#</strong></th>
                                        <th><strong>{{ $t('com.app.setting.value.name') }}</strong></th>
                                        <th><strong>{{ $t('com.app.setting.value.author') }}</strong></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(user, index) in detailGroup.users" :key="index">
                                        <td>{{ getIndex(index) }}</td>
                                        <td>{{ user.firstname }} {{ user.lastname }}</td>
                                        <td>{{ user.eMail }}</td>
                                        <td>
                                            <div class="dropdown">
                                                <button :id="getDropdownID(index)" type="button" class="btn btn-primary light sharp" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <circle fill="#000000" cx="5" cy="12" r="2" />
                                                            <circle fill="#000000" cx="12" cy="12" r="2" />
                                                            <circle fill="#000000" cx="19" cy="12" r="2" />
                                                        </g>
                                                    </svg>
                                                </button>
                                                <div class="dropdown-menu" :aria-labelledby="getDropdownID(index)">
                                                    <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#deleteUser" @click="setUser(user)">{{ $t('com.global.delete') }}</a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
            <!-- /Users-->
        </div>
    </div>
    <AddManagementUser v-if="isUserCanSetGroupManager"
                       :error="error"
                       :groupID="detailGroup.id"
                       :closeModal="closeModal.managementUser"
                       @add-manager="addUserManager"
    />

    <AddUser :error="error"
             :groupID="detailGroup.id"
             :closeModal="closeModal.user"
             @add="addUser"
    />

    <DeleteManagerUser v-if="isUserCanSetGroupManager"
                       :error="errorModalEdit"
                       :deletedItem="deletedItem"
                       :closeModal="closeModal.deleteManagement"
                       @unset-item="clearUserManagerData()"
                       @confirm-delete="deleteUserManager()"
    />

    <DeleteUser :error="errorModalEdit"
                :deletedItem="deletedItem"
                :closeModal="closeModal.delete"
                @unset-item="clearUserData()"
                @confirm-delete="deleteUser()"
    />

</template>

<script charset="utf-8">
    import PageTitle from "../../../components/common/PageTitle.vue";
    import GroupService from "../../../services/group.service"
    import AddManagementUser from "../../../components/client/group/AddManagementUser.vue"
    import AddUser from "../../../components/client/group/AddUser.vue"
    import DeleteManagerUser from "../../../components/client/group/DeleteManagerUser.vue"
    import DeleteUser from "../../../components/client/group/DeleteUser.vue"
    export default {
        components: {
            PageTitle,
            AddManagementUser,
            DeleteManagerUser,
            AddUser,
            DeleteUser
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                mainTitle: "Groups Detail",
                subTitle: "com.group.subtitle",
                detailGroup: {
                    id: "",
                    name: "",
                    i18nName: "",
                    description: "",
                    i18nDescription: "",
                    customer: {
                        id: "",
                        name: "",
                        description: "",
                        phone: "",
                        eMail: "",
                        citty: "",
                        street: "",
                        postalCode: "",
                        country: {
                            id: "",
                            abbr: "",
                            name: "",
                        },
                        language: {
                            culture: "",
                            description: "",
                            id: "",
                            name: "",
                            shortCulture: ""
                        }
                    },
                    users: [],
                    usersManager: []
                },
                error: {
                    isShow: false,
                    header: "test",
                    message: "",
                },
                errorModalEdit: {
                    isShow: false,
                    header: "test",
                    message: "",
                },
                deletedItem: "",
                closeModal: {
                    managementUser: false,
                    user: false,
                    deleteManagement: false,
                    delete: false,
                },
                selectUser: {},
                selectUserManager: {},
            };
        },
        computed: {
            groupID() {
                return this.$route.params.id;
            },
            isUserCanSetGroupManager() {
                return this.$store.state.auth.user.isUserCanSetGroupManager;
            }
        },
        watch: {

        },
        mounted() {
            this.fetchGroup();
        },
        methods: {
            async fetchGroup() {
                try {
                    this.$store.dispatch("group/getGroupByID", this.groupID).then(
                        (res) => {
                            this.detailGroup = res.data;
                            console.log("Detail Group Data", this.detailGroup)
                        },
                        (error) => {
                            console.log(error)
                            this.$toast.error(this.$t(error.data.message))
                        }
                    );
                }
                catch (err) {
                    console.log(err);
                }
            },

            async addUserManager(data) {
                try {
                    const req = {
                        groupID: this.detailGroup.id,
                        userID: data.user.id
                    }
                    console.log(req);

                    let res = (await GroupService.AssingUserManager(req));

                    if (res.status == 200) {
                        this.closeModal.managementUser = true;
                        this.clearUserManagerData();
                        this.fetchUsersManager();
                        //this.closeModal.managementUser = false
                    }

                }
                catch (e) {
                    //console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
            },

            async addUser(data) {
                try {
                    const req = {
                        groupID: this.detailGroup.id,
                        userID: data.user.id
                    }
                    console.log(req);

                    let res = (await GroupService.AssingUser(req));

                    if (res.status == 200) {
                        this.closeModal.user = true;
                        this.clearUserData();
                        this.fetchUsers();
                        //this.closeModal.managementUser = false
                    }

                }
                catch (e) {
                    //console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
            },

            async deleteUserManager() {
                try {
                    const req = {
                        groupID: this.detailGroup.id,
                        userID: this.selectUserManager.id
                    }

                    let res = (await GroupService.DeleteUserManager(req));

                    if (res.status == 200) {
                        this.closeModal.deleteManagement = true;
                        this.clearUserManagerData();
                        this.fetchUsersManager();
                    }
                }
                catch (e) {
                    //console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
            },

            async deleteUser() {
                try {
                    const req = {
                        groupID: this.detailGroup.id,
                        userID: this.selectUser.id
                    }

                    let res = (await GroupService.DeleteUser(req));

                    if (res.status == 200) {
                        this.closeModal.delete = true;
                        this.clearUserData();
                        this.fetchUsers();
                    }
                }
                catch (e) {
                    //console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
            },

            async fetchUsersManager() {
                try {
                    let res = (await GroupService.GetUsersManager(this.detailGroup.id))


                    this.closeModal.user = false;
                    //this.closeModal.deleteManagement = false;
                    if (res.status == 200) {
                        this.detailGroup.usersManager = res.data;
                    }
                }
                catch (e) {
                    //console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
            },

            async fetchUsers() {
                try {
                    let res = (await GroupService.GetUsers(this.detailGroup.id))


                    this.closeModal.user = false;
                    this.closeModal.delete = false;
                    if (res.status == 200) {
                        this.detailGroup.users = res.data;
                    }
                }
                catch (e) {
                    //console.log(e);
                    if (e.response.status == 401) {
                        this.$store.dispatch("auth/logout").then(
                            () => {
                                this.$router.push({ name: 'LoginView' })
                            },
                            (error) => {
                                console.log(error)
                            }
                        );
                    }
                }
            },

            getIndex(index) {
                let page = parseInt(this.$route.params.page);
                if (isNaN(page))
                    page = 1;

                let itemsPerPage = parseInt(this.$route.params.items);
                if (isNaN(itemsPerPage))
                    itemsPerPage = 0;

                return ((page - 1) * itemsPerPage) + index + 1
            },
            getDropdownID(str) {
                return "dropdownButton" + str;
            },

            setUser(user) {
                this.selectUser = user;
            },
            clearUserData() {
                this.selectUser = {}
            },

            setUserManager(user) {
                this.selectUserManager = user;
            },
            clearUserManagerData() {
                this.selectUserManager = {};
            }
        }
    }</script>

<style type="text/css">
    #customer-group-details .card {
        height: auto !important;
    }
</style>
